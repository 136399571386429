/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

import classNames from 'classnames'

import { ExclamationIcon } from '@heroicons/react/outline'

import useUser from '../../../hooks/use-user'

import { ReactComponent as ReloadIcon } from './assets/reload.svg'

import { capitalizeText } from '../../../utils'

import Button, { Props as ButtonProps } from '../../atoms/button'
import Message from '../../atoms/message'
import { feedbackMessage } from '../../atoms/feedback'

import { ConsumerBid, ConsumerChargeName, MatchedRate } from '../../../types'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'
import { transition } from '../../../styles'

import { ReactComponent as Active } from './assets/active.svg'
import { ReactComponent as Executed } from './assets/executed.svg'
import { ReactComponent as Withdrawn } from './assets/withdrawn.svg'
import { ReactComponent as UnderOffer } from './assets/underOffer.svg'

dayjs.extend(timezone)

/**
 * 30 minutes in milliseconds = 1.800.000
 */
const warnMinutesValue = 1800000

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

const parseTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  seconds %= 60
  minutes %= 60

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`
}

const ExpireTime: React.FC<{ expireAt: string }> = ({ expireAt }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'components.organisms.bidsTable',
  })

  const expireAtDate = dayjs(expireAt).tz('Europe/London')
  const [countDown, setCountDown] = useState(
    expireAtDate.valueOf() - dayjs().tz('Europe/London').valueOf(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(
        expireAtDate.valueOf() - dayjs().tz('Europe/London').valueOf(),
      )
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <ExclamationIcon
        className={classNames(
          transition,
          'w-5 h-5 mr-2 text-ppa/yellowWarning',
          countDown <= 0 && 'text-ppa/redError',
          countDown < warnMinutesValue ? 'opacity-1' : 'opacity-0',
        )}
      />

      {countDown <= 0 ? t('expired') : parseTime(countDown)}
    </>
  )
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'ACTIVE':
      return <Active />
    case 'EXECUTED':
      return <Executed />
    case 'WITHDRAWN':
      return <Withdrawn />
    case 'UNDER_OFFER':
      return <UnderOffer />
    default:
      return <Active />
  }
}

const defaultStyles = {
  eachRow:
    'flex min-h-[45px] min-w-[235px] px-1.5 text-ppa/grayText border-b border-b-ppa/grayBorder bg-white',
  titleRow: 'bg-ppa/grayBackground',
  selectedColumn:
    'border-l-2 border-l-ppa/secondarySelected border-r-2 border-r-ppa/secondarySelected',
}

const mappedPriceTypes: Record<string, string> = {
  pence_day: 'p/day',
  pound_day: '£/day',
  pound_month: '£/month',
  pence_kva_day: 'p/kVA/day',
  pound_kva_day: '£/kVA/day',
  pound_kva_month: '£/kVA/month',
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: (selectedBidId: string) => Promise<void>
}

interface TableBidsProps {
  bids?: ConsumerBid[]
  tenderStatus?: string
  error?: any
  refetch: () => void
  isLoading?: boolean
  buttons?: ButtonList[]
  enableSelectingBids?: boolean
}

const TableConsumerBids = ({
  bids,
  tenderStatus,
  error,
  isLoading,
  refetch,
  buttons,
  enableSelectingBids = false,
}: TableBidsProps) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.tenders.details.bids.table',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { user } = useUser<'CONSUMER' | 'ADMIN'>()

  const navigate = useNavigate()

  const [selectedBid, setSelectedBid] = useState<string | null>(null)

  const canSelectBids = useMemo(() => {
    return enableSelectingBids && buttons && buttons.length > 0
  }, [bids])

  const handleBidSelection = (bidId: string) => {
    if (enableSelectingBids && bidId) {
      setSelectedBid(selectedBid === bidId ? null : bidId)
    }
  }

  const getChargeValue = (
    charges: ConsumerBid['consumerBidCharges'],
    chargeName: ConsumerChargeName,
  ) => {
    const bidCharge = charges.find((charge) => charge.name === chargeName)
    return bidCharge
      ? `${bidCharge.value} ${mappedPriceTypes[bidCharge.type] || ''}`
      : '-'
  }

  const getRate = (
    rates: MatchedRate[],
    rateType: 'DAY_RATE' | 'NIGHT_RATE',
    matchType: 'MATCHED' | 'UNMATCHED',
  ): string => {
    const rate = rates.find(
      (bidRate) =>
        bidRate.rateType === rateType && bidRate.matchType === matchType,
    )
    return rate ? `${rate.value} p/kWh` : '-'
  }

  return (
    <div className="flex flex-col w-full min-w-[850px] overflow-y-hidden gap-y-6 pb-4">
      <div className="flex flex-col gap-y-3">
        <div className="flex">
          <h1 className="text-2xl font-medium text-ppa/title">{t('title')}</h1>
          <button
            type="button"
            onClick={() => {
              refetch()
              setSelectedBid(null)
            }}
            className="ml-2"
          >
            <ReloadIcon />
          </button>
        </div>
        {tenderStatus === 'ACTIVE' && (
          <Message variant="info" size="text-sm">
            {t('info.0')}
            <br />
            {t('info.1')}
          </Message>
        )}
      </div>

      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoading && !bids && (
        <strong className="text-lg font-medium text-ppa/title ml-6">
          {t('noBidsFound')}
        </strong>
      )}

      {!isLoading && bids && bids.length > 0 && (
        <div className="flex flex-col gap-y-5 fade-in">
          <section className="flex flex-col gap-y-5">
            <article className={classNames('flex overflow-x-auto')}>
              <div className="flex flex-col fixed z-50 bg-white w-[280px]">
                <div
                  className={classNames(
                    defaultStyles.eachRow,
                    defaultStyles.titleRow,
                  )}
                />

                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start border-y-ppa/grayBorder w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('bidsExpiresIn')}</span>
                </div>

                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start border-t border-t-ppa/grayBorder w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('contractType')}</span>
                </div>

                <div
                  className={classNames(
                    'text-ppa/grayText py-2 flex text-sm flex-col items-start justify-start gap-y-1 border-t border-t-ppa/grayBorder w-full h-24',
                  )}
                >
                  <span className="font-normal">{t('dayRate.title')}</span>
                  <span className="font-light text-xs">
                    {t('dayRate.smallTitle')}
                  </span>
                  <span className="font-light">{t('dayRate.matched')}</span>
                  <span className="font-light">{t('dayRate.unmatched')}</span>
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm py-2 flex flex-col items-start justify-start gap-y-1 border-t border-t-ppa/grayBorder w-full h-24',
                  )}
                >
                  <span className="font-normal">{t('nightRate.title')}</span>
                  <span className="font-light text-xs">
                    {t('nightRate.smallTitle')}
                  </span>
                  <span className="font-light">{t('dayRate.matched')}</span>
                  <span className="font-light">{t('dayRate.unmatched')}</span>
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start border-t border-t-ppa/grayBorder w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('forecastMatch')}</span>
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start border-t border-t-ppa/grayBorder w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('capacityCharge')}</span>
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start border-t border-t-ppa/grayBorder w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('standingCharge')}</span>
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start w-full h-12',
                    user.appMetadata.userType === 'ADMIN' &&
                      bids.some((bid) => bid.contractType === 'SLEEVED')
                      ? 'border-t border-t-ppa/grayBorder'
                      : 'border-y border-y-ppa/grayBorder',
                  )}
                >
                  <span className="font-normal">{t('greenContract')}</span>
                </div>
                {user.appMetadata.userType === 'ADMIN' &&
                  bids.some((bid) => bid.contractType === 'SLEEVED') && (
                    <div
                      className={classNames(
                        'text-ppa/grayText text-sm font-normal flex items-start justify-start border-t border-t-ppa/grayBorder w-full',
                      )}
                    >
                      <span className="font-normal pt-3.5">
                        {t('sleevedTenders')}
                      </span>
                    </div>
                  )}
              </div>

              {bids.map((bid, bidIdx) => (
                <div
                  key={bid.id}
                  className={classNames(
                    'flex flex-col',
                    bidIdx === 0 && 'ml-[280px]',
                  )}
                >
                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'items-center justify-end gap-y-3',
                      selectedBid === bid.id &&
                        `${defaultStyles.selectedColumn} border-t-2 border-t-ppa/secondarySelected rounded-t`,
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      ['ACTIVE', 'ACCEPTED'].includes(bid.status) &&
                        defaultStyles.titleRow,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span
                      className={classNames(
                        'font-normal text-sm text-ppa/grayText',
                      )}
                    >
                      {bid.consumerTenderInvitation.supplier.name}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-[49px]',
                      'text-ppa/grayText text-sm font-light flex items-center justify-end px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <ExpireTime expireAt={bid.expiresAt} />
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-12',
                      'text-ppa/grayText text-sm font-normal flex flex-col items-end justify-center px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>
                      {bid.contractType && capitalizeText(bid.contractType)}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-24',
                      'text-ppa/grayText text-xs font-light flex flex-col items-end justify-center gap-y-1 border-b border-b-ppa/grayBorder pt-7',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>{bid.dayRate ? `${bid.dayRate} p/kWh` : '-'}</span>
                    <span className="text-sm">
                      {bid.matchedRates
                        ? getRate(bid.matchedRates, 'DAY_RATE', 'MATCHED')
                        : '-'}
                    </span>
                    <span className="text-sm">
                      {bid.matchedRates
                        ? getRate(bid.matchedRates, 'DAY_RATE', 'UNMATCHED')
                        : '-'}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-24',
                      'text-ppa/grayText text-sm font-light flex flex-col items-end justify-center gap-y-1 border-b border-b-ppa/grayBorder pt-7',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>
                      {bid.nightRate ? `${bid.nightRate} p/kWh` : '-'}
                    </span>
                    <span className="text-sm">
                      {bid.matchedRates
                        ? getRate(bid.matchedRates, 'NIGHT_RATE', 'MATCHED')
                        : '-'}
                    </span>
                    <span className="text-sm">
                      {bid.matchedRates
                        ? getRate(bid.matchedRates, 'NIGHT_RATE', 'UNMATCHED')
                        : '-'}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-12',
                      'text-ppa/grayText text-sm font-light flex items-center justify-end px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>
                      {bid.forecastMatch
                        ? `${Number(bid.forecastMatch) * 100} %`
                        : '-'}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      'cursor-pointer',
                      'min-w-[235px] h-12',
                      'text-ppa/grayText text-sm font-light flex items-center justify-end px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>
                      {getChargeValue(
                        bid.consumerBidCharges,
                        'CAPACITY_CHARGE',
                      )}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-12',
                      'text-ppa/grayText text-sm font-light flex items-center justify-end px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBid === bid.id && defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>
                      {getChargeValue(
                        bid.consumerBidCharges,
                        'STANDING_CHARGE',
                      )}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-12',
                      'text-ppa/grayText text-sm font-light flex items-center justify-end px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      user.appMetadata.userType !== 'ADMIN'
                        ? selectedBid === bid.id &&
                            `${defaultStyles.selectedColumn} border-b-2 border-b-ppa/secondarySelected rounded-b`
                        : selectedBid === bid.id &&
                            defaultStyles.selectedColumn,
                    )}
                    onClick={() => handleBidSelection(bid.id)}
                  >
                    <span>{bid.greenContract ? 'Yes' : 'No'}</span>
                  </div>

                  {user.appMetadata.userType === 'ADMIN' &&
                    bid.contractType === 'SLEEVED' && (
                      <div
                        className={classNames(
                          transition,
                          'cursor-pointer',
                          'min-w-[235px] h-auto',
                          'text-ppa/grayText text-sm font-light flex items-start justify-end px-1.5 gap-y-1',
                          bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                          bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                          selectedBid === bid.id &&
                            `${defaultStyles.selectedColumn} border-b-2 border-b-ppa/secondarySelected rounded-b`,
                        )}
                        onClick={() => handleBidSelection(bid.id)}
                      >
                        <div className="flex flex-col items-start gap-1.5 py-2 overflow-y-auto max-h-full mt-0.5">
                          {bid.consumerTenderInvitation.consumerTender &&
                            bid.consumerTenderInvitation.consumerTender.supplierTenders.map(
                              (supplierTender) => (
                                <div
                                  key={supplierTender.id}
                                  className="flex items-center gap-2"
                                >
                                  {getStatusIcon(supplierTender.status)}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      navigate(
                                        `/sleevedTenders/${supplierTender.id}`,
                                      )
                                    }
                                    className="text-ppa/grayText underline"
                                  >
                                    {supplierTender.shortId}
                                  </button>
                                </div>
                              ),
                            )}
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </article>
          </section>

          {user.appMetadata.userType === 'ADMIN' &&
            bids.some((bid) => bid.contractType === 'SLEEVED') && (
              <div className="border-t border-t-ppa/grayBorder -mt-3 w-[515px]">
                <div className="flex gap-x-5 text-ppa/grayText text-sm font-light mt-2">
                  <div className="flex items-center gap-2">
                    <Active /> {t('tenderStatus.active')}
                  </div>
                  <div className="flex items-center gap-2">
                    <Withdrawn /> {t('tenderStatus.withdrawn')}
                  </div>
                  <div className="flex items-center gap-2">
                    <UnderOffer /> {t('tenderStatus.underOffer')}
                  </div>
                  <div className="flex items-center gap-2">
                    <Executed /> {t('tenderStatus.executed')}
                  </div>
                </div>
              </div>
            )}

          <div className="flex items-center justify-start gap-x-5 w-full mt-5">
            {canSelectBids &&
              buttons &&
              buttons.map((item) => (
                <Button
                  {...item.props}
                  key={item.text}
                  disabled={!selectedBid}
                  onClick={async () => {
                    if (!selectedBid) {
                      feedbackMessage(
                        {
                          title: tUtils('feedbackMessage.warn.title'),
                          description: t('noBidsSelected'),
                        },
                        'warn',
                      )
                      return
                    }
                    await item.onClick(selectedBid)
                  }}
                >
                  {item.text}
                </Button>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default TableConsumerBids
