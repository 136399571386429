import React from 'react'
import { sleevedTendersSubRoutes } from './routes'

interface TendersProps {
  status: keyof typeof sleevedTendersSubRoutes
}

const SleevedTenders: React.FC<TendersProps> = ({ status }) => {
  return <div>{status && sleevedTendersSubRoutes[status].index}</div>
}

export default SleevedTenders
