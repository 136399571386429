import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import usePPA from '../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import { SleevedTenderDetailsProps } from '../details'

import TenderDetails, { ButtonList } from '../components/details'
import SleevedConsumerTenderDetailsDefList from '../components/details-consumer-tender'
import MeterDetailsDefList from '../components/details-meter'
import AcceptInvitationModal from '../components/accept-invitation-modal'

const InvitedSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isAccepting, setIsAccepting] = useState(false)
  const [isDeclining, setIsDeclining] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleAcceptInvitation = async () => {
    if (isAccepting) return
    setIsAccepting(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier-tender/supplier/accept-invitation',
        body: { supplierTenderInvitationId: tenderInvitation.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.acceptInvitation.success'),
        },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplierTenderInvitation',
      })
    } finally {
      setIsAccepting(false)
      setIsModalOpen(false)
    }
  }

  const handleDeclineInvitation = async () => {
    if (isDeclining) return
    setIsDeclining(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier-tender/supplier/reject-invitation',
        body: { supplierTenderInvitationId: tenderInvitation.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.declineInvitation.success'),
        },
        'success',
      )

      navigate('/sleevedTenders/status/pending')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplierTenderInvitation',
      })
    } finally {
      setIsDeclining(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    return [
      {
        text: t('actions.acceptInvitation.button'),
        onClick: () => setIsModalOpen(true),
        props: { variant: 'primary', loading: isAccepting },
      },
      {
        text: t('actions.declineInvitation.button'),
        onClick: handleDeclineInvitation,
        props: { variant: 'secondary', loading: isDeclining },
      },
    ]
  }, [t, isAccepting, isDeclining])

  return (
    <section className="flex gap-8 w-full">
      <div className="flex-1">
        <TenderDetails tenderInvitation={tenderInvitation} buttons={buttons} />
      </div>
      <div className={classNames('flex flex-col', '2xl:flex-row')}>
        <div
          className={classNames(
            'flex flex-col flex-1 gap-y-4',
            'border-ppa/grayBorder',
            'border-t mt-10 pt-10',
            '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          )}
        >
          <SleevedConsumerTenderDetailsDefList
            data={tenderInvitation.supplierTender.consumerTender as any}
          />
          <MeterDetailsDefList
            data={
              tenderInvitation.supplierTender.consumerTender
                .consumerMeter as any
            }
          />
        </div>
      </div>
      <AcceptInvitationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAcceptInvitation={handleAcceptInvitation}
      />
    </section>
  )
}

export default InvitedSleevedTenderDetails
