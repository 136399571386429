import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { SupplierTenderBid } from '../../../../../types'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import SupplierTableBids, {
  ButtonList,
} from '../../../../../components/organisms/supplier-bids-table'

import TenderDetails from '../components/details'

import { SleevedTenderDetailsProps } from '../details'

const UnderOfferSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.sleevedTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const bidGetList = usePPAGetList<SupplierTenderBid>({
    dataKey: 'supplierTenderBids',
    path: `/core/private/supplier-tender-bid/admin/list/${tender.id}`,
  })

  const [isLoadingTenderAction, setIsLoadingTenderAction] = useState(false)

  const handleExecuteTender = async () => {
    if (isLoadingTenderAction) return
    setIsLoadingTenderAction(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/supplier-tender/admin/execute/${tender.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleExecuteTender.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'supplierTender',
      })
    } finally {
      setIsLoadingTenderAction(false)
    }
  }

  const handleRevertTender = async () => {
    if (isLoadingTenderAction) return
    setIsLoadingTenderAction(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/supplier-tender/admin/revert/${tender.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleRevertTender.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'supplierTender',
      })
    } finally {
      setIsLoadingTenderAction(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    if (!tender) return undefined

    return [
      {
        props: { variant: 'secondary' },
        onClick: () => handleRevertTender(),
        text: t('buttons.revert'),
      },
      {
        props: { variant: 'primary' },
        onClick: () => handleExecuteTender(),
        text: t('buttons.execute'),
      },
    ]
  }, [tender])

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} />
      <SupplierTableBids
        tender={tender}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
        buttons={buttons}
        enableSelectingBids
      />
    </section>
  )
}

export default UnderOfferSleevedTenderDetails
