import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { parseContractDuration } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'

import { dateFormats } from '../../../../../../utils/data'

import {
  SupplierTenderInvitation,
  TenderSubsidyName,
} from '../../../../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

interface TenderDetailsListSleevedProps {
  data: SupplierTenderInvitation['supplierTender']
}

const SleevedTenderDetailsDefList: React.FC<TenderDetailsListSleevedProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.defList',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('titles.contractDuration'),
        renderCustomEl: (item: SupplierTenderInvitation['supplierTender']) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('titles.deadline'),
        renderCustomEl: (item: SupplierTenderInvitation['supplierTender']) => {
          if (!item.deadline) return undefined
          return (
            <div className="flex text-center">
              {dayjs
                .utc(item.deadline)
                .tz('Europe/London')
                .format(dateFormats.userDateTime)}
            </div>
          )
        },
      },
      {
        keyName: 'generator',
        title: t('titles.generator'),
        renderCustomEl: (item: SupplierTenderInvitation['supplierTender']) => {
          if (!item?.generator) return undefined
          return item.generator.name
        },
      },
      {
        keyName: 'supplierTenderGreenCertificates',
        title: t('titles.greenCertificates.title'),
        renderCustomEl: (item: SupplierTenderInvitation['supplierTender']) => {
          if (!item.supplierTenderGreenCertificates.length) return undefined

          const toggledGreenCertificatesNames: TenderSubsidyName[] =
            item.supplierTenderGreenCertificates
              .filter(({ toggled }) => toggled)
              .map(({ name }) => t(`titles.greenCertificates.${name}`))

          return toggledGreenCertificatesNames.length > 0
            ? toggledGreenCertificatesNames.join(', ')
            : '-'
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('title')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default SleevedTenderDetailsDefList
