import React, { useState, useEffect } from 'react'

import { useDebounce } from 'use-debounce'

import { ReactComponent as SearchIcon } from './assets/search.svg'

interface SearchProps {
  onSearchChange: (search?: string) => void
}

const Search: React.FC<SearchProps> = ({ onSearchChange }) => {
  const [searchText, setSearchText] = useState<string>('')
  const [debouncedSearch] = useDebounce(searchText, 500)

  useEffect(() => {
    onSearchChange(debouncedSearch?.trim().toLowerCase())
  }, [debouncedSearch])

  return (
    <div className="flex w-full gap-x-2 items-center text-sm font-medium">
      <SearchIcon />
      <input
        type="text"
        placeholder="Search..."
        value={searchText}
        onChange={(e) => setSearchText(e.target?.value || '')}
        className="w-full border border-transparent"
      />
    </div>
  )
}

export default Search
