import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import DefList from '../../../../../../components/atoms/def-list'

/**
 * @FIXME
import { SupplierProfile } from '../../../../../../types'
 */
type SupplierProfile = any

interface SupplierProfileDetailsProps {
  data?: SupplierProfile
}

const SupplierProfileDetails: React.FC<SupplierProfileDetailsProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.supplierProfileDefList.titles',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'description',
        title: t('description'),
      },
      {
        keyName: 'totalCapacity',
        title: t('renewableEnergyUnderOperation'),
        renderCustomEl: (supplier: SupplierProfile) => {
          return supplier?.totalCapacity
        },
        suffix: 'MW',
      },
      {
        keyName: 'backing',
        title: t('financialBacking.title'),
        renderCustomEl: (supplier: SupplierProfile) => {
          const backingKeys = supplier?.backing
          if (backingKeys && backingKeys.length > 0) {
            const backingTranslations = backingKeys.map((key: any) =>
              t(`financialBacking.options.${key}`),
            )
            return backingTranslations.join(', ')
          }
          return ''
        },
      },
      {
        keyName: 'lenders',
        title: t('directlyAuthorisedLenders'),
        renderCustomEl: (supplier: SupplierProfile) => {
          return (supplier?.lenders || []).join(', ')
        },
      },
      {
        keyName: 'other',
        title: t('marketingText'),
      },
    ]
  }, [data])

  return <DefList rowKeys={rowKeys} data={data} />
}

export default SupplierProfileDetails
