import React from 'react'
import { useTranslation } from 'react-i18next'

import Pill, { variants } from '../../../../components/atoms/pill'
import { UserStatus } from '../../../../types/user'
import { Children } from '../../../../types'

type StatusToVariant = Record<UserStatus, keyof typeof variants>

const statusToVariant: StatusToVariant = {
  ACTIVE: 'green',
  ONBOARDING: 'orange',
  REVIEW: 'yellow',
  DELETED: 'red',
}

interface UserPillsProps extends Children {
  status: keyof typeof statusToVariant
  blocked?: boolean
}

const UserPill: React.FC<UserPillsProps> = ({ status, blocked }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'utils.userStatus',
  })

  return (
    <Pill variant={blocked ? 'red' : statusToVariant[status]}>
      {t(blocked ? 'blocked' : status)}
    </Pill>
  )
}

export default UserPill
