import React, { useEffect } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { generatePeriods } from '../utils'

import { dateFormats, priceTypeList } from '../../../../../../../utils/data'

import {
  PowerPriceName,
  PowerPriceNameWithMultiple,
  Bid,
} from '../../../../../../../types'

import Input from '../../../../../../../components/atoms/input'
import Button from '../../../../../../../components/atoms/button'
import Message from '../../../../../../../components/atoms/message'
import SelectComponent, {
  SelectOptions,
} from '../../../../../../../components/atoms/select'

import InputGroup from '../../../../../../../components/molecules/input-group'

import { TenderInvitation } from '../../details'
import CustomPricingForm from '../form-custom-pricing'
import SubsidiesForm from '../form-subsidies'
import BenefitsForm from '../form-benefits'
import ChargesForm from '../form-charges'

interface FormPeriods {
  id: string
  periodId: string
  startDate: string
  endDate: string
  value?: string
  priceType: SelectOptions<PowerPriceName>
  title?: string
}

export type FormData = {
  contractType: SelectOptions<PowerPriceNameWithMultiple>
  periods: FormPeriods[]
  expiresAt: string
}

interface FormProps {
  tender: TenderInvitation['tender']
  bid?: Bid
  defaultValues: FormData
  brokerFee: number
  onSubmit: (data: any) => void
  isLoading: boolean
}

const BidForm: React.FC<FormProps> = ({
  tender,
  bid,
  defaultValues,
  brokerFee,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.bids.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const methods = useForm<FormData>({ defaultValues })

  const {
    formState: { errors: formErrors, isSubmitting },
    control,
    watch,
    setValue,
  } = methods

  useEffect(() => {
    const contractType = watch('contractType')

    if (contractType.value === 'MULTIPLE_TYPES') return

    const periods = generatePeriods(
      tender.startDate,
      tender.endDate,
      contractType,
      true,
    )

    setValue(
      'periods',
      periods.map((period) => {
        return {
          ...period,
          startDate: dayjs(period.startDate).format(dateFormats.aws),
          endDate: dayjs(period.endDate).format(dateFormats.aws),
          priceType: {
            value: period.priceType.value,
            label: tUtils(`priceType.${period.priceType.label}`),
          },
          value: '0',
        }
      }),
    )
  }, [watch('contractType')])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="sm:max-w-[650px]"
      >
        <h1 className="text-xl text-ppa/title font-bold mb-5">
          {bid ? t('UpdateBidTitle') : t('placeBidTitle')}
        </h1>
        <div className="border-l border-ppa/primary mb-5">
          <span className="text-ppa/primary text-base font-semibold ml-3">
            {tender.shortId}
          </span>
        </div>
        <div className="flex flex-col gap-y-4">
          <Controller
            name="contractType"
            control={control}
            rules={{
              required: {
                message: t('fields.contractType.required'),
                value: true,
              },
            }}
            render={({ field: { ref: _, ...props } }) => (
              <InputGroup
                label={t('fields.contractType.label')}
                required
                error={formErrors.contractType?.message}
              >
                <div className="w-full max-w-[320px]">
                  <SelectComponent
                    {...props}
                    error={formErrors.contractType?.message}
                    type="single"
                    disableClean
                    options={priceTypeList.map((item) => {
                      return {
                        ...item,
                        label: tUtils(`priceType.${item.label}`),
                      }
                    })}
                    aria-label="priceType"
                    testId="select-priceType"
                  />
                </div>
              </InputGroup>
            )}
          />

          <CustomPricingForm
            tender={tender}
            contractType={watch('contractType')}
            startDate={tender.startDate}
            endDate={tender.endDate}
          />

          <BenefitsForm bid={bid} />
          <SubsidiesForm tender={tender} bid={bid} />
          <ChargesForm bid={bid} />

          <div className="border border-b border-ppa/grayBorder w-full" />
          <InputGroup label={t('fields.expiresAt.label')}>
            <Message variant="info" size="text-xs self-center">
              {t('fields.expiresAt.infoMessage')}
            </Message>
          </InputGroup>
          <div className="flex items-start w-72 -mt-3 mb-4 gap-x-3">
            <Controller
              name="expiresAt"
              control={control}
              rules={{ required: t('fields.expiresAt.required') }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('fields.expiresAt.title')}
                  error={formErrors.expiresAt?.message}
                  required
                >
                  <Input
                    type="datetime-local"
                    {...props}
                    min={dayjs().format(dateFormats.dateInput)}
                    error={formErrors.expiresAt?.message}
                  />
                </InputGroup>
              )}
            />
          </div>
        </div>
        <div className="border border-b border-ppa/grayBorder w-full" />
        <div className="flex justify-end mt-5">
          <div>
            <InputGroup label={t('fields.charges.brokerFee.label')}>
              <Message variant="info" size="text-xs self-center">
                {t('fields.charges.brokerFee.infoMessage')}
              </Message>
              <div className="w-60 mt-1 ml-[0.2px]">
                <Input
                  readOnly
                  disabled
                  type="number"
                  suffix="£/MWh"
                  disabledInput
                  value={Number(brokerFee).toFixed(2)}
                />
              </div>
            </InputGroup>
          </div>
        </div>
        <div className="border border-b border-ppa/grayBorder w-full my-3" />
        <div className="flex justify-end">
          <Message variant="warn" size="text-xs mt-1">
            {t('fields.submit.infoMessage')}
          </Message>
        </div>
        <div className="flex justify-end mt-2">
          <Button
            variant="primary"
            type="submit"
            loading={isLoading}
            disabled={isSubmitting}
            formWidth
          >
            {t('fields.submit.button')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default BidForm
