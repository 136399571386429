import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { parseSnippetAddress } from '../../../utils'

import { Company } from '../../../types'

import Button from '../../atoms/button'
import Modal from '../../molecules/modal'
import DefList from '../../atoms/def-list'

interface CompanyDefListModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  companyData: Company
}

const CompanyDefListModal: React.FC<CompanyDefListModalProps> = ({
  isOpen,
  onClose,
  title,
  companyData,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'companyDetailsModal',
  })

  const rowKeysCompany = useMemo(() => {
    return [
      {
        keyName: 'number',
        title: t('defList.company.number'),
        renderCustomEl: (consumerCompany: Company) => {
          return consumerCompany.number
        },
      },
      {
        keyName: 'type',
        title: t('defList.company.type'),
        renderCustomEl: (consumerCompany: Company) => {
          return consumerCompany.type
        },
      },
      {
        keyName: 'address',
        title: t('defList.company.address'),
        renderCustomEl: (consumerCompany: Company) => {
          return parseSnippetAddress(consumerCompany.address)
        },
      },
      {
        keyName: 'billingAddress',
        title: t('defList.company.billingAddress'),
        renderCustomEl: (consumerCompany: Company) => {
          return parseSnippetAddress(consumerCompany.billingAddress)
        },
      },
    ]
  }, [companyData])

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <div className="w-[670px] mx-8">
        <h1 className="text-3xl text-ppa/title font-semibold mt-10 mb-6">
          {title}
        </h1>
        <div className="flex flex-col gap-y-3">
          {/* <CompanyLogo variant="gray" /> */}
          <DefList rowKeys={rowKeysCompany} data={companyData} />
          <div className="flex self-end mt-2 mb-8 w-24">
            <Button variant="secondary" onClick={onClose} fullWidth>
              {t('closeButton')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CompanyDefListModal
