import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import { PencilIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPA from '../../../../../hooks/use-ppa'

import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import { Company, Address } from '../../../../../types'

import { parseSnippetAddress } from '../../../../../utils'

import { container, breakPointsContainer } from '../../../../../styles'
import { ReactComponent as Spinner } from '../../../../../assets/spinner/spinner.svg'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import DefList from '../../../../../components/atoms/def-list'
import Dropdown from '../../../../../components/atoms/dropdown'
import Button from '../../../../../components/atoms/button'

import EditCompany from './edit-company'

/**
 * @TODO add Bill data table with upload bill action when BE and design are ready!
 */

const MeterCompanyDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.companyList.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { fetchData } = usePPA()

  const { companyId } = useParams<{ companyId: string }>()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<Company>({
      dataKey: 'company',
      path: `/core/private/consumer/company/${companyId}`,
    })

  const companyDetails: Company | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'name', title: t('defList.name') },
      { keyName: 'number', title: t('defList.number') },
      {
        keyName: 'address',
        title: t('defList.address'),
        renderCustomEl: (item: Company) => {
          return parseSnippetAddress(item?.address as Address | undefined)
        },
      },
    ]
  }, [companyDetails])

  const handleEditCompany = () => {
    refetch()
    setIsEditDrawerOpen(false)
  }

  const handleDeleteCompany = async () => {
    if (isLoading) return

    try {
      const { response, error: err } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer/company/${companyId}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('actionsDropdown.delete.success'),
        },
        'success',
      )

      navigate('/meters')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerCompanyId',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes(
              'Deleting a company associated to a site is not allowed.',
            ) &&
            message.includes('ConsumerSites:')
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.consumerCompany.SERVER_ERROR.DELETE_ONLY_COMPANY.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerCompany.SERVER_ERROR.DELETE_ONLY_COMPANY.description',
              ),
              type: 'error',
            }
          }
          if (
            message.includes(
              'Site cannot be deleted when associated with any tenders.',
            )
          ) {
            const [_, consumerSites] = message.split('ConsumerSites: ')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.consumerCompany.SERVER_ERROR.DELETE_ASSOCIATED_TO_SITE.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.consumerCompany.SERVER_ERROR.DELETE_ASSOCIATED_TO_SITE.description',
              )}\n${consumerSites}.`,
              type: 'error',
              duration: 8000,
            }
          }

          return undefined
        },
      })
    }
  }

  useEffect(() => {
    if (!companyId) {
      navigate('/meters/companies')
    }
  }, [companyId])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return (
    <div>
      <section
        className={classNames(
          container,
          breakPointsContainer,
          'items-center pt-12 relative',
        )}
      >
        <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
          <Button
            variant="text"
            icon={<ChevronLeftIcon />}
            onClick={() => navigate('/meters/companies')}
          >
            {t('backButton')}
          </Button>
        </div>
        {isLoading && (
          <div className="w-full">
            <Spinner className="mx-auto animate-spin w-5 h-5" />
          </div>
        )}

        {!isLoading && !error && companyDetails && (
          <>
            <div className="flex items-center justify-between w-full">
              <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
                {companyDetails.name}
              </h1>
              <Dropdown
                content={
                  <div className="flex flex-col justify-start items-start">
                    <Button
                      variant="text"
                      icon={<PencilIcon />}
                      onClick={() => {
                        setIsEditDrawerOpen(true)
                      }}
                    >
                      {t('actionsDropdown.edit')}
                    </Button>
                    <Button
                      variant="text"
                      icon={<PencilIcon />}
                      onClick={handleDeleteCompany}
                    >
                      {t('actionsDropdown.delete')}
                    </Button>
                  </div>
                }
              />
            </div>
            <DefList rowKeys={rowKeys} data={companyDetails} />
            <EditCompany
              company={companyDetails}
              onSuccess={handleEditCompany}
              isOpen={isEditDrawerOpen}
              handleClose={() => setIsEditDrawerOpen(false)}
            />
          </>
        )}
      </section>
    </div>
  )
}

export default MeterCompanyDetails
