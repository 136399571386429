import React, { useEffect } from 'react'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { SupplierTenderBid } from '../../../../../types'

import SupplierTableBids from '../../../../../components/organisms/supplier-bids-table'

import TenderDetails from '../components/details'

import { SleevedTenderDetailsProps } from '../details'

const ExecutedSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
}) => {
  const bidGetList = usePPAGetList<SupplierTenderBid>({
    dataKey: 'supplierTenderBids',
    path: `/core/private/supplier-tender-bid/generator/list/${tender.id}`,
  })

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} />
      <SupplierTableBids
        tender={tender}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
      />
    </section>
  )
}

export default ExecutedSleevedTenderDetails
