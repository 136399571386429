import React from 'react'
import { ReactComponent as ExclamationIcon } from './assets/ExclamationSVG.svg'

import { Children } from '../../../types'

export interface InputGroupProps extends Children {
  label: string | JSX.Element
  infoLabel?: string
  warnLabel?: string
  infoLink?: JSX.Element
  required?: boolean
  error?: string
  hideErrorMessage?: boolean
}

const InputGroup: React.FC<InputGroupProps> = ({
  label,
  infoLabel,
  warnLabel,
  infoLink,
  required = false,
  children,
  error,
  hideErrorMessage = false,
}) => {
  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col mb-1.5">
        <span className="text-ppa/subtitle text-sm font-medium w-full flex">
          {label}
          {required && <span className="text-ppa/formStar ml-1">*</span>}
          {infoLink}
        </span>

        {infoLabel && (
          <span className="text-ppa/subtitle text-xs font-light flex items-start">
            {infoLabel}
          </span>
        )}
        {warnLabel && (
          <span className="text-ppa/subtitle text-xs font-light mt-1.5 flex items-start fade-in">
            {warnLabel}
          </span>
        )}
      </div>
      {children}
      {!hideErrorMessage && error && (
        <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
          <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
          <span className="text-xs font-light text-ppa/warning">{error}</span>
        </span>
      )}
    </div>
  )
}

export default InputGroup
