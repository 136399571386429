import React from 'react'

import classNames from 'classnames'

import { TenderDetailsSupplierProps } from '../details'

import TenderDetails from '../components/details'
import BidDetails from '../components/details-bid'

const TendersUnderOfferDetails: React.FC<TenderDetailsSupplierProps> = ({
  tenderInvitation,
  refetch,
}) => {
  /**
   *
   * @TODO Add a create bid logic here and work on the BID FLOW

  const handleSeeBid = () => {
    console.log('SEE BID')

  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    if (invitationData?.bid && invitationData?.bid?.status === 'EXECUTED') {
      return [
        isFeatureEnabled('supplierSeeBidButton') && {
          onClick: handleSeeBid,
          props: { variant: 'primary' },
          text: t('actions.seeBid.button'),
        },
      ]
    }

    return undefined
  }, [invitationData])
  */

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex-1">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>
      <div
        className={classNames(
          'border-ppa/grayBorder',
          'border-t mt-10 pt-10',
          'lg:border-t-0 lg:border-l lg:mt-0 lg:pt-0 lg:ml-10 lg:pl-10',
          'flex-1',
        )}
      >
        <BidDetails tender={tenderInvitation} refetchBidList={refetch} />
      </div>
    </div>
  )
}

export default TendersUnderOfferDetails
