import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Pill, { variants } from '../../../../components/atoms/pill'
import {
  TenderStatus,
  TenderInvitationStatus,
  BidStatus,
  Children,
} from '../../../../types'

import { statusToVariant as statusToVariantTender } from '../tender-pill'

export type AvailableStatus = TenderStatus | 'INVITED' | 'CLOSED'

type StatusToVariant = {
  [key in AvailableStatus]: keyof typeof variants
}

const statusToVariant: StatusToVariant = {
  ...statusToVariantTender,
  INVITED: 'orange',
  CLOSED: 'gray',
}

interface TenderPillProps extends Children {
  status: TenderStatus
  bidStatus?: BidStatus
  invitationStatus: TenderInvitationStatus
}

const TenderPill: React.FC<TenderPillProps> = ({
  status,
  bidStatus,
  invitationStatus,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'utils.status.tenderInvitation',
  })

  const parsedStatus = useMemo((): AvailableStatus => {
    if (invitationStatus === 'ACCEPTED') {
      if (status === 'EXECUTED') {
        if (bidStatus === 'EXECUTED') return 'EXECUTED'
        return 'CLOSED'
      }
      if (status === 'UNDER_OFFER') {
        if (bidStatus === 'ACCEPTED') return 'UNDER_OFFER'
        return 'CLOSED'
      }

      return 'ACTIVE'
    }

    if (invitationStatus === 'WON') {
      return 'EXECUTED'
    }

    if (invitationStatus === 'PENDING') {
      return 'INVITED'
    }

    return 'CLOSED'
  }, [status, invitationStatus, bidStatus])
  return <Pill variant={statusToVariant[parsedStatus]}>{t(parsedStatus)}</Pill>
}

export default TenderPill
