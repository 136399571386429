import React from 'react'

export interface PageTitleProps {
  title: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return (
    <div className="text-3xl text-ppa/title font-semibold">
      <span>{title}</span>
    </div>
  )
}

export default PageTitle
