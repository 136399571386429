import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DownloadIcon } from '@heroicons/react/outline'

import usePPA from '../../../../../../hooks/use-ppa'

import DefList from '../../../../../../components/atoms/def-list'

import { capitalizeText } from '../../../../../../utils'

import { Site } from '../../../../../../types'

interface TenderDetailsListSuppliersProps {
  data: Site
}

const SiteDetailsDefList: React.FC<TenderDetailsListSuppliersProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.defList',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData } = usePPA()

  const handleDownloadHHD = async (file: File) => {
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/tender/by-supplier/hhd?fileName=${file.name}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('siteDetailsList.titles.name'),
        renderCustomEl: (site: Site) => {
          return site.name
        },
      },
      {
        keyName: 'technology',
        title: t('siteDetailsList.titles.technology'),
        renderCustomEl: (site: Site) => {
          const technology = site?.technology
          if (!technology) return undefined
          return capitalizeText(tUtils(`technology.${technology}.abbreviation`))
        },
      },
      {
        keyName: 'capacity',
        title: t('siteDetailsList.titles.totalCapacity'),
        suffix: 'MW',
        renderCustomEl: (site: Site) => {
          if (!site.capacity) return undefined
          return site.capacity
        },
      },
      {
        keyName: 'voltage',
        title: t('siteDetailsList.titles.voltage'),
        suffix: 'kV',
        renderCustomEl: (site: Site) => {
          if (!site.voltage) return undefined
          return site.voltage
        },
      },
      {
        keyName: 'mpan',
        title: t('siteDetailsList.titles.mpan'),
        renderCustomEl: (site: Site) => {
          if (!site.mpan) return undefined
          return site.mpan
        },
      },
      {
        keyName: 'msid',
        title: t('siteDetailsList.titles.msid'),
        renderCustomEl: (site: Site) => {
          if (!site.msid) return undefined
          return site.msid
        },
      },
      {
        keyName: 'rocBand',
        title: t('siteDetailsList.titles.rocBand'),
        renderCustomEl: (site: Site) => {
          if (!site.rocBand) return undefined
          return site.rocBand
        },
      },
      {
        keyName: 'company',
        title: t('siteDetailsList.titles.company.name'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.name
        },
      },
      {
        keyName: 'number',
        title: t('siteDetailsList.titles.company.number'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.number
        },
      },
      {
        keyName: 'location',
        title: t('siteDetailsList.titles.location'),
        renderCustomEl: (site: Site) => {
          if (!site.location) return undefined
          return site.location
        },
      },
      // {
      //   keyName: 'hhd',
      //   title: t('siteDetailsList.titles.hhd'),
      //   renderCustomEl: (site: Site) => {
      //     if (!site.hhd) return undefined
      //     return (
      //       <span>
      //         <button
      //           type="button"
      //           onClick={() => handleDownloadHHD(site.hhd as any)}
      //           className="flex"
      //         >
      //           {t('siteDetailsList.downloadHhdButton')}
      //           <DownloadIcon className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary" />
      //         </button>
      //       </span>
      //     )
      //   },
      // },
      {
        keyName: 'brokerFee',
        suffix: '£/MWh',
        title: t('siteDetailsList.titles.brokerFee'),
        renderCustomEl: (site: Site) => {
          if (!site.brokerFee) return undefined
          return site.brokerFee
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('siteDetailsList.siteDetailsTitle')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default SiteDetailsDefList
