import React, { useState, useEffect } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import classNames from 'classnames'

import { ReactComponent as ExclamationIcon } from './assets/ExclamationSVG.svg'

import {
  dateFormats,
  priceTypeSleevedList,
} from '../../../../../../../utils/data'

import {
  SupplierTenderInvitation,
  SupplierTenderBid,
} from '../../../../../../../types'

import Input from '../../../../../../../components/atoms/input'
import Button from '../../../../../../../components/atoms/button'
import Message from '../../../../../../../components/atoms/message'
import SelectComponent, {
  SelectOptions,
} from '../../../../../../../components/atoms/select'

import InputGroup from '../../../../../../../components/molecules/input-group'

import GreenCertificatesForm from '../form-green-certificates'
import BenefitsForm from '../form-benefits'
import ChargesForm from '../form-charges'

export const targetMatchOptions = [
  {
    value: '0.25',
    label: '0% - 25%',
  },
  {
    value: '0.5',
    label: '25% - 50%',
  },
  {
    value: '0.75',
    label: '50% - 75%',
  },
  {
    value: '1',
    label: '50% - 100%',
  },
]

export type FormData = {
  contractType?: SelectOptions
  matchedPowerPrice?: string | number
  unmatchedPowerPrice?: string | number
  fixedPowerPrice?: string | number
  passthroughPowerPrice?: string | number
  matchPercentage?: SelectOptions
  expiresAt: string
}

interface FormProps {
  tender: SupplierTenderInvitation['supplierTender']
  bid?: SupplierTenderBid
  defaultValues: FormData
  brokerFee: number
  onSubmit: (data: any) => void
  isLoading: boolean
}

const SleevedBidForm: React.FC<FormProps> = ({
  tender,
  bid,
  defaultValues,
  brokerFee,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const methods = useForm<FormData>({ defaultValues })

  const {
    formState: { errors: formErrors, isSubmitting },
    control,
    watch,
  } = methods

  const contractType = watch('contractType')
  const [fieldsToShow, setFieldsToShow] = useState<
    'MATCH_AND_UNMATCHED' | 'FIXED' | 'PASSTHROUGH'
  >('MATCH_AND_UNMATCHED')

  useEffect(() => {
    if (contractType?.value === 'FIXED') {
      setFieldsToShow('FIXED')
    } else if (contractType?.value === 'PASSTHROUGH') {
      setFieldsToShow('PASSTHROUGH')
    } else {
      setFieldsToShow('MATCH_AND_UNMATCHED')
    }
  }, [contractType])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="sm:max-w-[650px] mx-1"
      >
        <h1 className="text-xl text-ppa/title font-bold mb-5">
          {bid ? t('UpdateBidTitle') : t('placeBidTitle')}
        </h1>
        <div className="border-l border-ppa/primary mb-5">
          <span className="text-ppa/primary text-base font-semibold ml-3">
            {tender.shortId}
          </span>
        </div>
        <div className="flex flex-col gap-y-4">
          <Controller
            name="contractType"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('fields.contractType.required'),
              },
            }}
            render={({ field: { ref: _, ...props } }) => (
              <InputGroup
                label={t('fields.contractType.label')}
                error={formErrors.contractType?.message}
                required
              >
                <div className="w-full max-w-[330px]">
                  <SelectComponent
                    {...props}
                    error={formErrors.contractType?.message}
                    type="single"
                    options={priceTypeSleevedList.map((item) => {
                      return {
                        ...item,
                        label: tUtils(`priceTypeSleeved.${item.label}`),
                      }
                    })}
                    placeholder={t('fields.contractType.placeholder')}
                    testId="select-priceType"
                  />
                </div>
              </InputGroup>
            )}
          />
          <InputGroup label={t('fields.powerPrice')}>
            {fieldsToShow === 'MATCH_AND_UNMATCHED' && (
              <>
                <Controller
                  name="matchedPowerPrice"
                  control={control}
                  rules={{ required: t('fields.matchedPowerPrice.required') }}
                  render={({ field: { ref: _, ...props } }) => (
                    <div className="flex items-center justify-between w-[380px]">
                      <span
                        className={classNames(
                          'text-ppa/subtitle text-sm',
                          formErrors.matchedPowerPrice?.message && '-mt-10',
                        )}
                      >
                        {t('fields.matchedPowerPrice.title')}
                        <span className="text-ppa/formStar ml-1">*</span>
                      </span>
                      <div className="flex flex-col w-full max-w-[180px] my-1">
                        <Input
                          type="number"
                          placeholder="0.00"
                          suffix="£/MWh"
                          step="0.01"
                          {...props}
                          error={formErrors.matchedPowerPrice?.message}
                        />
                        {formErrors.matchedPowerPrice?.message && (
                          <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                            <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                            <span className="text-xs font-light text-ppa/warning">
                              {formErrors.matchedPowerPrice?.message}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="unmatchedPowerPrice"
                  control={control}
                  rules={{ required: t('fields.unmatchedPowerPrice.required') }}
                  render={({ field: { ref: _, ...props } }) => (
                    <div className="flex items-center justify-between w-[380px]">
                      <span
                        className={classNames(
                          'text-ppa/subtitle text-sm',
                          formErrors.unmatchedPowerPrice?.message && '-mt-10',
                        )}
                      >
                        {t('fields.unmatchedPowerPrice.title')}
                        <span className="text-ppa/formStar ml-1">*</span>
                      </span>
                      <div className="flex flex-col w-full max-w-[180px] my-1">
                        <Input
                          type="number"
                          placeholder="0.00"
                          suffix="£/MWh"
                          step="0.01"
                          {...props}
                          error={formErrors.unmatchedPowerPrice?.message}
                        />
                        {formErrors.unmatchedPowerPrice?.message && (
                          <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                            <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                            <span className="text-xs font-light text-ppa/warning">
                              {formErrors.unmatchedPowerPrice?.message}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                />
              </>
            )}

            {fieldsToShow === 'FIXED' && (
              <Controller
                name="fixedPowerPrice"
                control={control}
                rules={{ required: t('fields.fixedPowerPrice.required') }}
                render={({ field: { ref: _, ...props } }) => (
                  <div className="flex items-center justify-between w-[380px]">
                    <span
                      className={classNames(
                        'text-ppa/subtitle text-sm',
                        formErrors.fixedPowerPrice?.message && '-mt-10',
                      )}
                    >
                      {t('fields.fixedPowerPrice.title')}
                      <span className="text-ppa/formStar ml-1">*</span>
                    </span>
                    <div className="flex flex-col w-full max-w-[180px] my-1">
                      <Input
                        type="number"
                        placeholder="0.00"
                        suffix="£/MWh"
                        step="0.01"
                        {...props}
                        error={formErrors.fixedPowerPrice?.message}
                      />
                      {formErrors.fixedPowerPrice?.message && (
                        <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                          <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                          <span className="text-xs font-light text-ppa/warning">
                            {formErrors.fixedPowerPrice?.message}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              />
            )}

            {fieldsToShow === 'PASSTHROUGH' && (
              <Controller
                name="passthroughPowerPrice"
                control={control}
                rules={{ required: t('fields.passthrough.required') }}
                render={({ field: { ref: _, ...props } }) => (
                  <div className="flex items-center justify-between w-[380px]">
                    <span
                      className={classNames(
                        'text-ppa/subtitle text-sm',
                        formErrors.passthroughPowerPrice?.message && '-mt-10',
                      )}
                    >
                      {t('fields.passthrough.title')}
                      <span className="text-ppa/formStar ml-1">*</span>
                    </span>
                    <div className="flex flex-col w-full max-w-[180px] my-1">
                      <Input
                        type="number"
                        placeholder="0.00"
                        suffix="%"
                        step="0.01"
                        {...props}
                        error={formErrors.passthroughPowerPrice?.message}
                      />
                      {formErrors.passthroughPowerPrice?.message && (
                        <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                          <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                          <span className="text-xs font-light text-ppa/warning">
                            {formErrors.passthroughPowerPrice?.message}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              />
            )}
          </InputGroup>

          <Controller
            name="matchPercentage"
            control={control}
            rules={{ required: t('fields.matchPercentage.required') }}
            render={({ field: { ref: _, ...props } }) => (
              <InputGroup
                label={t('fields.matchPercentage.label')}
                error={formErrors.matchPercentage?.message}
                required
              >
                <div className="w-full max-w-[230px]">
                  <SelectComponent
                    {...props}
                    error={formErrors.matchPercentage?.message}
                    type="single"
                    options={targetMatchOptions}
                    placeholder={t('fields.matchPercentage.placeholder')}
                    testId="select-matchPercentage"
                  />
                </div>
              </InputGroup>
            )}
          />

          <BenefitsForm bid={bid} />
          <GreenCertificatesForm tender={tender} bid={bid} />
          <ChargesForm bid={bid} />

          <div className="border border-b border-ppa/grayBorder w-full" />
          <InputGroup label={t('fields.expiresAt.label')}>
            <Message variant="info" size="text-xs self-center">
              {t('fields.expiresAt.infoMessage')}
            </Message>
          </InputGroup>
          <div className="flex items-start w-72 -mt-3 mb-4 gap-x-3">
            <Controller
              name="expiresAt"
              control={control}
              rules={{ required: t('fields.expiresAt.required') }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('fields.expiresAt.title')}
                  error={formErrors.expiresAt?.message}
                  required
                >
                  <Input
                    type="datetime-local"
                    {...props}
                    min={dayjs().format(dateFormats.dateInput)}
                    error={formErrors.expiresAt?.message}
                  />
                </InputGroup>
              )}
            />
          </div>
        </div>
        <div className="border border-b border-ppa/grayBorder w-full" />
        <div className="flex justify-end mt-5">
          <div>
            <InputGroup label={t('fields.charges.brokerFee.label')}>
              <Message variant="info" size="text-xs self-center">
                {t('fields.charges.brokerFee.infoMessage')}
              </Message>
              <div className="w-60 mt-1 ml-[0.2px]">
                <Input
                  readOnly
                  disabled
                  type="number"
                  suffix="£/MWh"
                  value={Number(brokerFee).toFixed(2)}
                />
              </div>
            </InputGroup>
          </div>
        </div>
        <div className="border border-b border-ppa/grayBorder w-full my-3" />
        <div className="flex justify-end">
          <Message variant="warn" size="text-xs mt-1">
            {t('fields.submit.infoMessage')}
          </Message>
        </div>
        <div className="flex justify-end mt-2">
          <Button
            variant="primary"
            type="submit"
            loading={isLoading}
            disabled={isSubmitting}
            formWidth
          >
            {t('fields.submit.button')}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default SleevedBidForm
