import {
  PathRouteProps,
  LayoutRouteProps,
  IndexRouteProps,
} from 'react-router-dom'

import { generatorRoutes, generatorLinks } from './generator/routes'
import { supplierRoutes, supplierLinks } from './supplier/routes'
import { adminRoutes, staffAdminLinks } from './staff-admin/routes'
import { consumerRoutes, consumerLinks } from './consumer/routes'

import Profile from './supplier/settings/profile'
import NotFound404 from './not-found'

export type RoutesArray = (
  | PathRouteProps
  | LayoutRouteProps
  | IndexRouteProps
)[]

interface PrivateRoutes {
  GENERATOR: RoutesArray
  SUPPLIER: RoutesArray
  CONSUMER: RoutesArray
  ADMIN: RoutesArray
}

const defaultRoutes = [
  { path: '/profile', element: <Profile /> },
  { path: '*', element: <NotFound404 /> },
]

export const privateRoutes: PrivateRoutes = {
  GENERATOR: [...generatorRoutes, ...defaultRoutes],
  SUPPLIER: [...supplierRoutes, ...defaultRoutes],
  CONSUMER: [...consumerRoutes, ...defaultRoutes],
  ADMIN: [...adminRoutes, ...defaultRoutes],
}

type SubMenu = { to: string; label: string }[]

export interface NavigationLink {
  // "to" will be ignored when we have subMenu
  to: string
  label: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  subMenu?: SubMenu
}

export type LinksArray = NavigationLink[]

interface PrivateLinks {
  GENERATOR: LinksArray
  SUPPLIER: LinksArray
  CONSUMER: LinksArray
  ADMIN: LinksArray
}

export const privateLinks: PrivateLinks = {
  GENERATOR: generatorLinks,
  SUPPLIER: supplierLinks,
  CONSUMER: consumerLinks,
  ADMIN: staffAdminLinks,
}
