import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { dateFormats } from '../../../../../../utils/data'

import { parseContractDuration } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'

import { SupplierTender, TenderSubsidyName } from '../../../../../../types'

interface TenderDetailsListSleevedProps {
  data: SupplierTender
}

const SleevedTenderDetailsDefList: React.FC<TenderDetailsListSleevedProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details.defList',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('titles.contractDuration'),
        renderCustomEl: (item: SupplierTender) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('titles.deadline'),
        renderCustomEl: (item: SupplierTender) => {
          if (!item.deadline) return undefined
          return (
            <div className="flex text-center">
              {dayjs
                .utc(item.deadline)
                .tz('Europe/London')
                .format(dateFormats.userDateTime)}
            </div>
          )
        },
      },
      {
        keyName: 'generator',
        title: t('titles.generator'),
        renderCustomEl: (item: SupplierTender) => {
          if (!item?.generator.name) return undefined
          return item.generator.name
        },
      },
      {
        keyName: 'supplierTenderGreenCertificates',
        title: t('titles.greenCertificates'),
        renderCustomEl: (item: SupplierTender) => {
          if (!item.supplierTenderGreenCertificates) return undefined
          const toggledGreenCertificatesNames: TenderSubsidyName[] = []
          item.supplierTenderGreenCertificates.forEach(({ name, toggled }) => {
            if (toggled) toggledGreenCertificatesNames.push(name)
          })
          return toggledGreenCertificatesNames.join(', ')
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('title')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default SleevedTenderDetailsDefList
