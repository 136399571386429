import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import classNames from 'classnames'
import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'

import { capitalizeText } from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import { parseQuery } from '../../../../../../hooks/use-ppa/use-ppa-get-list'
import usePPA from '../../../../../../hooks/use-ppa'

import CollapseList from '../../../../../../components/molecules/collapse-list'
import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'

import TenderInvitationPill from '../../../../components/tender-invitation-pill'

import { mappedTenderStatus, AvailableRouteStatus } from '../../../types'

import TenderDetailsDefList from '../details-tender'
import SiteDetailsDefList from '../details-site'

import {
  TenderInvitation as TenderInvitationData,
  Tender,
  SiteCompany,
  Bid,
  TenderSubsidies,
} from '../../../../../../types'

export type TenderInvitation = TenderInvitationData & {
  tender: Omit<Tender, 'targetPrice'> & {
    site: SiteCompany
    subsidies: TenderSubsidies[]
  }
  bid?: Bid
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface Files {
  id: string
  name: string
}

interface TenderDetailsSupplierProps {
  buttons?: ButtonList[]
  tenderInvitation: TenderInvitation
}

const TenderDetails: React.FC<TenderDetailsSupplierProps> = ({
  buttons,
  tenderInvitation,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders.details',
  })

  const navigate = useNavigate()

  const { fetchData } = usePPA()

  const [files, setFiles] = useState<Files[] | undefined>()

  useEffect(() => {
    const fetchFiles = async (): Promise<void> => {
      if (!tenderInvitation?.tender?.site?.id) return
      try {
        const { response, error: filesError } = await fetchData({
          method: 'GET',
          url: `/core/private/site/by-supplier/hhd/list/${tenderInvitation?.tender?.site?.id}`,
          responseType: 'json',
        })

        if (!response?.data || filesError) throw filesError

        setFiles(
          response.data.hhdFiles.map((file: any) => ({
            id: v4(),
            name: file,
          })),
        )
      } catch (err) {
        console.error(err)
      }
    }

    if (tenderInvitation?.tender?.site?.id) {
      fetchFiles()
    }
  }, [tenderInvitation])

  const handleDownloadHHD = async (file: File) => {
    if (!tenderInvitation) return
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/site/by-supplier/hhd?${parseQuery({
          fileName: file.name,
          siteId: tenderInvitation.tender.site.id,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/tenders/status/${
                mappedTenderStatus[
                  tenderInvitation.status as AvailableRouteStatus
                ]
              }`,
            )
          }
        >
          {t('backButton', {
            status: capitalizeText(tenderInvitation.tender.status),
          })}
        </Button>
      </div>

      <div className="flex flex-col gap-y-8 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start">
            <h1 className="text-3xl font-semibold text-ppa/title mr-5 w-full">
              {tenderInvitation.tender.shortId}
            </h1>
            <TenderInvitationPill
              status={tenderInvitation.tender.status}
              invitationStatus={tenderInvitation.status}
              bidStatus={tenderInvitation.bid?.status}
            />
          </div>
        </div>

        <TenderDetailsDefList data={tenderInvitation} />
        <SiteDetailsDefList data={tenderInvitation.tender.site} />
        <CollapseList
          title={t('hhd.collapseList.title')}
          titleEmptyList={t('hhd.collapseList.filesNotFound')}
          list={files}
          defaultOpen
          rowKeys={[
            { keyName: 'name' },
            {
              keyName: 'actions',
              containerClass: 'ml-auto pl-2 max-w-[50px]',
              renderCustomEl: (file: any) => {
                return (
                  <div className="flex items-center gap-x-2">
                    <DownloadIcon
                      className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                      onClick={() => handleDownloadHHD(file)}
                    />
                  </div>
                )
              },
            },
          ]}
        />

        {buttons && (
          <div className="flex items-center justify-start gap-x-1 w-full">
            {buttons.map((item) => (
              <div className="w-full flex max-w-[165px]" key={item.text}>
                <Button
                  {...item.props}
                  key={item.text}
                  onClick={() => item.onClick()}
                >
                  {item.text}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default TenderDetails
