import React, { useEffect } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'

import { auth0Config } from './auth-config'

import { UserProvider } from './hooks/use-user'

import { NotificationProvider } from './hooks/use-read-notifications'

import './utils/features'
import Routes from './routes'
import './styles/global.css'
import './locales/index'

export const App: React.FC = () => {
  useEffect(() => {
    document
      .getElementsByTagName('html')[0]
      .setAttribute('data-theme', process.env.REACT_APP_COMPANY || 'PPAYA')
  }, [])

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <UserProvider>
          <NotificationProvider>
            <Routes />
          </NotificationProvider>
        </UserProvider>
      </Auth0Provider>
    </BrowserRouter>
  )
}

export default App
