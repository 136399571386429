import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'

import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import DefList from '../../../../components/atoms/def-list'
import Dropdown from '../../../../components/atoms/dropdown'
import Button from '../../../../components/atoms/button'

import useUser from '../../../../hooks/use-user'
import usePPA from '../../../../hooks/use-ppa'
import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import usePPAGetList, {
  parseQuery,
} from '../../../../hooks/use-ppa/use-ppa-get-list'

import UpdateBrokerFeeDrawer from './components/update-broker-fee-drawer'
import { feedbackMessage } from '../../../../components/atoms/feedback'
import CollapseList from '../../../../components/molecules/collapse-list'

import { Company, Site as SiteDTO, Generator, Member } from '../../../../types'

import { container, breakPointsContainer } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'
import { ReactComponent as BrokerFeeIcon } from './assets/brokerFeeIcon.svg'
import { ReactComponent as ActAsIcon } from './assets/actAsIcon.svg'

export type Site = SiteDTO & { company: Company }

interface Files {
  id: string
  name: string
}

export type GeneratorDetails = Generator & {
  members: Member[]
}

const SiteDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.sites.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()
  const { siteId } = useParams<{ siteId: string }>()

  const { actAs } = useUser()
  const { fetchData } = usePPA()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [generatorDetails, setGeneratorDetails] = useState<
    GeneratorDetails | undefined
  >()
  const [isLoadingGeneratorDetails, setIsLoadingGeneratorDetails] =
    useState<boolean>(false)

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<Site>({
      dataKey: 'site',
      path: `/core/private/site/by-admin/${siteId}`,
    })

  const siteDetails: Site | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    const fetchGeneratorDetails = async () => {
      try {
        const { response, error: generatorDetailsError } = await fetchData({
          method: 'GET',
          url: `/core/private/generator/details/${siteDetails?.generatorId}`,
        })

        if (generatorDetailsError) {
          throw generatorDetailsError
        }

        setGeneratorDetails(response?.data.generator)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingGeneratorDetails(false)
      }
    }

    if (
      !siteDetails ||
      siteDetails.generatorId === undefined ||
      isLoadingGeneratorDetails
    ) {
      return
    }

    setIsLoadingGeneratorDetails(true)
    fetchGeneratorDetails()
  }, [siteDetails])

  const {
    isLoading: isLoadingFiles,
    data: filesData,
    error: filesError,
  } = usePPAGetList<string[]>({
    dataKey: 'hhdFiles',
    path: `/core/private/site/by-admin/hhd/list/${siteId}`,
  })

  const files = useMemo((): Files[] | undefined => {
    if (isLoadingFiles || filesError || !filesData) return undefined

    return filesData.map((file: any) => ({
      id: v4(),
      name: file,
    }))
  }, [filesData])

  const handleDownloadHHD = async (file: File) => {
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/site/by-admin/hhd?${parseQuery({
          fileName: file.name,
          siteId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  const handleActAs = () => {
    if (generatorDetails === undefined || isLoadingGeneratorDetails) return
    if (generatorDetails.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: t('handleActAs.warn.title'),
          description: t('handleActAs.warn.description'),
        },
        'warn',
      )
      return
    }

    const { id, name, members } = generatorDetails

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'GENERATOR',
        status: generatorDetails.status,
        userId: generatorDetails.userId,
        brokerContextName: generatorDetails.brokerContextName,
      },
    })

    navigate('/sites')
  }

  const rowKeysGenerator = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: 'Name',
        renderCustomEl: (item: any) => {
          return item?.name
        },
      },
    ]
  }, [])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'mpan', title: 'MPAN' },
      { keyName: 'msid', title: 'MSID' },
      {
        keyName: 'technology',
        title: 'Technology',
        renderCustomEl: (item: Site) => {
          return tUtils(`technology.${item.technology}.fullName`)
        },
      },
      { keyName: 'capacity', title: 'Capacity', suffix: 'MW' },
      { keyName: 'voltage', title: 'Voltage', suffix: 'kV' },
      { keyName: 'rocBand', title: 'ROC Band' },
      { keyName: 'brokerFee', title: 'Broker fee', suffix: '£/MWh' },
    ]
  }, [siteDetails])

  useEffect(() => {
    if (!siteId) {
      navigate('/sites')
    }
  }, [siteId])

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
        context: 'site',
      })
    }
  }, [error])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/sites')}
        >
          {t('backButton')}
        </Button>
      </div>
      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && error && (
        <div>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}
      {!isLoading && !error && siteDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
              {siteDetails.name}
            </h1>
            <Dropdown
              content={
                <div className="flex flex-col justify-start items-start">
                  <Button
                    variant="text"
                    icon={<BrokerFeeIcon />}
                    onClick={() => setIsEditDrawerOpen(true)}
                  >
                    {t('actionsDropdown.setBrokerFee')}
                  </Button>
                </div>
              }
            />
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-y-8">
            <div className="flex flex-col justify-start items-start w-full gap-y-2">
              <div className="flex justify-center items-center gap-x-8 max-w-[500px]">
                <h2 className="text-base font-medium text-ppa/title">
                  {t('generatorListTitle')}
                </h2>
                <Button
                  variant="primary"
                  icon={<ActAsIcon />}
                  onClick={handleActAs}
                >
                  {t('actAsButton')}
                </Button>
              </div>

              <div>
                <DefList rowKeys={rowKeysGenerator} data={generatorDetails} />
              </div>
            </div>

            <div className="flex flex-col gap-y-4">
              <div className="flex flex-col gap-y-2">
                <h2 className="text-base font-medium text-ppa/title">
                  {t('siteListTitle')}
                </h2>
                <DefList rowKeys={rowKeys} data={siteDetails} />
              </div>

              <CollapseList
                title={t('hhd.collapseList.title')}
                titleEmptyList={t('hhd.collapseList.filesNotFound')}
                list={files as any}
                defaultOpen
                rowKeys={[
                  { keyName: 'name' },
                  {
                    keyName: 'actions',
                    containerClass: 'ml-auto pl-2 max-w-[50px]',
                    renderCustomEl: (file: any) => {
                      return (
                        <div className="flex items-center gap-x-2">
                          <DownloadIcon
                            className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                            onClick={() => handleDownloadHHD(file)}
                          />
                        </div>
                      )
                    },
                  },
                ]}
              />
            </div>
          </div>

          <UpdateBrokerFeeDrawer
            siteId={siteId}
            brokerFee={siteDetails.brokerFee as number}
            isOpen={isEditDrawerOpen}
            onSuccess={refetch}
            handleClose={() => setIsEditDrawerOpen(false)}
          />
        </>
      )}
    </section>
  )
}

export default SiteDetails
