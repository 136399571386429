import dayjs from 'dayjs'
import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Spinner } from '../../../../../../assets/spinner/spinner.svg'

import { ChartData as ChartDataType } from '../types'

import {
  ChartJS,
  Chart,
  ChartData,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from '../../../../../../components/atoms/slider/chart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

/**
 * @FIXME
import { Commodity } from '../../../../../../types'
 */
type Commodity = any

interface ForecastChartProps {
  data?: ChartDataType
  title?: string
  isLoading?: boolean
  commodity: Commodity
}

const ForecastChart: React.FC<ForecastChartProps> = ({
  data,
  title,
  isLoading = false,
  commodity,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'market.forecast',
  })

  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  })
  useEffect(() => {
    if (!chartRef?.current) return
    if (!data?.dates || !data?.prices) return

    setChartData({
      labels: data.dates,
      datasets: [
        {
          label: title,
          data: data.prices,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: '#000',
          borderColor: '#F97316',
          backgroundColor: '#F97316',
          tension: 0.1,
        },
      ],
    })
  }, [data])

  return (
    <div>
      {isLoading && (
        <div className="w-16 mt-2 min-w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && !data && <h1>{t('infoMessage')}</h1>}
      {!isLoading && data && (
        <div className="relative h-96">
          <Chart
            ref={chartRef}
            type="line"
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              interaction: { intersect: false },
              plugins: {
                tooltip: {
                  titleAlign: 'center',
                  padding: 10,
                  callbacks: {
                    label: (item) => `Price: £ ${item.parsed.y.toFixed(2)}`,
                    title: (item) => dayjs(item[0].label).format('DD/MM/YYYY'),
                  },
                },
                legend: {
                  position: 'bottom',
                  labels: {
                    color: '#4C5172',
                    boxWidth: 30,
                    boxHeight: 0.4,
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: '#4C5172',
                    font: {
                      size: 14,
                      weight: '300',
                      family: 'Lexend, sans-serif',
                    },
                    callback: (value: any) =>
                      dayjs(data?.dates[value]).format('DD MMM'),
                    autoSkipPadding: 30,
                  },
                },
                y: {
                  ticks: {
                    color: '#4C5172',
                    font: {
                      size: 14,
                      weight: '300',
                      family: 'Lexend, sans-serif',
                    },
                    callback: (value) => {
                      if (commodity === 'GAS') return `${value} p/therm`
                      return `£ ${value}`
                    },
                  },
                },
              },
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ForecastChart
