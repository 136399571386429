/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'
import Backdrop from '../../atoms/back-drop/index'
import { transition } from '../../../styles'

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  handleClose: () => void
  children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  children,
  ...restProps
}) => {
  return (
    <div {...restProps}>
      <Backdrop isOpen={isOpen} handleClick={handleClose} />

      <div
        onClick={handleClose}
        className={classNames(
          transition,
          'flex fixed w-screen h-screen inset-0 z-[950]',
          'bg-transparent cursor-default',
          isOpen ? 'translate-y-0' : '-translate-y-full',
        )}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={classNames(
            transition,
            'm-auto align-middle',
            'bg-white rounded-md cursor-default max-h-screen overflow-y-auto',
            isOpen ? 'scale-1' : 'scale-0',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
