import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { parseCompanyToSelect } from '../../../../../../components/molecules/company-select'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { Company, Site as SiteDTO } from '../../../../../../types'
import { ErrorNames } from '../../../../../../types/errors'

import SiteForm, { FormValues } from '../form-site'

type Site = SiteDTO & { company: Company }

export interface EditSiteProps {
  onSuccess: () => void
  handleClose: () => void
  isOpen?: boolean
  site: Site
}

const EditSite: React.FC<EditSiteProps> = ({
  onSuccess,
  site: siteData,
  handleClose,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.siteDrawer.edit',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (data: any) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      let parsedRocBand: number | undefined

      if (data?.rocBand) {
        if (Number.isNaN(Number(data.rocBand))) {
          feedbackMessage(
            {
              title: t('create.onSubmit.error.rocNotANumber.title'),
              description: t('create.onSubmit.error.rocNotANumber.description'),
            },
            'error',
          )
          setIsSubmitting(false)
          return
        }
        parsedRocBand = parseFloat(parseFloat(data.rocBand).toFixed(3))
      }

      if (!data?.capacity || Number.isNaN(Number(data.capacity))) {
        feedbackMessage(
          {
            title: t('create.onSubmit.error.capacityNotANumber.title'),
            description: t(
              'create.onSubmit.error.capacityNotANumber.description',
            ),
          },
          'error',
        )
        setIsSubmitting(false)
        return
      }

      if (!data?.voltage || Number.isNaN(Number(data.voltage))) {
        feedbackMessage(
          {
            title: t('create.onSubmit.error.voltageNotANumber.title'),
            description: t(
              'create.onSubmit.error.voltageNotANumber.description',
            ),
          },
          'error',
        )
        setIsSubmitting(false)
        return
      }

      const parsedCompany = JSON.parse(data.company.value)

      const { response, error } = await fetchData({
        method: 'PUT',
        url: `/core/private/site/${siteData.id}`,
        body: {
          name: data.name,
          companyId: parsedCompany.id,
          technology: data.technology.value,
          location: data.location,
          capacity: parseFloat(parseFloat(data.capacity).toFixed(3)),
          voltage: parseFloat(parseFloat(data.voltage).toFixed(3)),
          rocBand: parsedRocBand,
          mpan: data?.mpan,
          msid: data?.msid,
        },
      })

      if (error && !response) throw error

      feedbackMessage(
        {
          title: t('onSubmit.success.title', { siteName: data.name }),
        },
        'success',
      )

      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'site',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes('The company') &&
            message.includes('must be active')
          ) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.site.SERVER_ERROR.companyMustBeActive',
              ),
              type: 'error',
            }
          }

          if (message.includes('Site.checkForActiveOrUnderOfferTenders()')) {
            const [_, tenders] = message.split('The tenders are: ')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.site.SERVER_ERROR.activeOrUnderOfferTenders.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.site.SERVER_ERROR.activeOrUnderOfferTenders.description',
              )}\n${tenders}.`,
              type: 'error',
              duration: 8000,
            }
          }

          return undefined
        },
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Site found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.site.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const siteEdit = useMemo((): FormValues | undefined => {
    if (!siteData) return undefined

    const technology = {
      value: siteData.technology,
      label: tUtils(`technology.${siteData.technology}.abbreviation`),
    }

    return {
      name: siteData.name,
      technology,
      location: siteData.location,
      capacity: siteData.capacity,
      voltage: siteData.voltage,
      company: parseCompanyToSelect(siteData.company),
      mpan: siteData?.mpan || '',
      msid: siteData?.msid || '',
      rocBand: siteData?.rocBand || '',
    }
  }, [siteData])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5">
        <h1 className="text-xl font-bold text-ppa/title">{t('title')}</h1>

        {isOpen && (
          <SiteForm
            onSubmit={onSubmit as any}
            isLoading={isSubmitting}
            defaultValues={siteEdit}
            onCancel={handleClose}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default EditSite
