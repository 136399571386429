import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import {
  PencilIcon,
  TrashIcon,
  DownloadIcon,
  ChevronLeftIcon,
  UploadIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import { feedbackMessage } from '../../../../components/atoms/feedback'
import DefList from '../../../../components/atoms/def-list'
import Dropdown from '../../../../components/atoms/dropdown'
import Button from '../../../../components/atoms/button'

import usePPA from '../../../../hooks/use-ppa'
import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import usePPAGetList, {
  parseQuery,
} from '../../../../hooks/use-ppa/use-ppa-get-list'

import SlidingDrawer from '../../../../components/molecules/sliding-drawer'
import CollapseList from '../../../../components/molecules/collapse-list'

import { Company, Site as SiteDTO } from '../../../../types'

import { container, breakPointsContainer, transition } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'

import EditSite from './components/edit-site'
import UploadHHD from './components/upload-hhd'

export type Site = SiteDTO & { company: Company }

const SiteDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { siteId } = useParams<{ siteId: string }>()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [isHHDDrawerOpen, setIsHHDDrawerOpen] = useState(false)

  const { fetchData } = usePPA()

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<Site>({
      dataKey: 'site',
      path: `/core/private/site/${siteId}`,
    })

  const siteDetails: Site | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const {
    isLoading: isLoadingFiles,
    throwFeedbackError: throwFeedbackErrorFiles,
    data: filesData,
    error: filesError,
    reset,
  } = usePPAGetList<File[]>({
    dataKey: 'hhdFiles',
    path: `/core/private/site/hhd/list/${siteId}`,
  })

  const files = useMemo((): File[] | undefined => {
    if (isLoadingFiles || filesError || !filesData) return undefined

    return filesData.map((file: any) => ({
      ...file,
      name: file,
    }))
  }, [filesData])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'mpan', title: 'MPAN' },
      { keyName: 'msid', title: 'MSID' },
      {
        keyName: 'technology',
        title: 'Technology',
        renderCustomEl: (item: Site) => {
          return tUtils(`technology.${item.technology}.fullName`)
        },
      },
      { keyName: 'capacity', title: 'Capacity', suffix: 'MW' },
      { keyName: 'voltage', title: 'Voltage', suffix: 'KV' },
      { keyName: 'rocBand', title: 'ROC Band' },
      {
        keyName: 'name',
        title: 'Company',
        renderCustomEl: (item: Site) => item.company.name,
      },
      { keyName: 'brokerFee', title: 'Broker fee', suffix: '£/MWh' },
    ]
  }, [siteDetails])

  const handleEditSite = () => {
    setIsEditDrawerOpen(false)
    refetch()
  }

  const handleDeleteSite = async () => {
    if (isLoading) return

    try {
      const { response, error: err } = await fetchData({
        method: 'DELETE',
        url: `/core/private/site/${siteId}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('actionsDropdown.delete.success'),
        },
        'success',
      )

      navigate('/sites')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'site',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes('Site.delete()') &&
            message.includes(
              'Site cannot be deleted when associated with any tenders.',
            )
          ) {
            const [_, tenders] = message.split('The tenders are: ')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.site.SERVER_ERROR.siteAssociatedWithTenders.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.site.SERVER_ERROR.siteAssociatedWithTenders.description',
              )}\n${tenders}.`,
              type: 'error',
              duration: 8000,
            }
          }

          return undefined
        },
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Site found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.site.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleDeleteHHD = async (file: File) => {
    if (isLoadingFiles) return

    try {
      const { error: hhdDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/site/hhd?${parseQuery({
          fileName: file.name,
          siteId,
        })}`,
      })

      if (hhdDeleteError) throw hhdDeleteError

      feedbackMessage(
        {
          title: 'hhd file deleted successfully',
        },
        'success',
      )

      reset()
    } catch (err: any) {
      throwFeedbackError({ err })
    }
  }

  const [isDeletingFiles, setIsDeletingFiles] = useState(false)

  const handleDownloadHHD = async (file: File) => {
    if (isDeletingFiles) return
    setIsDeletingFiles(true)
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/site/hhd?${parseQuery({
          fileName: file.name,
          siteId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDeletingFiles(false)
    }
  }

  const handleHHDUpload = () => {
    reset()
    setIsHHDDrawerOpen(false)
  }

  useEffect(() => {
    if (!siteId) navigate('/sites')
  }, [siteId])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  useEffect(() => {
    if (filesError) throwFeedbackErrorFiles({ err: filesError })
  }, [filesError])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/sites')}
        >
          {t('backButton')}
        </Button>
      </div>
      {isLoading && (
        <div className={classNames(transition, 'w-full fade-in')}>
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && error && (
        <div className={classNames(transition, 'flex flex-col fade-in')}>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}
      {!isLoading && !error && siteDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
              {siteDetails.name}
            </h1>
            <Dropdown
              content={
                <div className="flex flex-col justify-start items-start">
                  <Button
                    variant="text"
                    icon={<PencilIcon />}
                    onClick={() => {
                      setIsEditDrawerOpen(true)
                    }}
                  >
                    {t('actionsDropdown.edit')}
                  </Button>
                  <Button
                    variant="text"
                    icon={<TrashIcon />}
                    onClick={handleDeleteSite}
                    data-testid="button-delete"
                  >
                    {t('actionsDropdown.delete.button')}
                  </Button>
                </div>
              }
            />
          </div>
          <DefList rowKeys={rowKeys} data={siteDetails} />
          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('hhd.title')}
              </h1>
              <Button
                onClick={() => setIsHHDDrawerOpen(true)}
                variant="primary"
                icon={<UploadIcon className="w-4 h-4" />}
              >
                {t('hhd.uploadButton')}
              </Button>
            </div>

            <CollapseList
              title={t('hhd.collapseList.title')}
              titleEmptyList={t('hhd.collapseList.filesNotFound')}
              list={files as any}
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadHHD(file)}
                        />
                        <TrashIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDeleteHHD(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
          <EditSite
            site={siteDetails}
            onSuccess={handleEditSite}
            isOpen={isEditDrawerOpen}
            handleClose={() => setIsEditDrawerOpen(false)}
          />
        </>
      )}

      <SlidingDrawer
        isOpen={isHHDDrawerOpen}
        handleClose={() => setIsHHDDrawerOpen(false)}
      >
        <UploadHHD
          idSite={siteDetails?.id}
          isDrawerOpen={isHHDDrawerOpen}
          onSuccess={handleHHDUpload}
          onCancel={() => setIsHHDDrawerOpen(false)}
        />
      </SlidingDrawer>
    </section>
  )
}

export default SiteDetails
