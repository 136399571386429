import { SelectOptions } from '../components/atoms/select'
import { Technology } from '../types'

/**
 * ```
 * @return
 * [
 *  'AD',
 *  'BIOGAS',
 *  'BIOMASS',
 *  'CHP',
 *  'EFW',
 *  'HYDRO',
 *  'SOLAR',
 *  'WIND',
 * ]
 * ```
 */
export const technologiesList: Array<Technology> = [
  'AD',
  'BIOGAS',
  'BIOMASS',
  'CHP',
  'EFW',
  'HYDRO',
  'SOLAR',
  'WIND',
]

export const priceTypeList = [
  { value: 'ANNUAL', label: 'fixedAveraged' },
  { value: 'SEASONAL', label: 'fixedSeasonal' },
  { value: 'DAY_AHEAD', label: 'dayAhead' },
  { value: 'SPILL', label: 'spill' },
  { value: 'MULTIPLE_TYPES', label: 'multipleTypes' },
] as const

export const priceTypeBidList = [
  { value: 'FIXED_AVERAGED', label: 'Fixed Wholesale Power Price (annual)' },
  { value: 'FIXED_SEASONAL', label: 'Fixed Wholesale Power Price (seasonal)' },
  { value: 'FIT_EXPORT_HIGH', label: 'FIT Export (high rate)' },
  { value: 'FIT_EXPORT_LOW', label: 'FIT Export (low rate)' },
  { value: 'FLEXIBLE', label: 'Day Ahead (EPEX/N2EX)' },
  { value: 'SPILL', label: 'System Spill Price' },
  // { value: 'MULTIPLE_TYPES', label: 'Custom pricing' },
]

export const priceTypeConsumerTenderList = [
  { value: 'FIXED', label: 'fixed' },
  { value: 'PASSTHROUGH', label: 'passthrough' },
  { value: 'SLEEVED', label: 'sleeved' },
]

export const priceTypeSleevedList = [
  { value: 'MATCH_AND_UNMATCHED', label: 'MATCH_AND_UNMATCHED' },
  { value: 'FIXED', label: 'FIXED' },
  { value: 'PASSTHROUGH', label: 'PASSTHROUGH' },
]
/**
 * ```
 * @return
 * [
 *  { value: 'FIXED', label: 'fixed' },
 *  { value: 'FIXED_GDUOS', label: 'fixedWithFlexGduos' },
 *  { value: 'FLEXIBLE', label: 'passthrough' },
 * ]
 * ```
 */
export const embeddedBenefitsList = [
  { value: 'FIXED', label: 'fixed' },
  { value: 'FIXED_GDUOS', label: 'fixedWithFlexGduos' },
  { value: 'FLEXIBLE', label: 'passthrough' },
]

/**
 * @return
 * ```
 * {
 *   FIXED: 'Fixed',
 *   FLEXIBLE: '% Passthrough',
 * }
 * ```
 */
export const subsidyMappedObject = {
  FIXED: 'Fixed',
  FLEXIBLE: '% Passthrough',
}
export type KeyOfSubsidyMappedObject = keyof typeof subsidyMappedObject
/**
 * @return
 * ```
 * {
 *  user: 'DD/MM/YYYY',
 *  userMonthName: 'DD MMM YYYY',
 *  userReadableFullDate: 'DD MMM YYYY, hh:mm A',
 *  aws: 'YYYY-MM-DD',
 * }
 * ```
 */
export const dateFormats = {
  user: 'DD/MM/YYYY',
  userDateTime: 'DD/MM/YYYY HH:mm:ss',
  userMonthName: 'DD MMM YYYY',
  userReadableFullDate: 'DD MMM YYYY, hh:mm A',
  aws: 'YYYY-MM-DD',
  dateInput: 'YYYY-MM-DDTHH:mm',
  minDateInput: 'YYYY-MM-DDTHH:mm',
} as const
