import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'

import Button from '../../../../components/atoms/button'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'

import { mappedConsumerTenderStatus } from '../types'

import { Tender } from '../../../../types'

import { consumerTendersSubRoutes } from './routes'
import { TenderInvitation } from './components/details'

export interface TenderStatusProps {
  tenderDetails: TenderInvitation
  refetch: () => void
}

const TenderDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.tenders.details',
  })

  const { tenderId } = useParams<{ tenderId: string }>()

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<TenderInvitation>({
      dataKey: 'consumerTender',
      path: `/core/private/consumer-tender/consumer/details/${tenderId}`,
    })

  const detailsComponent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )
    }
    if (!isLoading && error) {
      return (
        <div>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )
    }
    if (!data) return undefined

    const Component =
      consumerTendersSubRoutes[
        mappedConsumerTenderStatus[
          data.status as Tender['status']
        ] as keyof typeof consumerTendersSubRoutes
      ].details

    return <Component tenderDetails={data} refetch={refetch} />
  }, [isLoading, error, data, refetch])

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  return <div className="flex flex-col">{detailsComponent}</div>
}
export default TenderDetails
