import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePPAGetListForAdmins, {
  QueryFilter,
} from '../hooks/use-ppa-get-list-admins'
import useUser from '../../../../hooks/use-user'

import PageTitle from '../../../../components/atoms/page-title'
import Table, { TableConfigProps } from '../../../../components/atoms/table'
import UserPill from '../../components/user-pill'

import { Member, SupplierWithCompany } from '../../../../types'

import SearchFilter from './components/search-filter'

export type SupplierList = SupplierWithCompany & { members: [Member] }

const defaultFilters: QueryFilter = [
  {
    field: 'status',
    operator: 'in',
    value: ['ACTIVE', 'REVIEW', 'ONBOARDING'],
  },
]

const SupplierList: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.suppliers.page',
  })

  const { user } = useUser()

  const navigate = useNavigate()

  const [filters, setFilters] = useState<QueryFilter>(defaultFilters)

  const { isLoading, loadMore, applyFilters, throwFeedbackError, data, error } =
    usePPAGetListForAdmins<SupplierList>({
      dataKey: 'suppliers',
      path: '/core/private/supplier/list',
      filters,
    })

  /**
   * @TODO re-work filters types and filter logic when BE is ready
   */

  const suppliers = useMemo((): SupplierList[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: QueryFilter): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        if (error) throw error

        await applyFilters([...defaultFilters, ...newFilters])
        setFilters([...defaultFilters, ...newFilters])
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter(
      (item) =>
        item.field === 'search' ||
        defaultFilters.some(
          (defaultFilter) => defaultFilter.field === item.field,
        ),
    )
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (filters.length === 0) {
      setFilters(defaultFilters)
    }
  }, [filters])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  const rowKeys = useMemo((): TableConfigProps[] => {
    const result: TableConfigProps[] = [
      {
        keyName: 'name',
        renderCustomEl: (item: SupplierList) => {
          return (
            <div className="flex flex-col ml-3">
              <strong className="font-normal">{item.company.name}</strong>
              <span className="font-light">{item.company.number}</span>
            </div>
          )
        },
      },
    ]

    if (user.appMetadata.brokerContextName === 'PPAYA') {
      result.push(
        ...[
          {
            keyName: 'members',
            renderCustomEl: (item: SupplierList) => {
              if (!item?.members) return undefined
              const filteredMembers = item.members.filter(
                (member) => member.memberType === 'OWNER',
              )
              if (filteredMembers.length === 0) return undefined

              return (
                <div className="flex flex-col items-start justify-center gap-y-1">
                  <strong>
                    {filteredMembers[0].firstName} {filteredMembers[0].lastName}
                  </strong>
                  <span>{filteredMembers[0].email}</span>
                </div>
              )
            },
          },
          {
            keyName: 'status',
            renderCustomEl: (item: SupplierList) => {
              return <UserPill status={item.status} blocked={item?.blocked} />
            },
          },
        ],
      )
    }

    return result
  }, [])

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex items-center mt-5 mb-8">
        <PageTitle title={t('title.text')} />
      </div>

      <SearchFilter
        onSubmit={fetchWithFilters}
        cleanFilters={handleCleanFilters}
      />

      <div className="relative z-20">
        <Table
          headers={[
            t('table.headers.companyName'),
            t('table.headers.contactPerson'),
            t('table.headers.status'),
          ]}
          onRowClick={(supplier: SupplierWithCompany) =>
            navigate(`/suppliers/${supplier.id}`)
          }
          rowKeys={rowKeys}
          isLoading={isLoading}
          data={suppliers}
          loadMore={loadMore}
        />
      </div>
    </div>
  )
}

export default SupplierList
