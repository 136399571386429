/* eslint-disable import/prefer-default-export */

import { TenderInvitation } from '../../../../types'
import { tendersSubRoutes } from '../export-tenders/routes'

export type AvailableRouteStatus = Exclude<
  TenderInvitation['status'],
  'ARCHIVED' | 'REJECTED' | 'CREATED'
>

export const mappedTenderStatus: Record<
  AvailableRouteStatus,
  keyof typeof tendersSubRoutes | 'archived'
> = {
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  UNDER_OFFER: 'underOffer',
  WON: 'won',
  LOST: 'lost',
}
