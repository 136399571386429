import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import InputGroup from '../../../../../../components/molecules/input-group'
import Message from '../../../../../../components/atoms/message'
import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'

type FormValues = {
  brokerFee?: number
}

export interface BrokerFeeDrawerProps {
  siteId?: string
  brokerFee?: number
  isOpen?: boolean
  handleClose: () => void
  onSuccess: () => void
}

const UpdateBrokerFeeDrawer: React.FC<BrokerFeeDrawerProps> = ({
  handleClose,
  onSuccess,
  siteId,
  brokerFee,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.generators.details.updateBrokerFeeDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      brokerFee: brokerFee || 0,
    },
  })

  const handleOnSubmit = async (formData: any) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error, response } = await fetchData({
        method: 'PATCH',
        url: `/core/private/site/${siteId}/broker-fee`,
        body: {
          brokerFee: parseFloat(formData.brokerFee),
        },
      })

      if (error || !response) {
        throw error
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )

      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'brokerFeeRuleGroup',
      })
    }
    reset()
    setIsLoading(false)
    handleClose()
  }

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5 sm:w-[495px]">
        {isOpen && (
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="flex flex-col gap-y-5"
          >
            <h1 className="text-xl font-semibold text-ppa/title">
              {t('title')}
            </h1>
            <Message variant="info" size="text-xs self-center">
              {t('infoMessage')}
            </Message>
            <Controller
              name="brokerFee"
              control={control}
              render={({ field: props }) => (
                <InputGroup
                  label={t('form.brokerFee.title')}
                  error={errors.brokerFee?.message}
                >
                  <Input
                    {...props}
                    error={errors.brokerFee?.message}
                    suffix="£/MWh"
                  />
                </InputGroup>
              )}
            />
            <div className="flex items-center justify-end gap-x-5">
              <div className="max-w-[150px] w-full">
                <Button
                  type="submit"
                  variant="primary"
                  fullWidth
                  loading={isLoading}
                >
                  {t('form.submit')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </SlidingDrawer>
  )
}

export default UpdateBrokerFeeDrawer
