import React, { useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { PlusIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import usePPA from '../../../../../../hooks/use-ppa'

import Stepper from '../../../../../../components/atoms/stepper'
import { SelectOptions } from '../../../../../../components/atoms/select'
import Message from '../../../../../../components/atoms/message'
import Button from '../../../../../../components/atoms/button'
import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { parseToFloatFormValues } from '../../../../../../utils'

import { dateFormats } from '../../../../../../utils/data'
import { TenderSiteWithInvitation } from '../../../../../../types'
import { Quote } from '../../../../../../types/quote'

import TenderForm from '../form-tender'
import TargetPriceForm from '../form-target-price'

type Tender = TenderSiteWithInvitation

type ParsedSubsidies = {
  REGO?: boolean
  ROC?: boolean
  ROC_BUYOUT?: number
  ROC_RECYCLE?: number
  FIT?: boolean
  FIT_EXPORT?: boolean
}

interface QuoteProps {
  data?: Quote
  error?: string
}
interface CreateTenderProps {
  onSuccess: () => void
}

const CreateTender: React.FC<CreateTenderProps> = ({ onSuccess }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [currentStep, setCurrentStep] = useState<number>(0)

  const [createdTender, setCreatedTender] = useState<Tender | undefined>()
  const [quoteProps, setQuoteProps] = useState<QuoteProps | undefined>()

  const handleClose = () => {
    setIsLoading(false)
    setIsDrawerOpen(false)
    setCreatedTender(undefined)
    setQuoteProps({})
    setCurrentStep(0)
  }

  const handleFetchQuote = async (tender = createdTender) => {
    if (isLoading || !tender) {
      setQuoteProps({ error: 'NOT_AVAILABLE' })
      return
    }

    setIsLoading(true)

    try {
      const subsidies: {
        roc: boolean
        rego: boolean
        fit: boolean
        fitExport: boolean
      } = {
        roc: false,
        rego: false,
        fit: false,
        fitExport: false,
      }

      if (tender.subsidies.length > 0) {
        tender.subsidies.forEach((item) => {
          if (item.toggled === true) {
            if (item.name === 'FIT') {
              subsidies.fit = true
            } else if (item.name === 'REGO') {
              subsidies.rego = true
            } else if (item.name === 'ROC') {
              subsidies.roc = true
            }
          }
        })
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/quote/private/quote',
        body: {
          startDate: tender.startDate,
          endDate: tender.endDate,
          site: {
            technology: tender.site.technology,
            capacity: parseFloat(`${tender.site.capacity}`),
            voltage: parseFloat(`${tender.site.voltage}`),
            rocBand: parseFloat(`${tender.site?.rocBand || 0}`),
            mpan: tender.site.mpan,
            brokerFee: parseFloat(`${tender.site.brokerFee}`),
          },
          imbalance: 0,
          administration: 0,
          subsidies,
        },
      })

      if (err || !response) throw err

      setQuoteProps({ data: response.data.quote.pricing })
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)
      if (error instanceof AxiosError) {
        const errorType = error.response?.data?.errorType
        const errorMessage = error.response?.data?.message

        if (errorType === 'PAYLOAD_ERROR') {
          if (errorMessage) {
            if (
              errorMessage.includes('site.mpan') &&
              errorMessage.includes('is not allowed to be empty')
            ) {
              setQuoteProps({ error: 'MPAN_NOT_FOUND' })
              return
            }
          }
        }
      }

      setQuoteProps({ error: 'NOT_AVAILABLE' })
    }
  }

  const onTenderCreateSubmit = async (formData: any) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const siteIds = formData.sites.map(({ value }: SelectOptions) => {
        return JSON.parse(value as string).id
      })

      let supplierIds: string[] = []

      if (formData.participatingSuppliers?.length > 0) {
        supplierIds = formData.participatingSuppliers.map(
          (item: SelectOptions) => JSON.parse(item.value as string).id,
        )
      }

      const { fit, rego, roc } = formData.subsidies

      const subsidies: ParsedSubsidies = { REGO: !!rego?.enabled }
      const tenderCreatedSubsidies = []

      if (rego?.enabled)
        tenderCreatedSubsidies.push({ name: 'REGO', toggled: true })

      if (roc?.enabled) {
        tenderCreatedSubsidies.push({ name: 'ROC', toggled: true })
        subsidies.ROC = true
      } else if (fit?.enabled) {
        tenderCreatedSubsidies.push({ name: 'FIT', toggled: true })
        subsidies.FIT = true
      } else {
        subsidies.ROC = false
        subsidies.FIT = false
      }

      const { startDate, durationMonths, customEndDate, otherRequirements } =
        formData

      let { endDate } = formData
      if (customEndDate) {
        endDate = formData.endDate
      } else {
        endDate = dayjs(startDate)
          .add(durationMonths.value, 'months')
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormats.aws)
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/core/private/tender',
        body: {
          startDate,
          endDate,
          siteIds,
          supplierIds,
          subsidies,
          otherRequirements,
        },
      })

      if (err || !response) throw err

      if (siteIds.length > 1) {
        handleClose()
        onSuccess()
        return
      }

      const newCreatedTender = {
        ...response.data.tenders[0],
        subsidies: tenderCreatedSubsidies,
      }
      setCreatedTender(newCreatedTender)

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t(
            'drawerCreateUpdateTender.form.create.onSubmit.success.description',
          ),
        },
        'success',
      )

      setCurrentStep(1)
      await handleFetchQuote(newCreatedTender)
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateTargetPrice = async (formData: any) => {
    if (!createdTender?.id) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t(
            'drawerTargetPrice.edit.onSubmit.error.unableToLoadTender',
          ),
        },
        'error',
      )
      return
    }

    if (isLoading) return
    setIsLoading(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/tender/${createdTender.id}/update-target-price`,
        body: {
          targetPrice: parseToFloatFormValues(`${formData.targetPrice}`),
          minimumPrice: parseToFloatFormValues(`${formData.minimumPrice}`) || 0,
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('drawerTargetPrice.edit.onSubmit.success.title'),
          description: t('drawerTargetPrice.edit.onSubmit.success.description'),
        },
        'success',
      )
      handleClose()
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex items-center text-">
      <div className="ml-9">
        <Button
          variant="primary"
          icon={<PlusIcon />}
          onClick={() => setIsDrawerOpen(true)}
        >
          {t('page.title.addTender')}
        </Button>

        <SlidingDrawer isOpen={isDrawerOpen} handleClose={handleClose}>
          <div className="flex flex-col gap-y-5 sm:w-[640px]">
            <h1 className="text-xl font-bold text-ppa/title">
              {t('drawerCreateUpdateTender.form.create.title')}
            </h1>

            <Message variant="info" size="text-sm">
              {t('drawerCreateUpdateTender.form.create.info.0')}
              <br />
              {t('drawerCreateUpdateTender.form.create.info.1')}
            </Message>

            {isDrawerOpen && (
              <>
                <div className="w-full">
                  <Stepper
                    steps={[
                      t('drawerCreateUpdateTender.stepper.details'),
                      t('drawerCreateUpdateTender.stepper.targetPrice'),
                    ]}
                    activeStep={currentStep}
                    setActiveStep={setCurrentStep}
                    disabledStep={currentStep === 0 || currentStep === 1}
                  />
                </div>

                <section className="flex flex-1 w-full">
                  {currentStep === 0 && (
                    <TenderForm
                      isLoading={isLoading}
                      onCancel={handleClose}
                      onSubmit={onTenderCreateSubmit}
                    />
                  )}

                  {currentStep === 1 && (
                    <TargetPriceForm
                      refetchQuote={handleFetchQuote}
                      isLoading={isLoading}
                      onSubmit={handleUpdateTargetPrice}
                      onSkip={() => {
                        handleClose()
                        onSuccess()
                      }}
                      quote={quoteProps?.data}
                      quoteError={quoteProps?.error}
                      tender={createdTender}
                    />
                  )}
                </section>
              </>
            )}
          </div>
        </SlidingDrawer>
      </div>
    </div>
  )
}

export default CreateTender
