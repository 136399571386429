import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { v4 } from 'uuid'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import dayjs from 'dayjs'

import { TrashIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import { transition } from '../../../../../../../styles'

import { priceTypeList } from '../../../../../../../utils/data'

import InputGroup from '../../../../../../../components/molecules/input-group'
import Input from '../../../../../../../components/atoms/input'
import SelectComponent, {
  SelectOptions,
} from '../../../../../../../components/atoms/select'
import Button from '../../../../../../../components/atoms/button'

import {
  PowerPriceName,
  PowerPriceNameWithMultiple,
  Tender,
} from '../../../../../../../types'

type PeriodForm = {
  id: string
  periodId: string
  title: string
  startDate?: string
  endDate?: string
  value?: string
  priceType: SelectOptions<PowerPriceName>
}

type FormValues = {
  periods: PeriodForm[]
}

interface CustomPricingFormProps {
  tender: Tender
  contractType: SelectOptions<PowerPriceNameWithMultiple>
  startDate: string
  endDate: string
}

const priceTypes = priceTypeList.filter(
  (item) => item.value !== 'MULTIPLE_TYPES',
)

const CustomPricingForm: React.FC<CustomPricingFormProps> = ({
  contractType,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.bids.form',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const {
    control,
    register,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext<FormValues>()

  const { fields, replace } = useFieldArray({
    control,
    name: 'periods',
  })

  const formValues = getValues()

  const priceTypeOptions = useMemo(() => {
    return priceTypes.map((item) => {
      return {
        value: item.value,
        label: tUtils(`priceType.${item.label}`),
      }
    })
  }, [])

  const addPeriod = useCallback(() => {
    if (contractType.value !== 'MULTIPLE_TYPES' || !watch('periods')) return

    const periods = watch('periods')
    const periodsLen = periods.length

    if (periodsLen === 1) {
      const [first] = periods
      const newPeriods = [
        { ...first, endDate: '' },
        {
          ...first,
          id: v4(),
          periodId: v4(),
          startDate: '',
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          value: '0',
        },
      ]
      replace(newPeriods as any)
      return
    }

    const newPeriods = [
      ...periods.slice(0, -1),
      {
        ...periods[0],
        id: v4(),
        periodId: v4(),
        startDate: '',
        endDate: '',
        value: '0',
      },
      {
        ...periods.slice(-1)[0],
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
      },
    ]

    replace(newPeriods as any)
  }, [replace, watch('periods'), contractType])

  const deletePeriod = useCallback(
    (id: string) => {
      if (contractType.value !== 'MULTIPLE_TYPES' || !watch('periods')) return

      const periods = watch('periods')
      const periodLen = periods.length
      if (periodLen <= 1) return

      if (periodLen === 2) {
        const [first] = periods
        const newPeriods = [
          {
            ...first,
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
          },
        ]
        replace(newPeriods as any)
        return
      }

      const filteredPeriods = periods.filter((item) => item.periodId !== id)
      const newPeriods = [
        ...filteredPeriods.slice(0, -1),
        {
          ...filteredPeriods.slice(-1)[0],
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        },
      ]

      replace(newPeriods as any)
    },
    [replace, watch('periods'), contractType],
  )

  return (
    <>
      <h2 className="text-ppa/textBlue text-base font-medium">
        {t('fields.customPricing.label')}
      </h2>

      {fields.map((period, idx: number) => {
        const isContractTypeMultipleTypes =
          contractType.value !== 'MULTIPLE_TYPES'

        const startDateRegister = {
          ...register(`periods.${idx}.startDate`, {
            required: {
              message: t('fields.customPricing.startDate.required'),
              value: true,
            },
          }),
          ref: undefined,
        }
        const endDateRegister = {
          ...register(`periods.${idx}.endDate`, {
            required: {
              message: t('fields.customPricing.endDate.required'),
              value: true,
            },
          }),
          ref: undefined,
        }

        const priceTypeRegister = {
          ...register(`periods.${idx}.priceType`),
          ref: undefined,
        }
        const valueRegister = {
          ...register(`periods.${idx}.value`),
          ref: undefined,
        }

        return (
          <div
            key={period.id}
            className={classNames('border-l border-ppa/grayBorder')}
          >
            <div className="ml-3">
              <div className="flex justify-between">
                <h3 className="text-ppa/title text-sm font-medium">
                  {period.title ||
                    `${t('fields.customPricing.titlePeriod')} ${idx + 1}`}
                </h3>

                {!isContractTypeMultipleTypes && idx !== 0 && (
                  <div
                    className={classNames(
                      transition,
                      'fade-in',
                      'flex items-center justify-center',
                    )}
                  >
                    <TrashIcon className="w-4 h-4" />
                    <button
                      type="button"
                      className="text-ppa/title text-xs font-medium underline"
                      onClick={() => deletePeriod(period.periodId)}
                    >
                      {t('fields.customPricing.button.deletePeriod')}
                    </button>
                  </div>
                )}
              </div>

              <div className="flex gap-x-6 my-2">
                <div className="flex flex-1">
                  <Controller
                    {...startDateRegister}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('fields.customPricing.startDate.label')}
                        required
                        error={
                          errors?.periods &&
                          errors?.periods[idx]?.startDate?.message
                        }
                      >
                        <Input
                          {...props}
                          type="date"
                          min={startDate}
                          max={endDate}
                          disabled={idx === 0 || isContractTypeMultipleTypes}
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <div className="flex flex-1">
                  <Controller
                    {...endDateRegister}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('fields.customPricing.endDate.label')}
                        required
                        error={
                          errors?.periods &&
                          errors?.periods[idx]?.endDate?.message
                        }
                      >
                        <Input
                          {...props}
                          type="date"
                          min={startDate}
                          max={endDate}
                          disabled={
                            fields.length - 1 === idx ||
                            isContractTypeMultipleTypes
                          }
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
              <InputGroup label={t('fields.customPricing.priceType.label')}>
                <div className="mb-2">
                  <Controller
                    {...priceTypeRegister}
                    render={({ field: { ref: _, ...props } }) => (
                      <div className="w-full">
                        <SelectComponent
                          {...props}
                          type="single"
                          disableClean
                          options={priceTypeOptions}
                          aria-label="priceType"
                          testId={`select-periods.${idx}.priceType`}
                          disabled={isContractTypeMultipleTypes}
                        />
                      </div>
                    )}
                  />
                </div>
              </InputGroup>

              <InputGroup label={t('fields.customPricing.priceType.value')}>
                <Controller
                  {...valueRegister}
                  render={({ field: { ...props } }) => (
                    <div className="flex space-x-4 items-center max-w-[175px]">
                      <Input
                        {...props}
                        type="number"
                        suffix={
                          ['DAY_AHEAD', 'SPILL'].includes(
                            formValues.periods[idx].priceType.value,
                          )
                            ? '%'
                            : '£/MWh'
                        }
                        placeholder="0"
                      />
                    </div>
                  )}
                />
              </InputGroup>
            </div>
          </div>
        )
      })}
      <div className="flex justify-end mt-2.5">
        <Button
          variant="tertiary"
          type="button"
          icon={<PlusIcon />}
          disabled={contractType.value !== 'MULTIPLE_TYPES'}
          onClick={addPeriod}
        >
          {t('fields.customPricing.button.addPeriod')}
        </Button>
      </div>
    </>
  )
}

export default CustomPricingForm
