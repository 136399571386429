import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { isFeatureEnabled } from '../../../../../../utils/features'

import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'
import InputFile from '../../../../../../components/atoms/input-file'
import TextArea from '../../../../../../components/atoms/text-area'
import SelectComponent from '../../../../../../components/atoms/select'

import InputGroup from '../../../../../../components/molecules/input-group'

type FormData = {
  logoFile?: File
  description?: string
  totalCapacity?: number
  backing?: string[]
  lenders?: string[]
  other?: string
}

interface FormProps {
  supplierProfile?: FormData
  onSkip?: () => void
  onSubmit: (data: any) => void
}
const EditSupplierForm: React.FC<FormProps> = ({
  supplierProfile,
  onSubmit,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.editSupplierForm',
  })

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: {
      description: supplierProfile?.description || '',
      totalCapacity: supplierProfile?.totalCapacity || 0,
      backing: supplierProfile?.backing || [],
      lenders: supplierProfile?.lenders || [],
      other: supplierProfile?.other || '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-y-5 px-0.5">
        <h1 className="text-xl text-ppa/title font-bold">{t('title')}</h1>
        {isFeatureEnabled('supplierLogoFile') && (
          <Controller
            name="logoFile"
            control={control}
            render={({ field: { onChange: _, ref: __, ...props } }) => (
              <InputGroup
                label={t('setup.fields.logo.label')}
                infoLabel={t('setup.fields.logo.infoLabel')}
              >
                <InputFile
                  {...props}
                  name="logoFile"
                  setValue={() => console.log('this is logo file')}
                  aria-label="logoFile"
                  clickToRemove
                  error={formErrors?.logoFile?.message}
                />
              </InputGroup>
            )}
          />
        )}
        <Controller
          name="description"
          control={control}
          render={({ field: { ...props } }) => (
            <InputGroup label={t('fields.servicesText.label')}>
              <TextArea
                className="w-full h-32 rounded-md border border-ppa/placeholder pt-1 pl-2.5 text-ppa/title"
                placeholder={t('fields.servicesText.placeholder')}
                aria-label="name"
                error={formErrors?.description?.message}
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="totalCapacity"
          control={control}
          render={({ field: { ...props } }) => (
            <InputGroup label={t('fields.renewableEnergyUnderOperation.label')}>
              <Input
                type="text"
                placeholder="0.0"
                suffix="MW"
                aria-label="totalCapacity"
                error={formErrors?.totalCapacity?.message}
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="backing"
          control={control}
          render={({ field: { ref: _, ...props } }) => (
            <InputGroup label={t('fields.financialBacking.label')}>
              <SelectComponent
                type="multi"
                options={[
                  {
                    value: 'bankBond',
                    label: t('fields.financialBacking.options.bankBond.label'),
                  },
                  {
                    value: 'pcg',
                    label: t('fields.financialBacking.options.pcg.label'),
                  },
                  {
                    value: 'strongCreditRating',
                    label: t(
                      'fields.financialBacking.options.strongCreditRating.label',
                    ),
                  },
                ]}
                aria-label="backing"
                testId="select-financial"
                error={formErrors?.backing?.message}
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="lenders"
          control={control}
          render={({ field: { ref: _, onChange, value, ...props } }) => (
            <InputGroup label={t('fields.directlyAuthorisedLenders.label')}>
              <SelectComponent
                type="multi"
                options={[]}
                onCreateOption={(label) => {
                  return {
                    label,
                    value: label,
                  }
                }}
                value={value}
                onChange={onChange}
                aria-label="directlyAuthorisedLenders"
                testId="select-lenders"
                error={formErrors?.lenders?.message}
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="other"
          control={control}
          render={({ field: { ...props } }) => (
            <InputGroup label={t('fields.marketingText.label')}>
              <TextArea
                className="w-full h-32 rounded-md border border-ppa/placeholder pt-1 pl-2.5 text-ppa/title"
                placeholder="Example: this and that, none, etc"
                aria-label="marketingText"
                error={formErrors?.other?.message}
                {...props}
              />
            </InputGroup>
          )}
        />
      </div>
      <div className="flex justify-end">
        <div className="mt-5 ml-5 w-40">
          <Button
            variant="primary"
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('submitButton')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default EditSupplierForm
