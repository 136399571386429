import React, { useEffect, useMemo } from 'react'

import classNames from 'classnames'

import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import TenderDetails from '../components/details'
import BidDetails from '../components/details-bid'
import SleevedConsumerTenderDetailsDefList from '../components/details-consumer-tender'
import MeterDetailsDefList from '../components/details-meter'

import { SleevedTenderDetailsProps } from '../details'

const UnderOfferSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { isLoading, throwFeedbackError, data, error } = usePPAGetDetails<any>({
    dataKey: 'supplierTenderBid',
    path: `/core/private/supplier-tender-bid/supplier/details/${tenderInvitation.supplierTenderBid.id}`,
  })

  const bidDetails: any | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  return (
    <section className="flex flex-col gap-y-8">
      <div className="flex-1">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>
      <div className={classNames('flex flex-col', '2xl:flex-row')}>
        <div
          className={classNames(
            'flex flex-col flex-1 gap-y-4',
            'border-ppa/grayBorder',
            'border-t mt-10 pt-10',
            '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          )}
        >
          <BidDetails bid={bidDetails} refetchBidList={refetch} />
          <SleevedConsumerTenderDetailsDefList
            data={tenderInvitation.supplierTender.consumerTender as any}
          />
          <MeterDetailsDefList
            data={
              tenderInvitation.supplierTender.consumerTender
                .consumerMeter as any
            }
          />
        </div>
      </div>
    </section>
  )
}

export default UnderOfferSleevedTenderDetails
