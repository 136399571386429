import dayjs from 'dayjs'
import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import { parseQuery } from '../../../../../hooks/use-ppa/use-ppa-get-list'
import {
  ChartJS,
  Chart,
  ChartData,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from '../../../../../components/atoms/slider/chart'

import { ReactComponent as Spinner } from '../../../../../assets/spinner/spinner.svg'
import { dateFormats } from '../../../../../utils/data'

type dayAheadSpillListData = {
  id: string
  name: string
  date: string
  price1: number
  price2: number
  price3: number
  period: number
  createdAt: string
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

function periodToTime(period: number) {
  return {
    1: '0:30',
    2: '1:00',
    3: '1:30',
    4: '2:00',
    5: '2:30',
    6: '3:00',
    7: '3:30',
    8: '4:00',
    9: '4:30',
    10: '5:00',
    11: '5:30',
    12: '6:00',
    13: '6:30',
    14: '7:00',
    15: '7:30',
    16: '8:00',
    17: '8:30',
    18: '9:00',
    19: '9:30',
    20: '10:00',
    21: '10:30',
    22: '11:00',
    23: '11:30',
    24: '12:00',
    25: '12:30',
    26: '13:00',
    27: '13:30',
    28: '14:00',
    29: '14:30',
    30: '15:00',
    31: '15:30',
    32: '16:00',
    33: '16:30',
    34: '17:00',
    35: '17:30',
    36: '18:00',
    37: '18:30',
    38: '19:00',
    39: '19:30',
    40: '20:00',
    41: '20:30',
    42: '21:00',
    43: '21:30',
    44: '22:00',
    45: '22:30',
    46: '23:00',
    47: '23:30',
    48: '0:00',
  }[period]
}

interface DayAheadSpilltProps {
  isLoading: boolean
}

const DayAheadSpillChart: React.FC<DayAheadSpilltProps> = ({ isLoading }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'market.dayaheadSpillChart',
  })

  const { fetchData } = usePPA()

  const [isLoadingDayAhead, setIsLoadingDayAhead] = useState(false)
  const [listPowerDayAhead, setListPowerDayAhead] =
    useState<dayAheadSpillListData[]>()

  const [isLoadingSpill, setIsLoadingSpill] = useState(false)
  const [listPowerSpill, setListPowerSpill] =
    useState<dayAheadSpillListData[]>()

  const [minPowerSpill, setMinPowerSpill] = useState<number>(0)
  const [minPowerDayAhead, setMinPowerDayAhead] = useState<number>(0)

  const getListPowerDayAhead = async () => {
    if (isLoading || isLoadingDayAhead || listPowerDayAhead) return
    setIsLoadingDayAhead(true)

    try {
      const { error: errorDayAhead, response } = await fetchData({
        method: 'GET',
        url: `/market/private/power/day-ahead?${parseQuery({
          pagination: {
            offset: 0,
            limit: 50,
          },
          filters: [
            {
              field: 'date',
              operator: 'equals',
              value: dayjs().subtract(1, 'day').format(dateFormats.aws),
            },
          ],
        })}`,
      })

      if (errorDayAhead || !response?.data) throw errorDayAhead

      let minPrice = minPowerDayAhead || 0

      response.data.marketDataResult.forEach(
        ({ price1 }: dayAheadSpillListData) => {
          if (price1 < minPrice) minPrice = price1
        },
      )

      setMinPowerDayAhead(minPrice)

      setListPowerDayAhead(response.data.marketDataResult)
      setIsLoadingDayAhead(false)
    } catch (err) {
      setIsLoadingDayAhead(false)
      console.error(err)
    }
  }

  const getListPowerSpill = async () => {
    if (isLoading || isLoadingSpill || listPowerSpill) return
    setIsLoadingSpill(true)

    try {
      const { error: errorSpill, response } = await fetchData({
        method: 'GET',
        url: `/market/private/power/spill?${parseQuery({
          pagination: {
            offset: 0,
            limit: 50,
          },
          filters: [
            {
              field: 'date',
              operator: 'equals',
              value: dayjs().format(dateFormats.aws),
            },
          ],
        })}`,
      })

      if (errorSpill || !response?.data) throw errorSpill

      let minPrice = minPowerSpill || 0

      response.data.marketDataResult.forEach(
        ({ price1 }: dayAheadSpillListData) => {
          if (price1 < minPrice) minPrice = price1
        },
      )

      setMinPowerSpill(minPrice)

      setListPowerSpill(response.data.marketDataResult)
      setIsLoadingSpill(false)
    } catch (err) {
      setIsLoadingSpill(false)
      console.error(err)
    }
  }

  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    labels: [],
    datasets: [],
  })

  const [parsedData, setParsedData] = useState<
    { dates: string[]; spill: number[]; dayAhead: number[] } | undefined
  >()

  useEffect(() => {
    if (!listPowerSpill || !listPowerDayAhead) return
    if (isLoadingSpill || isLoadingDayAhead) return

    const datesResult: string[] = []
    const spillPriceResult: number[] = []
    const dayAheadPriceResult: number[] = []

    const maxListLength = Math.max(
      listPowerSpill.length,
      listPowerDayAhead.length,
    )

    // eslint-disable-next-line no-plusplus
    for (let idx = 0; idx < maxListLength; idx++) {
      const dayAheadItem = listPowerDayAhead[idx]
      const spillItem = listPowerSpill[idx]

      if (dayAheadItem || spillItem) {
        let eventTimeControl = false
        if (spillItem) {
          const {
            period,
            price1: spillprice1,
            price2: spillprice2,
            price3: spillprice3,
          } = spillItem
          spillPriceResult.push(spillprice1 || spillprice2 || spillprice3)

          const eventTime = periodToTime(period - 2)
          if (eventTime) {
            datesResult.push(eventTime)
            eventTimeControl = true
          }
        }

        if (dayAheadItem) {
          const {
            period,
            price1: dayAheadprice1,
            price2: dayAheadprice2,
            price3: dayAheadprice3,
          } = dayAheadItem
          dayAheadPriceResult.push(
            dayAheadprice1 || dayAheadprice2 || dayAheadprice3,
          )
          const eventTime = periodToTime(period - 2)
          if (eventTime && !eventTimeControl) {
            datesResult.push(eventTime)
          }
        }
      }
    }

    setParsedData({
      dates: datesResult,
      spill: spillPriceResult,
      dayAhead: dayAheadPriceResult,
    })
  }, [listPowerDayAhead, listPowerSpill])

  useEffect(() => {
    if (isLoading) return

    getListPowerDayAhead()
  }, [])

  useEffect(() => {
    if (isLoading || listPowerSpill) return

    getListPowerSpill()
  }, [])

  useEffect(() => {
    if (!chartRef?.current) return
    if (!parsedData) return

    setChartData({
      labels: parsedData.dates,
      datasets: [
        {
          label: 'System Sell Price',
          data: parsedData.spill.map((data) => data),
          pointBorderColor: 'transparent',
          pointHoverBorderColor: '#000',
          borderColor: '#0090FF',
          backgroundColor: '#0090FF',
          tension: 0.01,
        },
        {
          label: 'Day-ahead',
          data: parsedData.dayAhead.map((data) => data),
          pointBorderColor: 'transparent',
          pointHoverBorderColor: '#000',
          borderColor: '#83BB35',
          backgroundColor: '#83BB35',
          tension: 0.01,
        },
      ],
    })
  }, [parsedData])

  return (
    <div>
      {(isLoadingSpill || isLoadingDayAhead) && (
        <div className="w-16 mt-2 min-w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoadingSpill && !isLoadingDayAhead && !parsedData && (
        <h1 className="text-ppa/textBlue text-xl font-semibold my-7">
          {t('infoMessage')}
        </h1>
      )}

      {!isLoadingSpill && !isLoadingDayAhead && parsedData && (
        <div className="relative h-96">
          <Chart
            ref={chartRef}
            type="line"
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              interaction: { intersect: false },
              plugins: {
                tooltip: {
                  titleAlign: 'center',
                  mode: 'index',
                  padding: 10,
                },
                legend: {
                  position: 'bottom',
                  labels: {
                    color: '#4C5172',
                    boxWidth: 30,
                    boxHeight: 0.4,
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: '#4C5172',
                    font: {
                      size: 12,
                      weight: '300',
                      family: 'Lexend, sans-serif',
                    },
                    autoSkipPadding: 5,
                  },
                  min: 0,
                },
                y: {
                  ticks: {
                    color: '#4C5172',
                    font: {
                      size: 12,
                      weight: '300',
                      family: 'Lexend, sans-serif',
                    },
                    callback: (value) => {
                      return `£ ${Number(value).toFixed()}`
                    },
                  },
                  min: Math.min(minPowerSpill, minPowerDayAhead),
                },
              },
            }}
          />
        </div>
      )}
    </div>
  )
}

export default DayAheadSpillChart
