import React, { InputHTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'
import {
  transition,
  defaultFocusAnimation,
  defaultTextBoxStyles,
} from '../../../styles'

export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  [x: string]: any
}

const TextArea = forwardRef<any, TextAreaProps>(({ ...rest }, ref) => {
  return (
    <textarea
      className={classNames(
        transition,
        defaultFocusAnimation,
        defaultTextBoxStyles,
        'font-normal text-sm px-3 py-1.5 min-h-[100px]',
      )}
      {...rest}
      ref={ref}
    />
  )
})

export default TextArea
