import React from 'react'
import LogoSVG from '../../../assets/logos/ppaya/main-logo.svg'

const Loader: React.FC = () => {
  return (
    <section className="flex flex-col items-center justify-center bg-white w-full h-full fixed top-0 left-0">
      <img
        className="w-full h-auto max-w-[175px]"
        src={LogoSVG}
        alt="PPAYA logo"
      />
    </section>
  )
}

export default Loader
