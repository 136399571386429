/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ChevronLeftIcon, BanIcon, TrashIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import usePPA from '../../../../hooks/use-ppa'
import useUser from '../../../../hooks/use-user'

import { isFeatureEnabled } from '../../../../utils/features'

import { container, breakPointsContainer } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'
import { ReactComponent as UserInOrgIcon } from '../assets/UserInOrgIcon.svg'
import { ReactComponent as UserIcon } from '../assets/userIcon.svg'
import { ReactComponent as UnblockAccess } from '../assets/locker-unlock.svg'
import { ReactComponent as CheckIcon } from '../assets/checkIcon.svg'
import { ReactComponent as XIcon } from '../assets/xIcon.svg'

import UserPill from '../../components/user-pill'
import Dropdown from '../../../../components/atoms/dropdown'
import DefList from '../../../../components/atoms/def-list'
import Button from '../../../../components/atoms/button'
import { feedbackMessage } from '../../../../components/atoms/feedback'
import Table, { TableConfigProps } from '../../../../components/atoms/table'

import CompanyDefListModal from '../../../../components/organisms/company-details-modal'

import { Member, Consumer } from '../../../../types'

export type ConsumerDetails = Consumer & {
  members: Member[]
}

const rowKeys: TableConfigProps[] = [
  {
    keyName: 'name',
    renderCustomEl: (member: Member) => {
      if (member.firstName || member.lastName || member.email) {
        return (
          <div className="flex items-center">
            <UserInOrgIcon />
            <div className="ml-5 font-normal">
              {member.firstName} {member.lastName}
              <div className="font-light">{member?.email}</div>
            </div>
          </div>
        )
      }
      return undefined
    },
  },
  {
    keyName: 'phoneNumber',
    renderCustomEl: (member: Member) => {
      if (!member.phoneNumber) return undefined
      return member.phoneNumber
    },
  },
]

const ConsumerDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumers.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { actAs } = useUser<'ADMIN'>()
  const { fetchData, throwFeedbackError } = usePPA()
  const navigate = useNavigate()

  const { consumerId } = useParams<{ consumerId?: string }>()

  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<
    ConsumerDetails['parentCompany'] | null
  >(null)

  const {
    isLoading,
    refetch,
    throwFeedbackError: detailsThrowFeedbackError,
    data,
    error,
  } = usePPAGetDetails<ConsumerDetails>({
    dataKey: 'consumer',
    path: `/core/private/consumer/admin/details/${consumerId}`,
  })

  const consumerDetails = useMemo((): ConsumerDetails | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const rowKeysCompany = useMemo(() => {
    return [
      {
        keyName: 'company.name',
        title: t('defList.company.name'),
        renderCustomEl: (consumer: ConsumerDetails) => {
          if (!consumer.parentCompany) return undefined
          return (
            <div
              onClick={() => {
                setSelectedCompany(consumer.parentCompany)
                setModalOpen(true)
              }}
              className="border-b border-ppa/grayText cursor-pointer"
            >
              {consumer.parentCompany.name}
            </div>
          )
        },
      },
      {
        keyName: 'company.associatedCompanies',
        title: t('defList.company.associatedCompanies'),
        renderCustomEl: (consumer: ConsumerDetails) => {
          if (!consumer.companies) return undefined
          const filteredCompanies = consumer.companies.filter(
            (company) => company.id !== consumer.parentCompany?.id,
          )
          const associatedCompanies = filteredCompanies.map(
            (company, index) => (
              <span
                key={company.id}
                onClick={() => {
                  setSelectedCompany(company)
                  setModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer"
              >
                {company.name}
                {index < filteredCompanies.length - 1 && ', '}
              </span>
            ),
          )
          return (
            <div className="cursor-pointer flex flex-row space-x-2">
              {associatedCompanies.length ? associatedCompanies : '-'}
            </div>
          )
        },
      },
    ]
  }, [consumerDetails])

  const handleBlock = async () => {
    if (!consumerDetails || consumerDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/consumer/disable/${consumerDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumer',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleDelete = async () => {
    if (!consumerDetails || !consumerDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'DELETE',
        url: `/core/private/consumer/${consumerDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      navigate('/consumers')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumer',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Failed to delete user in auth0')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.delete',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleUnblock = async () => {
    if (!consumerDetails || !consumerDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/consumer/enable/${consumerDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumer',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleAcceptOnboarding = async () => {
    if (!consumerDetails || consumerDetails.status !== 'REVIEW') return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/consumer/admin/approve/${consumerDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumer',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleRejectOnboarding = async () => {
    if (!consumerDetails || consumerDetails.status !== 'REVIEW') return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/consumer/admin/reject/${consumerDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      navigate('/consumers')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumer',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleActAs = () => {
    if (consumerDetails === undefined) return
    if (consumerDetails.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: 'You cannot do this action.',
          description:
            'Users that are not active cannot being used in "acting as".',
        },
        'warn',
      )
      return
    }

    const { id, name, members } = consumerDetails

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'CONSUMER',
        status: consumerDetails.status,
        userId: consumerDetails.userId,
        brokerContextName: consumerDetails.brokerContextName,
      },
    })

    navigate('/meters')
  }

  useEffect(() => {
    if (error) {
      detailsThrowFeedbackError({ err: error })
    }
  }, [error])

  useEffect(() => {
    if (!consumerId) {
      navigate('/consumers')
    }
  }, [consumerId])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/consumers')}
        >
          {t('backButton')}
        </Button>
      </div>
      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && error && (
        <div>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}
      {!isLoading && !error && consumerDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-1 items-center justify-start mt-5 gap-x-3">
              <h1 className="text-3xl font-semibold text-ppa/title mr-4">
                {consumerDetails.name}
              </h1>
              <UserPill
                status={consumerDetails.status}
                blocked={consumerDetails.blocked}
              />
              {consumerDetails.blocked && (
                <div className="flex items-center gap-x-2 font-semibold text-xs">
                  <span>{t('userBlocked')}</span>
                  <BanIcon className="w-5 h-5 text-ppa/redError" />
                </div>
              )}
            </div>
            <Dropdown
              content={
                <div className="flex flex-col items-start">
                  {consumerDetails.status === 'REVIEW' && (
                    <>
                      <Button
                        variant="text"
                        icon={<CheckIcon />}
                        onClick={handleAcceptOnboarding}
                        data-testid="button-accept-onboarding"
                      >
                        {t('actionsDropdown.accept')}
                      </Button>
                      <Button
                        variant="text"
                        icon={<XIcon />}
                        onClick={handleRejectOnboarding}
                        data-testid="button-reject-onboarding"
                      >
                        {t('actionsDropdown.reject')}
                      </Button>
                    </>
                  )}
                  {consumerDetails.blocked ? (
                    <>
                      <Button
                        variant="text"
                        icon={<UnblockAccess />}
                        onClick={handleUnblock}
                        data-testid="button-unblock"
                      >
                        {t('actionsDropdown.unblock')}
                      </Button>
                      <Button
                        variant="text"
                        icon={<TrashIcon />}
                        onClick={handleDelete}
                        data-testid="button-delete"
                      >
                        {t('actionsDropdown.delete.button')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="text"
                      icon={<BanIcon />}
                      onClick={handleBlock}
                      data-testid="button-block"
                    >
                      {t('actionsDropdown.block')}
                    </Button>
                  )}
                  {!consumerDetails.blocked &&
                    consumerDetails.status === 'ACTIVE' && (
                      <Button
                        variant="text"
                        icon={<UserIcon />}
                        onClick={handleActAs}
                        data-testid="button-act-as"
                      >
                        {t('actionsDropdown.actAs')}
                      </Button>
                    )}
                </div>
              }
            />
          </div>
          <div className="flex flex-col mr-auto">
            <h2 className="mt-2 mb-5  text-ppa/title text-lg font-medium">
              {t('defList.company.title')}
            </h2>
            <DefList rowKeys={rowKeysCompany} data={data} />
            {/* <DefList rowKeys={rowKeysCompaniesList} data={companies} /> */}
          </div>

          {consumerDetails.parentCompany &&
            consumerDetails.parentCompany.members &&
            consumerDetails.parentCompany.members.length > 0 && (
              <div className="flex flex-col mr-auto gap-y-2 text-ppa/title text-lg font-medium">
                <h2 className="mt-2 mb-5">
                  {t('usersInOrganisationDetails.title')}
                </h2>
                <Table
                  headers={[
                    t('table.headers.userDetails'),
                    t('table.headers.phoneNumber'),
                    t('table.headers.memberType'),
                    isFeatureEnabled('memberStatusAdmin') &&
                      t('table.headers.status'),
                  ]}
                  rowKeys={[
                    ...rowKeys,
                    {
                      keyName: 'memberType',
                      renderCustomEl: (member: Member) =>
                        t(`table.body.memberType.${member.memberType}`),
                    },
                    // {
                    //   keyName: 'status',
                    //   renderCustomEl: (member: Member) => (
                    //     <UserPill>{member.status}</UserPill>
                    //   ),
                    // },
                    // {
                    //   keyName: 'action',
                    //   renderCustomEl: (member: Member) => {
                    //     return (
                    //       <Dropdown
                    //         orientation="bottom-left"
                    //         content={
                    //           <button
                    //             type="button"
                    //             onClick={() => handleDeleteMember(member)}
                    //             className="flex items-center w-24"
                    //           >
                    //             <TrashIcon className="w-4 h-4 mr-2.5" />
                    //             {t('deleteButton')}
                    //           </button>
                    //         }
                    //       />
                    //     )
                    //   },
                    // },
                  ]}
                  isLoading={isLoading}
                  data={consumerDetails.parentCompany.members}
                />
              </div>
            )}
        </>
      )}
      {selectedCompany && (
        <CompanyDefListModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          title={t('defList.company.modalTitle', {
            companyName: selectedCompany.name,
          })}
          companyData={selectedCompany}
        />
      )}
    </section>
  )
}

export default ConsumerDetails
