import React from 'react'

import { TenderStatusProps } from '../details'

import TenderDetails from '../components/details'

const TendersWithdrawnDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
}) => {
  return <TenderDetails tender={tenderDetails} />
}

export default TendersWithdrawnDetails
