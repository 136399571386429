import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import usePPA from '../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import { ConsumerTenderDetailsProps } from '../details'

import ConsumerTenderDetails, { ButtonList } from '../components/details'
import BidDetails from '../components/details-bid'

const ConsumerTendersInvitedDetails: React.FC<ConsumerTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isAccepting, setIsAccepting] = useState(false)

  const handleAcceptInvitation = async () => {
    if (isAccepting) return
    setIsAccepting(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/consumer-tender/supplier/accept-invitation',
        body: { consumerTenderInvitationId: tenderInvitation.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.acceptInvitation.success'),
        },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsAccepting(false)
    }
  }

  const [isDeclining, setIsDeclining] = useState(false)

  const handleDeclineInvitation = async () => {
    if (isDeclining) return
    setIsDeclining(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/consumer-tender/supplier/reject-invitation',
        body: { consumerTenderInvitationId: tenderInvitation.id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.declineInvitation.success'),
        },
        'success',
      )

      navigate('/consumerTenders/status/pending')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsDeclining(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    return [
      {
        text: t('actions.acceptInvitation.button'),
        onClick: handleAcceptInvitation,
        props: { variant: 'primary', loading: isAccepting },
      },
      {
        text: t('actions.declineInvitation.button'),
        onClick: handleDeclineInvitation,
        props: { variant: 'secondary', loading: isDeclining },
      },
    ]
  }, [tenderInvitation])

  return (
    <div className="flex flex-col 2xl:flex-row">
      <div className="flex-1">
        <ConsumerTenderDetails tender={tenderInvitation} buttons={buttons} />
      </div>
      <div
        className={classNames(
          'border-ppa/grayBorder',
          'border-t mt-10 pt-10',
          '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          'flex-1',
        )}
      >
        <BidDetails
          consumerTenderInvitation={tenderInvitation}
          refetchBidList={refetch}
        />
      </div>
    </div>
  )
}

export default ConsumerTendersInvitedDetails
