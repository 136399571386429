import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import { PlusIcon } from '@heroicons/react/solid'

import useRegisterCompany from '../../../../../../hooks/use-register-company'

import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'
import InputGroup from '../../../../../../components/molecules/input-group'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { SelectOptions } from '../../../../../../components/atoms/select'

import SelectExistingCompanies, {
  SelectCompaniesRef,
} from '../../../../../../components/molecules/company-select'

import SelectTechnologies from '../select-technologies'

export type FormValues = {
  name?: string
  technology?: SelectOptions
  location?: string
  capacity?: string | number
  voltage?: string | number
  rocBand?: string | number
  mpan?: string
  msid?: string
  company?: SelectOptions
}

export interface SiteFormProps {
  onCancel: () => void
  onSubmit: (data: any) => void
  defaultValues?: FormValues
  isLoading?: boolean
}

const formDefaultValues: FormValues = {
  name: '',
  location: '',
  capacity: '',
  voltage: '',
  mpan: '',
  msid: '',
  rocBand: '',
  company: undefined,
}

const SiteForm: React.FC<SiteFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.siteDrawer.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const selectCompaniesRef = useRef<SelectCompaniesRef>(null)
  const { drawer, openDrawer } = useRegisterCompany({
    onSuccess: (company) => {
      if (selectCompaniesRef.current?.refetch)
        selectCompaniesRef.current.refetch()

      feedbackMessage(
        {
          title: t('registerCompany.onSuccessMessage.title'),
          description: t('registerCompany.onSuccessMessage.description', {
            companyName: company.name,
          }),
        },
        'success',
      )
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  useEffect(() => {
    reset(defaultValues || formDefaultValues)
  }, [defaultValues])

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mx-px gap-y-5 min-w-[500px] max-w-[510px]"
      >
        <Controller
          name="company"
          control={control}
          rules={{ required: { value: true, message: t('company.required') } }}
          render={({ field: { ref: _, ...props } }) => (
            <div className="flex flex-col gap-x-2">
              <InputGroup
                label={t('company.title')}
                infoLabel={t('company.infoLabel')}
                error={errors.company?.message}
                required
              >
                <SelectExistingCompanies
                  {...props}
                  ref={selectCompaniesRef}
                  placeholder={t('company.placeholder')}
                />
              </InputGroup>
              <div className="flex justify-end">
                <Button
                  variant="text"
                  type="button"
                  icon={<PlusIcon />}
                  onClick={openDrawer}
                >
                  {t('addNewCompany')}
                </Button>
              </div>
            </div>
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{
            required: { value: true, message: t('name.required') },
            minLength: {
              value: 3,
              message: t('name.minLength'),
            },
          }}
          render={({ field: props }) => (
            <InputGroup
              label={t('name.title')}
              error={errors.name?.message}
              required
            >
              <Input
                {...props}
                placeholder={t('name.placeholder')}
                error={errors.name?.message}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="technology"
          control={control}
          rules={{
            required: { value: true, message: t('technology.required') },
          }}
          render={({ field: { ref: _, value, onChange, ...props } }) => (
            <InputGroup
              label={t('technology.title')}
              error={errors.technology?.message}
              required
            >
              <SelectTechnologies
                {...props}
                placeholder={t('technology.placeholder')}
                onChange={onChange}
                value={value}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="location"
          control={control}
          rules={{
            required: { value: true, message: t('location.required') },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('location.title')}
              error={errors.location?.message}
              required
              infoLabel={t('location.infoLabel')}
            >
              <Input
                {...props}
                placeholder={t('location.placeholder')}
                error={errors.location?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="capacity"
          control={control}
          rules={{
            required: { value: true, message: t('capacity.required') },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('capacity.title')}
              error={errors.capacity?.message}
              infoLabel={t('capacity.infoLabel')}
              required
            >
              <Input
                {...props}
                type="number"
                step="0.01"
                placeholder="0.0"
                suffix="MW"
                error={errors.capacity?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="voltage"
          control={control}
          rules={{
            required: { value: true, message: t('voltage.required') },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('voltage.title')}
              error={errors.voltage?.message}
              infoLabel={t('voltage.infoLabel')}
              required
            >
              <Input
                {...props}
                type="number"
                step="0.01"
                placeholder="0.0"
                suffix="kV"
                error={errors.voltage?.message}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="mpan"
          control={control}
          rules={{
            maxLength: { value: 13, message: t('mpan.minLength') },
            minLength: { value: 13, message: t('mpan.maxLength') },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('mpan.title')}
              error={errors.mpan?.message}
              infoLabel={t('mpan.infoLabel')}
            >
              <Input
                {...props}
                type="text"
                placeholder={t('mpan.placeholder')}
                error={errors.mpan?.message}
                disabled={watch('msid') && !watch('mpan')}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="msid"
          control={control}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('msid.title')}
              error={errors.msid?.message}
              infoLabel={t('msid.infoLabel')}
            >
              <Input
                {...props}
                placeholder={t('msid.placeholder')}
                error={errors.msid?.message}
                disabled={watch('mpan') && !watch('msid')}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="rocBand"
          control={control}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('rocBand.title')}
              error={errors.rocBand?.message}
            >
              <Input
                {...props}
                type="number"
                placeholder={t('rocBand.placeholder')}
                step="0.01"
                error={errors.rocBand?.message}
              />
            </InputGroup>
          )}
        />

        <div className="w-full flex items-center justify-end gap-x-4">
          <div className="max-w-[150px] w-full">
            <Button
              type="button"
              variant="secondary"
              fullWidth
              onClick={onCancel}
            >
              {tUtils('form.buttons.cancel')}
            </Button>
          </div>
          <div className="max-w-[150px] w-full">
            <Button
              type="submit"
              variant="primary"
              fullWidth
              loading={isLoading}
            >
              {tUtils('form.buttons.submit')}
            </Button>
          </div>
        </div>
      </form>
      {drawer}
    </>
  )
}

export default SiteForm
