import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { parseSnippetAddress } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'

import { Meter } from '../../../../../../types'

interface TenderDetailsListSuppliersProps {
  data: Meter
}

const SiteDetailsDefList: React.FC<TenderDetailsListSuppliersProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('meterDetailsList.titles.name'),
        renderCustomEl: (meter: Meter) => {
          return meter.name
        },
      },
      {
        keyName: 'mpan',
        title: t('meterDetailsList.titles.mpan'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.mpan) return undefined
          return meter.mpan
        },
      },
      {
        keyName: 'hhd',
        title: t('meterDetailsList.titles.hhd'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.hhdTimeFrame) return undefined
          return meter?.hhdTimeFrame
        },
      },
      {
        keyName: 'annualConsumption',
        title: t('meterDetailsList.titles.annualConsumption'),
        suffix: 'MWh',
        renderCustomEl: (meter: Meter) => {
          if (!meter.annualConsumption) return undefined
          return meter.annualConsumption
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('meterDetailsTitle')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default SiteDetailsDefList
