import React, { useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { parseToFloatFormValues } from '../../../../../../utils'
import { TenderSiteWithInvitation } from '../../../../../../types'
import { Quote } from '../../../../../../types/quote'

import TargetPriceForm from '../form-target-price'

interface QuoteProps {
  data?: Quote
  error?: string
}

interface UpdateTargetPriceProps {
  tender: TenderSiteWithInvitation
  isDrawerOpen: boolean
  closeDrawer: () => void
  onSuccess: () => void
}

const UpdateTargetPrice: React.FC<UpdateTargetPriceProps> = ({
  closeDrawer,
  isDrawerOpen,
  onSuccess,
  tender,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()
  const [isLoading, setIsLoading] = useState(false)
  const [quoteProps, setQuoteProps] = useState<QuoteProps | undefined>()

  const handleUpdateTargetPrice = async (formData: any) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/tender/${tender.id}/update-target-price`,
        body: {
          targetPrice: parseToFloatFormValues(`${formData.targetPrice}`),
          minimumPrice: parseToFloatFormValues(`${formData.minimumPrice}`),
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('drawerCreateUpdateTender.form.edit.onSubmit.success.title'),
          description: t(
            'drawerCreateUpdateTender.form.edit.onSubmit.success.description',
          ),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'tender',
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Tender found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.tender.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const quotePayload = useMemo(() => {
    const subsidies: {
      roc: null | {
        buyout: number
        recycle: number
      }
      rego: boolean
      fit: boolean
      fitExport: boolean
    } = {
      roc: null,
      rego: false,
      fit: false,
      fitExport: false,
    }

    if (tender.subsidies.length > 0) {
      let roc: any = {
        buyout: 0,
        recycle: 0,
      }

      tender.subsidies.forEach((item) => {
        if (item.toggled === true) {
          if (item.name === 'FIT') {
            subsidies.fit = true
          } else if (item.name === 'FIT_EXPORT') {
            subsidies.fitExport = true
          } else if (item.name === 'REGO') {
            subsidies.rego = true
          } else if (item.name === 'ROC_BUYOUT') {
            roc.buyout = parseInt(`${item.value}`, 10)
          } else if (item.name === 'ROC_RECYCLE') {
            roc.recycle = parseInt(`${item.value}`, 10)
          }
        }
      })

      if (roc?.buyout && roc?.recycle) {
        subsidies.roc = roc
      } else {
        roc = undefined
      }
    }

    return {
      startDate: tender.startDate,
      endDate: tender.endDate,
      site: {
        technology: tender.site.technology,
        capacity: parseFloat(`${tender.site.capacity}`),
        voltage: parseFloat(`${tender.site.voltage}`),
        rocBand: parseFloat(`${tender.site?.rocBand || 0}`),
        mpan: tender.site.mpan,
        brokerFee: parseFloat(`${tender.site.brokerFee}`),
      },
      imbalance: 0,
      administration: 0,
      subsidies,
    }
  }, [tender])

  const getQuoteData = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const subsidies: {
        roc: null | {
          buyout: number
          recycle: number
        }
        rego: boolean
        fit: boolean
        fitExport: boolean
      } = {
        roc: null,
        rego: false,
        fit: false,
        fitExport: false,
      }

      if (tender.subsidies.length > 0) {
        let roc: any = {
          buyout: 0,
          recycle: 0,
        }

        tender.subsidies.forEach((item) => {
          if (item.toggled === true) {
            if (item.name === 'FIT') {
              subsidies.fit = true
            } else if (item.name === 'FIT_EXPORT') {
              subsidies.fitExport = true
            } else if (item.name === 'REGO') {
              subsidies.rego = true
            } else if (item.name === 'ROC_BUYOUT') {
              roc.buyout = parseInt(`${item.value}`, 10)
            } else if (item.name === 'ROC_RECYCLE') {
              roc.recycle = parseInt(`${item.value}`, 10)
            }
          }
        })

        if (roc?.buyout && roc?.recycle) {
          subsidies.roc = roc
        } else {
          roc = undefined
        }
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/quote/private/quote',
        body: quotePayload,
      })

      if (err || !response) throw err

      setQuoteProps({ data: response.data.quote.pricing })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      if (error instanceof AxiosError) {
        const errorType = error.response?.data?.errorType
        const errorMessage = error.response?.data?.message

        if (errorType === 'PAYLOAD_ERROR') {
          if (errorMessage) {
            if (
              errorMessage.includes('site.mpan') &&
              errorMessage.includes('is not allowed to be empty')
            ) {
              setQuoteProps({ error: 'MPAN_NOT_FOUND' })
              return
            }
          }
        }
      }
      setQuoteProps({ error: 'NOT_AVAILABLE' })
    }
  }

  useEffect(() => {
    if (!quoteProps?.data && isDrawerOpen && !isLoading) {
      getQuoteData()
    }
  }, [isDrawerOpen])

  const handleCloseDrawer = () => {
    setQuoteProps(undefined)
    setIsLoading(false)
    closeDrawer()
  }

  const targetPrice = useMemo((): string => {
    if (!tender?.targetPrice) return ''

    return parseFloat(`${tender.targetPrice}`).toFixed(2)
  }, [tender])

  const minimumTargetPrice = useMemo((): string => {
    if (!tender?.minimumPrice) return '0'

    return parseFloat(`${tender.minimumPrice}`).toFixed(2)
  }, [tender])

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={handleCloseDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        <h1 className="text-xl font-bold text-ppa/title">
          {t('drawerTargetPrice.edit.title')}
        </h1>

        {isDrawerOpen && (
          <TargetPriceForm
            isLoading={isLoading}
            refetchQuote={getQuoteData}
            onSubmit={handleUpdateTargetPrice}
            onCancel={handleCloseDrawer}
            quote={quoteProps?.data}
            quoteError={quoteProps?.error}
            quotePayload={quotePayload}
            tender={tender}
            targetPrice={targetPrice}
            minimumPrice={minimumTargetPrice}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default UpdateTargetPrice
