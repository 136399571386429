import React from 'react'

const Documents: React.FC = () => {
  return (
    <div>
      <h1 className="text-6xl font-bold">Documents</h1>
    </div>
  )
}

export default Documents
