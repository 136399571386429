import React, { forwardRef, InputHTMLAttributes } from 'react'
import { v4 } from 'uuid'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  [x: string]: any
}

const Checkbox = forwardRef<any, CheckboxProps>(
  ({ label, value, onChange, id = v4(), ...props }, ref) => {
    return (
      <div className="flex items-center justify-center">
        <input
          className="rounded-lg border border-ppa/subtitle w-3.5 h-3.5"
          type="checkbox"
          id={id}
          ref={ref}
          value={value}
          onChange={onChange}
          checked={!!value}
          {...props}
        />
        {label && (
          <label
            className="text-sm font-medium text-ppa/subtitle select-none ml-1.5"
            htmlFor={id}
          >
            {label}
          </label>
        )}
      </div>
    )
  },
)

export default Checkbox
