import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { capitalizeText } from '../../../../../utils'

import usePPAGetList, {
  Filters,
} from '../../../../../hooks/use-ppa/use-ppa-get-list'

import PageTitle from '../../../../../components/atoms/page-title'
import Table, { TableConfigProps } from '../../../../../components/atoms/table'

import SearchFilterSleevedTenders from '../components/search-filter'

import { dateFormats } from '../../../../../utils/data'

import { TenderStatus, SupplierTender } from '../../../../../types'

const rowKeys: TableConfigProps[] = [
  { keyName: 'shortId' },
  {
    keyName: 'site',
    renderCustomEl: (item: SupplierTender) => {
      if (!item.site) return undefined
      return item.site.name
    },
  },
  {
    keyName: 'mpan',
    renderCustomEl: (item: SupplierTender) => {
      if (!item.site) return undefined
      return item.site.mpan
    },
  },
  {
    keyName: 'startDate',
    renderCustomEl: (item: SupplierTender) => {
      return dayjs(item.startDate).format(dateFormats.user)
    },
  },
  {
    keyName: 'endDate',
    renderCustomEl: (item: SupplierTender) => {
      return dayjs(item.endDate).format(dateFormats.user)
    },
  },
  {
    keyName: 'deadline',
    renderCustomEl: (item: SupplierTender) => {
      if (!item?.deadline) return undefined

      return dayjs
        .utc(item.deadline)
        .tz('Europe/London')
        .format(dateFormats.userDateTime)
    },
  },
  {
    keyName: 'targetMatch',
    suffix: '%',
    renderCustomEl: (item: SupplierTender) => {
      if (!item.consumerTender.targetMatch) return undefined
      return item.consumerTender.targetMatch * 100
    },
  },
  {
    keyName: 'annualConsumption',
    suffix: 'MWh',
    renderCustomEl: (item: SupplierTender) => {
      if (!item.consumerTender.consumerMeter.annualConsumption) return undefined
      return item.consumerTender.consumerMeter.annualConsumption
    },
  },
]

const tenderStatus: TenderStatus = 'EXECUTED'

const defaultFilters: Filters = [
  { field: 'status', operator: 'equals', value: tenderStatus },
]

const SleevedTendersExecuted: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.page',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const { applyFilters, isLoading, loadMore, throwFeedbackError, data, error } =
    usePPAGetList<SupplierTender>({
      dataKey: 'supplierTenders',
      path: '/core/private/supplier-tender/generator/list',
      filters,
    })

  const tenders = useMemo((): SupplierTender[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        await applyFilters([...defaultFilters, ...newFilters])
        setFilters([...defaultFilters, ...newFilters])
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter(
      (item) =>
        item.field === 'search' ||
        defaultFilters.some(
          (defaultFilter) => defaultFilter.field === item.field,
        ),
    )
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title.executed')} />
      </div>
      <SearchFilterSleevedTenders
        onSubmit={fetchWithFilters}
        cleanFilters={handleCleanFilters}
      />
      <div className="relative z-10">
        <Table
          headers={[
            t('headers.tender'),
            t('headers.site'),
            t('headers.mpan'),
            t('headers.startDate'),
            t('headers.endDate'),
            t('headers.deadline'),
            t('headers.targetMatch'),
            t('headers.annualConsumption'),
          ]}
          onRowClick={(item: SupplierTender) => {
            navigate(`/sleevedTenders/${item.id}`)
          }}
          rowKeys={rowKeys}
          loadMore={loadMore}
          isLoading={isLoading}
          data={tenders}
        />
      </div>
    </div>
  )
}

export default SleevedTendersExecuted
