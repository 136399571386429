import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { parseContractDuration } from '../../../../../../utils'
import { dateFormats } from '../../../../../../utils/data'

import DefList from '../../../../../../components/atoms/def-list'

import { TenderInvitation } from '../details'
import { TenderSubsidyName } from '../../../../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

interface TenderDetailsListSuppliersProps {
  data: TenderInvitation
}

const TenderDetailsDefList: React.FC<TenderDetailsListSuppliersProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.defList',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('tenderDetailsList.titles.contractDuration'),
        renderCustomEl: (item: TenderInvitation) => {
          return parseContractDuration(
            item.tender.startDate,
            item.tender.endDate,
          )
        },
      },
      {
        keyName: 'deadline',
        title: t('tenderDetailsList.titles.deadline'),
        renderCustomEl: (item: TenderInvitation) => {
          if (!item?.tender?.deadline) return undefined
          return (
            <div className="flex text-center">
              {dayjs
                .utc(item.tender.deadline)
                .tz('Europe/London')
                .format(dateFormats.userDateTime)}
            </div>
          )
        },
      },
      {
        keyName: 'generator',
        title: t('tenderDetailsList.titles.generator'),
        renderCustomEl: (item: TenderInvitation) => {
          return item.tender?.generator?.name
        },
      },
      {
        keyName: 'subsidies',
        title: t('tenderDetailsList.titles.subsidies'),
        renderCustomEl: (item: TenderInvitation) => {
          const toggledSubsidyNames: TenderSubsidyName[] = []
          item.tender.subsidies.forEach(({ name, toggled }) => {
            if (toggled) toggledSubsidyNames.push(name)
          })
          return toggledSubsidyNames.join(', ')
        },
      },
      {
        keyName: 'otherRequirements',
        title: t('tenderDetailsList.titles.otherRequirements'),
        renderCustomEl: (item: TenderInvitation) =>
          item.tender?.otherRequirements,
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('tenderDetailsList.tenderDetailsTitle')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default TenderDetailsDefList
