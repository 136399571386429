import React from 'react'
import { consumerTendersSubRoutes } from './routes'

interface TendersProps {
  status: keyof typeof consumerTendersSubRoutes
}

const Tenders: React.FC<TendersProps> = ({ status }) => {
  return consumerTendersSubRoutes[status].index
}

export default Tenders
