import { useState, useEffect, useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import classNames from 'classnames'

import { useAuth0 } from '@auth0/auth0-react'

import usePPA from '../../../../hooks/use-ppa'

import { parseSnippetAddress, getCountryOptionsData } from '../../../../utils'

import Button from '../../../../components/atoms/button'
import Input from '../../../../components/atoms/input'
import Checkbox from '../../../../components/atoms/checkbox'
import DefList from '../../../../components/atoms/def-list'
import { feedbackMessage } from '../../../../components/atoms/feedback'
import RequiredField from '../../../../components/atoms/required-form-field'
import SelectComponent, {
  SelectOptions,
} from '../../../../components/atoms/select'
import Stepper from '../../../../components/atoms/stepper'

import InputGroup from '../../../../components/molecules/input-group'
import SelectCompaniesHouse from '../../../../components/molecules/select-companies-house'

import { transition } from '../../../../styles'

import { Address as CompleteAddress } from '../../../../types'
import { UserAuth0 } from '../../../../types/auth0'

type Address = {
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  region?: string
  locality?: string
  country?: SelectOptions
}

type Member = {
  firstName: string
  lastName: string
  phoneNumber: string
}

type Company = {
  name?: string
  number?: string
  type?: string
  address?: Address
  enableBillingAddress?: boolean
  billingAddress?: Address
}

interface FormValues {
  houseAPI?: SelectOptions
  company?: Company
  member?: Member
}

const formDefaultValues: FormValues = {
  company: {
    number: undefined,
    name: undefined,
    type: undefined,
    address: {
      addressLine1: undefined,
      addressLine2: undefined,
      postalCode: undefined,
      region: undefined,
      locality: undefined,
    },
    enableBillingAddress: false,
    billingAddress: {
      addressLine1: undefined,
      addressLine2: undefined,
      postalCode: undefined,
      region: undefined,
      locality: undefined,
    },
  },
  member: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
}

const ConsumerOnboarding: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.onboarding',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)
  const [formValues, setFormValues] = useState<FormValues | undefined>()
  const [currentStep, setCurrentStep] = useState(0)

  const countryOptions = useMemo(() => getCountryOptionsData(), [])

  const mappedCountryOptions = useMemo(() => {
    return (
      countryOptions.map((country) => ({
        value: country.alpha3,
        label: country.country,
      })) || []
    )
  }, [countryOptions])

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  const handleSubmitForm = (formData: FormValues) => {
    const {
      company: {
        name,
        number,
        type,
        address,
        enableBillingAddress,
        billingAddress,
      },
      member,
    } = formData as any

    if (address && address.country) {
      address.country = address.country.value
    }

    if (address && !address.addressLine2) {
      address.addressLine2 = null
    }

    if (billingAddress && billingAddress.country) {
      billingAddress.country = billingAddress.country.value
    }

    const payload = {
      company: {
        name,
        number: number || undefined,
        type: type || undefined,
        address,
        billingAddress,
      },
      member,
    }
    if (enableBillingAddress) {
      payload.company.billingAddress = billingAddress
    } else {
      payload.company.billingAddress = address
    }

    setFormValues({
      ...payload,
    })
    setCurrentStep(1)
  }

  const handleSubmitCompany = async () => {
    if (formValues === undefined) {
      setCurrentStep(0)
      return
    }

    if (isLoading) return
    setIsLoading(true)

    try {
      const { error } = await fetchData({
        method: 'POST',
        url: '/core/onboarding/consumer',
        body: {
          ...formValues,
          brokerContextName: process.env.REACT_APP_COMPANY,
        },
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t(
            'setup.handleCompleteConsumerProfile.success.description',
          ),
        },
        'success',
      )

      setCurrentStep(2)
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'onboardingConsumer',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (watch('houseAPI.value')) {
      const { name, companyNumber, companyType, completeAddress } =
        JSON.parse(watch('houseAPI.value')) || {}

      let country: SelectOptions | undefined

      if (completeAddress?.country) {
        const filteredCoutries = mappedCountryOptions.filter(
          (item) => item.label === completeAddress.country,
        )
        if (filteredCoutries.length > 0) {
          country = filteredCoutries[0]
        }
      }

      setValue('company', {
        name,
        type: companyType,
        number: companyNumber,
        address: {
          addressLine1: completeAddress?.address_line_1 || undefined,
          addressLine2: completeAddress?.address_line_2 || undefined,
          postalCode: completeAddress?.postal_code || undefined,
          region: completeAddress?.region || undefined,
          locality: completeAddress?.locality || undefined,
          country,
        },
      })
      setValue('company.address.country', country)
    }
  }, [watch('houseAPI')])

  const { user } = useAuth0<UserAuth0>()

  useEffect(() => {
    if (!user?.app_metadata?.status || currentStep === 2) return
    if (user?.app_metadata?.status === 'REVIEW') {
      setCurrentStep(2)
    }
  }, [user])

  return (
    <div className="flex flex-col items-center">
      <div className={classNames('mb-10 fade-in')}>
        <Stepper
          steps={[
            t('steps.companySetup'),
            t('steps.companyReview'),
            t('steps.validation'),
          ]}
          activeStep={currentStep}
          setActiveStep={setCurrentStep}
          disabledStep={currentStep === 2}
        />
      </div>
      {currentStep === 0 && (
        <section className="flex flex-col text-ppa/title gap-y-3 mx-6">
          <form
            className={classNames(
              transition,
              'fade-in',
              'flex flex-col gap-y-3 w-full max-w-[500px]',
            )}
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <div className="flex flex-col items-start justify-start">
              <h2 className="text-2xl font-medium mb-3">{t('setup.title')}</h2>
              <span className="text-base font-light mb-3">
                {t('setup.info')}
              </span>
            </div>
            <Controller
              name="houseAPI"
              control={control}
              render={({ field: { ref: _, onChange, ...props } }) => (
                <InputGroup
                  label={t('setup.company.title')}
                  infoLabel={t('setup.company.infoTitle')}
                  error={errors.company?.message}
                >
                  <SelectCompaniesHouse
                    {...props}
                    onChange={onChange}
                    placeholder={t('setup.company.placeholder')}
                  />
                </InputGroup>
              )}
            />
            <div className="w-full border" />
            <Controller
              name="company.name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('setup.name.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('setup.name.title')}
                  error={errors.company?.name?.message as any}
                  required
                >
                  <Input
                    {...props}
                    placeholder={t('setup.name.placeholder')}
                    error={errors.company?.name?.message as any}
                    attribute="organization"
                  />
                </InputGroup>
              )}
            />
            <div className="flex flex-col gap-3 md:flex-row">
              <Controller
                name="company.number"
                control={control}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('setup.number.title')}
                    error={errors.company?.number?.message}
                  >
                    <Input
                      {...props}
                      placeholder={t('setup.number.placeholder')}
                      error={errors.company?.number?.message}
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="company.type"
                control={control}
                render={({ field: props }) => (
                  <InputGroup label={t('setup.type.title')}>
                    <Input
                      {...props}
                      placeholder={t('setup.type.placeholder')}
                    />
                  </InputGroup>
                )}
              />
            </div>
            <InputGroup label={t('setup.address.title')}>
              <div
                className={classNames(
                  'flex flex-col',
                  'pt-5 mt-3 gap-y-3',
                  'border-t border-ppa/grayBorder',
                  'sm:border-t-0 sm:border-l sm:pt-0 sm:pl-5',
                )}
              >
                <Controller
                  name="company.address.addressLine1"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.addressLine1.required'),
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.addressLine1.title')}
                      error={
                        errors.company?.address?.addressLine1?.message as any
                      }
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t('setup.addressLine1.placeholder')}
                        error={errors.company?.address?.addressLine1?.message}
                        attribute="address-line1"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="company.address.addressLine2"
                  control={control}
                  render={({ field: props }) => (
                    <InputGroup label={t('setup.addressLine2.title')}>
                      <Input
                        {...props}
                        placeholder={t('setup.addressLine2.placeholder')}
                        attribute="address-line2
                     "
                      />
                    </InputGroup>
                  )}
                />
                <div className="flex flex-col gap-3 md:flex-row">
                  <Controller
                    name="company.address.postalCode"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.postalCode.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.postalCode.title')}
                        error={
                          errors.company?.address?.postalCode?.message as any
                        }
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t('setup.postalCode.placeholder')}
                          error={errors.company?.address?.postalCode?.message}
                          attribute="postal-code"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="company.address.locality"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.locality.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.locality.title')}
                        error={
                          errors.company?.address?.locality?.message as any
                        }
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t('setup.locality.placeholder')}
                          error={errors.company?.address?.locality?.message}
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-3 md:flex-row">
                  <Controller
                    name="company.address.region"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.region.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.region.title')}
                        error={errors.company?.address?.region?.message as any}
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t('setup.region.placeholder')}
                          error={errors.company?.address?.region?.message}
                          attribute="address-level1"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="company.address.country"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.country.required'),
                      },
                    }}
                    render={({ field: { ref: _, ...props } }) => (
                      <InputGroup
                        label={t('setup.country.title')}
                        error={
                          errors?.company?.address?.country?.message as any
                        }
                        required
                      >
                        <SelectComponent
                          {...props}
                          type="single"
                          options={mappedCountryOptions}
                          placeholder={t('setup.country.placeholder')}
                          error={errors?.company?.address?.country?.message}
                          testId="select-country"
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
            </InputGroup>

            <div className="flex flex-col">
              <Controller
                name="company.enableBillingAddress"
                control={control}
                render={({ field: props }) => (
                  <div className="flex items-center justify-start">
                    <Checkbox
                      label={t('setup.enableBillingAddress.title')}
                      {...props}
                    />
                  </div>
                )}
              />
              {watch('company.enableBillingAddress') && (
                <div
                  className={classNames(
                    'flex flex-col',
                    'pt-5 mt-3 gap-y-3',
                    'border-t border-ppa/grayBorder',
                    'sm:border-t-0 sm:border-l sm:pt-1 sm:pl-5',
                  )}
                >
                  <Controller
                    name="company.billingAddress.addressLine1"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.addressLine1.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.addressLine1.title')}
                        error={
                          errors.company?.billingAddress?.addressLine1
                            ?.message as any
                        }
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t('setup.addressLine1.placeholder')}
                          error={
                            errors.company?.billingAddress?.addressLine1
                              ?.message
                          }
                          attribute="address-line1"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="company.billingAddress.addressLine2"
                    control={control}
                    render={({ field: props }) => (
                      <InputGroup label={t('setup.addressLine2.title')}>
                        <Input
                          {...props}
                          placeholder={t('setup.addressLine2.placeholder')}
                          error={
                            errors.company?.billingAddress?.addressLine2
                              ?.message
                          }
                          attribute="address-line2"
                        />
                      </InputGroup>
                    )}
                  />
                  <div className="flex flex-col gap-3 md:flex-row">
                    <Controller
                      name="company.billingAddress.postalCode"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t('setup.postalCode.required'),
                        },
                      }}
                      render={({ field: props }) => (
                        <InputGroup
                          label={t('setup.postalCode.title')}
                          error={
                            errors.company?.billingAddress?.postalCode
                              ?.message as any
                          }
                          required
                        >
                          <Input
                            {...props}
                            placeholder={t('setup.postalCode.placeholder')}
                            error={
                              errors.company?.billingAddress?.postalCode
                                ?.message
                            }
                            attribute="postal-code"
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name="company.billingAddress.locality"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t('setup.locality.required'),
                        },
                      }}
                      render={({ field: props }) => (
                        <InputGroup
                          label={t('setup.locality.title')}
                          error={
                            errors.company?.billingAddress?.locality
                              ?.message as any
                          }
                          required
                        >
                          <Input
                            {...props}
                            placeholder={t('setup.locality.placeholder')}
                            error={
                              errors.company?.billingAddress?.locality?.message
                            }
                          />
                        </InputGroup>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-3 md:flex-row">
                    <Controller
                      name="company.billingAddress.region"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t('setup.region.required'),
                        },
                      }}
                      render={({ field: props }) => (
                        <InputGroup
                          label={t('setup.region.title')}
                          error={
                            errors.company?.billingAddress?.region
                              ?.message as any
                          }
                          required
                        >
                          <Input
                            {...props}
                            placeholder={t('setup.region.placeholder')}
                            error={
                              errors.company?.billingAddress?.region?.message
                            }
                            attribute="address-level1"
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name="company.billingAddress.country"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t('setup.country.required'),
                        },
                      }}
                      render={({ field: { ref: _, ...props } }) => (
                        <InputGroup
                          label={t('setup.country.title')}
                          error={
                            errors?.company?.address?.country?.message as any
                          }
                          required
                        >
                          <SelectComponent
                            {...props}
                            type="single"
                            options={mappedCountryOptions}
                            placeholder={t('setup.country.placeholder')}
                            error={errors?.company?.address?.country?.message}
                          />
                        </InputGroup>
                      )}
                    />
                  </div>
                </div>
              )}
              <div className="mt-5 flex flex-col gap-3">
                <Controller
                  name="member.firstName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.firstName.required'),
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.firstName.title')}
                      error={errors.member?.firstName?.message as any}
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t('setup.firstName.placeholder')}
                        error={errors.member?.firstName?.message}
                        attribute="given-name"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="member.lastName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.lastName.required'),
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.lastName.title')}
                      error={errors.member?.lastName?.message as any}
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t('setup.lastName.placeholder')}
                        error={errors.member?.lastName?.message}
                        attribute="family-name"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="member.phoneNumber"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.contactNumber.required'),
                    },
                    maxLength: {
                      message: t('setup.contactNumber.notValid'),
                      value: 12,
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.contactNumber.title')}
                      infoLabel={t('setup.contactNumber.infoLabel')}
                      error={errors?.member?.phoneNumber?.message as any}
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t('setup.contactNumber.placeholder')}
                        error={errors?.member?.phoneNumber?.message}
                        attribute="tel"
                      />
                    </InputGroup>
                  )}
                />
              </div>
            </div>
            <RequiredField label="required fields" />
            <div className="flex items-center justify-end w-full max-w-[150px] ml-auto">
              <Button type="submit" variant="primary" fullWidth>
                {t('setup.next')}
              </Button>
            </div>
          </form>
        </section>
      )}

      {currentStep === 1 && (
        <section className="flex flex-col text-ppa/title gap-y-3 mx-6">
          <div
            className={classNames(
              transition,
              'fade-in',
              'flex flex-col gap-y-5',
              'max-w-[600px] w-full',
            )}
          >
            <div className="flex flex-col items-start justify-start">
              <h2 className="text-2xl font-medium mb-3">{t('setup.title')}</h2>

              <span className="text-base font-light">
                <Trans
                  ns="private/index"
                  i18nKey="consumers.underReview.review.info"
                  defaults="Please review the information you provided. When you're ready to validate your company details, click <span>Submit</span>."
                  components={{
                    span: <span className="font-medium" />,
                  }}
                />
              </span>
            </div>

            <DefList
              rowKeys={[
                {
                  keyName: 'name',
                  title: t('review.defList.name'),
                  renderCustomEl: (item: FormValues) => {
                    return item.company?.name
                  },
                },
                {
                  keyName: 'number',
                  title: t('review.defList.number'),
                  renderCustomEl: (item: FormValues) => {
                    return item.company?.number
                  },
                },
                {
                  keyName: 'type',
                  title: t('review.defList.type'),
                  renderCustomEl: (item: FormValues) => {
                    return item.company?.type
                  },
                },
                {
                  keyName: 'address',
                  title: t('review.defList.address'),
                  renderCustomEl: (item: FormValues) => {
                    return parseSnippetAddress(
                      item.company?.address as CompleteAddress,
                    )
                  },
                },
                ...(formValues?.company?.billingAddress
                  ? [
                      {
                        keyName: 'billingAddress',
                        title: t('review.defList.billingAddress'),
                        renderCustomEl: (item: FormValues) => {
                          return parseSnippetAddress(
                            item?.company?.billingAddress as
                              | CompleteAddress
                              | undefined,
                          )
                        },
                      },
                    ]
                  : []),
                {
                  keyName: 'firstName',
                  title: t('review.defList.memberName'),
                  renderCustomEl: (item: FormValues) => {
                    return item.member?.firstName
                  },
                },
                {
                  keyName: 'phoneNumber',
                  title: t('review.defList.phoneNumber'),
                  renderCustomEl: (item: FormValues) => {
                    return <span>+{item.member?.phoneNumber}</span>
                  },
                },
              ]}
              data={formValues}
            />

            <div className="flex items-center justify-end gap-x-3">
              <div className="w-full max-w-[100px]">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setCurrentStep(0)
                  }}
                  fullWidth
                >
                  {t('review.goBack')}
                </Button>
              </div>
              <div className="w-full max-w-[150px]">
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleSubmitCompany}
                  fullWidth
                  loading={isLoading}
                  disabled={isLoading}
                >
                  {t('review.submit')}
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}

      {currentStep === 2 && (
        <section className="flex flex-col text-ppa/title gap-y-5 w-full max-w-[500px]">
          <h1 className="text-3xl font-semibold">{t('validation.title')}</h1>
          <span className="text-xl font-normal">{t('validation.info.0')}</span>
          <span className="text-xl font-normal">{t('validation.info.1')}</span>
          <span className="text-xl font-normal">{t('validation.info.2')}</span>
          <span className="text-xl font-normal">{t('validation.info.3')}</span>
        </section>
      )}
    </div>
  )
}

export default ConsumerOnboarding
