/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ReactMarkdown from 'react-markdown'

import { strapi, mountQuery } from '../../../../../services/strapi'

interface MarketData {
  title?: string
  content: string
  reportDate: string
  createdAt: string
  publishedAt: string
  updatedAt: string
}

interface MarketObject {
  id: number
  attributes: MarketData
}

const MarketSummary: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'market.summary',
  })

  const [marketSummary, setMarketSummary] = useState<
    MarketObject[] | undefined
  >()

  const getMarketSummary = async () => {
    try {
      const queryObj = {
        sort: 'reportDate:desc',
        'pagination[page]': '1',
        'pagination[pageSize]': '3',
      }
      const response = await strapi.get(
        `/market-updates${mountQuery(queryObj)}`,
      )

      const responseData = response.data.data

      setMarketSummary(responseData)
    } catch (error) {
      console.error('GET MARKET SUMMARY ERROR:', error)
    }
  }

  useEffect(() => {
    getMarketSummary()
  }, [])

  return (
    <div className="flex flex-col text-ppa/title">
      {marketSummary && marketSummary?.length > 0 ? (
        <>
          <h2 className="text-2xl font-semibold">
            {`${t('label')} - ${dayjs(
              marketSummary[0].attributes.reportDate,
            ).format('DD/MM/YYYY')}`}
          </h2>
          <div className="border-l border-ppa/primary mt-5 pl-7 flex flex-col gap-y-2.5">
            {marketSummary[0].attributes.title && (
              <strong className="text-xl font-bold">
                {marketSummary[0].attributes.title}
              </strong>
            )}
            <div className="text-sm font-normal whitespace-pre-wrap">
              <ReactMarkdown>
                {marketSummary[0].attributes.content}
              </ReactMarkdown>
            </div>
          </div>
        </>
      ) : (
        <span>{t('infoMessage')}</span>
      )}
    </div>
  )
}

export default MarketSummary
