import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import EditSupplierForm from '../form-edit-supplier'

type FormData = {
  logoFile?: File
  description?: string
  totalCapacity?: number
  backing?: string[]
  lenders?: string[]
  other?: string
}

interface SupplierProfileProps {
  supplierProfile: any
  onSuccess: () => void
  isDrawerOpen: boolean
  closeDrawer: () => void
}

const SupplierEditProfile: React.FC<SupplierProfileProps> = ({
  supplierProfile,
  onSuccess,
  isDrawerOpen,
  closeDrawer,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.editSupplierProfile',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleEditSupplierForm = async (formData: FormData) => {
    if (isLoading) return
    setIsLoading(true)

    const { description, totalCapacity, backing, lenders, other } = formData

    const payload = {
      description,
      totalCapacity: totalCapacity || 0,
      backing: (backing || []).map((item: any) => {
        return item.value
      }),
      lenders: (lenders || []).map((item: any) => {
        return item.value
      }),
      other,
    }

    try {
      const { error } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier/profile',
        body: payload,
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleEditSupplier.success.title'),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({ err, context: 'supplier' })
    } finally {
      setIsLoading(false)
      closeDrawer()
    }
  }

  const supplierProfileForm = useMemo(() => {
    if (
      !supplierProfile ||
      !supplierProfile.lenders ||
      !supplierProfile.backing
    )
      return undefined

    const backingLabels = {
      strongCreditRating: t(
        'financialBacking.options.strongCreditRating.label',
      ),
      pcg: t('financialBacking.options.pcg.label'),
      bankBond: t('financialBacking.options.bankBond.label'),
    } as { [key: string]: string }

    return {
      ...supplierProfile,
      backing: supplierProfile?.backing.map((finback: string) => {
        return {
          value: finback,
          label: backingLabels[finback] || finback,
        }
      }),
      lenders: supplierProfile?.lenders.map((lender: string) => {
        return {
          value: lender,
          label: lender,
        }
      }),
    }
  }, [supplierProfile])

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={() => closeDrawer()}>
      <div className="flex flex-col gap-y-5 w-[510px]">
        {isDrawerOpen && (
          <EditSupplierForm
            onSubmit={handleEditSupplierForm}
            supplierProfile={supplierProfileForm as any}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default SupplierEditProfile
