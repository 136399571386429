import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PencilIcon, TrashIcon } from '@heroicons/react/outline'

import usePPA from '../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import TenderDetails, { ButtonList } from '../components/details'
import EditTender from '../components/edit-tender'
import UpdateTargetPrice from '../components/update-target-price'

import { TenderStatusProps } from '../details'

const WithdrawnTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isActivating, setIsActivating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)

  const handleDelete = async () => {
    if (isDeleting) return
    setIsDeleting(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'DELETE',
        url: `/core/private/tender/${tenderDetails.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.delete.success'),
        },
        'success',
      )

      navigate('/tenders/status/withdrawn')
    } catch (err: any) {
      console.error(err)
      throwFeedbackError({ err, context: 'tender' })
    }

    setIsDeleting(false)
  }

  const handleActivate = async () => {
    if (isActivating || !tenderDetails) return
    setIsActivating(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/tender/update-status',
        body: { tenderId: tenderDetails.id, status: 'ACTIVE' },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.activate.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsActivating(false)
    }
  }

  const onClickEdit = () => {
    if (!tenderDetails) {
      feedbackMessage(
        {
          title: 'Something went wrong.',
          description:
            'We could not load tender information, please reload the page and try again.',
        },
        'error',
      )
      return
    }

    setIsEditDrawerOpen(true)
  }

  const onEditSuccess = () => {
    refetch()
    setIsEditDrawerOpen(false)
  }

  const [isTargetPriceDrawerOpen, setIsTargetPriceDrawerOpen] = useState(false)

  const onSetTargetPriceSuccess = () => {
    refetch()
    setIsTargetPriceDrawerOpen(false)
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    if (!tenderDetails?.targetPrice) {
      return [
        {
          text: t('buttons.setTargetPrice'),
          onClick: () => setIsTargetPriceDrawerOpen(true),
          props: { variant: 'primary' },
        },
      ]
    }

    return [
      {
        text: t('buttons.activate'),
        onClick: handleActivate,
        props: { variant: 'primary', loading: isActivating, fullWidth: true },
      },
      {
        text: t('buttons.updateTargetPrice'),
        onClick: () => setIsTargetPriceDrawerOpen(true),
        props: { variant: 'secondary' },
      },
    ]
  }, [tenderDetails, isActivating, handleActivate])

  return (
    <>
      <TenderDetails
        actionsDropdown={[
          {
            text: t('actionsDropdown.edit'),
            onClick: onClickEdit,
            props: { variant: 'text', fullWidth: true, icon: <PencilIcon /> },
          },
          {
            text: t('actionsDropdown.delete'),
            onClick: handleDelete,
            props: { variant: 'text', fullWidth: true, icon: <TrashIcon /> },
          },
        ]}
        buttons={buttons}
        tender={tenderDetails}
      />

      <EditTender
        tender={tenderDetails}
        isDrawerOpen={isEditDrawerOpen}
        handleCloseDrawer={() => setIsEditDrawerOpen(false)}
        onSuccess={onEditSuccess}
      />
      <UpdateTargetPrice
        tender={tenderDetails}
        isDrawerOpen={isTargetPriceDrawerOpen}
        closeDrawer={() => setIsTargetPriceDrawerOpen(false)}
        onSuccess={onSetTargetPriceSuccess}
      />
    </>
  )
}

export default WithdrawnTenderDetails
