import React from 'react'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'

import { container } from '../../../../../../styles'

import { SupplierTenderBid } from '../../../../../../types'

import BidTable from '../bid-table'

import Message from '../../../../../../components/atoms/message'

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface BidDetailsProps {
  bid?: SupplierTenderBid
  refetchBidList: () => void
  buttons?: ButtonList[]
}

const BidDetails: React.FC<BidDetailsProps> = ({
  buttons,
  bid,
  refetchBidList,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details',
  })

  return (
    <section className={classNames(container, 'gap-y-5 pt-12')}>
      <div className="flex gap-x-8 items-center">
        <h1 className="text-left text-2xl text-ppa/title font-semibold">
          {t('bidTitle')}
        </h1>
        {!bid && buttons && (
          <div className="flex items-center justify-start gap-x-5 w-full">
            {buttons.map((item) => (
              <Button
                {...item.props}
                key={item.text}
                icon={<PlusIcon />}
                onClick={() => item.onClick()}
              >
                {item.text}
              </Button>
            ))}
          </div>
        )}
      </div>
      <Message variant="info" size="text-xs">
        {t('bidInfo.0')}
        <br />
        {t('bidInfo.1')}
      </Message>
      <div className="flex flex-col w-full gap-x-3">
        {bid && (
          <BidTable
            bid={bid}
            buttons={buttons}
            refetchBidList={refetchBidList}
          />
        )}
        {!bid && (
          <strong className="text-ppa/title text-base font-normal">
            {t('noBid')}
          </strong>
        )}
      </div>
    </section>
  )
}

export default BidDetails
