import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import usePPAGetList from '../../../../../../../hooks/use-ppa/use-ppa-get-list'

import Input from '../../../../../../../components/atoms/input'
import Message from '../../../../../../../components/atoms/message'

import InputGroup from '../../../../../../../components/molecules/input-group'

import {
  SupplierTenderBid,
  EmbeddedBenefitType,
  PowerPriceType,
} from '../../../../../../../types'

interface FormBenefit {
  type: PowerPriceType
  value: string
}

export interface FormEmbeddedBenefits {
  GDUOS: FormBenefit
  BSUOS: FormBenefit
  AAHEDC: FormBenefit
  DLOSS: FormBenefit
  TLOSS: FormBenefit
  OTHER: FormBenefit
  RCRC: FormBenefit
  ELEXON: FormBenefit
}

type BenefitKeys = keyof FormEmbeddedBenefits

export type FormValues = {
  embeddedBenefits: FormEmbeddedBenefits
}

export interface FormProps {
  bid?: SupplierTenderBid
}

const BenefitsForm: React.FC<FormProps> = ({ bid }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.form',
  })

  const { isLoading, data, error } = usePPAGetList<EmbeddedBenefitType>({
    dataKey: 'activeBenefits',
    path: '/core/private/supplier-tender-bid-benefit/list',
  })

  const [benefitsOptions, setBenefitsOptions] = useState<EmbeddedBenefitType[]>(
    [],
  )

  const { register, setValue } = useFormContext<FormValues>()

  useEffect(() => {
    if (isLoading || error || !data) return

    const uniqueBenefitNames = new Set()
    const uniqueBenefits = data.filter((item: any) => {
      if (item.active && !uniqueBenefitNames.has(item.name)) {
        uniqueBenefitNames.add(item.name)
        return true
      }
      return false
    })

    setBenefitsOptions(uniqueBenefits)

    uniqueBenefits.forEach((benefit) => {
      const benefitName = benefit.name as BenefitKeys

      if (bid) {
        const matchingBenefit = bid.supplierTenderBidBenefits.find(
          (ben) => ben.name === benefit.name,
        )
        if (matchingBenefit) {
          const value = `${matchingBenefit?.value || 0}`
          const type = (matchingBenefit?.type || 'FLEXIBLE') as PowerPriceType

          setValue(`embeddedBenefits.${benefitName}`, { type, value })
        } else {
          setValue(`embeddedBenefits.${benefitName}`, {
            type: 'FLEXIBLE',
            value: '0',
          })
        }
      } else {
        setValue(`embeddedBenefits.${benefitName}`, {
          type: 'FLEXIBLE',
          value: '0',
        })
      }
    })
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      console.error('Benefits could not be loaded', error)
    }
  }, [error])
  return (
    <div>
      <div className="border border-b border-ppa/grayBorder w-full" />
      <div className="mt-3.5">
        <InputGroup label={t('fields.embeddedBenefits.label')}>
          <Message variant="info" size="text-xs self-center">
            {t('fields.embeddedBenefits.infoMessage')}
          </Message>
        </InputGroup>

        <div className="mt-1 flex flex-wrap gap-x-7 gap-y-3">
          {benefitsOptions.map(({ name }) => {
            const thisRegister = {
              ...register(`embeddedBenefits.${name}.value`),
              ref: undefined,
            }
            return (
              <Controller
                {...thisRegister}
                key={name}
                render={({ field: { ...props } }) => {
                  const selectRegister = {
                    ...{ ...register(`embeddedBenefits.${name}.type`) },
                    ref: undefined,
                  }

                  return (
                    <div className="max-w-[150px]">
                      <InputGroup
                        label={t(`fields.embeddedBenefits.${name}.label`)}
                      >
                        <Input
                          {...props}
                          type="number"
                          step="0.01"
                          placeholder="0,0"
                          suffix={
                            <Controller
                              {...selectRegister}
                              render={({ field: { ...selectProps } }) => (
                                <select
                                  {...selectProps}
                                  className="text-center items-center"
                                  data-testid={`embeddedBenefits.${name}.benefitType`}
                                >
                                  <option value="FIXED">£/MWh</option>
                                  <option value="FLEXIBLE">%</option>
                                </select>
                              )}
                            />
                          }
                        />
                      </InputGroup>
                    </div>
                  )
                }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BenefitsForm
