import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AxiosError } from 'axios'
import usePPA from '../../../../../../hooks/use-ppa'

import type { HHDFile } from '../../../../../../types'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import FormHHD, { HHDFormRef } from '../form-hhd'

interface UploadHHDProps {
  idSite?: string
  isDrawerOpen: boolean
  onSuccess: () => void
  onCancel: () => void
}

const UploadHHD: React.FC<UploadHHDProps> = ({
  idSite,
  isDrawerOpen,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.hhdDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const hhdForm = useRef<HHDFormRef>(null)

  const [isLoading, setIsLoading] = useState(false)

  const handleUploadHHD = async (data: HHDFile) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      if (!idSite) throw Error('Missing site ID')

      const formData = new FormData()
      formData.append('file', data.hhdFile as any)

      const { error } = await fetchData({
        method: 'POST',
        url: `/core/private/site/hhd/${idSite}`,
        body: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('onUpload.success.description'),
        },
        'success',
      )

      onSuccess()
      hhdForm.current?.resetForm()
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-y-5 sm:w-[640px]">
      <h1 className="text-xl font-bold text-ppa/title">{t('uploadTitle')}</h1>

      {isDrawerOpen && (
        <FormHHD
          isLoading={isLoading}
          onSubmit={handleUploadHHD}
          onCancel={onCancel}
        />
      )}
    </div>
  )
}

export default UploadHHD
