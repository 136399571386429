import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SupplierProfile } from '../../../types'

import Button from '../../atoms/button'
import Modal from '../../molecules/modal'
import DefList from '../../atoms/def-list'

interface SupplierDefListModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  supplierProfileData: SupplierProfile
}

const SupplierDefListModal: React.FC<SupplierDefListModalProps> = ({
  isOpen,
  onClose,
  title,
  supplierProfileData,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'supplierDetailsModal',
  })

  const rowKeysCompany = useMemo(() => {
    return [
      {
        keyName: 'description',
        title: t('defList.description.title'),
        smallTitle: t('defList.description.smallTitle'),
        renderCustomEl: (supplier: SupplierProfile) => {
          if (!supplier.description) return undefined
          return supplier.description
        },
      },
      {
        keyName: 'renewableEnergyUnderOperation',
        suffix: 'MW',
        title: t('defList.renewableEnergyUnderOperation'),
        renderCustomEl: (supplier: SupplierProfile) => {
          if (!supplier.totalCapacity) return undefined
          return supplier.totalCapacity
        },
      },
      {
        keyName: 'financialBacking',
        title: t('defList.financialBacking.title'),
        renderCustomEl: (supplier: SupplierProfile) => {
          if (!supplier.backing) return undefined
          const backingKeys = supplier.backing
          if (backingKeys && backingKeys.length > 0) {
            const backingTranslations = backingKeys.map((key: any) =>
              t(`defList.financialBacking.options.${key}`),
            )
            return backingTranslations.join(', ')
          }
          return ''
        },
      },
      {
        keyName: 'lenders',
        title: t('defList.lenders'),
        renderCustomEl: (supplier: SupplierProfile) => {
          if (!supplier?.lenders) return undefined
          return (supplier.lenders || []).join(', ')
        },
      },
      {
        keyName: 'other',
        title: t('defList.other'),
        renderCustomEl: (supplier: SupplierProfile) => {
          if (!supplier?.other) return undefined
          return supplier.other
        },
      },
    ]
  }, [supplierProfileData])

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <div className="w-[670px] mx-8">
        <h1 className="text-3xl text-ppa/title font-semibold mt-10 mb-6">
          {title}
        </h1>
        <div className="flex flex-col gap-y-3">
          {/* <CompanyLogo variant="gray" /> */}
          <DefList rowKeys={rowKeysCompany} data={supplierProfileData} />
          <div className="flex self-end mt-2 mb-8 w-24">
            <Button variant="secondary" onClick={onClose} fullWidth>
              {t('closeButton')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SupplierDefListModal
