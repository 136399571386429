import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'

import { XIcon } from '@heroicons/react/outline'

import usePPA from '../../../../../../hooks/use-ppa'

import { Filters } from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import { parseToFloatFormValues } from '../../../../../../utils'

import { dateFormats } from '../../../../../../utils/data'

import Filter from '../../../../../../components/molecules/filter'
import Search from '../../../../../../components/atoms/search'

import SelectComponent, {
  SelectOptions,
} from '../../../../../../components/atoms/select'
import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'

export type FilterFormValues = {
  consumer?: SelectOptions
  targetMatch?: SelectOptions
  consumptionOperator?: SelectOptions
  consumptionValue?: string
  startDate?: string
  endDate?: string
  deadline?: string
}

export type onSearchOrFilterData = {
  consumer?: SelectOptions
  targetMatch?: SelectOptions
  consumptionOperator?: SelectOptions
  consumptionValue?: number
  startDate?: string
  endDate?: string
  deadline?: string
}

const targetMatchOptions = [
  {
    value: '0.25',
    label: '0% - 25%',
  },
  {
    value: '0.5',
    label: '25% - 50%',
  },
  {
    value: '0.75',
    label: '50% - 75%',
  },
  {
    value: '1',
    label: '50% - 100%',
  },
]

export interface FilterProps {
  onSubmit: (data: Filters) => void
  cleanFilters: () => void
}
const SearchFilterConsumerTenders: React.FC<FilterProps> = ({
  onSubmit,
  cleanFilters,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.filters',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [searchText, setSearchText] = useState<string | undefined>(undefined)
  const [filtersCount, setFiltersCount] = useState<number>(0)

  const [isLoadingConsumersList, setIsLoadingConsumersList] =
    useState<boolean>(false)
  const [consumersOptions, setConsumersOptions] = useState<SelectOptions[]>([])

  const operatorOptions = [
    {
      value: 'equals',
      label: t('selectComponentOptions.equal'),
    },
    {
      value: 'gt',
      label: t('selectComponentOptions.greaterThan'),
    },
    {
      value: 'gte',
      label: t('selectComponentOptions.greaterOrEqualThan'),
    },
    {
      value: 'in',
      label: t('selectComponentOptions.in'),
    },
    {
      value: 'lt',
      label: t('selectComponentOptions.lessThan'),
    },
    {
      value: 'lte',
      label: t('selectComponentOptions.lessOfEqualThan'),
    },
    {
      value: 'not',
      label: t('selectComponentOptions.not'),
    },
    {
      value: 'notIn',
      label: t('selectComponentOptions.notIn'),
    },
  ]

  const defaultValues = {
    consumptionOperator: operatorOptions[1],
    consumptionValue: '',
    targetMatch: undefined,
    startDate: '',
    endDate: '',
    deadline: '',
  } as any

  const { control, handleSubmit, reset, getValues } = useForm<FilterFormValues>(
    {
      defaultValues,
    },
  )

  const handleSubmitForm = (data: FilterFormValues & { search?: string }) => {
    const result: Filters = []

    if (data?.search) {
      result.push({ field: 'search', operator: 'contains', value: data.search })
    }

    if (data?.consumer?.value) {
      result.push({
        field: 'consumerId',
        operator: 'equals',
        value: data.consumer.value,
      })
    }

    if (data?.targetMatch?.value) {
      result.push({
        field: 'targetMatch',
        operator: 'equals',
        value: data.targetMatch.value,
      })
    }

    if (data?.consumptionValue && data?.consumptionOperator?.value) {
      result.push({
        field: 'consumption',
        operator: data.consumptionOperator.value,
        value: parseToFloatFormValues(data.consumptionValue),
      })
    }

    if (data?.startDate) {
      result.push({
        field: 'startDate',
        operator: 'gte',
        value: data.startDate,
      })
    }

    if (data?.endDate) {
      result.push({
        field: 'endDate',
        operator: 'lte',
        value: data.endDate,
      })
    }

    if (data?.deadline) {
      result.push({
        field: 'deadline',
        operator: 'lte',
        value: data?.deadline,
      })
    }

    const numberOfFiltersToApply = result.filter(
      ({ field }) => field !== 'search',
    )

    onSubmit(result)
    setFiltersCount(numberOfFiltersToApply.length)
  }

  const handleSearchChange = async (newSearch?: string) => {
    const parsedSearch = newSearch?.trim().toLowerCase() || ''
    setSearchText(parsedSearch)

    if (filtersCount >= 1) {
      handleSubmitForm({
        ...getValues(),
        search: parsedSearch,
      })
    } else {
      handleSubmitForm({
        search: parsedSearch,
      })
      setFiltersCount(0)
    }
  }

  const getConsumersList = async () => {
    if (isLoadingConsumersList || consumersOptions.length) return
    setIsLoadingConsumersList(true)

    try {
      const { error, response } = await fetchData({
        method: 'GET',
        url: '/core/private/consumer/admin/list',
      })

      if (response?.data && !error) {
        const consumerLits = response.data.consumers || []
        setConsumersOptions(
          consumerLits.map((consumer: any): SelectOptions => {
            return {
              value: consumer.id,
              label: consumer.name,
            }
          }),
        )
      }
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoadingConsumersList(false)
    }
  }

  useEffect(() => {
    if (isLoadingConsumersList || consumersOptions.length) return

    getConsumersList()
  }, [])

  return (
    <div className="flex">
      <Search onSearchChange={handleSearchChange} />
      {filtersCount > 0 && (
        <button
          type="button"
          onClick={() => {
            reset()
            cleanFilters()
            setFiltersCount(0)
          }}
          className="flex items-center text-ppa/placeholder text-xs font-normal gap-x-1.5 mr-2 w-24"
        >
          <XIcon className="w-3 h-3" />
          {t('clearAll')}
        </button>
      )}
      <Filter numFiltersApplied={filtersCount}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit((data) => {
              handleSubmitForm({ ...data, search: searchText })
            })()
          }}
          className="min-w-[270px] max-w-[300px]"
        >
          <div className="flex flex-col overflow-visible gap-y-3.5 mt-5 mb-3.5">
            <div className="flex flex-col gap-y-3 min-w-[270px]">
              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.consumer')}
                </span>
                <Controller
                  name="consumer"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <SelectComponent
                      options={consumersOptions || []}
                      placeholder={t('placeholder.selectConsumer')}
                      value={value}
                      {...props}
                    />
                  )}
                />
              </div>

              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.targetMatch')}
                </span>
                <Controller
                  name="targetMatch"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <SelectComponent
                      options={targetMatchOptions || []}
                      placeholder={t('placeholder.selectTargetMatch')}
                      value={value}
                      {...props}
                    />
                  )}
                />
              </div>

              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.deadline')}
                </span>
                <div className="w-full">
                  <Controller
                    name="deadline"
                    control={control}
                    render={({ field: props }) => (
                      <Input
                        type="date"
                        min={dayjs().format(dateFormats.minDateInput)}
                        placeholder={t('deadline.placeholder')}
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.consumption')}
                </span>
                <div className="flex gap-x-1.5">
                  <div className="min-w-[190px]">
                    <Controller
                      name="consumptionOperator"
                      control={control}
                      render={({ field: { ref, value, ...props } }) => (
                        <SelectComponent
                          options={operatorOptions || []}
                          placeholder={t('placeholder.graterThanOperator')}
                          value={value}
                          disableClean
                          {...props}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="consumptionValue"
                    control={control}
                    render={({ field: { ref, value, ...props } }) => (
                      <Input
                        type="text"
                        suffix="MWh"
                        value={value}
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-1">
              <span className="text-sm text-ppa/title font-normal">
                {t('label.duration')}
              </span>
              <div className="flex justify-center items-center gap-x-1.5">
                <div className="max-w-[145px]">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: props }) => (
                      <Input
                        type="date"
                        min={dayjs().format(dateFormats.minDateInput)}
                        placeholder={t('startDate.placeholder')}
                        {...props}
                      />
                    )}
                  />
                </div>
                <div className="max-w-[145px]">
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: props }) => (
                      <Input
                        type="date"
                        min={dayjs().format(dateFormats.minDateInput)}
                        placeholder={t('endDate.placeholder')}
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="mt-2">
              <Button type="submit" variant="primary" fullWidth>
                {t('applyFilters')}
              </Button>
            </div>
          </div>
        </form>
      </Filter>
    </div>
  )
}

export default SearchFilterConsumerTenders
