import React, { useCallback, useState, forwardRef } from 'react'
import Select, { StylesConfig, OptionContext } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import makeAnimated from 'react-select/animated'
import classNames from 'classnames'

const colors = {
  text: '#2D3463',
  placeholder: '#9AA1AD',
  indicators: '#9AA1AD',
  backgroundColor: '#fff',
  border: '#9AA1AD',
  borderFocus: '#356FD7',
  borderError: '#0090FF,',
  optionColor: '#6B7280',
  optionBackground: '#fff',
  optionFocusText: '#fff',
  optionFocusBackground: '#356FD7',
  multiColor: '#fff',
  multiBackground: '#356FD7',
}

const hoverIndicators = {
  transition: 'filter .25s ease-in-out',
  cursor: 'pointer',
  ':hover': {
    filter: 'brightness(75%)',
  },
}

export const customStyles: StylesConfig<true> = {
  singleValue: (provided) => {
    return {
      ...provided,
      padding: '0',
      fontSize: '0.875rem',
      fontWeight: '400',
      color: colors.text,
    }
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      color: colors.text,
      padding: '0.375rem 0.75rem 0.375rem 0.75rem',
    }
  },
  placeholder: (provided) => {
    return {
      ...provided,
      fontWeight: '400',
      color: colors.placeholder,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      padding: '0',
      margin: '0',
    }
  },
  input: (provided) => {
    return {
      ...provided,
      fontWeight: '400',
      color: colors.text,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      margin: '0',
      padding: '0',
    }
  },
  indicatorSeparator: (provided) => {
    return { ...provided, background: colors.indicators }
  },
  indicatorsContainer: (provided) => {
    return { ...provided, color: colors.indicators }
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      ...hoverIndicators,
      color: colors.indicators,
      padding: '7px',
      '> svg': {
        width: '21px',
        height: '21px',
      },
    }
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      ...hoverIndicators,
      color: colors.indicators,
    }
  },
  control: (provided) => {
    return {
      ...provided,
      minHeight: 'unset',
      borderColor: 'transparent',
      background: 'transparent',
      borderWidth: '0',
      border: '0',
      boxShadow: '0',
      marginTop: '-3px',
      ':focus': {
        border: '0',
        boxShadow: '0',
      },
    }
  },
  container: (provided, { className }) => {
    const stylesObj: any = {
      border: `1px solid ${colors.border}`,
      borderRadius: '6px',
      backgroundColor: colors.backgroundColor,
      transition: 'all ease-in-out 200ms',
      ':focus': {
        border: `1px solid ${colors.borderFocus}`,
        boxShadow: ' 0px 0px 1px 0.5px rgba(53, 111, 215, 0.25)',
      },
      ':focus-within': {
        border: `1px solid ${colors.borderFocus}`,
        boxShadow: ' 0px 0px 1px 0.5px rgba(53, 111, 215, 0.25)',
      },
    }

    if (className?.includes('errored')) {
      stylesObj.border = `1px solid ${colors.borderError}`
      stylesObj[':focus'].border = `1px solid ${colors.borderError}`
      stylesObj[':focus'].boxShadow = `1px 0.5px ${colors.borderError}`
      stylesObj[':focus-within'].border = `1px solid ${colors.borderError}`
      stylesObj[':focus-within'].boxShadow = `1px 0.5px ${colors.borderError}`
    }
    return {
      ...provided,
      ...stylesObj,
      ':focus': { ...stylesObj[':focus'] },
      ':focus-within': { ...stylesObj[':focus-within'] },
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      border: '0',
      boxShadow: '0px 0px 1px 1px rgba(53, 111, 215, 0.25)',
      color: colors.text,
      transition: 'all ease-in-out 200ms',
    }
  },
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    let stylesObj: any = {
      backgroundColor: colors.optionBackground,
      color: colors.optionColor,
      cursor: 'pointer',
      filter: undefined,
      ':focus': {
        color: colors.optionFocusText,
        backgroundColor: colors.optionFocusBackground,
      },
      ':hover': {
        color: colors.optionFocusText,
        backgroundColor: colors.optionFocusBackground,
      },
    }

    if (isDisabled) {
      stylesObj = {
        backgroundColor: '#fff',
        filter: 'brightness(90%)',
        color: '#6B7280',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        ':hover': {
          backgroundColor: '#fff',
          filter: 'brightness(90%)',
          color: '#6B7280',
          cursor: 'not-allowed',
          pointerEvents: 'none',
        },
        ':focus': {
          backgroundColor: '#fff',
          filter: 'brightness(90%)',
          color: '#6B7280',
          cursor: 'not-allowed',
          pointerEvents: 'none',
        },
      }
    } else if (isFocused) {
      stylesObj.color = colors.optionFocusText
      stylesObj.backgroundColor = colors.optionFocusBackground
      stylesObj.filter = undefined
    } else if (isSelected) {
      stylesObj.filter = 'brightness(90%)'
      stylesObj.cursor = 'not-allowed'
      stylesObj.pointerEvents = 'none'
    }

    return {
      ...provided,
      backgroundColor: stylesObj.backgroundColor,
      color: stylesObj.color,
      cursor: stylesObj.cursor,
      pointerEvents: stylesObj.pointerEvents,
      filter: stylesObj.filter,
      transition: 'all ease-in-out 200ms',
      ':focus': {
        ...provided[':focus'],
        backgroundColor: stylesObj[':focus'].backgroundColor,
        color: stylesObj[':focus'].color,
        pointerEvents: stylesObj[':focus'].pointerEvents,
        cursor: stylesObj[':focus'].cursor,
        filter: stylesObj[':focus'].filter,
      },
      ':hover': {
        ...provided[':hover'],
        color: stylesObj[':hover'].color,
        backgroundColor: stylesObj[':hover'].backgroundColor,
        pointerEvents: stylesObj[':hover'].pointerEvents,
        cursor: stylesObj[':hover'].cursor,
        filter: stylesObj[':hover'].filter,
      },
    }
  },
  multiValue: () => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '0.875rem',
      fontWeight: '400',
      margin: '2px',
      backgroundColor: colors.multiBackground,
      color: colors.multiColor,
      borderRadius: '5px',
    }
  },
  multiValueRemove: () => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 6px',
      color: colors.multiColor,
      transition: 'all ease-in-out 200ms',
      ':hover': {
        filter: 'brightness(75%)',
      },
    }
  },
  multiValueLabel: (_, { isFocused }) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 'fit-content',
      backgroundColor: colors.multiBackground,
      color: colors.multiColor,
      borderRadius: '5px',
      boxSizing: 'border-box',
      margin: '0px 6px',
      filter: isFocused ? 'brightness(75%)' : undefined,
      transition: 'all ease-in-out 200ms',
    }
  },
}

export interface SelectOptions<Value = string, Label = string | JSX.Element> {
  value: Value
  label: Label
}

export interface SelectProps {
  onChange: (value?: any) => void
  onCreateOption?: (label: string) => Promise<SelectOptions> | SelectOptions
  type?: 'single' | 'multi'
  disabled?: boolean
  disableClean?: boolean
  disablePlaceholder?: boolean
  placeholder?: string
  name: string
  options: SelectOptions[]
  error?: any
  value?: any
  testId?: string
}

export const selectConfig = {
  single: {
    isMulti: false,
    placeholder: 'Select one option',
  },
  multi: {
    isMulti: true,
    placeholder: 'Select at least one option',
  },
}

const animatedComponents = makeAnimated()

const SelectComponent: React.FC<SelectProps> = ({
  onChange,
  onCreateOption,
  name,
  disableClean,
  disablePlaceholder = false,
  type = 'single',
  placeholder = selectConfig[type].placeholder,
  disabled,
  options,
  error,
  value,
  testId = 'select-component',
}) => {
  const [optionList, setOptionsList] = useState<SelectOptions[]>(options)
  const [isLoading, setIsLoading] = useState(false)

  const onAddNewOption = useCallback(
    async (label: string) => {
      if (onCreateOption) {
        setIsLoading(true)
        try {
          const newOption = await onCreateOption(label)
          const updatedValue = [...(value || []), newOption]
          setOptionsList([...optionList, newOption])
          onChange(updatedValue)
        } catch (err) {
          console.error('Error occurred when trying to add a new option')
          /**
           * @TODO Add a warning message saying that adding a new option got an error
           */
        }
        setIsLoading(false)
      }
    },
    [optionList, onCreateOption, onChange, value],
  )

  return (
    <div className="w-full" data-testid={testId}>
      {!onCreateOption ? (
        <Select
          onChange={onChange}
          className={classNames('react-select-container', error && 'errored')}
          styles={customStyles as any}
          isClearable={!disableClean}
          isDisabled={disabled}
          components={animatedComponents}
          isMulti={selectConfig[type].isMulti}
          placeholder={!disablePlaceholder && placeholder}
          options={options as any}
          value={value || ''}
          name={name}
        />
      ) : (
        <CreatableSelect
          onChange={onChange}
          className={classNames('react-select-container', error && 'errored')}
          onCreateOption={onAddNewOption}
          styles={customStyles as any}
          isClearable={!disableClean}
          isDisabled={isLoading || disabled}
          isLoading={isLoading}
          components={animatedComponents}
          isMulti={selectConfig[type].isMulti}
          placeholder={isLoading ? 'Adding new option' : placeholder}
          options={optionList as any}
          value={value}
          name={name}
        />
      )}
    </div>
  )
}

export default SelectComponent
