/* eslint-disable import/prefer-default-export */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_STRAPI_URL
const token = process.env.REACT_APP_STRAPI_TOKEN

export const mountQuery = (query: Record<any, any>) => {
  const queryKeys = Object.keys(query)

  const filteredKeys = queryKeys.filter((key) => key && query[key])

  if (filteredKeys.length === 0) return ''

  const queryUrl = filteredKeys
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
    )
    .join('&')

  return `?${queryUrl}`
}

export const strapi = axios.create({
  baseURL: `${baseUrl}/api`,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${token}`,
  },
})
