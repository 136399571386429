import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'

import Button from '../../../../components/atoms/button'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'

import { mappedTenderStatus, AvailableRouteStatus } from '../types'

import { sleevedTendersSubRoutes } from './routes'
import { SupplierTenderInvitation } from '../../../../types'

export interface SleevedTenderDetailsProps {
  tenderInvitation: SupplierTenderInvitation
  refetch: () => void
}

const SleevedTenderDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details',
  })

  const { tenderId } = useParams<{ tenderId: string }>()

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<SupplierTenderInvitation>({
      dataKey: 'supplierTenderInvitation',
      path: `/core/private/supplier-tender/supplier/details/${tenderId}`,
    })

  const detailsComponent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )
    }
    if (!isLoading && error) {
      return (
        <div>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )
    }
    if (!data) return undefined

    const Component =
      sleevedTendersSubRoutes[
        mappedTenderStatus[
          data.status as AvailableRouteStatus
        ] as keyof typeof sleevedTendersSubRoutes
      ].details

    return <Component tenderInvitation={data} refetch={refetch} />
  }, [isLoading, error, data, refetch])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return <div className="flex flex-col">{detailsComponent}</div>
}

export default SleevedTenderDetails
