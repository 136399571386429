import React, { useState, useEffect } from 'react'

import usePPA from '../../../hooks/use-ppa'

import { capitalizeText } from '../../../utils'

import Select, { SelectProps, SelectOptions } from '../../atoms/select'

/**
 * @FIXME
import type { MemberType } from '../../../../../../../types'
 */

type MemberType = any

interface SelectSiteProps
  extends Omit<SelectProps, 'type' | 'onCreateOption' | 'options'> {
  memberType?: MemberType[]
}

const SelectMemberType: React.FC<SelectSiteProps> = ({
  memberType,
  ...props
}) => {
  const [memberTypeOptions, setMemberTypeOptions] = useState(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { fetchData } = usePPA()

  const getMemberTypeList = async () => {
    if (isLoading || memberTypeOptions) return
    setIsLoading(true)

    try {
      const { error, response } = await fetchData({
        method: 'GET',
        url: '/core/private/default-values/supplier/member-type',
      })

      setIsLoading(false)
      if (response?.data && !error) {
        setMemberTypeOptions(
          response.data.memberTypes.map((typeMember: string): SelectOptions => {
            return {
              value: typeMember,
              label: capitalizeText(typeMember),
            }
          }),
        )

        return
      }

      throw error
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    if (isLoading || memberTypeOptions) return

    getMemberTypeList()
  }, [])

  return (
    <Select
      {...props}
      type="single"
      options={memberTypeOptions || []}
      testId="select-memberType"
    />
  )
}

export default SelectMemberType
