import React from 'react'
import { consumerTendersSubRoutes } from './routes'

interface TendersProps {
  status: keyof typeof consumerTendersSubRoutes
}

const ConsumerTenders: React.FC<TendersProps> = ({ status }) => {
  return <div>{status && consumerTendersSubRoutes[status].index}</div>
}

export default ConsumerTenders
