import React from 'react'
import { useTranslation } from 'react-i18next'

import Pill, { variants } from '../../../../components/atoms/pill'

import { TenderStatus, Children } from '../../../../types'

type StatusToVariant = {
  [key in TenderStatus]: keyof typeof variants
}

export const statusToVariant: StatusToVariant = {
  WITHDRAWN: 'blue',
  DRAFT: 'yellow',
  ACTIVE: 'green',
  EXECUTED: 'gray',
  UNDER_OFFER: 'blue',
  ARCHIVED: 'grayBgTransparetnt',
}

interface TenderPillProps extends Children {
  status: TenderStatus
}

const TenderPill: React.FC<TenderPillProps> = ({ status }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'utils.status.tender',
  })

  return <Pill variant={statusToVariant[status]}>{t(status)}</Pill>
}

export default TenderPill
