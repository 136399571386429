import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Confetti from 'react-confetti'

import usePPA from '../../../hooks/use-ppa'
import useUser from '../../../hooks/use-user'

import CompanyLogo from '../../../components/atoms/company-logo'

import Button from '../../../components/atoms/button'
import Modal from '../../../components/molecules/modal'

const AllSetModal: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'allSetPage',
  })

  const { user } = useUser<'SUPPLIER' | 'GENERATOR' | 'CONSUMER' | 'ADMIN'>()

  const { fetchData, throwFeedbackError } = usePPA()

  const [isActive, setIsActive] = useState(true)
  const [confettiOpacity, setConfettiOpacity] = useState(1)
  const [isAllSetModalOpen, setIsAllSetModalOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const handleAllSetData = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error } = await fetchData({
        method: 'PATCH',
        url:
          user.appMetadata.userType !== 'CONSUMER'
            ? `/core/private/${user.appMetadata.userType.toLowerCase()}/${
                user.appMetadata.id
              }/user-metadata`
            : `/core/private/consumer/user-metadata/${user.appMetadata.id}`,
        body: {
          ...user.userMetadata,
          onboardingStep: 'done',
        },
      })

      if (error) throw error
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
      setIsAllSetModalOpen(false)
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const durationTime = performance.now() - 2000

      if (durationTime >= 2500) {
        setConfettiOpacity((prevOpacity) => prevOpacity - 0.1)
      }

      if (durationTime >= 3500) {
        setIsActive(false)
        clearInterval(timer)
      }
    }, 150)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (user?.userMetadata?.onboardingStep === 'triggerAllSet')
      setIsAllSetModalOpen(true)
  }, [user])

  return (
    <Modal
      isOpen={isAllSetModalOpen}
      handleClose={() => setIsAllSetModalOpen(false)}
    >
      <div className="max-w-[370px] min-h-[440px] mx-8">
        <h1 className="text-3xl text-ppa/title font-semibold my-8">
          {t('title')} 🎉
        </h1>
        <div className="flex flex-col gap-y-5 text-ppa/title text-lg font-normal">
          <p>{t('infoMessage')}</p>
          <p>
            <Trans
              i18nKey="allSetPage.findOurLatestNews"
              ns="private/index"
              components={{
                websiteAnchor: (
                  <a
                    href="https://ppaya.co.uk"
                    target="_blank"
                    rel="noreferrer"
                    className="text-ppa/blueFocus cursor-pointer"
                  >
                    {t('website')}
                  </a>
                ),
              }}
            />
          </p>
          <p className="mt-3">
            <Trans
              i18nKey="allSetPage.contactUs"
              ns="private/index"
              components={{
                emailAnchor: (
                  <a
                    href="mailto:support@ppaya.co.uk"
                    className="text-ppa/blueFocus cursor-pointer"
                  >
                    support@ppaya.co.uk
                  </a>
                ),
              }}
            />
          </p>
          <div className="flex justify-between items-center">
            <CompanyLogo variant="allSet" />
            <div className="mr-4 min-w-[150px]">
              <Button
                variant="primary"
                fullWidth
                onClick={() => handleAllSetData()}
              >
                {t('goExploreButton')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ opacity: confettiOpacity }}>
        {isActive && (
          <Confetti
            width={window.outerWidth + 200}
            height={window.outerHeight}
          />
        )}
      </div>
    </Modal>
  )
}

export default AllSetModal
