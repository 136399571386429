import React, { useMemo } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { transition, transitionAll } from '../../styles'
import { NavigationLink } from './routes'

interface NavigationLinkProps extends NavigationLink {
  currentPathname: string
  isSubMenuOpen: boolean
  onSubMenuToggle: (label: string) => void
}

const NavItem: React.FC<NavigationLinkProps> = ({
  icon: Icon,
  label,
  to,
  subMenu,
  currentPathname,
  isSubMenuOpen,
  onSubMenuToggle,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation('private/index')

  const handleMenuClick = () => {
    onSubMenuToggle(label)
    if (subMenu && subMenu.length > 0) {
      navigate(`${to}${subMenu[0].to}`)
    } else {
      navigate(to)
    }
  }

  const NavElement = useMemo(() => {
    return (
      <div className="flex flex-col">
        <button
          type="button"
          onClick={handleMenuClick}
          className={classNames(
            transition,
            'flex items-center justify-start py-2 px-5',
            'border-none hover:brightness-75 rounded-md bg-ppa/sidebarBackground font-normal',
            currentPathname.includes(to) && 'bg-ppa/sidebarButtonBackground',
          )}
        >
          <Icon
            className={classNames(
              transition,
              'mr-3 w-6 h-6 fill-ppa/sidebarButtonIcon',
              currentPathname.includes(to) &&
                'fill-ppa/sidebarButtonIconActive',
            )}
          />
          <span
            className={classNames(
              'text-ppa/sidebarButtonText text-xl',
              currentPathname.includes(to) &&
                'text-ppa/sidebarButtonTextActive',
            )}
          >
            {t(`sideBar.${label}`)}
          </span>
        </button>

        {isSubMenuOpen && subMenu && (
          <div
            className={classNames(
              'bg-transparent flex flex-col h-full max-h-full overflow-hidden',
              transitionAll,
            )}
          >
            {subMenu.map((subItem) => (
              <NavLink
                key={subItem.label}
                to={`${to}${subItem.to}`}
                className="pl-12 font-normal focus:brightness-100 focus-within:brightness-100"
              >
                {({ isActive }) => (
                  <div className="flex items-center justify-start py-2 px-5 rounded-md hover:brightness-75">
                    <span
                      className={classNames(
                        transition,
                        'text-ppa/sidebarButtonText text-sm',
                        isActive && 'text-ppa/sidebarButtonSubmenuTextActive',
                      )}
                    >
                      {t(`sideBar.${subItem.label}`)}
                    </span>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    )
  }, [Icon, label, to, subMenu, isSubMenuOpen, currentPathname, t])

  return NavElement
}

export default NavItem
