import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import DefList from '../../../../../../components/atoms/def-list'

import { SupplierTenderInvitation } from '../../../../../../types'

interface TenderDetailsListSleevedProps {
  data: SupplierTenderInvitation['supplierTender']
}

const MeterDetailsDefList: React.FC<TenderDetailsListSleevedProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('meterDetailsList.titles.name'),
        renderCustomEl: (
          meter: SupplierTenderInvitation['supplierTender']['consumerTender']['consumerMeter'],
        ) => {
          if (!meter.name) return undefined
          return meter.name
        },
      },
      {
        keyName: 'mpan',
        title: t('meterDetailsList.titles.mpan'),
        renderCustomEl: (
          meter: SupplierTenderInvitation['supplierTender']['consumerTender']['consumerMeter'],
        ) => {
          if (!meter.mpan) return undefined
          return meter.mpan
        },
      },
      {
        keyName: 'hhd',
        title: t('meterDetailsList.titles.hhd'),
        renderCustomEl: (
          meter: SupplierTenderInvitation['supplierTender']['consumerTender']['consumerMeter'],
        ) => {
          if (!meter.hhdTimeFrame) return undefined
          return meter?.hhdTimeFrame
        },
      },
      {
        keyName: 'annualConsumption',
        title: t('meterDetailsList.titles.annualConsumption'),
        suffix: 'MWh',
        renderCustomEl: (
          meter: SupplierTenderInvitation['supplierTender']['consumerTender']['consumerMeter'],
        ) => {
          if (!meter.annualConsumption) return undefined
          return meter.annualConsumption
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('meterDetailsTitle')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default MeterDetailsDefList
