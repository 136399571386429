import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import { ReactComponent as Pencil } from './assets/PencilSVG.svg'

import Message from '../../../../../components/atoms/message'
import Dropdown from '../../../../../components/atoms/dropdown'

import SupplierProfileDetails from './profile-details-list'
import EditSupplierProfile from './edit-supplier-profile'

import { SupplierProfileData } from '../../../../../types'

const Profile: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.profile',
  })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { isLoading, throwFeedbackError, data, error, refetch } =
    usePPAGetDetails<SupplierProfileData>({
      dataKey: 'supplierProfile',
      path: '/core/private/supplier/profile',
    })

  const supplierDetails = useMemo((): SupplierProfileData | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return (
    <div className="max-w-[800px] overflow-auto">
      <div className="ml-3">
        <Message variant="info" size="text-xs">
          {t('supplierProfileInfoMessage')}
        </Message>
        <div
          className={classNames(
            'mt-11 flex',
            // profileData?.logoUrl ? 'justify-between' :
            'justify-end',
          )}
        >
          {/* {profileData?.logoUrl && (
            <img
              src={profileData?.logoUrl}
              alt={data?.supplierList[0]?.name}
              className="w-20 h-16"
            />
          )} */}
          <div className="h-12">
            <Dropdown
              orientation="bottom-left"
              content={
                <button
                  type="button"
                  onClick={() => setIsDrawerOpen(true)}
                  className="flex items-center w-24"
                >
                  <Pencil className="mr-2" />
                  <span className="text-sm font-normal">
                    {t('editProfileButton')}
                  </span>
                </button>
              }
            />
          </div>
        </div>
      </div>
      <SupplierProfileDetails data={supplierDetails} />
      <div className="h-full">
        <EditSupplierProfile
          isDrawerOpen={isDrawerOpen}
          supplierProfile={supplierDetails}
          onSuccess={() => {
            refetch()
            setIsDrawerOpen(false)
          }}
          closeDrawer={() => setIsDrawerOpen(false)}
        />
      </div>
    </div>
  )
}

export default Profile
