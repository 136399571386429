import React from 'react'
import { tendersSubRoutes } from './routes'

interface TendersProps {
  status: keyof typeof tendersSubRoutes
}

const Tenders: React.FC<TendersProps> = ({ status }) => {
  return <div>{status && tendersSubRoutes[status].index}</div>
}

export default Tenders
