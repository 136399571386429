/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import usePPAGetList from '../../../../../../../hooks/use-ppa/use-ppa-get-list'

import {
  SupplierTenderInvitation,
  SupplierTenderBid,
  SubsidyType,
  PowerPriceType,
} from '../../../../../../../types'

import Input from '../../../../../../../components/atoms/input'
import Message from '../../../../../../../components/atoms/message'

import InputGroup from '../../../../../../../components/molecules/input-group'

interface FormGreenCertificate {
  enabled?: boolean | string
  type: PowerPriceType
  value: string
}

export interface FormGreenCertificates {
  REGO: FormGreenCertificate
  ROC_BUYOUT: FormGreenCertificate
  ROC_RECYCLE: FormGreenCertificate
  FIT: FormGreenCertificate
}

type GreenCertificateKeys = keyof FormGreenCertificates

export type FormValues = {
  greenCertificates: FormGreenCertificates
}

interface GreenCertificatesFormProps {
  tender: SupplierTenderInvitation['supplierTender']
  bid?: SupplierTenderBid
}

const GreenCertificatesForm: React.FC<GreenCertificatesFormProps> = ({
  tender,
  bid,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.form',
  })

  const { isLoading, data, error } = usePPAGetList<SubsidyType>({
    dataKey: 'activeGreenCertificates',
    path: '/core/private/supplier-tender-bid-green-certificate/list',
  })

  const [greenCertificatesOptions, setGreenCertificatesOptions] = useState<
    SubsidyType[]
  >([])

  const { register, setValue } = useFormContext<FormValues>()

  const activeGreenCertificatesNames = useMemo(() => {
    return tender.supplierTenderGreenCertificates
      .filter((item) => item.toggled === true)
      .map((item) => item.name)
  }, [tender])

  useEffect(() => {
    if (isLoading || error || !data || greenCertificatesOptions.length > 0) {
      return
    }

    if (
      !activeGreenCertificatesNames ||
      activeGreenCertificatesNames.length === 0
    ) {
      return
    }

    const hasROC = activeGreenCertificatesNames.includes('ROC' as any)

    const greenCertificatesList: SubsidyType[] = Array.from(
      new Set(
        data
          .filter((item) =>
            activeGreenCertificatesNames.includes(item.name as any),
          )
          .filter((item) => item.type !== 'FIXED'),
      ),
    )

    if (hasROC) {
      greenCertificatesList.push(
        { name: 'ROC_BUYOUT', active: true } as any,
        { name: 'ROC_RECYCLE', active: true } as any,
      )
    }

    setGreenCertificatesOptions(greenCertificatesList)

    greenCertificatesList.forEach(({ name: greenCertificateName }) => {
      if (bid) {
        const matchingCertificate =
          bid?.supplierTenderBidGreenCertificates?.find(
            (greenCer) => greenCer.name === greenCertificateName,
          )

        if (matchingCertificate) {
          const value = `${matchingCertificate.value || 0}`
          const type = (matchingCertificate.type || 'FIXED') as PowerPriceType
          if (greenCertificateName === 'FIT') {
            setValue(`greenCertificates.${greenCertificateName}`, {
              type: 'FLEXIBLE',
              value: '100',
            })
          } else {
            setValue(
              `greenCertificates.${
                greenCertificateName as GreenCertificateKeys
              }`,
              {
                type,
                value,
              },
            )
          }
        }
      } else {
        let type: PowerPriceType
        if (greenCertificateName === 'REGO') {
          type = 'FIXED'
        } else {
          type = 'FLEXIBLE'
        }
        const value = greenCertificateName === 'FIT' ? '100' : '0'
        setValue(
          `greenCertificates.${greenCertificateName as GreenCertificateKeys}`,
          {
            type,
            value,
          },
        )
      }
    })
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      console.error('Green Certificates could not be loaded', error)
    }
  }, [error])

  return (
    <>
      {greenCertificatesOptions.length > 0 && (
        <div>
          <div className="border border-b border-ppa/grayBorder w-full" />
          <div className="mt-3.5">
            <InputGroup label={t('fields.greenCertificates.label')}>
              <Message variant="info" size="text-xs self-center">
                {t('fields.greenCertificates.infoMessage')}
              </Message>
            </InputGroup>
            <div className="flex flex-col mt-1 gap-y-3">
              {greenCertificatesOptions.map(({ name }) => {
                const thisRegister = {
                  ...register(`greenCertificates.${name}.value` as any),
                  ref: undefined,
                }
                return (
                  <div key={name}>
                    <Controller
                      {...thisRegister}
                      render={({ field: { ...props } }) => {
                        return (
                          <InputGroup
                            label={
                              <>
                                <span>
                                  {t(`fields.greenCertificates.${name}.label`)}
                                </span>
                                <span className="text-xs font-light ml-2 mt-[2px]">
                                  {t(
                                    `fields.greenCertificates.${name}.sublabel`,
                                  )}
                                </span>
                              </>
                            }
                          >
                            <div className="max-w-[170px]">
                              <Input
                                {...props}
                                type="number"
                                step="0.01"
                                placeholder="0,00"
                                suffix={name !== 'REGO' ? '%' : '£/MWh'}
                                max={
                                  ['ROC_BUYOUT', 'ROC_RECYCLE'].includes(name)
                                    ? '100'
                                    : undefined
                                }
                                readOnly={name === 'FIT'}
                                disabled={name === 'FIT'}
                                disabledInput={name === 'FIT'}
                              />
                            </div>
                          </InputGroup>
                        )
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default GreenCertificatesForm
