import React, { ReactElement } from 'react'

import { ReactComponent as PPALogo } from '../../../assets/logos/ppaya/colored.svg'
import { ReactComponent as PPALogoWhite } from '../../../assets/logos/ppaya/white.svg'
import { ReactComponent as PPALogoGray } from '../../../assets/logos/ppaya/gray-logo.svg'
import { ReactComponent as PPALogoMobile } from '../../../assets/logos/ppaya/mobile-logo.svg'
import { ReactComponent as CJLogo } from '../../../assets/logos/carter-jonas/colored.svg'
import { ReactComponent as CJLogoWhite } from '../../../assets/logos/carter-jonas/white.svg'
import { ReactComponent as CJLogoMobile } from '../../../assets/logos/carter-jonas/mobile-logo.svg'

import { ReactComponent as PpayaAllSetLogo } from '../../../assets/logos/ppaya/all-set-logo.svg'
import { ReactComponent as CJAllSetLogo } from '../../../assets/logos/carter-jonas/all-set-cj.svg'

const companyIdentifier = process.env.REACT_APP_COMPANY || 'PPAYA'

const logos: Record<
  string,
  Record<'dark' | 'light' | 'gray' | 'allSet' | 'mobile', ReactElement>
> = {
  PPAYA: {
    dark: <PPALogo />,
    light: <PPALogoWhite />,
    gray: <PPALogoGray />,
    allSet: <PpayaAllSetLogo />,
    mobile: <PPALogoMobile />,
  },
  CARTER_JONAS: {
    dark: (
      <div className="mt-12 -mb-7">
        <CJLogo />,
      </div>
    ),
    light: (
      <div className="mt-10">
        <CJLogoWhite />,
      </div>
    ),
    gray: (
      <div className="mt-10">
        <PPALogoGray />,
      </div>
    ),
    allSet: (
      <div className="flex flex-col items-end mt-3">
        <CJAllSetLogo />
      </div>
    ),
    mobile: (
      <div className="flex flex-col items-end mt-5">
        <CJLogoMobile />
        <div className="flex justify-center items-center text-[6px] font-normal -mt-7 -mb-5">
          powered by
          <PPALogoMobile className="ml-1 w-8" />
        </div>
      </div>
    ),
  },
}

interface CompanyLogoSwitcherProps {
  variant?: 'dark' | 'light' | 'gray' | 'allSet' | 'mobile'
}

const CompanyLogo: React.FC<CompanyLogoSwitcherProps> = ({
  variant = 'light',
}) => {
  const LogoComponent: ReactElement = logos[companyIdentifier][variant]

  return LogoComponent
}

export default CompanyLogo
