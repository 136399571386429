import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../../../../../components/atoms/button'
import Modal from '../../../../../../components/molecules/modal'

interface AcceptInvitationModalProps {
  isOpen: boolean
  onClose: () => void
  onAcceptInvitation: () => void
}

const AcceptInvitationModal: React.FC<AcceptInvitationModalProps> = ({
  isOpen,
  onClose,
  onAcceptInvitation,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.acceptInvitationModal',
  })

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <div className="w-[460px] mx-8">
        <h1 className="text-xl text-ppa/title font-bold my-8">{t('title')}</h1>
        <div className="flex flex-col gap-y-5 text-ppa/title text-base font-normal">
          <div> {t('infoMessage')}</div>
          <div> {t('continueQuestion')}</div>

          <div className="flex items-center justify-end gap-x-5 mt-2 mb-8">
            <Button variant="secondary" onClick={onClose}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="primary" onClick={onAcceptInvitation}>
              {t('buttons.acceptInvitation')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AcceptInvitationModal
