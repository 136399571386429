import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'

interface ToggleSwitchProps {
  name: string
  control: any
  userPreferences: boolean
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  name,
  control,
  userPreferences,
}) => {
  const [isChecked, setIsChecked] = useState(userPreferences)

  useEffect(() => {
    setIsChecked(userPreferences)
  }, [userPreferences])

  return (
    <div className="relative inline-flex items-center cursor-pointer">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <label htmlFor={name} className="inline-flex items-center">
            <input
              type="checkbox"
              id={name}
              className="sr-only peer"
              onChange={(e) => {
                const newValue = e.target.checked
                setIsChecked(newValue)
                onChange(newValue)
              }}
              checked={isChecked}
            />
            <div className="w-6 h-4 peer-focus:outline-none rounded-full peer dark:bg-[#E7E7E7] peer-checked:after:translate-x-full peer-checked:after:border-white after:absolute after:top-[3px] after:left-[1.5px] after:bg-white after:rounded-full after:h-2.5 after:w-2.5 after:transition-all peer-checked:bg-ppa/primary" />
          </label>
        )}
      />
    </div>
  )
}

export default ToggleSwitch
