import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import RequiredField from '../../../../../../../components/atoms/required-form-field'

import Button from '../../../../../../../components/atoms/button'

import Input from '../../../../../../../components/atoms/input'
import InputGroup from '../../../../../../../components/molecules/input-group'

import { dateFormats } from '../../../../../../../utils/data'

dayjs.extend(utc)
dayjs.extend(timezone)

export type FormValues = {
  dateTime: string
}

interface TargetPriceFormProps {
  onSubmit: (data: any) => void
  onCancel?: () => void
  isLoading?: boolean
  deadline?: string
}

const TargetPriceForm: React.FC<TargetPriceFormProps> = ({
  onSubmit,
  onCancel,
  isLoading,
  deadline,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.drawerDeadline.form',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      dateTime: deadline
        ? dayjs.utc(deadline).tz('Europe/London').format('YYYY-MM-DDThh:mm')
        : '',
    },
  })

  useEffect(() => {
    reset({
      dateTime: deadline
        ? dayjs.utc(deadline).tz('Europe/London').format('YYYY-MM-DDThh:mm')
        : '',
    })
  }, [deadline])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-1 flex-col gap-y-5"
    >
      <Controller
        name="dateTime"
        control={control}
        rules={{ required: t('dateTime.required') }}
        render={({ field: props }) => (
          <InputGroup
            label={t('dateTime.title')}
            error={errors.dateTime?.message}
            required
          >
            <Input
              type="datetime-local"
              {...props}
              min={dayjs().format(dateFormats.minDateInput)}
              placeholder={t('dateTime.placeholder')}
              error={errors.dateTime?.message}
            />
          </InputGroup>
        )}
      />
      <div>
        <RequiredField />
      </div>
      <div className="flex items-center justify-end w-full gap-x-5">
        {onCancel && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              onClick={onCancel}
              variant="secondary"
              fullWidth
              disabled={isLoading}
            >
              {t('cancel')}
            </Button>
          </div>
        )}
        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default TargetPriceForm
