import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import usePPA from '../../../../../../hooks/use-ppa'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import CreateTenderModal from './modal'

import { ConsumerTender, Site } from '../../../../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

interface CreateTenderProps {
  tenderData: ConsumerTender
  siteData: Site
  isModalOpen: boolean
  closeModal: () => void
  reset: () => void
}

const CreateTender: React.FC<CreateTenderProps> = ({
  tenderData,
  siteData,
  isModalOpen,
  closeModal,
  reset,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.createTenderModal',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()
  const [isLoading, setIsLoading] = useState(false)

  const handleCreateTender = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { response, error } = await fetchData({
        method: 'POST',
        url: '/core/private/supplier-tender/supplier',
        body: {
          consumerTenderId: tenderData.id,
          startDate: tenderData.startDate,
          endDate: tenderData.endDate,
          siteId: siteData.id,
          otherRequirements: tenderData.otherRequirements,
        },
      })

      if (error || !response) throw error

      reset()
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('create.onSubmit.success.description'),
        },
        'success',
      )
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplierTender',
      })
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  return (
    <div>
      <CreateTenderModal
        isOpen={isModalOpen}
        onClose={closeModal}
        tenderData={tenderData}
        siteData={siteData}
        onCreateTender={handleCreateTender}
      />
    </div>
  )
}

export default CreateTender
