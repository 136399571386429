import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { FilterIcon } from '@heroicons/react/outline'

import useClickOutside from '../../../hooks/use-click-outside'
import { transition } from '../../../styles'
import { Children } from '../../../types'

export interface FilterProps extends Children {
  numFiltersApplied: number
}

const Filter: React.FC<FilterProps> = ({ numFiltersApplied, children }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'filter',
  })

  const filterRef = useRef(null)

  const [isVisible, setIsVisible] = useState(false)

  useClickOutside({
    ref: filterRef,
    onClickOutside: () => setIsVisible(false),
  })

  return (
    <div className="relative" ref={filterRef}>
      <button
        type="button"
        onClick={() => setIsVisible(!isVisible)}
        className={classNames(
          transition,
          'relative z-0',
          'flex items-center justify-center w-full mb-1',
          'rounded-md text-sm font-medium px-3 py-1',
          'bg-transparent border-solid border',
          !isVisible && !numFiltersApplied
            ? 'text-ppa/grayText border-[#E6E8EC]'
            : 'text-ppa/primary border-ppa/primary',
          numFiltersApplied && 'text-ppa/primary border-[#E6E8EC]',
        )}
      >
        <span className="flex text-center content-center text-sm font-medium">
          <FilterIcon
            className={classNames(
              'w-4 h-4 mr-2 self-center',
              !isVisible && !numFiltersApplied
                ? 'text-ppa/grayTextTitle'
                : 'text-ppa/primary',
            )}
          />
          {t('filterButton')}
          &nbsp;
          {numFiltersApplied ? <span>({numFiltersApplied})</span> : ''}
        </span>
      </button>
      <div
        className={classNames(
          transition,
          'absolute flex min-w-max transform z-50 shadow-md rounded-md',
          'bottom-0 right-0 translate-y-full',
          isVisible
            ? '-translate-y-full h-auto'
            : 'translate-y-0 h-0 overflow-hidden',
        )}
      >
        <div className="flex flex-col items-center bg-white text-ppa/title py-2 px-4">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Filter
