import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { parseCompanyToSelect } from '../../../../../../components/molecules/company-select'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { Company } from '../../../../../../types'

import RegisterNewCompanyForm, {
  FormValues,
} from '../../../../../../hooks/use-register-company/form-register-company'

export interface EditCompanyProps {
  onSuccess: () => void
  handleClose: () => void
  isOpen?: boolean
  company: Company
}

const EditCompany: React.FC<EditCompanyProps> = ({
  onSuccess,
  company,
  handleClose,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.companyList.details.companyDrawer.edit',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData } = usePPA()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (data: Company) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      const { response, error } = await fetchData({
        method: 'PUT',
        url: `/core/private/consumer/company/${company.id}`,
        body: {
          consumerCompanyId: company.id,
          name: data.name,
          number: data?.number || null,
          type: data?.type,
          address: {
            addressLine1: data.address.addressLine1,
            addressLine2: data.address?.addressLine2 || null,
            postalCode: data.address.postalCode,
            locality: data.address.locality,
            region: data.address.region,
            country: data.address.country,
          },
          billingAddress: {
            addressLine1: data.billingAddress.addressLine1,
            addressLine2: data.billingAddress?.addressLine2 || null,
            postalCode: data.billingAddress.postalCode,
            locality: data.billingAddress.locality,
            region: data.billingAddress.region,
            country: data.billingAddress.country,
          },
        },
      })

      if (error && !response) throw error

      feedbackMessage(
        {
          title: t('success.description'),
        },
        'success',
      )

      onSuccess()
    } catch (error) {
      console.error(error)
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: tUtils('feedbackMessage.error.description'),
        },
        'error',
      )
    }

    setIsSubmitting(false)
  }

  const companyEdit = useMemo((): FormValues | undefined => {
    if (!company) return undefined

    const parsedCompany = parseCompanyToSelect(company)

    return {
      company: parsedCompany,
      name: company.name,
      number: company?.number,
      type: company?.type,
      address: {
        addressLine1: company?.address?.addressLine1,
        addressLine2: company?.address?.addressLine2,
        postalCode: company?.address?.postalCode,
        region: company?.address?.region,
        locality: company?.address?.locality,
      },
    }
  }, [company])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5">
        {isOpen && (
          <RegisterNewCompanyForm
            handleSubmitCompany={onSubmit as any}
            handleClose={handleClose}
            isLoading={isSubmitting}
            defaultValues={companyEdit}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default EditCompany
