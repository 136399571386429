import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePPAGetListForAdmins, {
  QueryFilter,
} from '../hooks/use-ppa-get-list-admins'

import Table, { TableConfigProps } from '../../../../components/atoms/table'
import PageTitle from '../../../../components/atoms/page-title'

import UserPill from '../../components/user-pill'
import SearchFilter from './components/search-filter'

import { Member, Consumer } from '../../../../types'
import useUser from '../../../../hooks/use-user'

export type ConsumerList = Consumer & { members: [Member] }

const defaultFilters: QueryFilter = [
  {
    field: 'status',
    operator: 'in',
    value: ['ACTIVE', 'REVIEW', 'ONBOARDING'],
  },
]

const Consumers: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumers.page',
  })

  const navigate = useNavigate()

  const { user } = useUser()

  const [filters, setFilters] = useState<QueryFilter>(defaultFilters)

  const { isLoading, loadMore, applyFilters, throwFeedbackError, data, error } =
    usePPAGetListForAdmins<ConsumerList>({
      dataKey: 'consumers',
      path: '/core/private/consumer/admin/list',
      filters,
    })

  const consumers = useMemo((): ConsumerList[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: QueryFilter): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        if (error) throw error

        await applyFilters([...defaultFilters, ...newFilters])
        setFilters([...defaultFilters, ...newFilters])
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter(
      (item) =>
        item.field === 'search' ||
        defaultFilters.some(
          (defaultFilter) => defaultFilter.field === item.field,
        ),
    )
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (filters.length === 0) {
      setFilters(defaultFilters)
    }
  }, [filters])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  const tableHeaders = useMemo((): string[] => {
    return [
      t('table.headers.companyName'),
      t('table.headers.contactPerson'),
      t('table.headers.status'),
    ]
  }, [])

  const rowKeys = useMemo((): TableConfigProps[] => {
    const result = [
      {
        keyName: 'name',
        renderCustomEl: (item: ConsumerList) => {
          if (!item?.parentCompany) return undefined
          return (
            <div className="flex flex-col ml-3">
              <strong className="font-normal">{item.parentCompany.name}</strong>
              <span className="font-light">{item.parentCompany.number}</span>
            </div>
          )
        },
      },
      {
        keyName: 'members',
        renderCustomEl: (item: ConsumerList) => {
          if (!item?.members) return undefined
          const filteredMembers = item.members.filter(
            (member) => member.memberType === 'OWNER',
          )
          if (filteredMembers.length === 0) return undefined

          return (
            <div className="flex flex-col items-start justify-center gap-y-1">
              <strong>
                {filteredMembers[0].firstName} {filteredMembers[0].lastName}
              </strong>
              <span>{filteredMembers[0].email}</span>
            </div>
          )
        },
      },
      {
        keyName: 'status',
        renderCustomEl: (item: ConsumerList) => {
          return <UserPill status={item.status} blocked={item?.blocked} />
        },
      },
    ]

    return result
  }, [])

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex items-center mt-5 mb-8">
        <PageTitle title={t('title.text')} />
      </div>

      <SearchFilter
        onSubmit={fetchWithFilters}
        cleanFilters={handleCleanFilters}
      />

      <div className="relative z-20">
        <Table
          headers={tableHeaders}
          rowKeys={rowKeys}
          onRowClick={(consumer: Consumer) =>
            navigate(`/consumers/${consumer.id}`)
          }
          isLoading={isLoading}
          error={error}
          data={consumers}
          loadMore={loadMore}
        />
      </div>
    </div>
  )
}

export default Consumers
