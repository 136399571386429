import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { SupplierTenderBid } from '../../../../../types'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import SupplierTableBids, {
  ButtonList as BidsButtonList,
} from '../../../../../components/organisms/supplier-bids-table'

import TenderDetails from '../components/details'

import { SleevedTenderDetailsProps } from '../details'

const ActiveSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const bidGetList = usePPAGetList<SupplierTenderBid>({
    dataKey: 'supplierTenderBids',
    path: `/core/private/supplier-tender-bid/generator/list/${tender.id}`,
  })

  const [isLoadingBidAction, setIsLoadingBidAction] = useState(false)

  const handleDeclineBid = async (id: string) => {
    if (isLoadingBidAction || !bidGetList?.data) return
    setIsLoadingBidAction(true)

    const findBid = bidGetList.data.find((bid) => bid.id === id)
    if (!findBid) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('bids.handleDeclineBid.error.description'),
        },
        'error',
      )
      return
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier-tender-bid/generator/reject',
        body: { supplierTenderBidId: id },
      })

      if (err || !response) throw err

      bidGetList.reset()
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('bids.handleDeclineBid.success.description'),
        },
        'success',
      )
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'bid',
        ERR_NOT_AUTHORIZED: ({ message }) => {
          if (
            message.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoadingBidAction(false)
    }
  }

  const handleAcceptBid = async (id: string) => {
    if (isLoadingBidAction || !bidGetList?.data) return
    setIsLoadingBidAction(true)

    const findBid = bidGetList.data.find((bid) => bid.id === id)
    if (!findBid) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('bids.handleAcceptBid.error.description'),
        },
        'error',
      )
      return
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/supplier-tender-bid/generator/accept',
        body: { supplierTenderBidId: id },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('bids.handleAcceptBid.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'bid',
        ERR_NOT_AUTHORIZED: ({ message }) => {
          if (message.includes('You cannot accept a Bid that has expired')) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.CANNOT_ACCEPT_EXPIRED_BID',
              ),
              type: 'error',
            }
          }

          if (
            message.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoadingBidAction(false)
    }
  }

  const bidButtons = useMemo((): BidsButtonList[] | undefined => {
    if (!bidGetList?.data || bidGetList.isLoading || bidGetList?.error)
      return undefined

    if (!tender) return undefined

    if (tender.status === 'ACTIVE') {
      return [
        {
          props: { variant: 'primary', loading: isLoadingBidAction },
          onClick: handleAcceptBid,
          text: t('bids.accept'),
        },
        {
          props: { variant: 'secondary', loading: isLoadingBidAction },
          onClick: handleDeclineBid,
          text: t('bids.decline'),
        },
      ]
    }

    return undefined
  }, [bidGetList, tender, isLoadingBidAction])

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} />
      <SupplierTableBids
        tender={tender}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
        buttons={bidButtons}
        enableSelectingBids
      />
    </section>
  )
}

export default ActiveSleevedTenderDetails
