import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPAGetList from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import Select, {
  SelectProps,
  SelectOptions,
} from '../../../../../../components/atoms/select'

import { capitalizeText } from '../../../../../../utils'
import { SiteCompany } from '../../../../../../types'

type SelectSiteProps = Omit<
  SelectProps,
  'type' | 'onCreateOption' | 'options'
> & {
  type?: 'multi' | 'single'
}

const defaultFilters = [
  { field: 'archivedAt', operator: 'equals', value: null },
]

const SelectSite: React.FC<SelectSiteProps> = ({
  value,
  type = 'multi',
  ...props
}) => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { isLoading, data, error } = usePPAGetList<SiteCompany>({
    dataKey: 'sites',
    path: '/core/private/site/list',
    pagination: {
      limit: 50,
      offset: 0,
    },
    filters: [...defaultFilters],
  })

  const siteList = useMemo((): SiteCompany[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, error, tUtils])

  const options = useMemo((): SelectOptions[] => {
    if (!siteList) return []

    let selectedLabel: string | undefined
    if (value && value.length > 0) selectedLabel = value[0].label

    return siteList.map((site) => {
      const technology = capitalizeText(
        tUtils(`technology.${site.technology}.abbreviation`),
      )
      let isDisabled = false
      if (selectedLabel && !selectedLabel.includes(technology)) {
        isDisabled = true
      }

      return {
        value: JSON.stringify(site),
        label: `${site.name} (${technology})`,
        isDisabled,
        rocBand: site.rocBand,
        /**
         * @TODO Review this custom element, because it overrides all styles.
         * Breaking animations (focus, hover etc), colors and so goes on.
         */
        // label: (
        //   <div className="flex items-end text-sm text-ppa/formText hover:text-white">
        //     <strong className="font-medium">{site.name}</strong>
        //     <span className="text-xs font-light ml-1">
        //       ({tUtils(`technology.${site.technology}.abbreviation`)})
        //     </span>
        //   </div>
        // ),
      }
    })
  }, [siteList, value])

  return (
    <Select
      {...props}
      value={value}
      options={options}
      type={type}
      testId="select-sites"
    />
  )
}

export default SelectSite
