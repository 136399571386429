import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { ConsumerBid } from '../../../../../types'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import TenderDetails from '../components/details'
import TableConsumerBids, {
  ButtonList,
} from '../../../../../components/organisms/consumer-bids-table'

import { TenderStatusProps } from '../details'

const ConsumerUnderOfferTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoadingTenderAction, setIsLoadingTenderAction] = useState(false)

  const {
    isLoading,
    throwFeedbackError: bidThrowFeedbackError,
    data,
    error,
  } = usePPAGetList<ConsumerBid>({
    dataKey: 'consumerTenderBids',
    path: `/core/private/consumer-tender-bid/admin/${tenderDetails.id}`,
  })

  const bidList = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const handleExecuteTender = async () => {
    if (isLoadingTenderAction) return
    setIsLoadingTenderAction(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/consumer-tender/admin/execute/${tenderDetails.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.execute.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsLoadingTenderAction(false)
    }
  }

  const handleRevertTender = async () => {
    if (isLoadingTenderAction) return
    setIsLoadingTenderAction(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/consumer-tender/admin/revert/${tenderDetails.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.revert.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsLoadingTenderAction(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    if (!tenderDetails) return undefined

    return [
      {
        props: { variant: 'secondary' },
        onClick: () => handleRevertTender(),
        text: t('buttons.revert'),
      },
      {
        props: { variant: 'primary' },
        onClick: () => handleExecuteTender(),
        text: t('buttons.execute'),
      },
    ]
  }, [tenderDetails])

  useEffect(() => {
    if (error) {
      bidThrowFeedbackError({ err: error })
    }
  }, [bidList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} />
      <TableConsumerBids
        bids={bidList}
        tenderStatus={tenderDetails.status}
        refetch={refetch}
        buttons={buttons}
        enableSelectingBids
      />
    </section>
  )
}

export default ConsumerUnderOfferTenderDetails
