import React, { useMemo } from 'react'
import { Routes as RoutesManager, Route, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'

import Onboarding from '../pages/private/onboarding'

import Loader from '../pages/public/loader'

import Private from '../pages/private'
import NotFound404 from '../pages/private/not-found'

import { privateRoutes } from '../pages/private/routes'

import { UserTypes } from '../types/user'
import { UserAuth0 } from '../types/auth0'
import useUser from '../hooks/use-user'

const company = process.env.REACT_APP_COMPANY || 'PPAYA'

const Routes: React.FC = () => {
  const { ready } = useTranslation()
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user: userA0,
  } = useAuth0<UserAuth0>()

  const { isReady, user } = useUser()

  if (!isLoading && !isAuthenticated) {
    if (company === 'PPAYA') {
      loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
      })
    } else if (company === 'CARTER_JONAS') {
      loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
      })
    }
  }

  const userType: UserTypes | undefined = useMemo(() => {
    return user?.appMetadata?.userType
  }, [user])

  const userUnderReview = useMemo(() => {
    if (!userA0 || !ready || !isAuthenticated || isLoading) return undefined

    if (!userA0.app_metadata?.userType || !userA0.app_metadata?.status)
      return true

    if (userA0.app_metadata.userType === 'ADMIN') return false
    if (
      ['GENERATOR', 'SUPPLIER', 'CONSUMER'].includes(
        userA0.app_metadata.userType,
      )
    ) {
      const { status } = userA0.app_metadata

      if (status === 'ACTIVE') return false
    }

    return true
  }, [isLoading, ready, isAuthenticated, user])

  return (
    <RoutesManager>
      {isLoading && !ready && <Route index element={<Loader />} />}

      {userUnderReview === true && (
        <>
          <Route path="/" element={<Onboarding />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}
      {userUnderReview === false && isReady && userType && (
        <>
          <Route path="/" element={<Private />}>
            {privateRoutes[userType].map((route) => (
              <Route key={(route as any).path} {...route} />
            ))}
          </Route>
          <Route path="*" element={<NotFound404 />} />
        </>
      )}
    </RoutesManager>
  )
}

export default Routes
