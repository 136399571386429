import classNames from 'classnames'
import React, { useMemo } from 'react'

import { ItemData, TableConfigProps } from '../table'

export interface RowKeys extends TableConfigProps {
  title: string
  smallTitle?: string
  additionalTitles?: { text: string; className?: string }[]
  bidTable?: boolean
}

interface DefListItemProps {
  rowKeys: RowKeys
  data: ItemData
}

const DefListItem: React.FC<DefListItemProps> = ({ rowKeys, data }) => {
  const ItemElement = useMemo((): JSX.Element => {
    const emptyResult = <>-</>
    const { keyName, renderCustomEl, suffix } = rowKeys

    if (renderCustomEl) {
      const customElResult = renderCustomEl(data)
      if (!customElResult) return emptyResult
      return (
        <>
          {customElResult}
          {suffix && <> {suffix}</>}
        </>
      )
    }

    return data[keyName] ? (
      <div className="flex items-center">
        {data[keyName]} {suffix || ''}
      </div>
    ) : (
      emptyResult
    )
  }, [rowKeys, data])

  return ItemElement
}

export interface DefListProps {
  isLoading?: boolean
  rowKeys: RowKeys[]
  data?: ItemData
  padding?: string
}

const DefList: React.FC<DefListProps> = ({
  rowKeys,
  data,
  isLoading,
  padding = 'py-4 px-2',
}) => {
  return (
    <dl className="bg-transparent text-sm text-ppa/secondary w-full">
      {rowKeys.map((item) => (
        <div
          key={item.title}
          className={classNames(
            'flex sm:items-center',
            'border-b border-gray-400 border-opacity-50',
            padding,
          )}
        >
          <dt
            className={classNames(
              'font-normal flex min-w-[130px] sm:min-w-[300px]',
              item.bidTable ? 'flex-col items-start' : 'items-center',
            )}
          >
            {item.title}
            {item?.smallTitle && (
              <span
                className={classNames('font-light', !item.bidTable && 'pl-1.5')}
              >
                {item.smallTitle}
              </span>
            )}
            {item.additionalTitles && (
              <div
                className={classNames(
                  'font-normal mt-2',
                  !item.bidTable && 'pl-1.5',
                )}
              >
                {item.additionalTitles.map((subtitle) => (
                  <span
                    key={`additionalTitle-${subtitle.text}`}
                    className={classNames('block', subtitle.className)}
                  >
                    {subtitle.text}
                  </span>
                ))}
              </div>
            )}
          </dt>
          <dd
            className={classNames(
              'w-full font-light flex items-center',
              item.bidTable && 'justify-end',
            )}
          >
            {(isLoading || !data) && '-'}
            {!isLoading && data && <DefListItem data={data} rowKeys={item} />}
          </dd>
        </div>
      ))}
    </dl>
  )
}

export default DefList
