import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'
import usePPA from '../../../../../hooks/use-ppa'

import { TenderStatusProps } from '../details'
import TenderDetails, { ButtonList } from '../components/details'
import BidsTable, { Bid } from '../../../../../components/organisms/bids-table'
import SetDeadline from '../components/tender-set-deadline'
import { feedbackMessage } from '../../../../../components/atoms/feedback'

const TendersActiveDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.tenders.details',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const bidGetList = usePPAGetList<Bid>({
    dataKey: 'bids',
    path: `/core/private/bid/by-admin/${tenderDetails.id}`,
  })

  const [isDeadlineDrawerOpen, setIsDeadlineDrawerOpen] = useState(false)

  const onSetDeadlineSuccess = () => {
    setIsDeadlineDrawerOpen(false)
    refetch()
  }

  const handleClearDeadline = async () => {
    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/tender/${tenderDetails.id}/update-deadline`,
        body: {
          deadline: null,
        },
      })

      if (err || !response?.data) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleClearDeadline.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    const buttonList: ButtonList[] = [
      {
        props: { variant: 'primary' },
        onClick: () => setIsDeadlineDrawerOpen(true),
        text: tenderDetails?.deadline
          ? t('buttons.updateDeadline')
          : t('buttons.setDeadline'),
      },
    ]

    if (tenderDetails?.deadline) {
      buttonList.push({
        props: { variant: 'secondary' },
        onClick: () => handleClearDeadline(),
        text: t('buttons.clearDeadline'),
      })
    }

    return buttonList
  }, [tenderDetails])

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} buttons={buttons} />
      <BidsTable
        tender={tenderDetails}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
      />
      <SetDeadline
        tender={tenderDetails}
        isDrawerOpen={isDeadlineDrawerOpen}
        onCloseDrawer={() => setIsDeadlineDrawerOpen(false)}
        onSuccess={onSetDeadlineSuccess}
      />
    </section>
  )
}

export default TendersActiveDetails
