import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import Message from '../../../../../../components/atoms/message'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { ConsumerTenderWithInvitation } from '../../../../../../types'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import SetDeadlineForm from './form-set-deadline'

interface SetDeadlineProps {
  tender: ConsumerTenderWithInvitation
  isDrawerOpen: boolean
  onSuccess: () => void
  onCloseDrawer: () => void
}

const SleevedTenderSetDeadline: React.FC<SetDeadlineProps> = ({
  tender,
  onCloseDrawer,
  isDrawerOpen,
  onSuccess,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.drawerDeadline',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleSetDeadline = async (formData: any) => {
    if (isLoading) return
    setIsLoading(true)

    const feedbackMessages = {
      success: t('create.onSubmit.success.description'),
      error: t('create.onSubmit.error.description'),
    }

    if (tender.deadline) {
      feedbackMessages.success = t('update.onSubmit.success.description')
      feedbackMessages.error = t('update.onSubmit.error.description')
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/consumer-tender/admin/update-deadline/${tender.id}`,
        body: { deadline: formData.dateTime },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: feedbackMessages.success,
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerTender',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={onCloseDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        <h1 className="text-xl font-bold text-ppa/title">
          {tender.deadline ? t('update.title') : t('create.title')}
        </h1>
        <Message variant="warn">{t('warnMessage')}</Message>
        {isDrawerOpen && (
          <SetDeadlineForm
            isLoading={isLoading}
            onSubmit={handleSetDeadline}
            onCancel={onCloseDrawer}
            deadline={tender.deadline}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default SleevedTenderSetDeadline
