import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { v4 } from 'uuid'

import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import { capitalizeText, parseContractDuration } from '../../../../../../utils'
import { dateFormats } from '../../../../../../utils/data'

import { container, breakPointsContainer } from '../../../../../../styles'
import { parseQuery } from '../../../../../../hooks/use-ppa/use-ppa-get-list'
import usePPA from '../../../../../../hooks/use-ppa'

import CollapseList from '../../../../../../components/molecules/collapse-list'
import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'
import Dropdown from '../../../../../../components/atoms/dropdown'

import TenderPill from '../../../../components/tender-pill'
import Message from '../../../../../../components/atoms/message'

import {
  TenderSiteWithInvitation,
  Site,
  TenderSubsidyName,
} from '../../../../../../types'

import { mappedTenderStatus } from '../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

export type Tender = TenderSiteWithInvitation & {
  bids?: any[]
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface TenderDetailsProps {
  tender: Tender
  buttons?: ButtonList[]
  actionsDropdown?: ButtonList[]
}
interface Files {
  id: string
  name: string
}

const TenderDetails: React.FC<TenderDetailsProps> = ({
  buttons,
  tender,
  actionsDropdown,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()
  const { fetchData } = usePPA()

  const rowKeysDetails = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('list.titles.contractDuration'),
        renderCustomEl: (item: Tender) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('list.titles.deadline'),
        renderCustomEl: (item: Tender) => {
          if (!item?.deadline) return undefined

          return dayjs
            .utc(item.deadline)
            .tz('Europe/London')
            .format(dateFormats.userDateTime)
        },
      },
      {
        keyName: 'participatingSuppliers',
        title: t('list.titles.participatingSuppliers'),
        renderCustomEl: (item: Tender) => {
          return item.tenderInvitations
            .map((invitation) => invitation.supplier.name)
            .join(', ')
        },
      },
      {
        keyName: 'subsidies',
        title: t('list.titles.subsidies'),
        renderCustomEl: (item: Tender) => {
          const toggledSubsidyNames: TenderSubsidyName[] = []
          item.subsidies.forEach(({ name, toggled }) => {
            if (toggled) toggledSubsidyNames.push(name)
          })
          return toggledSubsidyNames.join(', ')
        },
      },
      {
        keyName: 'otherRequirements',
        title: t('list.titles.otherRequirements'),
      },
      {
        keyName: 'targetPrice',
        title: t('list.titles.targetPrice'),
        renderCustomEl: (item: Tender) => {
          if (!item?.targetPrice) return undefined

          return (
            <span className="font-semibold">
              {`${item.targetPrice}`}&nbsp;£/MWh
            </span>
          )
        },
      },
    ]
  }, [tender])

  const rowKeysSiteDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('siteDetailsList.titles.name'),
        renderCustomEl: (site: Site) => {
          return site.name
        },
      },
      {
        keyName: 'technology',
        title: t('siteDetailsList.titles.technology'),
        renderCustomEl: (site: Site) => {
          const technology = site?.technology
          if (!technology) return undefined
          return capitalizeText(tUtils(`technology.${technology}.abbreviation`))
        },
      },
      {
        keyName: 'capacity',
        title: t('siteDetailsList.titles.totalCapacity'),
        suffix: 'MW',
        renderCustomEl: (site: Site) => {
          if (!site.capacity) return undefined
          return site.capacity
        },
      },
      {
        keyName: 'voltage',
        title: t('siteDetailsList.titles.voltage'),
        suffix: 'kV',
        renderCustomEl: (site: Site) => {
          if (!site.voltage) return undefined
          return site.voltage
        },
      },
      {
        keyName: 'mpan',
        title: t('siteDetailsList.titles.mpan'),
        renderCustomEl: (site: Site) => {
          if (!site.mpan) return undefined
          return site.mpan
        },
      },
      {
        keyName: 'msid',
        title: t('siteDetailsList.titles.msid'),
        renderCustomEl: (site: Site) => {
          if (!site.msid) return undefined
          return site.msid
        },
      },
      {
        keyName: 'rocBand',
        title: t('siteDetailsList.titles.rocBand'),
        renderCustomEl: (site: Site) => {
          if (!site.rocBand) return undefined
          return site.rocBand
        },
      },
      {
        keyName: 'company',
        title: t('siteDetailsList.titles.company.name'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.name
        },
      },
      {
        keyName: 'number',
        title: t('siteDetailsList.titles.company.number'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.number
        },
      },
      {
        keyName: 'location',
        title: t('siteDetailsList.titles.location'),
        renderCustomEl: (site: Site) => {
          if (!site.location) return undefined
          return site.location
        },
      },
    ]
  }, [tender?.site])

  const [files, setFiles] = useState<Files[] | undefined>()

  useEffect(() => {
    const fetchFiles = async (): Promise<void> => {
      if (!tender?.site?.id) return
      try {
        const { response, error: filesError } = await fetchData({
          method: 'GET',
          url: `/core/private/site/hhd/list/${tender.site.id}`,
          responseType: 'json',
        })

        if (!response?.data || filesError) throw filesError

        setFiles(
          response.data.hhdFiles.map((file: any) => ({
            id: v4(),
            name: file,
          })),
        )
      } catch (err) {
        console.error(err)
      }
    }

    if (tender?.site?.id) {
      fetchFiles()
    }
  }, [tender])

  const handleDownloadHHD = async (file: File) => {
    if (!tender) return
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/site/hhd?${parseQuery({
          fileName: file.name,
          siteId: tender.site.id,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(`/tenders/status/${mappedTenderStatus[tender.status]}`)
          }
        >
          {t('backButton')}
        </Button>
      </div>

      <div className="flex flex-col gap-y-6 w-full">
        <div className="flex items-center justify-between ">
          <div className="flex flex-col">
            <div className="flex items-center justify-start my-5">
              <h1 className="text-2xl font-semibold text-ppa/title mr-8 w-full">
                {tender.shortId}
              </h1>
              <TenderPill status={tender.status} />
            </div>
            {tender.status === 'ACTIVE' &&
              tender.finalAuctionDate &&
              dayjs(tender.finalAuctionDate).diff(dayjs(), 'day') < 15 && (
                <Message variant="warn" size="text-xs">
                  <div className="flex flex-col">
                    {t('warnMessageAuctionDateLessThan.label')}
                    <br />
                    {t('warnMessageAuctionDateLessThan.info')}
                  </div>
                </Message>
              )}
            {tender.status === 'ACTIVE' &&
              tender.startDate &&
              dayjs().isAfter(dayjs(tender.startDate)) && (
                <Message variant="warn" size="text-xs">
                  <div className="flex flex-col">
                    {t('warnMessageTenderInThePast.label')}
                    <br />
                    {t('warnMessageTenderInThePast.info')}
                  </div>
                </Message>
              )}
            {!tender?.targetPrice && (
              <div className="flex flex-col w-full">
                <Message variant="warn" size="text-xs">
                  {t('targetPriceNotFound.0')}
                  <br />
                  {t('targetPriceNotFound.1')}
                </Message>
              </div>
            )}
          </div>
          {actionsDropdown && (
            <Dropdown
              content={
                <div className="flex flex-col">
                  {actionsDropdown &&
                    actionsDropdown.map((item) => (
                      <Button
                        {...item.props}
                        key={item.text}
                        onClick={() => item.onClick()}
                      >
                        {item.text}
                      </Button>
                    ))}
                </div>
              }
            />
          )}
        </div>

        <div className={classNames('flex flex-col', '2xl:flex-row')}>
          <div className="flex flex-col gap-y-3 max-w-[550px]">
            <h3 className="font-semibold text-lg text-ppa/title">
              {t('list.title')}
            </h3>

            <DefList rowKeys={rowKeysDetails} data={tender} />

            {buttons && (
              <div className="flex items-center justify-start gap-x-5 w-full mt-5">
                {buttons.map((item) => (
                  <div className="w-full flex max-w-[165px]" key={item.text}>
                    <Button {...item.props} onClick={() => item.onClick()}>
                      {item.text}
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={classNames(
              'flex flex-col flex-1 gap-y-4',
              'border-ppa/grayBorder',
              'border-t mt-10 pt-10',
              '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            )}
          >
            <div className="flex flex-col gap-y-2">
              <h2 className="font-semibold text-lg text-ppa/title">
                {t('siteDetailsTitle')}
              </h2>
              <DefList rowKeys={rowKeysSiteDetails} data={tender.site} />
            </div>
            <CollapseList
              title={t('hhd.collapseList.title')}
              titleEmptyList={t('hhd.collapseList.filesNotFound')}
              list={files as any}
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadHHD(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TenderDetails
