/* eslint-disable camelcase */
import React from 'react'
import axios from 'axios'

import SelectAsync, {
  SelectAsyncProps,
  RequestOptionsFn,
} from '../../atoms/select-async'

/**
 * Converts House API address (or just by sending one address line) to the PPAYA
 * object address that should be sent in the mutation `SPV_ADD_ADDRESS`
 * @param address The address to be converted
 */
export const toPPAYAAddress = ({
  line1,
  address_line_1,
  address_line_2,
  country,
  locality,
  postal_code,
  region,
}: any) => {
  return {
    addressType: 'REGISTERED',
    line1: line1 || address_line_1,
    line2: address_line_2,
    postalCode: postal_code,
    locality,
    country,
    region,
  }
}

/**
 * Receives address data from House API or PPAYA and format it to one line address text.
 * @param address
 * @returns
 * `Street number Street name, Locality, Postal code`
 */
export const formatAddress = (spv: any) => {
  return [
    spv?.address_line_1,
    spv?.address_line_2,
    spv?.line1,
    spv?.line2,
    spv?.locality,
    spv?.postal_code,
    spv?.postalCode,
  ]
    .filter((item) => item)
    .join(', ')
}

/**
 * Receives SPV data from House API or PPAYA and format it to a `Option Type` from
 * `Select` component.
 * @param spv
 * @returns
 * ```
 * {
 *    value: JSON.stringify(spvDataObject),
 *    label: <Element />
 * }
 * ```
 */

export const parseSPVtoSelectOption = (spv: any) => {
  const name = spv?.company_name || spv?.name
  const type = spv?.company_type || spv?.companyType
  const spvNumber = spv?.company_number || spv?.companyNumber

  const spvAddress = spv?.registered_office_address || spv?.address
  const address = formatAddress(spvAddress)

  const labelSpvNumber = spvNumber ? ` (${spvNumber})` : ''
  const label = (
    <div className="leading-tight text-sm">
      <strong className="font-medium">
        {name}
        {labelSpvNumber}
      </strong>
      <span className="text-xs block truncate">{address}</span>
    </div>
  )

  const value = JSON.stringify({
    name,
    companyNumber: spvNumber,
    companyType: type,
    address,
    completeAddress: spv?.registered_office_address,
  })
  return { value, label }
}

const searchCompaniesHouse: RequestOptionsFn = async (inputSearch) => {
  try {
    /**
     * @SEE This is a serverless function that's run by vercel.
     * To check it in the browser go to `https://${appUrl}/api/search-company?q=${companySearch}`
     * @SEE API reference https://developer-specs.company-information.service.gov.uk/companies-house-public-data-api/reference/search/advanced-company-search
     */
    const res = await axios.get(
      `/api/search-company/?q=${encodeURIComponent(inputSearch)}`,
    )
    return res.data.map((company: any) => {
      return parseSPVtoSelectOption(company)
    })
  } catch (error) {
    /**
     * @SILENCED_ERROR Force the function that called this to handle exceptions.
     */
    return undefined
  }
}

type SelectCompaniesHouseProps = Omit<
  SelectAsyncProps,
  'requestOptions' | 'onCreateOption'
>

const SelectCompaniesHouse: React.FC<SelectCompaniesHouseProps> = ({
  ...selectProps
}) => {
  return <SelectAsync requestOptions={searchCompaniesHouse} {...selectProps} />
}

export default SelectCompaniesHouse
