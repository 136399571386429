import { Navigate, Route } from 'react-router-dom'

import { isFeatureEnabled } from '../../../utils/features'

import { ReactComponent as TenderSVG } from '../assets/Tender.svg'
import { ReactComponent as SleevedTenderSVG } from '../assets/SleevedTender.svg'
import { ReactComponent as ConsumerTenderSVG } from '../assets/ConsumerTender.svg'
import { ReactComponent as DocumentSVG } from '../assets/Document.svg'
import { ReactComponent as MarketSVG } from '../assets/Market.svg'

import Tenders from './export-tenders'
import TenderDetails from './export-tenders/details'
import { tendersLinks, tendersSubRoutes } from './export-tenders/routes'

import SleevedTenders from './sleeved-tenders'
import SleevedTenderDetails from './sleeved-tenders/details'
import {
  sleevedTendersLinks,
  sleevedTendersSubRoutes,
} from './sleeved-tenders/routes'

import ConsumerTenders from './consumer-tenders'
import ConsumerTenderDetails from './consumer-tenders/details'
import {
  consumerTendersLinks,
  consumerTendersSubRoutes,
} from './consumer-tenders/routes'

import Documents from './documents'
import Market from '../market'
import { LinksArray } from '../routes'
import NotFound from '../not-found'
import Notifications from '../notifications'
import Settings from './settings'

export const supplierLinks: LinksArray = [
  {
    to: '/tenders',
    label: 'export',
    icon: TenderSVG,
    subMenu: tendersLinks,
  },
  {
    to: '/sleevedTenders',
    label: 'sleeved',
    icon: SleevedTenderSVG,
    subMenu: sleevedTendersLinks,
  },
  {
    to: '/consumerTenders',
    label: 'import',
    icon: ConsumerTenderSVG,
    subMenu: consumerTendersLinks,
  },
  { to: '/market', label: 'market', icon: MarketSVG },
]

if (isFeatureEnabled('documentsPage')) {
  supplierLinks.push({
    to: '/documents',
    label: 'documents',
    icon: DocumentSVG,
  })
}

export const supplierRoutes = [
  { path: '/', element: <Navigate to="/tenders/status/accepted" /> },
  {
    path: '/tenders',
    children: (
      <>
        <Route index element={<Navigate to="/tenders/status/accepted" />} />
        {Object.keys(tendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/tenders/status/${key}`}
              element={<Tenders status={key} />}
            />
            <Route path="/tenders/:tenderId" element={<TenderDetails />} />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/sleevedTenders',
    children: (
      <>
        <Route
          index
          element={<Navigate to="/sleevedTenders/status/accepted" />}
        />
        {Object.keys(sleevedTendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/sleevedTenders/status/${key}`}
              element={<SleevedTenders status={key} />}
            />
            <Route
              path="/sleevedTenders/:tenderId"
              element={<SleevedTenderDetails />}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/consumerTenders',
    children: (
      <>
        <Route
          index
          element={<Navigate to="/consumerTenders/status/accepted" />}
        />
        {Object.keys(consumerTendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/consumerTenders/status/${key}`}
              element={<ConsumerTenders status={key} />}
            />
            <Route
              path="/consumerTenders/:tenderId"
              element={<ConsumerTenderDetails />}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  { path: '/documents', element: <Documents /> },
  { path: '/market', element: <Market /> },
  { path: '/settings', element: <Settings /> },
]

if (isFeatureEnabled('notificationsPage')) {
  supplierRoutes.push({
    path: '/notifications',
    element: <Notifications />,
  })
}
