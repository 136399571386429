import React from 'react'
import classnames from 'classnames'
import toast, { Toast } from 'react-hot-toast'
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  XIcon,
} from '@heroicons/react/outline'
import { transition } from '../../../styles'

const statuses = {
  success: {
    icon: <CheckCircleIcon className="stroke-ppa/greenSuccess" />,
    borderColor: 'border-ppa/greenSuccess',
  },
  error: {
    icon: <XCircleIcon className="stroke-ppa/redError" />,
    borderColor: 'border-ppa/redError',
  },
  info: {
    icon: <ExclamationCircleIcon className="stroke-ppa/blueFocus rotate-180" />,
    borderColor: 'border-ppa/blueFocus',
  },
  warn: {
    icon: <ExclamationCircleIcon className="stroke-ppa/yellowWarning" />,
    borderColor: 'border-ppa/yellowWarning',
  },
}

export type ToastType = 'success' | 'error' | 'info' | 'warn'

export type Message = {
  title: string
  description?: string
}

const getToastCommonProps = (status: ToastType) => {
  return {
    position: 'bottom-right',
    className: 'w-full max-w-[375px] rounded-md',
    icon: (
      <div
        className={classnames(
          'ml-1 h-full flex items-center',
          'border-l-2 pl-3',
          statuses[status].borderColor,
        )}
      >
        {React.cloneElement(statuses[status].icon, {
          width: '32px',
          height: '32px',
        })}
      </div>
    ),
  }
}

export interface FeedbackProps {
  title: string
  message?: string
  handleClose: () => void
}

const Feedback: React.FC<FeedbackProps> = ({ title, message, handleClose }) => {
  return (
    <div
      className={classnames(
        transition,
        'flex w-full relative pointer-events-auto animate-popAnimation',
      )}
    >
      <div className="flex flex-col w-full gap-y-1">
        <span className="text-xs font-medium text-ppa/title break-words mr-6">
          {title}
        </span>
        <p className="text-xs font-light text-ppa/subtitle break-words">
          {message}
        </p>
      </div>
      <button
        type="button"
        onClick={handleClose}
        className="absolute right-0 top-0"
      >
        <XIcon className="stroke-ppa/grayBorder w-4 h-4" />
      </button>
    </div>
  )
}

type FeedbackMessageFn = (
  message: Message,
  status: ToastType,
  duration?: number,
) => void

export const feedbackMessage: FeedbackMessageFn = (
  { title, description },
  status,
  duration = 4000,
) => {
  toast(
    (t) => (
      <Feedback
        title={title}
        message={description}
        handleClose={() => toast.remove(t.id)}
      />
    ),
    {
      ...(getToastCommonProps(status) as Toast),
      duration,
    },
  )
}
