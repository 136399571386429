import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { feedbackMessage } from '../../../../../components/atoms/feedback'
import BidsTable, {
  Bid,
  ButtonList as BidsButtonList,
} from '../../../../../components/organisms/bids-table'

import TenderDetails from '../components/details'

import { SleevedTenderDetailsProps } from '../details'

const WithdrawnSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tender,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.sleevedTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  // const bidGetList = usePPAGetList<Bid>({
  //   dataKey: 'bids',
  //   path: `/core/private/bid/by-consumer/${tenderDetails.id}`,
  // })

  // useEffect(() => {
  //   if (bidGetList?.error) {
  //     bidGetList.throwFeedbackError({ err: bidGetList?.error })
  //   }
  // }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tender} />
      {/* <BidsTable
        tender={tenderDetails}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
        buttons={bidButtons}
        enableSelectingBids
      /> */}
    </section>
  )
}

export default WithdrawnSleevedTenderDetails
