import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import {
  TrashIcon,
  DownloadIcon,
  ChevronLeftIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import { ReactComponent as BrokerFeeIcon } from './assets/brokerFeeIcon.svg'
import { ReactComponent as ActAsIcon } from './assets/actAsIcon.svg'

import { parseSnippetAddress } from '../../../../utils'

import { ConsumerDetails } from '../consumer/details'

import { feedbackMessage } from '../../../../components/atoms/feedback'
import DefList from '../../../../components/atoms/def-list'
import Button from '../../../../components/atoms/button'

import useUser from '../../../../hooks/use-user'
import usePPA from '../../../../hooks/use-ppa'
import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import usePPAGetList, {
  parseQuery,
} from '../../../../hooks/use-ppa/use-ppa-get-list'

import UpdateBrokerFeeDrawer from './components/broker-fee-drawer'
import CollapseList from '../../../../components/molecules/collapse-list'

import { Meter, MeterCompany } from '../../../../types'

import { container, breakPointsContainer, transition } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'

const MeterDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.meters.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })
  const { actAs } = useUser<'ADMIN'>()
  const navigate = useNavigate()

  const { meterId } = useParams<{ meterId: string }>()

  const { fetchData } = usePPA()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [consumerDetails, setConsumerDetails] = useState<
    ConsumerDetails | undefined
  >()
  const [isLoadingConsumerDetails, setIsLoadingConsumerDetails] =
    useState<boolean>(false)

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<MeterCompany>({
      dataKey: 'meter',
      path: `/core/private/consumer-meter/admin/details/${meterId}`,
    })

  const meterDetails: MeterCompany | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    const fetchConsumerDetails = async () => {
      try {
        const { response, error: consumerDetailsError } = await fetchData({
          method: 'GET',
          url: `/core/private/consumer/admin/details/${meterDetails?.consumerId}`,
        })

        if (consumerDetailsError) {
          throw consumerDetailsError
        }

        setConsumerDetails(response?.data.consumer)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoadingConsumerDetails(false)
      }
    }

    if (
      !meterDetails ||
      meterDetails.consumerId === undefined ||
      isLoadingConsumerDetails
    ) {
      return
    }

    setIsLoadingConsumerDetails(true)
    fetchConsumerDetails()
  }, [meterDetails])

  const handleActAs = () => {
    if (consumerDetails === undefined) return
    if (consumerDetails.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: 'You cannot do this action.',
          description:
            'Users that are not active cannot being used in "acting as".',
        },
        'warn',
      )
      return
    }

    const { id, name, members } = consumerDetails

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'CONSUMER',
        status: consumerDetails.status,
        userId: consumerDetails.userId,
        brokerContextName: consumerDetails.brokerContextName,
      },
    })

    navigate('/meters')
  }

  const {
    isLoading: isLoadingFiles,
    throwFeedbackError: throwFeedbackErrorFiles,
    data: filesData,
    error: filesError,
    reset,
  } = usePPAGetList<File[]>({
    dataKey: 'hhdFiles',
    path: `/core/private/consumer-meter/admin/hhd/list/${meterId}`,
  })

  const files = useMemo((): File[] | undefined => {
    if (isLoadingFiles || filesError || !filesData) return undefined

    return filesData.map((file: any) => ({
      ...file,
      name: file,
    }))
  }, [filesData])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'mpan', title: 'MPAN' },
      { keyName: 'hhd', title: 'HHD timeframe' },
      {
        keyName: 'annualConsumption',
        title: 'Average annual consumption',
        suffix: 'MWh',
      },
      {
        keyName: 'gridConnectionSize',
        title: 'Grid connection size',
        suffix: 'kVA',
      },
      {
        keyName: 'profileClass',
        title: 'Profile class',
        renderCustomEl: (item: Meter) => item.profileClass,
      },
      {
        keyName: 'name',
        title: 'Company name',
        renderCustomEl: (item: Meter) => item.company?.name,
      },
      {
        keyName: 'number',
        title: 'Company number',
        renderCustomEl: (item: Meter) => item.company?.number,
      },
      {
        keyName: 'address',
        title: 'Company address',
        renderCustomEl: (item: Meter) =>
          parseSnippetAddress(item.company?.address),
      },
      {
        keyName: 'existingSupplier',
        title: 'Existing supplier',
        renderCustomEl: (item: Meter) => item.existingSupplier,
      },
      {
        keyName: 'brokerFee',
        title: 'Broker fee',
        suffix: '£/MWh',
        renderCustomEl: (item: Meter) => item.brokerFee,
      },
    ]
  }, [meterDetails])

  const handleDeleteHHD = async (file: File) => {
    if (isLoadingFiles) return

    try {
      const { error: hhdDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-meter/admin/hhd?${parseQuery({
          fileName: file.name,
          meterId,
        })}`,
      })

      if (hhdDeleteError) throw hhdDeleteError

      feedbackMessage(
        {
          title: 'hhd file deleted successfully',
        },
        'success',
      )

      reset()
    } catch (err: any) {
      throwFeedbackError({ err })
    }
  }

  const [isDeletingFiles, setIsDeletingFiles] = useState(false)

  const handleDownloadHHD = async (file: File) => {
    if (isDeletingFiles) return
    setIsDeletingFiles(true)
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/admin/hhd?${parseQuery({
          fileName: file.name,
          meterId,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDeletingFiles(false)
    }
  }

  const rowKeysConsumer = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: 'Name',
        renderCustomEl: (item: any) => {
          return item?.name
        },
      },
    ]
  }, [])

  useEffect(() => {
    if (!meterId) navigate('/meters')
  }, [meterId])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  useEffect(() => {
    if (filesError) throwFeedbackErrorFiles({ err: filesError })
  }, [filesError])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/meters')}
        >
          {t('backButton')}
        </Button>
      </div>
      {isLoading && (
        <div className={classNames(transition, 'w-full fade-in')}>
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && error && (
        <div className={classNames(transition, 'flex flex-col fade-in')}>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}
      {!isLoading && !error && meterDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
              {meterDetails.name}
            </h1>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-y-8">
            <div className="flex flex-col justify-start items-start w-full gap-y-2">
              <div className="flex justify-center items-center gap-x-8 max-w-[500px]">
                <h2 className="text-base font-medium text-ppa/title">
                  {t('consumerListTitle')}
                </h2>
                <Button
                  variant="primary"
                  icon={<ActAsIcon />}
                  onClick={handleActAs}
                >
                  {t('actAsButton')}
                </Button>
              </div>

              <div>
                <DefList rowKeys={rowKeysConsumer} data={consumerDetails} />
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-start items-start w-full gap-y-8 mt-8">
            <div className="flex flex-col justify-start items-start w-full gap-y-2">
              <div className="flex justify-center items-center gap-x-7 max-w-[500px]">
                <h2 className="text-base font-medium text-ppa/title">
                  {t('siteListTitle')}
                </h2>
                <Button
                  variant="primary"
                  icon={<BrokerFeeIcon />}
                  onClick={() => setIsEditDrawerOpen(true)}
                >
                  {meterDetails.brokerFee
                    ? t('actionsDropdown.updateBrokerFee')
                    : t('actionsDropdown.setBrokerFee')}
                </Button>
              </div>

              <div>
                <DefList rowKeys={rowKeys} data={meterDetails} />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('hhd.title')}
              </h1>
            </div>

            <CollapseList
              title={t('hhd.collapseList.title')}
              titleEmptyList={t('hhd.collapseList.filesNotFound')}
              list={files as any}
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadHHD(file)}
                        />
                        <TrashIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDeleteHHD(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
          <UpdateBrokerFeeDrawer
            meterId={meterDetails.id}
            brokerFee={meterDetails.brokerFee}
            isOpen={isEditDrawerOpen}
            onSuccess={refetch}
            handleClose={() => setIsEditDrawerOpen(false)}
          />
        </>
      )}
    </section>
  )
}

export default MeterDetails
