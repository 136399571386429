import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import useUser from '../../../hooks/use-user'
import usePPAGetList from '../../../hooks/use-ppa/use-ppa-get-list'

import { NotificationMessage } from '../../../types'

import Navbar from '../../../components/atoms/navbar'

import NotificationsList from './notifications-list'

const Notifications: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'notifications',
  })

  const { user } = useUser()
  const { userId } = user.appMetadata

  const [selectedContext, setSelectedContext] = useState<string | null>(null)

  const { data, isLoading, error, loadMore, reset, throwFeedbackError } =
    usePPAGetList<NotificationMessage>({
      dataKey: 'notifications',
      path: `/notifications/private/notifications/list/${userId}`,
      pagination: { offset: 0, limit: 25 },
    })

  const handleTabClick = (context: string | null = null) => {
    setSelectedContext(context)
  }

  const tabs = [
    {
      label: 'All',
      onClick: () => handleTabClick(),
    },
    {
      label: 'Tenders',
      onClick: () => handleTabClick('TENDER'),
    },
    {
      label: 'Bids',
      onClick: () => handleTabClick('BID'),
    },
  ]

  const filteredNotifications = useMemo(():
    | NotificationMessage[]
    | undefined => {
    if (data) {
      return selectedContext
        ? data.filter(
            (notification) => notification.context === selectedContext,
          )
        : data
    }
    return undefined
  }, [data, selectedContext, isLoading, reset, loadMore])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return (
    <div className="flex flex-col gap-y-5 min-w-fit max-w-[1100px]">
      <h1 className="text-3xl text-ppa/secondary font-semibold mt-8 mb-3">
        {t('title')}
      </h1>
      <Navbar tabs={tabs} />
      <NotificationsList
        notifications={filteredNotifications}
        isLoading={isLoading}
        refetch={reset}
        loadMore={loadMore}
      />
    </div>
  )
}

export default Notifications
