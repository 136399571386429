import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { ChevronLeftIcon } from '@heroicons/react/outline'

import { capitalizeText } from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import Button from '../../../../../../components/atoms/button'

import TenderPill from '../../../../components/tender-pill'

import { mappedTenderStatus } from '../../../types'

import { TenderStatus, SupplierTender } from '../../../../../../types'

import SleevedTenderDetailsDefList from '../details-tender'
import SiteDetailsDefList from '../details-site'
import SleevedConsumerTenderDetailsDefList from '../details-consumer-tender'

interface TenderDetailsSleevedProps {
  tender: SupplierTender
}

const SleevedTenderDetails: React.FC<TenderDetailsSleevedProps> = ({
  tender,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details',
  })

  const navigate = useNavigate()

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/sleevedTenders/status/${
                mappedTenderStatus[tender.status as TenderStatus]
              }`,
            )
          }
        >
          {t('backButton', {
            status: capitalizeText(
              tender.status.replace('_', ' ') as TenderStatus,
            ),
          })}
        </Button>
      </div>

      <div className="flex flex-col gap-y-8 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start">
            <h1 className="text-3xl font-semibold text-ppa/title mr-5 w-full">
              {tender.shortId}
            </h1>
            <TenderPill status={tender.status as any} />
          </div>
        </div>

        <div className="flex flex-col 2xl:flex-row">
          <div className="2xl:w-1/2">
            <SleevedTenderDetailsDefList data={tender} />
            <SiteDetailsDefList data={tender.site} />
          </div>

          <div
            className={classNames(
              '2xl:w-1/2',
              'border-ppa/grayBorder',
              'border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            )}
          >
            <SleevedConsumerTenderDetailsDefList
              data={tender.consumerTender as any}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SleevedTenderDetails
