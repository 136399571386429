import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import classNames from 'classnames'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/outline'

import useUser from '../../../../../../hooks/use-user'
import { parseQuery } from '../../../../../../hooks/use-ppa/use-ppa-get-list'
import usePPA from '../../../../../../hooks/use-ppa'

import { container, breakPointsContainer } from '../../../../../../styles'

import { ReactComponent as ActAsIcon } from '../../assets/actAsIcon.svg'

import CollapseList from '../../../../../../components/molecules/collapse-list'
import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'

import { parseContractDuration, capitalizeText } from '../../../../../../utils'
import { dateFormats } from '../../../../../../utils/data'

import {
  TenderSiteWithInvitation,
  Site,
  Generator,
  Member,
} from '../../../../../../types'

import TenderPill from '../../../../components/tender-pill'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { mappedTenderStatus } from '../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

export type Tender = TenderSiteWithInvitation & {
  generator: Generator & { members: Member[] }
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface Files {
  id: string
  name: string
}

interface TenderDetailsProps {
  buttons?: ButtonList[]
  tender: Tender
}

const TenderDetails: React.FC<TenderDetailsProps> = ({ buttons, tender }) => {
  const navigate = useNavigate()

  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.tenders.details',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { actAs } = useUser()
  const { fetchData } = usePPA()

  const handleActAs = () => {
    if (tender?.generator === undefined) return
    if (tender?.generator.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: t('handleActAs.warn.title'),
          description: t('handleActAs.warn.description'),
        },
        'warn',
      )
      return
    }

    const { id, name, members } = tender.generator

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    /**
     * @FIXME
     * @TODO
     * This will not currently work with the request, the BE need to include the member.
     */
    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'GENERATOR',
        status: tender.generator.status,
        userId: tender.generator.userId,
        brokerContextName: tender.generator.brokerContextName,
      },
    })

    navigate('/sites')
  }

  const rowKeysGeneratorDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('defList.titles.generator'),
        renderCustomEl: (item: Generator) => {
          return item.name
        },
      },
    ]
  }, [tender])

  const rowKeysTenderDetails = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('defList.titles.contractDuration'),
        renderCustomEl: (item: Tender) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'indicativeDeadline',
        title: t('defList.titles.indicativeDeadline'),
        renderCustomEl: (item: Tender) => {
          if (!item?.deadline) return undefined

          return dayjs
            .utc(item.deadline)
            .tz('Europe/London')
            .format(dateFormats.userDateTime)
        },
      },
      {
        keyName: 'participatingSuppliers',
        title: t('defList.titles.participatingSuppliers'),
        renderCustomEl: (item: Tender) => {
          return item.tenderInvitations
            .map((invitation) => invitation.supplier.name)
            .join(', ')
        },
      },
      {
        keyName: 'otherRequirements',
        title: t('defList.titles.otherRequirements'),
        renderCustomEl: (item: Tender) => {
          return item.otherRequirements
        },
      },
      {
        keyName: 'targetPrice',
        title: t('defList.titles.targetPrice'),
        renderCustomEl: (item: Tender) => {
          if (!item?.targetPrice) return undefined

          return (
            <span className="font-semibold">
              {`${item.targetPrice}`}&nbsp;£/MWh
            </span>
          )
        },
      },
      {
        keyName: 'quote',
        title: t('defList.titles.quote'),
        renderCustomEl: (item: Tender) => {
          if (!item?.quote?.pricing) return '-'

          return (
            <span className="font-semibold">
              {`${item.quote.pricing.averaged.summary.finalPrice}`}&nbsp;£/MWh
            </span>
          )
        },
      },
    ]
  }, [tender])

  const rowKeysSiteDetails = useMemo(() => {
    return [
      {
        keyName: 'technology',
        title: t('defList.titles.technology'),
        renderCustomEl: (site: Site) => {
          const { technology } = site
          if (!technology) return undefined
          return capitalizeText(tUtils(`technology.${technology}.abbreviation`))
        },
      },
      {
        keyName: 'capacity',
        title: t('defList.titles.totalCapacity'),
        suffix: 'MW',
        renderCustomEl: (site: Site) => {
          if (!site.capacity) return undefined
          return site.capacity
        },
      },
      {
        keyName: 'voltage',
        title: t('defList.titles.voltage'),
        suffix: 'kV',
        renderCustomEl: (site: Site) => {
          if (!site.voltage) return undefined
          return site.voltage
        },
      },
      {
        keyName: 'mpan',
        title: t('defList.titles.mpan'),
        renderCustomEl: (site: Site) => {
          if (!site.mpan) return undefined
          return site.mpan
        },
      },
      {
        keyName: 'msid',
        title: t('defList.titles.msid'),
        renderCustomEl: (site: Site) => {
          if (!site.msid) return undefined
          return site.msid
        },
      },
      {
        keyName: 'rocBand',
        title: t('defList.titles.rocBand'),
        renderCustomEl: (site: Site) => {
          if (!site.rocBand) return undefined
          return site.rocBand
        },
      },
      {
        keyName: 'company',
        title: t('defList.titles.company.name'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.name
        },
      },
      {
        keyName: 'number',
        title: t('defList.titles.company.number'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.number
        },
      },
      {
        keyName: 'location',
        title: t('defList.titles.location'),
        renderCustomEl: (site: Site) => {
          if (!site.location) return undefined
          return site.location
        },
      },
      /*
      {
        keyName: 'hhd',
        title: t('titles.hhd'),
      },
      */
      {
        keyName: 'brokerFee',
        suffix: '£/MWh',
        title: t('defList.titles.brokerFee'),
        renderCustomEl: (site: Site) => {
          if (!site.brokerFee) return undefined
          return site.brokerFee
        },
      },
    ]
  }, [tender?.site])

  const [files, setFiles] = useState<Files[] | undefined>()

  useEffect(() => {
    const fetchFiles = async (): Promise<void> => {
      if (!tender?.site?.id) return
      try {
        const { response, error: filesError } = await fetchData({
          method: 'GET',
          url: `/core/private/site/by-admin/hhd/list/${tender.site.id}`,
          responseType: 'json',
        })

        if (!response?.data || filesError) throw filesError

        setFiles(
          response.data.hhdFiles.map((file: any) => ({
            id: v4(),
            name: file,
          })),
        )
      } catch (err) {
        console.error(err)
      }
    }

    if (tender?.site?.id) {
      fetchFiles()
    }
  }, [tender])

  const handleDownloadHHD = async (file: File) => {
    if (!tender?.site?.id) return
    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/site/by-admin/hhd?${parseQuery({
          fileName: file.name,
          siteId: tender.site.id,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(`/tenders/status/${mappedTenderStatus[tender.status]}`)
          }
        >
          {t('backButton', {
            status: capitalizeText(tUtils(`status.tender.${tender.status}`)),
          })}
        </Button>
      </div>

      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-start my-8">
          <h1 className="text-2xl sm:text-3xl font-semibold text-ppa/title mr-8 w-full">
            {tender.shortId}
          </h1>
          <TenderPill status={tender.status} />
        </div>
      </div>
      <div
        className={classNames(
          'flex flex-col 2xl:flex-row w-full',
          'gap-y-8 my-3',
        )}
      >
        <div className="flex flex-col flex-1 gap-y-8">
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-start items-center gap-x-8">
              <h3 className="text-lg font-semibold text-ppa/title">
                {t('generatorLabel')}
              </h3>
              <Button
                variant="primary"
                icon={<ActAsIcon />}
                onClick={handleActAs}
              >
                {t('actAsButton')}
              </Button>
            </div>

            <DefList
              rowKeys={rowKeysGeneratorDetails}
              data={tender.generator}
            />
          </div>

          <div className="flex flex-col gap-x-3">
            <h3 className="text-lg font-semibold text-ppa/title">
              {t('tenderDetailsListTitle')}
            </h3>
            <DefList rowKeys={rowKeysTenderDetails} data={tender} />
          </div>
        </div>

        <div
          className={classNames(
            'flex flex-col flex-1 gap-y-4',
            'border-ppa/grayBorder',
            'border-t mt-10 pt-10',
            '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          )}
        >
          <h2 className="text-lg font-semibold text-ppa/title">
            {t('siteDetailsListTitle')}
          </h2>
          <DefList rowKeys={rowKeysSiteDetails} data={tender.site} />
          <CollapseList
            title={t('hhd.collapseList.title')}
            titleEmptyList={t('hhd.collapseList.filesNotFound')}
            list={files}
            defaultOpen
            rowKeys={[
              { keyName: 'name' },
              {
                keyName: 'actions',
                containerClass: 'ml-auto pl-2 max-w-[50px]',
                renderCustomEl: (file: any) => {
                  return (
                    <div className="flex items-center gap-x-2">
                      <DownloadIcon
                        className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                        onClick={() => handleDownloadHHD(file)}
                      />
                    </div>
                  )
                },
              },
            ]}
          />
        </div>
      </div>

      {buttons && (
        <div className="flex items-center justify-start gap-x-5 w-full mt-5">
          {buttons.map((item) => (
            <Button
              {...item.props}
              key={item.text}
              onClick={() => item.onClick()}
            >
              {item.text}
            </Button>
          ))}
        </div>
      )}
    </section>
  )
}

export default TenderDetails
