import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { v4 } from 'uuid'

import { priceTypeList } from '../../../../../../../utils/data'
import {
  toSeasons,
  isSummerStart,
  isSummerEnd,
  isWinterStart,
  isWinterEnd,
} from '../../../../../../../utils'

import {
  PowerPriceName,
  PowerPriceNameWithMultiple,
} from '../../../../../../../types'

import { SelectOptions } from '../../../../../../../components/atoms/select'

dayjs.extend(isBetween)

/**
 * @FIXME
import { DayPeriod, ExpireAtFormFilled } from '../../../../../../../types'
 */
type DayPeriod = any
type ExpireAtFormFilled = any

const seasonsDate = {
  SUMMER: {
    start: '-04-01',
    end: '-09-30',
  },
  WINTER: {
    start: '-10-01',
    end: '-03-31',
  },
}

export const hourOptions: SelectOptions[] = Array.from(
  { length: 12 },
  (_, index) => {
    return { value: (index + 1).toString(), label: (index + 1).toString() }
  },
)

/**
 * @DO_NOT_CHANGE_IT This function has 61 of length because of the first index 0
 * creating a list of 0 to 60 minutes as options
 * Do not change it!
 */
export const minuteOptions: SelectOptions[] = Array.from(
  { length: 61 },
  (_, index) => {
    return { value: index.toString(), label: index.toString() }
  },
)

export interface Periods {
  startDate: string
  endDate: string
  powerPriceTypeName: SelectOptions<PowerPriceName> | string
  title?: string
}

type GenerateSeasonTitlesFn = (
  period: Omit<Periods, 'powerPriceTypeName' | 'title'>,
) => string

export const generateSeasonTitles: GenerateSeasonTitlesFn = (period) => {
  if (
    dayjs(period.startDate).isBefore(
      `${dayjs(period.startDate).year()}${seasonsDate.SUMMER.start}`,
    )
  ) {
    let year = dayjs(period.startDate).year()
    if (
      !dayjs(period.startDate).isBetween(
        dayjs(`${year}-01-01`),
        dayjs(`${year}${seasonsDate.SUMMER.start}`),
      )
    ) {
      year -= 1
    }
    return `Winter ${year}`
  }
  if (
    isSummerStart(dayjs(period.startDate)) &&
    isSummerEnd(dayjs(period.endDate))
  ) {
    return `Summer ${dayjs(period.startDate).year()}`
  }
  if (
    isWinterStart(dayjs(period.startDate)) &&
    isWinterEnd(dayjs(period.endDate))
  ) {
    return `Winter ${dayjs(period.startDate).year()}`
  }

  return ''
}

export interface ParsedPeriods {
  id: string
  periodId: string
  startDate: string
  endDate: string
  priceType: SelectOptions<PowerPriceName>
  title?: string
}

type GenerateSeasonFn = (
  startDate: string,
  endDate: string,
  contractType: SelectOptions<PowerPriceNameWithMultiple>,
  // Create the titles and insert them in the periods
  createTitles?: boolean,
) => ParsedPeriods[]

/**
 * Generate a preset of periods within the `startDate`, `endDate` and `contractType`.
 * ______________________
 * SIDE NOTE: `contractType = 'MULTIPLE_TYPES'` WILL NOT BE HANDLED
 * (i.e.: Custom pricing)
 * @return
 * ```
 * generatePeriods(
 *  '2022-01-01',
 *  '2023-06-01',
 *  { value: 'FIXED_AVERAGED', label: 'string'}
 * ) => [
 *   {
 *     startDate: '2022-01-01',
 *     endDate: '2022-12-31',
 *     priceType: { value: 'FIXED_AVERAGED', label: 'fixedAveraged' },
 *   },
 *   {
 *     startDate: '2023-01-01',
 *     endDate: '2023-12-31',
 *     priceType: { value: 'FIXED_AVERAGED', label: 'fixedAveraged' },
 *   }
 * ]
 * ```
 */

export const generatePeriods: GenerateSeasonFn = (
  startDate,
  endDate,
  contractType,
  createTitles = false,
) => {
  if (contractType.value === 'MULTIPLE_TYPES') return []

  if (contractType.value === 'SEASONAL') {
    return toSeasons(startDate, endDate).map((season) => {
      return {
        ...season,
        id: v4(),
        periodId: v4(),
        priceType: priceTypeList.filter(
          (item) => item.value === contractType.value,
        )[0] as SelectOptions<PowerPriceName>,
        title: createTitles ? generateSeasonTitles(season as any) : '',
      }
    })
  }

  return [
    {
      id: v4(),
      periodId: v4(),
      startDate,
      endDate,
      priceType: priceTypeList.filter(
        (item) => item.value === contractType.value,
      )[0] as SelectOptions<PowerPriceName>,
    },
  ]
}

type ValidatePeriodsFn = (
  startDate: string,
  endDate: string,
  contractType: SelectOptions<PowerPriceNameWithMultiple>,
  periods: Periods[],
) => boolean
export const validatePeriods: ValidatePeriodsFn = (
  startDate,
  endDate,
  contractType,
  periods,
) => {
  /**
   * @TODO Create validation for multiple types as well
   * Such as: Validate if the dates overlap or if each period includes the same date and then throw a validation error
   */
  if (contractType.value === 'MULTIPLE_TYPES') return false

  if (periods.length === 0) return false

  const checkPeriods = generatePeriods(startDate, endDate, contractType)

  if (checkPeriods.length === 0 || periods.length !== checkPeriods.length)
    return false

  let isPeriodValid = true

  for (let idx = 0; idx < checkPeriods.length; idx += 1) {
    const checkElement = checkPeriods[idx]
    const periodElement = periods[idx]

    const checkPriceType =
      typeof checkElement.priceType === 'string'
        ? checkElement.priceType
        : checkElement.priceType.value

    const periodPriceType =
      typeof checkElement.priceType === 'string'
        ? checkElement.priceType
        : checkElement.priceType.value

    if (checkPriceType !== periodPriceType) {
      isPeriodValid = false
      break
    }

    if (checkElement.startDate !== periodElement.startDate) {
      isPeriodValid = false
      break
    }

    if (checkElement.endDate !== periodElement.endDate) {
      isPeriodValid = false
      break
    }
  }

  return isPeriodValid
}
