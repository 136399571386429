/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import classNames from 'classnames'

import { transition } from '../../../styles'

interface NavbarProps {
  tabs: { label: string; onClick?: () => void }[]
}

const Navbar: React.FC<NavbarProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabClick = (index: number, onClick?: () => void) => {
    setActiveTab(index)
    if (onClick) onClick()
  }
  return (
    <div className="flex space-x-4 w-full border-b">
      {tabs.map((tab, index) => (
        <nav
          key={tab.label}
          className={classNames(
            'flex text-ppa/secondary text-sm px-1.5 cursor-pointer  border-b',
            transition,
            activeTab === index
              ? 'font-semibold border-ppa/warning'
              : 'font-normal border-transparent',
          )}
          onClick={() => handleTabClick(index, tab.onClick)}
        >
          {tab.label}
        </nav>
      ))}
    </div>
  )
}

export default Navbar
