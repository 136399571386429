import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { feedbackMessage } from '../../../../../components/atoms/feedback'
import BidsTable, {
  Bid,
  ButtonList as BidsButtonList,
} from '../../../../../components/organisms/bids-table'

import TenderDetails, { ButtonList } from '../components/details'

import { TenderStatusProps } from '../details'

const ActiveTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const bidGetList = usePPAGetList<Bid>({
    dataKey: 'bids',
    path: `/core/private/bid/by-generator/${tenderDetails.id}`,
  })

  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const handleWithdrawn = async () => {
    if (isWithdrawing) return
    setIsWithdrawing(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/tender/update-status',
        body: { tenderId: tenderDetails.id, status: 'WITHDRAWN' },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('actions.withdraw.success'),
        },
        'success',
      )

      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsWithdrawing(false)
    }
  }

  const [isLoadingBidAction, setIsLoadingBidAction] = useState(false)

  const handleDeclineBid = async (bidId: string) => {
    if (isLoadingBidAction || !bidGetList?.data) return
    setIsLoadingBidAction(true)

    const findBid = bidGetList.data.find((bid) => bid.id === bidId)
    if (!findBid) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('bids.handleDeclineBid.error.description'),
        },
        'error',
      )
      return
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/bid/reject',
        body: { bidId },
      })

      if (err || !response) throw err

      bidGetList.reset()
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('bids.handleDeclineBid.success.description'),
        },
        'success',
      )
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'bid',
        ERR_NOT_AUTHORIZED: ({ message }) => {
          if (
            message.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoadingBidAction(false)
    }
  }

  const handleAcceptBid = async (bidId: string) => {
    if (isLoadingBidAction || !bidGetList?.data) return
    setIsLoadingBidAction(true)

    const findBid = bidGetList.data.find((bid) => bid.id === bidId)
    if (!findBid) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t('bids.handleAcceptBid.error.description'),
        },
        'error',
      )
      return
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: '/core/private/bid/accept',
        body: { bidId },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('bids.handleAcceptBid.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'bid',
        ERR_NOT_AUTHORIZED: ({ message }) => {
          if (message.includes('You cannot accept a Bid that has expired')) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.CANNOT_ACCEPT_EXPIRED_BID',
              ),
              type: 'error',
            }
          }

          if (
            message.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoadingBidAction(false)
    }
  }

  const bidButtons = useMemo((): BidsButtonList[] | undefined => {
    if (!bidGetList?.data || bidGetList.isLoading || bidGetList?.error)
      return undefined

    if (!tenderDetails) return undefined

    if (tenderDetails.status === 'ACTIVE') {
      return [
        {
          props: { variant: 'primary', loading: isLoadingBidAction },
          onClick: handleAcceptBid,
          text: t('bids.accept'),
        },
        {
          props: { variant: 'secondary', loading: isLoadingBidAction },
          onClick: handleDeclineBid,
          text: t('bids.decline'),
        },
      ]
    }

    return undefined
  }, [bidGetList, tenderDetails, isLoadingBidAction])

  const buttons = useMemo((): ButtonList[] | undefined => {
    return [
      {
        text: t('actions.withdraw.button'),
        onClick: handleWithdrawn,
        props: { variant: 'secondary', loading: isWithdrawing },
      },
    ]
  }, [tenderDetails])

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} buttons={buttons} />
      <BidsTable
        tender={tenderDetails}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
        buttons={bidButtons}
        enableSelectingBids
      />
    </section>
  )
}

export default ActiveTenderDetails
