export const defaultDuration = 'duration-200'
export const transitionTiming = 'ease-in-out'

export const transitionAll = `transition-all ${defaultDuration} ${transitionTiming}`
export const transition = `transition ${defaultDuration} ${transitionTiming}`

export const defaultFocusAnimation = `${transition} border border-ppa/placeholder focus-within:border-ppa/formText focus-within:shadow-ppa/formShadow`
export const defaultTextBoxStyles =
  'rounded-md placeholder-ppa/placeholder placeholder:font-normal text-ppa/formText'

export const erroredFocusAnimation = `${transition} border border-ppa/warning`

/**
 * Setting max-width only in big screens
 * Try to use this class on the first element(usually <section/>) after <main/>
 * @See https://tailwindcss.com/docs/screens
 * @See https://tailwindcss.com/docs/max-width
 */
export const container = 'flex flex-col w-full'

export const breakPointsContainer = '3xl:max-w-[calc(1600px-225px)]'
