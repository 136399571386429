import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import BrokerFeeForm, {
  ConditionString,
  FormValues,
  Condition,
  ConditionTechnology,
  ConditionNumeric,
} from '../broker-fee-form'

import { BrokerFeeGroup } from '../../../../../../types'
import { capitalizeText } from '../../../../../../utils'

export interface BrokerFeeDrawerProps {
  generatorId: string
  brokerFee?: BrokerFeeGroup[]
  isOpen?: boolean
  handleClose: () => void
  onSuccess: () => void
}

const BrokerFeeDrawer: React.FC<BrokerFeeDrawerProps> = ({
  handleClose,
  onSuccess,
  generatorId,
  brokerFee,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.generators.details.brokerFeeDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleOnSubmit = async (data: FormValues) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error, response } = await fetchData({
        method: 'POST',
        url: '/core/private/generator/broker-fee/group',
        body: {
          generatorId,
          groups: data.rules.map((rule, idx) => {
            return {
              priority: idx + 1,
              brokerFee: parseFloat(rule.brokerFee as string),
              conditions: rule.conditions.map((condition) => {
                return {
                  field: condition.type.value.toLowerCase(),
                  operator: condition.comparison.value,
                  value:
                    condition.type.value === 'technology'
                      ? (condition as any).option.value
                      : parseFloat((condition as any).value),
                }
              }),
            }
          }),
        },
      })

      if (error || !response) {
        throw error
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'brokerFeeRuleGroup',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const formBrokerFee = useMemo((): FormValues | undefined => {
    if (!brokerFee || brokerFee.length === 0) return undefined

    return {
      rules: brokerFee
        .sort((a, b) => (a.priority > b.priority ? -1 : 1))
        .map((group) => {
          return {
            id: group.id,
            conditions: group.conditions.map((condition): Condition => {
              let result: any = {
                id: v4(),
                type: {
                  label: capitalizeText(condition.field),
                  value: condition.field,
                },
                comparison: {
                  label: condition.operator,
                  value: condition.operator,
                },
                value: undefined,
                option: undefined,
              }

              if (condition.field === 'technology') {
                result = {
                  ...result,
                  option: { label: condition.value, value: condition.value },
                } as ConditionTechnology
              } else {
                result = { ...result, value: condition.value } as
                  | ConditionNumeric
                  | ConditionString
              }

              return result as Condition
            }),
            /**
             * @CHECK_THIS
             * Backend is returning `brokerFee` as string, rather than float.
             */
            brokerFee: `${group.brokerFee}`,
          }
        }),
    }
  }, [brokerFee])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        <h1 className="text-xl font-bold text-ppa/title">
          {formBrokerFee?.rules ? t('title.update') : t('title.create')}
        </h1>

        {isOpen && (
          <BrokerFeeForm
            onSubmit={handleOnSubmit}
            onCancel={handleClose}
            isLoading={isLoading}
            formDefaultValues={formBrokerFee}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default BrokerFeeDrawer
