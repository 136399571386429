import React from 'react'
import classNames from 'classnames'

export const variants = {
  green: 'bg-ppa/greenBgPill text-ppa/greenTextPill',
  yellow: 'bg-ppa/yellowBgPill text-ppa/yellowTextPill',
  orange: 'bg-ppa/orangeBgPill text-ppa/orangeTextPill',
  orangeBgTransparetnt: 'bg-transparent text-ppa/orangeTextPill',
  gray: 'bg-ppa/grayBgPill text-ppa/grayTextPill',
  grayBgTransparetnt: 'bg-transparent text-ppa/grayTextPill',
  blue: 'bg-ppa/blueBgPill text-ppa/blueTextPill',
  red: 'bg-ppa/redBgPill text-ppa/redTextPill',
  redBgTransparetnt: 'bg-transparent text-ppa/redTextPill',
}

export interface PillProps {
  children?: React.ReactNode
  variant: keyof typeof variants
}

const Pill: React.FC<PillProps> = ({ children, variant }) => {
  return (
    <div
      className={classNames(
        'flex items-center rounded-full text-xs font-medium',
        variants[variant],
      )}
    >
      <span className="px-3 py-1 text-center min-w-[100px]">{children}</span>
    </div>
  )
}

export default Pill
