import React from 'react'
import { useTranslation } from 'react-i18next'

import BidsTable from '../../../../../components/organisms/bids-table'

import TenderDetails from '../components/details'

import { TenderStatusProps } from '../details'

const ConsumerDraftTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.details',
  })

  /**
   * @TODO new bids table for consumers should be added
   */
  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} />
      {/* <BidsTable
        tender={tenderDetails}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
        buttons={bidButtons}
        enableSelectingBids
      /> */}
    </section>
  )
}

export default ConsumerDraftTenderDetails
