import React, { useEffect } from 'react'

interface useClickOutsideProps {
  ref?: React.MutableRefObject<any>
  onClickOutside: () => void
}

const useClickOutside = ({
  onClickOutside,
  ref,
}: useClickOutsideProps): void => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !(ref?.current as any)?.contains(event.target)) {
        if (onClickOutside) onClickOutside()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [ref, onClickOutside])
}

export default useClickOutside
