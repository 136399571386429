import React from 'react'

import { TenderStatusProps } from '../details'

import TenderDetails from '../components/details'

const TendersDraftDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
}) => {
  return <TenderDetails tender={tenderDetails} />
}

export default TendersDraftDetails
