/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { ChevronLeftIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import { dateFormats } from '../../../../../../utils/data'

import {
  parseSnippetAddress,
  parseContractDuration,
} from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'
import TenderPill from '../../../../components/tender-pill'
import CompanyDefListModal from '../../../../../../components/organisms/company-details-modal'
import SupplierDefListModal from '../../../../../../components/organisms/supplier-details-modal'

import {
  ConsumerTenderWithInvitation,
  Meter,
  Supplier,
  SupplierProfile,
} from '../../../../../../types'

import { mappedTenderStatus } from '../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

type ParticipatingSupplier = Supplier & {
  profile: SupplierProfile
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}
interface TenderDetailsProps {
  tender: ConsumerTenderWithInvitation
  buttons?: ButtonList[]
}

const TenderDetails: React.FC<TenderDetailsProps> = ({ tender, buttons }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.details',
  })

  const navigate = useNavigate()

  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] =
    useState<ParticipatingSupplier | null>(null)

  const rowKeysDetails = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('list.titles.contractDuration'),
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('list.titles.deadline'),
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          if (!item?.deadline) return undefined

          return dayjs
            .utc(item.deadline)
            .tz('Europe/London')
            .format(dateFormats.userDateTime)
        },
      },
      {
        keyName: 'targetMatch',
        title: t('list.titles.targetMatch'),
        suffix: '%',
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          if (!item?.targetMatch) return undefined
          return item?.targetMatch * 100
        },
      },
      {
        keyName: 'participatingSuppliers',
        title: t('list.titles.participatingSuppliers'),
        renderCustomEl: (item: ConsumerTenderWithInvitation) => {
          if (!item?.consumerTenderInvitations) return undefined
          const participatingSuppliers = item.consumerTenderInvitations.map(
            (invitation, index) => (
              <span
                key={invitation.supplier.id}
                onClick={() => {
                  setSelectedSupplier(invitation.supplier as any)
                  setIsSupplierModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer"
              >
                {invitation.supplier.name}
                {index < item.consumerTenderInvitations.length - 1 && ', '}
              </span>
            ),
          )

          return (
            <div className="cursor-pointer flex flex-row space-x-2">
              {participatingSuppliers.length ? participatingSuppliers : '-'}
            </div>
          )
        },
      },
    ]
  }, [tender])

  const rowKeysSiteDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('meterDetailsList.titles.name'),
        renderCustomEl: (meter: Meter) => {
          return meter.name
        },
      },
      {
        keyName: 'mpan',
        title: t('meterDetailsList.titles.mpan'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.mpan) return undefined
          return meter.mpan
        },
      },
      {
        keyName: 'hhd',
        title: t('meterDetailsList.titles.hhd'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.hhdTimeFrame) return undefined
          return meter.hhdTimeFrame
        },
      },
      {
        keyName: 'annualConsumption',
        title: t('meterDetailsList.titles.annualConsumption'),
        suffix: 'MWh',
        renderCustomEl: (meter: Meter) => {
          if (!meter.annualConsumption) return undefined
          return meter.annualConsumption
        },
      },
      {
        keyName: 'gridConnectionSize',
        title: t('meterDetailsList.titles.gridConnectionSize'),
        suffix: 'kVA',
        renderCustomEl: (meter: Meter) => {
          if (!meter.gridConnectionSize) return undefined
          return meter.gridConnectionSize
        },
      },
      {
        keyName: 'company name',
        title: t('meterDetailsList.titles.company.name'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.company) return undefined
          return (
            <div
              onClick={() => {
                setIsCompanyModalOpen(true)
              }}
              className="border-b border-ppa/grayText cursor-pointer"
            >
              {meter.company.name}
            </div>
          )
        },
      },
      {
        keyName: 'company number',
        title: t('meterDetailsList.titles.company.number'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.company) return undefined
          return meter.company.number
        },
      },
      {
        keyName: 'company address',
        title: t('meterDetailsList.titles.company.address'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.company?.address) return undefined
          return parseSnippetAddress(meter.company.address as any)
        },
      },

      {
        keyName: 'existingSupplier',
        title: t('meterDetailsList.titles.existingSupplier'),
        renderCustomEl: (meter: Meter) => {
          if (!meter.existingSupplier) return undefined
          return meter.existingSupplier
        },
      },
    ]
  }, [tender?.consumerMeter])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/consumerTenders/status/${mappedTenderStatus[tender.status]}`,
            )
          }
        >
          {t('backButton')}
        </Button>
      </div>

      <div className="flex flex-col gap-y-6 w-full">
        <div className="flex items-center justify-between ">
          <div className="flex flex-col">
            <div className="flex items-center justify-start my-5">
              <h1 className="text-2xl font-semibold text-ppa/title mr-8 w-full">
                {tender.shortId}
              </h1>
              <TenderPill status={tender.status} />
            </div>
          </div>
        </div>

        <div className={classNames('flex flex-col', '2xl:flex-row')}>
          <div className="flex flex-col gap-y-3 w-full">
            <h3 className="font-semibold text-lg text-ppa/title">
              {t('list.title')}
            </h3>

            <DefList rowKeys={rowKeysDetails} data={tender} />

            {buttons && (
              <div className="flex items-center justify-start gap-x-5 w-full mt-5">
                {buttons.map((item) => (
                  <Button
                    {...item.props}
                    key={item.text}
                    onClick={() => item.onClick()}
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
            )}
          </div>

          <div
            className={classNames(
              'flex flex-col gap-y-4',
              'border-ppa/grayBorder',
              'pt-10',
              '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
            )}
          >
            <div className="flex flex-col gap-y-2">
              <h2 className="font-semibold text-lg text-ppa/title">
                {t('meterDetailsTitle')}
              </h2>
              <DefList
                rowKeys={rowKeysSiteDetails}
                data={tender.consumerMeter}
              />
            </div>
          </div>
        </div>
      </div>
      {selectedSupplier && (
        <SupplierDefListModal
          isOpen={isSupplierModalOpen}
          onClose={() => setIsSupplierModalOpen(false)}
          title={selectedSupplier.name}
          supplierProfileData={selectedSupplier.profile as any}
        />
      )}
      {tender.consumerMeter.company && (
        <CompanyDefListModal
          isOpen={isCompanyModalOpen}
          onClose={() => setIsCompanyModalOpen(false)}
          title={tender.consumerMeter.company.name}
          companyData={tender.consumerMeter.company}
        />
      )}
    </section>
  )
}

export default TenderDetails
