import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NotFoundIcon } from './assets/notFound.svg'

import PageTitle from '../../components/atoms/page-title'

const NotFound: React.FC = () => {
  const { t } = useTranslation('private/index')

  return (
    <div className="flex flex-col items-start justify-center gap-y-5">
      <h1 className="text-5xl text-ppa/title font-semibold mt-16">Oops!</h1>
      <PageTitle title={t('pageNotFound.title')} />
      <NotFoundIcon />
      <span className="text-xl text-ppa/title font-normal">
        {t('pageNotFound.warnMessage')}
      </span>
      <span className="text-xl text-ppa/title font-normal">
        {t('pageNotFound.infoMessage')}
      </span>
    </div>
  )
}

export default NotFound
