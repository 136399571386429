import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ChartJS,
  Chart,
  ChartData,
  ChartArea,
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Filler,
  Legend,
} from './chart'

ChartJS.register(
  CategoryScale,
  PointElement,
  LinearScale,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Filler,
  Legend,
)

const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea) => {
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0)

  gradient.addColorStop(0, 'rgba(154, 191, 93, 1)')
  gradient.addColorStop(0.15, 'rgba(249, 228, 104, 1)')
  gradient.addColorStop(0.3, 'rgba(241, 199, 104, 1)')
  gradient.addColorStop(1, 'rgba(229, 149, 103, 1)')

  return gradient
}

interface SliderProps {
  data: {
    prices: string[]
    probabilities: number[]
  }
}

const Slider: React.FC<SliderProps> = ({ data }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })
  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'line'>>({
    datasets: [],
  })

  useEffect(() => {
    if (!chartRef?.current) return

    const chart = chartRef.current

    setChartData({
      labels: data.prices,
      datasets: [
        {
          fill: true,
          label: t('slider.label'),
          data: data.probabilities,
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
          pointBorderColor: 'transparent',
          pointHoverBorderColor: '#000',
        },
      ],
    })
  }, [data])

  return (
    <div className="flex h-[190px]">
      <Chart
        ref={chartRef}
        type="line"
        data={chartData}
        options={{
          maintainAspectRatio: false,
          elements: { point: { radius: 0 } },
          interaction: { intersect: false },
          plugins: {
            tooltip: {
              titleAlign: 'center',
              callbacks: {
                label: (item) =>
                  `${t('slider.tooltip.label')}: ${item.formattedValue} %`,
                title: (item) => `${item[0].label} £`,
              },
            },
          },
          scales: {
            x: {
              title: {
                color: '#4C5172',
                align: 'center',
                display: true,
                text: '£/MWh',
                font: { weight: '400', family: 'Lexend, sans-serif' },
              },
              ticks: {
                color: '#4C5172',
                font: {
                  size: 10,
                  weight: '300',
                  family: 'Lexend, sans-serif',
                },
                autoSkipPadding: 15,
              },
            },
            y: {
              max: 100,
              title: {
                color: '#4C5172',
                align: 'center',
                display: true,
                text: 'Probability',
                font: { weight: '400', family: 'Lexend, sans-serif' },
              },
              ticks: {
                color: '#4C5172',
                font: {
                  size: 10,
                  weight: '300',
                  family: 'Lexend, sans-serif',
                },
                callback: (value) => `${value} %`,
              },
            },
          },
        }}
      />
    </div>
  )
}

export default Slider
