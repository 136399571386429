import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { parseContractDuration } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'

import {
  ConsumerTender,
  PaymentMethodConsumer,
  PriceTypeConsumer,
} from '../../../../../../types'

const targetMatchOptions = [
  {
    value: '0.25',
    label: '0% - 25%',
  },
  {
    value: '0.5',
    label: '25% - 50%',
  },
  {
    value: '0.75',
    label: '50% - 75%',
  },
  {
    value: '1',
    label: '50% - 100%',
  },
]

const formatNames = (
  type: PriceTypeConsumer | PaymentMethodConsumer | undefined,
): string => {
  if (!type) return '-'

  return type
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

interface TenderDetailsListSuppliersProps {
  data: ConsumerTender
}

const ConsumerTenderDetailsDefList: React.FC<
  TenderDetailsListSuppliersProps
> = ({ data }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.defList',
  })

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('titles.contractDuration'),
        renderCustomEl: (item: ConsumerTender) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'targetMatch',
        title: t('titles.targetMatch'),
        renderCustomEl: (item: ConsumerTender) => {
          if (!item.targetMatch) return undefined
          const matchOption = targetMatchOptions.find(
            (option: any) => option.value === item.targetMatch,
          )
          return matchOption ? matchOption.label : ''
        },
      },
      {
        keyName: 'preferredContractType',
        title: t('titles.preferredContractType'),
        renderCustomEl: (item: ConsumerTender) => {
          if (!item?.preferredContractType) return undefined
          return formatNames(item.preferredContractType)
        },
      },
      {
        keyName: 'paymentMethodName',
        title: t('titles.paymentMethodName'),
        renderCustomEl: (item: ConsumerTender) => {
          if (!item?.paymentMethodName) return undefined
          return formatNames(item.paymentMethodName)
        },
      },
      {
        keyName: 'greenTariff',
        title: t('titles.greenTariff'),
        renderCustomEl: (item: ConsumerTender) => {
          if (!item?.greenTariff) return undefined
          return item.greenTariff ? 'Yes' : 'No'
        },
      },

      {
        keyName: 'otherRequirements',
        title: t('titles.otherRequirements'),
        renderCustomEl: (item: ConsumerTender) => {
          if (!item?.otherRequirements) return undefined
          return item.otherRequirements
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('title')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
    </div>
  )
}

export default ConsumerTenderDetailsDefList
