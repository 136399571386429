import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'
import RequiredField from '../../../../../../components/atoms/required-form-field'

import InputGroup from '../../../../../../components/molecules/input-group'

import SelectMemberType from '../../../../../../components/molecules/generator-select-member-type'

export type FormData = {
  memberType: {
    value?: string
    label?: string
  }
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

interface FormProps {
  onSubmit: (data: any) => void
}
const InviteUserToOrganisationForm: React.FC<FormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.users.inviteUsersToOrganisationForm',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: {
      memberType: undefined,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-y-5">
        <h1 className="text-xl text-ppa/title font-bold">{t('label')}</h1>
        <Controller
          name="memberType"
          control={control}
          rules={{
            required: {
              value: true,
              message: tUtils('form.requiredField'),
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('form.memberType.label')}
              error={formErrors?.memberType?.message}
              required
            >
              <SelectMemberType
                {...props}
                placeholder={t('form.memberType.placeholder')}
                error={formErrors?.memberType?.message}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: {
              value: true,
              message: tUtils('form.requiredField'),
            },
            minLength: {
              value: 3,
              message: t('form.firstName.validationNameMessage'),
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('form.firstName.label')}
              error={formErrors?.firstName?.message}
              required
            >
              <Input
                type="text"
                placeholder={t('form.firstName.placeholder')}
                attribute="given-name"
                error={formErrors?.firstName?.message}
                required
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: {
              value: true,
              message: tUtils('form.requiredField'),
            },
            minLength: {
              value: 3,
              message: t('form.lastName.validationNameMessage'),
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('form.lastName.label')}
              error={formErrors?.lastName?.message}
              required
            >
              <Input
                type="text"
                placeholder={t('form.lastName.placeholder')}
                attribute="family-name"
                error={formErrors?.lastName?.message}
                required
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: tUtils('form.requiredField'),
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('form.email.label')}
              error={formErrors?.email?.message}
              required
            >
              <Input
                type="text"
                placeholder={t('form.email.placeholder')}
                error={formErrors?.email?.message}
                required
                {...props}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('form.phoneNumber.required'),
            },
            maxLength: {
              message: t('form.phoneNumber.notValid'),
              value: 12,
            },
          }}
          render={({ field: props }) => (
            <InputGroup
              label={t('form.phoneNumber.title')}
              error={formErrors?.phoneNumber?.message as any}
              required
            >
              <Input
                {...props}
                error={formErrors?.phoneNumber?.message}
                attribute="tel"
              />
            </InputGroup>
          )}
        />

        <RequiredField label="required fields" />
      </div>
      <div className="flex justify-end">
        <div className="mt-5 ml-5 w-40">
          <Button
            variant="primary"
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('form.inviteButton')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default InviteUserToOrganisationForm
