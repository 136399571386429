import React from 'react'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import Backdrop from '../../atoms/back-drop/index'
import { transition } from '../../../styles'

export interface SlidingDrawerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean
  handleClose: () => void
  children?: React.ReactNode
}

const SlidingDrawer: React.FC<SlidingDrawerProps> = ({
  isOpen,
  handleClose,
  children,
  ...restProps
}) => {
  return (
    <div {...restProps}>
      <Backdrop isOpen={isOpen} handleClick={handleClose} />
      <div
        className={classNames(
          transition,
          'flex right-0 top-0 fixed h-screen w-auto z-[950]',
          '!bg-[white] shadow-xl',
          isOpen ? 'translate-x-0' : 'translate-x-[calc(100%+0.5rem+1.5rem)]',
        )}
      >
        <XIcon
          onClick={handleClose}
          className={classNames(
            transition,
            /**
             * @FIXME For some reason this "hover:brightness-90" doesn't work in practice.
             */
            'cursor-pointer hover:brightness-90',
            'absolute right-4 top-2 w-6 h-6 md:-left-8',
            'stroke-[black] md:stroke-[white]',
          )}
        />
        <section
          className={classNames(
            'flex flex-col p-8',
            'h-screen w-screen md:w-auto md:max-w-[calc(768px-2rem-1.5rem)]',
            'overflow-x-hidden overflow-y-auto',
          )}
        >
          {children}
        </section>
      </div>
    </div>
  )
}

export default SlidingDrawer
