import React from 'react'
import classnames from 'classnames'
import {
  ExclamationIcon,
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'

import { Children } from '../../../types'

const defaultClassesIcon = 'w-full h-full max-w-[20px] max-h-[20px]'

const variants = {
  success: {
    icon: (
      <CheckCircleIcon
        className={classnames(defaultClassesIcon, 'stroke-green-600')}
      />
    ),
    border: 'border-green-600',
  },
  warn: {
    icon: (
      <ExclamationIcon
        className={classnames(defaultClassesIcon, 'stroke-orange-600')}
      />
    ),
    border: 'border-orange-600',
  },
  info: {
    icon: (
      <InformationCircleIcon
        className={classnames(defaultClassesIcon, 'stroke-blue-600')}
      />
    ),
    border: 'border-blue-600',
  },
  error: {
    icon: (
      <XCircleIcon
        className={classnames(defaultClassesIcon, 'stroke-red-600')}
      />
    ),
    border: 'border-red-600',
  },
}

export interface MessageProps extends Children {
  variant: keyof typeof variants

  size?: string
}

const Message: React.FC<MessageProps> = ({ variant, children, size }) => {
  return (
    <div
      className={classnames(
        'border-l flex items-start justify-start p-2',
        variants[variant].border,
      )}
    >
      {variants[variant].icon}
      <span className={classnames('font-light ml-2 text-ppa/title', size)}>
        {children}
      </span>
    </div>
  )
}

export default Message
