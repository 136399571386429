import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import { ConsumerBid } from '../../../../../types'

import SleevedTenderSetDeadline from '../components/set-deadline'
import TenderDetails, { ButtonList } from '../components/details'
import { TenderStatusProps } from '../details'
import SetDeadlineModal from '../components/set-deadline/modal'
import TableConsumerBids from '../../../../../components/organisms/consumer-bids-table'

const ConsumerActiveTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.consumerTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isDeadlineDrawerOpen, setIsDeadlineDrawerOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const onSetDeadlineSuccess = () => {
    setIsDeadlineDrawerOpen(false)
    refetch()
  }

  const handleClearDeadline = async () => {
    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/consumer-tender/admin/update-deadline/${tenderDetails.id}`,
        body: {
          deadline: null,
        },
      })

      if (err || !response?.data) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleClearDeadline.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    }
  }

  const handleSetDeadlineClick = () => {
    if (!tenderDetails?.deadline) {
      setIsConfirmModalOpen(true)
    } else {
      setIsDeadlineDrawerOpen(true)
    }
  }

  const confirmSetDeadline = () => {
    setIsConfirmModalOpen(false)
    setIsDeadlineDrawerOpen(true)
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    const buttonList: ButtonList[] = [
      {
        props: { variant: 'primary' },
        onClick: handleSetDeadlineClick,
        text: tenderDetails?.deadline
          ? t('buttons.updateDeadline')
          : t('buttons.setDeadline'),
      },
    ]

    if (tenderDetails?.deadline) {
      buttonList.push({
        props: { variant: 'secondary' },
        onClick: () => handleClearDeadline(),
        text: t('buttons.clearDeadline'),
      })
    }

    return buttonList
  }, [tenderDetails])

  const {
    isLoading,
    throwFeedbackError: bidThrowFeedbackError,
    data,
    error,
  } = usePPAGetList<ConsumerBid>({
    dataKey: 'consumerTenderBids',
    path: `/core/private/consumer-tender-bid/admin/${tenderDetails.id}`,
  })

  const bidList = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [bidList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} buttons={buttons} />
      <TableConsumerBids
        bids={bidList}
        tenderStatus={tenderDetails.status}
        refetch={refetch}
      />
      <SleevedTenderSetDeadline
        tender={tenderDetails}
        isDrawerOpen={isDeadlineDrawerOpen}
        onCloseDrawer={() => setIsDeadlineDrawerOpen(false)}
        onSuccess={onSetDeadlineSuccess}
      />
      <SetDeadlineModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onSetDeadline={confirmSetDeadline}
      />
    </section>
  )
}

export default ConsumerActiveTenderDetails
