import React, { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'

export interface ItemData {
  [key: string]: any
}

export interface TableConfigProps {
  keyName: keyof ItemData
  suffix?: JSX.Element | string
  renderCustomEl?: (item: any) => JSX.Element | string | number | undefined
  customRowClassFn?: (item: any) => string | undefined
}

export interface TableProps {
  isLoading?: boolean
  data?: ItemData[]
  error?: Record<any, any>
  headers: string[]
  rowKeys: TableConfigProps[]
  onRowClick?: (item: any) => void
  loadMore?: () => Promise<void>
  readOnly?: boolean
}

interface GenericColumnProps {
  item: ItemData
  config: TableConfigProps
}

type GenerateColumnContent = (
  config: TableConfigProps,
  item: ItemData,
) => JSX.Element | string | number

const columnContent: GenerateColumnContent = (config, item) => {
  const customRowClass =
    config.customRowClassFn && config.customRowClassFn(item)

  if (config.renderCustomEl) {
    const customElResult = config.renderCustomEl(item)
    if (!customElResult) return <div className="flex items-center">-</div>
    return (
      <div className="flex items-center w-full">
        <div
          className={classNames(
            'flex gap-x-1 w-full',
            'text-ppa/grayTextTable',
            customRowClass,
          )}
        >
          {customElResult}
          {config.suffix && <> {config.suffix}</>}
        </div>
      </div>
    )
  }

  return _.get(item, config.keyName) ? (
    <div
      className={classNames(
        'flex items-center',
        'text-ppa/grayTextTable',
        customRowClass,
      )}
    >
      {_.get(item, config.keyName)} {config.suffix}
    </div>
  ) : (
    '-'
  )
}

const Column: React.FC<GenericColumnProps> = ({ config, item }) => {
  return (
    <td className="text-sm font-light px-5 py-3">
      {columnContent(config, item)}
    </td>
  )
}

const Table: React.FC<TableProps> = ({
  data,
  error,
  isLoading,
  headers,
  rowKeys,
  onRowClick,
  loadMore,
  readOnly = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'table',
  })

  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const callLoadMore = useCallback(
    async (inView: boolean) => {
      /**
       * !loadMore => not tracking loadMore
       * isLoadingMore => it's already loading more
       * !inView => the element it's not in View yet
       */
      if (!loadMore || isLoadingMore || !inView) return

      setIsLoadingMore(true)

      await loadMore()

      setIsLoadingMore(false)
    },
    [loadMore, isLoadingMore],
  )

  const { ref } = useInView({
    onChange: callLoadMore,
    threshold: 0,
    rootMargin: '-50px 0px 0px 0px',
    triggerOnce: false,
  })

  return (
    <div className="scroll-shadow-horizontal pb-5 flex flex-col relative z-[100]">
      <table className="bg-transparent w-full">
        <thead className="bg-ppa/tableTitleBackground border-b border-ppa/grayBorder">
          <tr>
            {headers.map((item) => (
              <th
                key={item}
                className="text-sm text-ppa/tableTitleText text-left font-normal py-3 px-5"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            !error &&
            data !== undefined &&
            data.length > 0 &&
            data.map((item, indexRow) => (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={indexRow}
                onClick={() => {
                  if (onRowClick) onRowClick(item)
                }}
                className={classNames(
                  !readOnly && 'hover:bg-ppa/tableRowHover',
                  'border-b border-grayBorder font-light text-ppa/tableRowText',
                  onRowClick && 'cursor-pointer',
                )}
              >
                {rowKeys.map((rowKey, indexColumn) => (
                  <Column
                    key={headers[indexColumn]}
                    config={rowKey}
                    item={item}
                  />
                ))}
              </tr>
            ))}
        </tbody>
      </table>

      {!error && !isLoadingMore && isLoading && (
        <div className="flex justify-center text-ppa/tableRowText border-b border-ppa/grayBorder">
          <div className="flex items-center">
            <Spinner className="mx-auto animate-spin w-5 h-5" />
            <span className="text-base font-normal py-3 ml-2">
              {t('loadingData')}
            </span>
          </div>
        </div>
      )}

      {!isLoading && (data === undefined || data?.length === 0) && (
        <div className="flex items-center justify-center text-ppa/tableRowText border-b border-ppa/grayBorder">
          <span className="text-base font-normal py-3">{t('noData')}</span>
        </div>
      )}

      {data && isLoadingMore && (
        <div className={classNames('flex flex-col items-center mt-2')}>
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {data && data.length > 0 && !isLoadingMore && (
        <div
          ref={ref}
          className={classNames('flex w-full h-[1px] absolute bottom-5')}
        />
      )}
    </div>
  )
}

export default Table
