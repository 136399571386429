import Meters from '.'
import MeterDetails from './details'

import MetersCompanyList from './company-list'
import MeterCompanyDetails from './company-list/details'

export const metersLinks = [
  { to: '/portfolio', label: 'metersOptions.portfolio' },
  {
    to: '/companies',
    label: 'metersOptions.companiesList',
  },
]

export const metersSubRoutes = {
  portfolio: {
    index: <Meters />,
    details: <MeterDetails />,
  },
  companies: {
    index: <MetersCompanyList />,
    details: <MeterCompanyDetails />,
  },
}
