import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from 'react'

import useUser from '../use-user'
import usePPA from '../use-ppa'

interface NotificationContextProps {
  children: ReactNode
}

interface NotificationContextValue {
  unreadNotifications: boolean
  handleNotificationsChange: () => Promise<void>
}

const NotificationContext = createContext<NotificationContextValue | undefined>(
  undefined,
)

export const NotificationProvider: React.FC<NotificationContextProps> = ({
  children,
}) => {
  const { fetchData } = usePPA()
  const { user } = useUser()

  const [unreadNotifications, setUnreadNotifications] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleNotificationsChange = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const { error, response } = await fetchData({
        method: 'GET',
        url: `/notifications/private/notifications/has-unread/${user.appMetadata.userId}`,
      })

      if (!response?.data && error) {
        throw error
      }

      setUnreadNotifications(response?.data.hasUnreadNotifications)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const contextValue: NotificationContextValue = useMemo(() => {
    return {
      handleNotificationsChange,
      unreadNotifications,
    }
  }, [handleNotificationsChange, unreadNotifications])

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}

export default function useNotification(): NotificationContextValue {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    )
  }
  return context
}
