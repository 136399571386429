import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import { SelectOptions } from '../../../../../../components/atoms/select'
import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import { TenderSiteWithInvitation, SubsidyType } from '../../../../../../types'
import { capitalizeText } from '../../../../../../utils'
import { dateFormats } from '../../../../../../utils/data'

import TenderForm, { optionsDurations, FormValues } from '../form-tender'

type Tender = TenderSiteWithInvitation

type Subsidy = Omit<SubsidyType, 'type' | 'active' | 'order'> & {
  toggled: boolean
}

type ParsedSubsidies = {
  REGO?: boolean
  ROC?: boolean
  FIT?: boolean
}

interface EditTenderProps {
  tender: Tender
  isDrawerOpen: boolean
  handleCloseDrawer: () => void
  onSuccess: () => void
}

const EditTender: React.FC<EditTenderProps> = ({
  tender,
  onSuccess,
  isDrawerOpen,
  handleCloseDrawer,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()
  const [isLoading, setIsLoading] = useState(false)

  const tenderFormData = useMemo((): any => {
    if (!tender) return undefined

    const { startDate, endDate, otherRequirements, subsidies } = tender

    const defaultSubsidies: FormValues['subsidies'] = {
      roc: { enabled: false },
      fit: { enabled: false },
      rego: { enabled: false },
    }

    subsidies.forEach((subsidy: Subsidy) => {
      if (subsidy.toggled === true) {
        if (subsidy.name === 'REGO') {
          defaultSubsidies.rego.enabled = true
        } else if (subsidy.name === 'FIT') {
          defaultSubsidies.fit.enabled = true
        } else if (subsidy.name === 'ROC') {
          defaultSubsidies.roc.enabled = true
        }
      }
    })

    return {
      startDate: dayjs(startDate).format(dateFormats.aws),
      endDate: dayjs(endDate).format(dateFormats.aws),
      durationMonths: optionsDurations[0],
      customEndDate: true,
      sites: [
        {
          value: JSON.stringify(tender.site),
          label: `${tender.site.name} (${capitalizeText(
            tUtils(`technology.${tender.site.technology}.abbreviation`),
          )})`,
        },
      ],
      participatingSuppliers: tender.tenderInvitations.map((item) => {
        return {
          value: JSON.stringify(item.supplier),
          label: item.supplier.name,
        }
      }),
      subsidies: defaultSubsidies,
      otherRequirements: otherRequirements || '',
    }
  }, [tender])

  const handleEdit = async (formData: any) => {
    if (formData.sites.length > 1) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t(
            'drawerCreateUpdateTender.form.edit.onSubmit.error.moreThanOneSite',
          ),
        },
        'error',
      )
      return
    }

    if (isLoading || !tender) return
    setIsLoading(true)

    try {
      const siteId = JSON.parse(formData.sites[0].value).id

      let supplierIds: string[] = []

      if (formData.participatingSuppliers?.length > 0) {
        supplierIds = formData.participatingSuppliers.map(
          (item: SelectOptions) => JSON.parse(item.value).id,
        )
      }

      const { fit, rego, roc } = formData.subsidies

      const subsidies: ParsedSubsidies = { REGO: !!rego?.enabled }

      if (roc?.enabled) {
        subsidies.ROC = true
      } else if (fit?.enabled) {
        subsidies.FIT = true
      } else {
        subsidies.ROC = false
        subsidies.FIT = false
      }

      const { startDate, durationMonths, customEndDate, otherRequirements } =
        formData

      let { endDate } = formData
      if (customEndDate) {
        endDate = formData.endDate
      } else {
        endDate = dayjs(startDate)
          .add(durationMonths.value, 'months')
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormats.aws)
      }

      const { response, error: err } = await fetchData({
        method: 'PUT',
        url: `/core/private/tender/${tender.id}`,
        body: {
          startDate,
          endDate,
          siteId,
          supplierIds,
          ...(Object.keys(subsidies).length > 0 && { subsidies }),
          otherRequirements,
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('drawerCreateUpdateTender.form.edit.onSubmit.success.title'),
          description: t(
            'drawerCreateUpdateTender.form.edit.onSubmit.success.description',
          ),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'tender',
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Tender found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.tender.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={handleCloseDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        <h1 className="text-xl font-bold text-ppa/title">
          {t('drawerCreateUpdateTender.form.edit.title')}
        </h1>

        {isDrawerOpen && (
          <TenderForm
            isLoading={isLoading}
            onSubmit={handleEdit}
            onCancel={handleCloseDrawer}
            defaultValues={tenderFormData}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default EditTender
