import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import { PencilIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import { Company, Address } from '../../../../../types'

import { parseSnippetAddress } from '../../../../../utils'

import { container, breakPointsContainer } from '../../../../../styles'
import { ReactComponent as Spinner } from '../../../../../assets/spinner/spinner.svg'

import DefList from '../../../../../components/atoms/def-list'
import Dropdown from '../../../../../components/atoms/dropdown'
import Button from '../../../../../components/atoms/button'

import EditCompany from './edit-company'

const SiteCompanyDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.companyList.details',
  })

  const navigate = useNavigate()

  const { companyId } = useParams<{ companyId: string }>()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<Company>({
      dataKey: 'company',
      path: `/core/private/generator/company/${companyId}`,
    })

  const companyDetails: Company | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'name', title: t('defList.name') },
      { keyName: 'number', title: t('defList.number') },
      { keyName: 'type', title: t('defList.type') },
      {
        keyName: 'address',
        title: t('defList.address'),
        renderCustomEl: (item: Company) => {
          return parseSnippetAddress(item?.address as Address | undefined)
        },
      },
    ]
  }, [companyDetails])

  const handleEditCompany = () => {
    refetch()
    setIsEditDrawerOpen(false)
  }

  useEffect(() => {
    if (!companyId) {
      navigate('/sites/companies')
    }
  }, [companyId])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return (
    <div>
      <section
        className={classNames(
          container,
          breakPointsContainer,
          'items-center pt-12 relative',
        )}
      >
        <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
          <Button
            variant="text"
            icon={<ChevronLeftIcon />}
            onClick={() => navigate('/sites/companies')}
          >
            {t('backButton')}
          </Button>
        </div>
        {isLoading && (
          <div className="w-full">
            <Spinner className="mx-auto animate-spin w-5 h-5" />
          </div>
        )}

        {!isLoading && !error && companyDetails && (
          <>
            <div className="flex items-center justify-between w-full">
              <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
                {companyDetails.name}
              </h1>
              <Dropdown
                content={
                  <div className="flex flex-col justify-start items-start">
                    <Button
                      variant="text"
                      icon={<PencilIcon />}
                      onClick={() => {
                        setIsEditDrawerOpen(true)
                      }}
                    >
                      {t('actionsDropdown.edit')}
                    </Button>
                  </div>
                }
              />
            </div>
            <DefList rowKeys={rowKeys} data={companyDetails} />
            <EditCompany
              company={companyDetails}
              onSuccess={handleEditCompany}
              isOpen={isEditDrawerOpen}
              handleClose={() => setIsEditDrawerOpen(false)}
            />
          </>
        )}
      </section>
    </div>
  )
}

export default SiteCompanyDetails
