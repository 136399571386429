import React from 'react'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'

import { useDebouncedCallback } from 'use-debounce'

import { customStyles, selectConfig, SelectOptions } from '../select'

export type RequestOptionsFn = (
  inputSearch: string,
) => Promise<SelectOptions[] | undefined>

export interface SelectAsyncProps {
  onChange: (value?: any) => void
  requestOptions: RequestOptionsFn
  options?: SelectOptions[]
  type?: 'single' | 'multi'
  disabled?: boolean
  placeholder?: string
  name: string
  value?: any
  testId?: string
}

const animatedComponents = makeAnimated()

const SelectAsync: React.FC<SelectAsyncProps> = ({
  onChange,
  requestOptions,
  name,
  options,
  type = 'single',
  placeholder = selectConfig[type].placeholder,
  disabled,
  value,
  testId = 'async-select-component',
}) => {
  const debounceLoadOptions = useDebouncedCallback(
    (inputValue: string, callback: (res: unknown) => typeof res) => {
      requestOptions(inputValue)
        .then(callback)
        .catch((rejection) => {
          /**
           * @TODO Create a error handler here (E.G.: Toast).
           */
          console.error(rejection)
        })
    },
    400,
  )

  return (
    <div className="w-full" data-testid={testId}>
      <AsyncSelect
        onChange={onChange}
        className="react-select-container"
        styles={customStyles as any}
        isClearable
        isDisabled={disabled}
        components={animatedComponents}
        isMulti={selectConfig[type].isMulti}
        placeholder={placeholder}
        loadOptions={debounceLoadOptions as any}
        defaultOptions={options}
        name={name}
        value={value || ''}
        defaultValue={value || undefined}
      />
    </div>
  )
}

export default SelectAsync
