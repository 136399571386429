import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'

import { XIcon } from '@heroicons/react/outline'

import { Filters } from '../../../../../../hooks/use-ppa/use-ppa-get-list'
import usePPA from '../../../../../../hooks/use-ppa'

import { parseToFloatFormValues } from '../../../../../../utils'

import { dateFormats } from '../../../../../../utils/data'

import Filter from '../../../../../../components/molecules/filter'
import Search from '../../../../../../components/atoms/search'

import SelectComponent, {
  SelectOptions,
} from '../../../../../../components/atoms/select'
import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'
import Checkbox from '../../../../../../components/atoms/checkbox'

import { Technology } from '../../../../../../types'

export type FilterFormValues = {
  generator?: SelectOptions
  capacityOperator?: SelectOptions
  capacityValue?: string
  technology?: SelectOptions<Technology>
  startDate?: string
  endDate?: string
  deadline?: string
  hhd?: boolean
}

export type onSearchOrFilterData = {
  generator?: SelectOptions
  capacity?: number
  capacityOperator?: SelectOptions
  technology?: SelectOptions<Technology>
  startDate?: string
  endDate?: string
  deadline?: string
  hhd?: boolean
}

export interface FilterSitesProps {
  onSubmit: (data: Filters) => void
  cleanFilters: () => void
}
const SearchFilter: React.FC<FilterSitesProps> = ({
  onSubmit,
  cleanFilters,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.filters',
  })
  const { t: tUtils } = useTranslation('private/index', { keyPrefix: 'utils' })

  const { fetchData, throwFeedbackError } = usePPA()

  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const [filtersCount, setFiltersCount] = useState<number>(0)

  const [isLoadingGeneratorsList, setIsLoadingGeneratorsList] =
    useState<boolean>(false)
  const [generatorsOptions, setGeneratorsOptions] = useState<SelectOptions[]>(
    [],
  )

  const [isLoadingTechnologyList, setIsLoadingTechnologyList] =
    useState<boolean>(false)
  const [technologyOptions, setTechnologyOptions] = useState<SelectOptions[]>(
    [],
  )

  const operatorOptions = [
    {
      value: 'equals',
      label: t('selectComponentOptions.equal'),
    },
    {
      value: 'gt',
      label: t('selectComponentOptions.greaterThan'),
    },
    {
      value: 'gte',
      label: t('selectComponentOptions.greaterOrEqualThan'),
    },
    {
      value: 'in',
      label: t('selectComponentOptions.in'),
    },
    {
      value: 'lt',
      label: t('selectComponentOptions.lessThan'),
    },
    {
      value: 'lte',
      label: t('selectComponentOptions.lessOfEqualThan'),
    },
    {
      value: 'not',
      label: t('selectComponentOptions.not'),
    },
    {
      value: 'notIn',
      label: t('selectComponentOptions.notIn'),
    },
  ]

  const defaultValues = {
    capacityOperator: operatorOptions[1],
    capacityValue: '',
    startDate: '',
    endDate: '',
    deadline: '',
    hhd: false,
  } as any

  const { control, handleSubmit, reset, getValues } = useForm<FilterFormValues>(
    {
      defaultValues,
    },
  )

  const handleSubmitForm = (data: FilterFormValues & { search?: string }) => {
    const result: Filters = []

    if (data?.search) {
      result.push({ field: 'search', operator: 'contains', value: data.search })
    }

    if (data?.capacityValue && data?.capacityOperator?.value) {
      result.push({
        field: 'capacity',
        operator: data.capacityOperator.value,
        value: parseToFloatFormValues(data.capacityValue),
      })
    }

    if (data?.generator?.value) {
      result.push({
        field: 'generatorId',
        operator: 'equals',
        value: data.generator.value,
      })
    }

    if (data?.technology?.value) {
      result.push({
        field: 'technology',
        operator: 'equals',
        value: data.technology.value,
      })
    }

    if (data?.startDate) {
      result.push({
        field: 'startDate',
        operator: 'gte',
        value: data.startDate,
      })
    }

    if (data?.endDate) {
      result.push({
        field: 'endDate',
        operator: 'lte',
        value: data.endDate,
      })
    }

    if (data?.deadline) {
      result.push({
        field: 'deadline',
        operator: 'lte',
        value: data?.deadline,
      })

      if (data?.hhd) {
        result.push({
          field: 'hhd',
          operator: 'equals',
          value: data?.hhd,
        })
      }
    }

    const numberOfFiltersToApply = result.filter(
      ({ field }) => field !== 'search',
    )

    onSubmit(result)
    setFiltersCount(numberOfFiltersToApply.length)
  }

  const handleSearchChange = async (newSearch?: string) => {
    const parsedSearch = newSearch?.trim().toLowerCase() || ''
    setSearchText(parsedSearch)

    if (filtersCount >= 1) {
      handleSubmitForm({
        ...getValues(),
        search: parsedSearch,
      })
    } else {
      handleSubmitForm({
        search: parsedSearch,
      })
      setFiltersCount(0)
    }
  }

  const getGeneratorList = async () => {
    if (isLoadingGeneratorsList || generatorsOptions.length) return
    setIsLoadingGeneratorsList(true)

    try {
      const { error, response } = await fetchData({
        method: 'GET',
        url: '/core/private/generator/list-by-supplier',
      })

      if (response?.data && !error) {
        const genLits = response.data.generators || []
        setGeneratorsOptions(
          genLits.map((generator: any): SelectOptions => {
            return {
              value: generator.id,
              label: generator.name,
            }
          }),
        )
      }
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoadingGeneratorsList(false)
    }
  }

  const getTechnologyList = async () => {
    if (isLoadingTechnologyList || technologyOptions.length) return
    setIsLoadingTechnologyList(true)

    try {
      const { error, response } = await fetchData({
        method: 'GET',
        url: '/core/private/default-values/technology',
      })

      if (response?.data && !error) {
        const techList = response.data.technologies || []
        setTechnologyOptions(
          techList.map(({ name }: any): SelectOptions => {
            return {
              value: name,
              label: tUtils(`technology.${name}.fullName`),
            }
          }),
        )
      }
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoadingTechnologyList(false)
    }
  }

  useEffect(() => {
    if (isLoadingGeneratorsList || generatorsOptions.length) return

    getGeneratorList()
  }, [])

  useEffect(() => {
    if (isLoadingTechnologyList || technologyOptions.length) return

    getTechnologyList()
  }, [])

  return (
    <div className="flex">
      <Search onSearchChange={handleSearchChange} />
      {filtersCount > 0 && (
        <button
          type="button"
          onClick={() => {
            reset()
            cleanFilters()
            setFiltersCount(0)
          }}
          className="flex items-center text-ppa/placeholder text-xs font-normal gap-x-1.5 mr-2 w-24"
        >
          <XIcon className="w-3 h-3" />
          {t('clearAll')}
        </button>
      )}
      <Filter numFiltersApplied={filtersCount}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit((data) => {
              handleSubmitForm({ ...data, search: searchText })
            })()
          }}
          className="min-w-[270px] max-w-[300px]"
        >
          <div className="flex flex-col overflow-visible gap-y-3.5 mt-5 mb-3.5">
            <div className="flex flex-col gap-y-3 min-w-[270px]">
              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.generator')}
                </span>
                <Controller
                  name="generator"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <SelectComponent
                      options={generatorsOptions || []}
                      placeholder={t('placeholder.selectGenerator')}
                      value={value}
                      {...props}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.capacity')}
                </span>
                <div className="flex gap-x-1.5">
                  <div className="min-w-[190px]">
                    <Controller
                      name="capacityOperator"
                      control={control}
                      render={({ field: { ref, value, ...props } }) => (
                        <SelectComponent
                          options={operatorOptions || []}
                          placeholder={t('placeholder.graterThanOperator')}
                          value={value}
                          disableClean
                          {...props}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="capacityValue"
                    control={control}
                    render={({ field: { ref, value, ...props } }) => (
                      <Input type="text" suffix="MW" value={value} {...props} />
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-y-1">
                <span className="text-sm text-ppa/title font-normal">
                  {t('label.technology')}
                </span>
                <Controller
                  name="technology"
                  control={control}
                  render={({ field: { ref, value, ...props } }) => (
                    <SelectComponent
                      options={technologyOptions || []}
                      placeholder={t('placeholder.selectTechnology')}
                      value={value}
                      {...props}
                      testId="filter-technology"
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex flex-col gap-y-1">
              <span className="text-sm text-ppa/title font-normal">
                {t('label.duration')}
              </span>
              <div className="flex justify-center items-center gap-x-1.5">
                <div className="max-w-[145px]">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: props }) => (
                      <Input
                        type="date"
                        min={dayjs().format(dateFormats.minDateInput)}
                        placeholder={t('startDate.placeholder')}
                        {...props}
                      />
                    )}
                  />
                </div>
                <div className="max-w-[145px]">
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: props }) => (
                      <Input
                        type="date"
                        min={dayjs().format(dateFormats.minDateInput)}
                        placeholder={t('endDate.placeholder')}
                        {...props}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <span className="text-sm text-ppa/title font-normal">
                {t('label.deadline')}
              </span>
              <div className="w-full">
                <Controller
                  name="deadline"
                  control={control}
                  render={({ field: props }) => (
                    <Input
                      type="date"
                      min={dayjs().format(dateFormats.minDateInput)}
                      placeholder={t('deadline.placeholder')}
                      {...props}
                    />
                  )}
                />
              </div>
            </div>
            <Controller
              name="hhd"
              control={control}
              render={({ field: props }) => (
                <div className="flex items-center justify-start">
                  <Checkbox label={t('label.hhd')} {...props} />
                </div>
              )}
            />
            <div className="mt-2">
              <Button type="submit" variant="primary" fullWidth>
                {t('applyFilters')}
              </Button>
            </div>
          </div>
        </form>
      </Filter>
    </div>
  )
}

export default SearchFilter
