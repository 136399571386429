import React from 'react'
import VerticalTab from '../../../../components/atoms/vertical-tab'

import { isFeatureEnabled } from '../../../../utils/features'

import Profile from './profile'
import Users from './users'
import Notifications from './notifications'

const Settings: React.FC = () => {
  return (
    <VerticalTab
      header="Settings"
      tabs={[
        { label: 'Profile', content: <Profile /> },
        { label: 'Users', content: <Users /> },
        isFeatureEnabled('notificationsPage') && {
          label: 'Notifications',
          content: <Notifications />,
        },
      ]}
    />
  )
}

export default Settings
