/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import classNames from 'classnames'
import { transition } from '../../../styles'

export interface BackdropProps {
  isOpen: boolean
  handleClick: () => void
}

const Backdrop: React.FC<BackdropProps> = ({ handleClick, isOpen }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <div
      className={classNames(
        transition,
        'flex fixed w-screen h-screen overflow-hidden inset-0 z-[900]',
        'bg-ppa/blackShadow bg-opacity-30 cursor-default',
        isOpen
          ? 'duration-500 opacity-100'
          : 'duration-500 opacity-0 pointer-events-none select-none',
      )}
      onClick={handleClick}
      role="button"
    />
  )
}

export default Backdrop
