import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'

import usePPA, { ThrowFeedbackErrorFn, PPAError } from '../index'

type UsePPAGetObjectProps = {
  dataKey: string
  path: string
} & Record<any, any>

type FetchDataFn<DataType = any> = (
  props: UsePPAGetObjectProps,
) => Promise<DataType | undefined>

interface UsePPAGetObjectData<DataType = any> {
  data?: DataType
  isLoading: boolean
  error?: PPAError
  refetch: () => Promise<void>
  throwFeedbackError: ThrowFeedbackErrorFn
}

const usePPAGetDetails = <
  DataType extends Record<any, any> = Record<any, any>,
>({
  dataKey,
  path,
}: UsePPAGetObjectProps): UsePPAGetObjectData<DataType> => {
  const { fetchData, throwFeedbackError } = usePPA()

  const [data, setData] = useState<undefined | DataType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<PPAError | undefined>()

  const fetchDataObject: FetchDataFn = async () => {
    setError(undefined)

    try {
      const url = path
      const fetch = await fetchData({
        method: 'GET',
        url,
      })

      const responseData = fetch?.response?.data[dataKey]
      if (fetch?.error && fetch?.error instanceof AxiosError) {
        throw new AxiosError(fetch.error.message)
      } else if (!responseData || typeof responseData !== 'object') {
        throw new Error('Wrong data type in response.')
      }

      return responseData as DataType
    } catch (err: any) {
      console.error('usePPA.fetchDataObject() error:', err)
      if (err instanceof AxiosError) {
        if (typeof err === 'object' && err?.response?.data?.errorType) {
          setError({
            errorType: err?.response?.data?.errorType,
            message: err?.response?.data?.message,
          })
        }
      } else {
        setError({
          errorType: 'UNHANDLED_RESPONSE_ERROR',
          message: 'The response error was not recognized.',
        })
      }
      return undefined
    }
  }

  const refetch = async () => {
    setIsLoading(true)
    setData(await fetchDataObject({ dataKey, path }))
    setIsLoading(false)
  }

  useEffect(() => {
    const firstFetch = async () => {
      setIsLoading(true)
      setData(await fetchDataObject({ dataKey, path }))
      setIsLoading(false)
    }

    firstFetch()
  }, [])

  return {
    data,
    isLoading,
    error,
    refetch,
    throwFeedbackError,
  }
}

export default usePPAGetDetails
