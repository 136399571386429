import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import classNames from 'classnames'

import { ReactComponent as Spinner } from '../../../../../../assets/spinner/spinner.svg'

import Button from '../../../../../../components/atoms/button'
import Message from '../../../../../../components/atoms/message'
import { SelectOptions } from '../../../../../../components/atoms/select'
import DefList, { RowKeys } from '../../../../../../components/atoms/def-list'

import { dateFormats } from '../../../../../../utils/data'

import { TenderSiteWithInvitation } from '../../../../../../types'
import {
  Quote as QuoteData,
  PricingTables,
} from '../../../../../../types/quote'

import { transition } from '../../../../../../styles'

type Tender = TenderSiteWithInvitation

interface QuoteProps {
  quoteError?: string
  quoteData?: QuoteData
  isLoading?: boolean
  tender?: Tender
  refetchQuote: () => void
}

const NavButton: React.FC<{
  text: string
  onClick: () => void
  isActive?: boolean
}> = ({ onClick, text, isActive = false }) => {
  return (
    <button
      className={classNames(
        transition,
        'border-none text-ppa/grayTextTitle hover:brightness-150',
        'py-1 px-3 text-xs font-bold',
        isActive ? 'bg-white' : 'bg-transparent',
      )}
      type="button"
      onClick={onClick}
    >
      {text}
    </button>
  )
}

interface GuaranteedForecastedData {
  guaranteed?: number
  forecasted?: number
}

const GuaranteedForecastedRow: React.FC<{
  data?: GuaranteedForecastedData
}> = ({ data }) => {
  return (
    <div className="flex flex-1 text-xs text-ppa/title items-end">
      <span className="flex-1 text-right pr-3">
        {data?.guaranteed ? data.guaranteed : '-'}
      </span>
      <span className="flex-1 text-right">
        {data?.forecasted ? data.forecasted : '-'}
      </span>
    </div>
  )
}

const embeddedBenefitsRowKeys: RowKeys[] = [
  {
    keyName: 'no-key',
    title: '',
    renderCustomEl: () => {
      return (
        <div className="flex flex-1 text-xs text-ppa/title items-end">
          <span className="flex-1 text-right pr-3">Guaranteed</span>
          <span className="flex-1 text-right">Forecasted</span>
        </div>
      )
    },
  },
  {
    keyName: 'aahedc',
    title: 'aahedc:',
    renderCustomEl: (item: PricingTables) => {
      return <GuaranteedForecastedRow data={item?.other?.aahedc} />
    },
  },
  {
    keyName: 'tloss',
    title: 'tloss:',
    renderCustomEl: (item: PricingTables) => {
      return <GuaranteedForecastedRow data={item?.losses?.tloss} />
    },
  },
  {
    keyName: 'dloss',
    title: 'dloss:',
    renderCustomEl: (item: PricingTables) => {
      return <GuaranteedForecastedRow data={item?.losses.dloss} />
    },
  },
  {
    keyName: 'gduos',
    title: 'gduos:',
    renderCustomEl: (item: PricingTables) => {
      return <GuaranteedForecastedRow data={item?.gduos} />
    },
  },
  {
    keyName: 'rcrc',
    title: 'rcrc:',
    renderCustomEl: (item: PricingTables) => {
      return <GuaranteedForecastedRow data={item?.other?.rcrc} />
    },
  },
]

const chargesRowKeys: RowKeys[] = [
  {
    keyName: 'no-key',
    title: '',
    renderCustomEl: () => {
      return (
        <div className="flex flex-1 text-xs text-ppa/title items-end">
          <span className="flex-1 text-right pr-3">Guaranteed</span>
          <span className="flex-1 text-right">Forecasted</span>
        </div>
      )
    },
  },
  {
    keyName: 'brokerFee',
    title: 'broker fee:',
    renderCustomEl: (item: PricingTables) => {
      return <GuaranteedForecastedRow data={item?.fees?.brokerFee} />
    },
  },
  /**
   * @SEE This was hidden because (words from Kristina) "The Suppliers do the calculations on top of the wholesale, everything goes on top of it, visually speaking"
   * Decided in a meeting with David, Kristina, Miguel and Zim
   */
  // {
  //   keyName: 'imbalance',
  //   title: 'imbalance:',
  //   renderCustomEl: (item: PricingTables) => {
  //     return <GuaranteedForecastedRow data={item?.fees?.imbalance} />
  //   },
  // },
]

const now = dayjs()

export type FormValues = {
  sites: SelectOptions[]
}

const Quote: React.FC<QuoteProps> = ({
  quoteData,
  quoteError,
  tender,
  refetchQuote,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })

  const [currentPage, setCurrentPage] = useState(0)

  const subsidiesRowKeys: (RowKeys | false)[] = [
    {
      keyName: 'no-key',
      title: '',
      renderCustomEl: () => {
        return (
          <div className="flex flex-1 text-xs text-ppa/title items-end">
            <span className="flex-1 text-right pr-3">Guaranteed</span>
            <span className="flex-1 text-right">Forecasted</span>
          </div>
        )
      },
    },
    tender?.subsidies.some(
      (subsidy) => subsidy.name === 'REGO' && subsidy.toggled,
    ) && {
      keyName: 'rego',
      title: 'REGO:',
      renderCustomEl: (item: PricingTables) => {
        return <GuaranteedForecastedRow data={item?.subsidies?.rego} />
      },
    },
    tender?.subsidies.some(
      (subsidy) => subsidy.name === 'FIT' && subsidy.toggled,
    ) && {
      keyName: 'fit',
      title: 'FIT:',
      renderCustomEl: (item: PricingTables) => {
        return <GuaranteedForecastedRow data={item?.subsidies?.fit} />
      },
    },
    tender?.subsidies.some(
      (subsidy) => subsidy.name === 'ROC' && subsidy.toggled,
    ) && {
      keyName: 'roc',
      title: 'ROC (buyout):',
      renderCustomEl: (item: PricingTables) => {
        return <GuaranteedForecastedRow data={item?.subsidies?.rocBuyout} />
      },
    },
    tender?.subsidies.some(
      (subsidy) => subsidy.name === 'ROC' && subsidy.toggled,
    ) && {
      keyName: 'roc',
      title: 'ROC (recycle):',
      renderCustomEl: (item: PricingTables) => {
        return <GuaranteedForecastedRow data={item?.subsidies?.rocRecycle} />
      },
    },
  ].filter(Boolean) as RowKeys[]

  const cardPages = useMemo(() => {
    return [
      <DefList
        rowKeys={embeddedBenefitsRowKeys}
        data={quoteData?.averaged}
        padding="p-1"
      />,
      <DefList
        rowKeys={chargesRowKeys}
        data={quoteData?.averaged}
        padding="p-1"
      />,
      <div className="w-full">
        {tender?.subsidies ? (
          <DefList
            rowKeys={subsidiesRowKeys as RowKeys[]}
            data={quoteData?.averaged}
            padding="p-1"
          />
        ) : (
          t('drawerTargetPrice.quote.card.table.noSubsidies')
        )}
      </div>,
      <div className="w-full">{tender?.otherRequirements || '-'}</div>,
    ]
  }, [
    embeddedBenefitsRowKeys,
    chargesRowKeys,
    quoteData,
    tender?.subsidies,
    subsidiesRowKeys,
    tender?.otherRequirements,
  ])

  const wholesale = useMemo((): number => {
    if (quoteData?.averaged) {
      const imbalance = quoteData.averaged.fees?.imbalance?.guaranteed || 0

      return parseFloat(
        (quoteData.averaged.wholesale.guaranteed + imbalance).toFixed(2),
      )
    }

    return 0
  }, [quoteData?.averaged])
  return (
    <div className="flex flex-col mb-1.5">
      <div className="flex flex-col mb-4">
        <span className="text-ppa/title text-base font-medium">
          {t('drawerTargetPrice.quote.title')}
        </span>
      </div>

      {isLoading && (
        <div className="flex flex-col items-start justify-start w-full fade-in">
          <Spinner className="animate-spin w-10 h-10" />
        </div>
      )}

      {!isLoading && quoteError && (
        <div className="flex flex-col gap-y-2">
          <Message variant="error">
            {t('createQuote.error.title')}
            <br />
            <strong>{t(`createQuote.error.exceptions.${quoteError}`)}</strong>
          </Message>
          <div className="flex max-w-[225px]">
            <Button type="button" variant="primary" onClick={refetchQuote}>
              {t('drawerTargetPrice.quote.refetch')}
            </Button>
          </div>
        </div>
      )}

      {!isLoading && quoteData && (
        <div className="flex flex-col gap-y-3 fade-in max-w-fit">
          <span className="text-ppa/subtitle text-xs font-light flex items-start fade-in">
            {t('drawerTargetPrice.quote.infoLabel')}:&nbsp;
            {now.format(dateFormats.userReadableFullDate)}
          </span>

          <div className="flex flex-col gap-y-5 pl-3 border-l border-ppa/grayBorder max-w-fit">
            <div
              className={classNames(
                'flex items-center text-sm text-ppa/title border-b',
              )}
            >
              <span className="py-4 px-2 flex-1 font-normal">
                {t('drawerTargetPrice.quote.card.summary.powerPrice')}:
              </span>
              <span className="py-4 px-2 flex-1 font-medium text-center">
                {wholesale}&nbsp;£/MWh
              </span>
            </div>

            <nav className="flex items-start bg-ppa/grayBackground p-1 rounded">
              <NavButton
                onClick={() => setCurrentPage(0)}
                text={t('drawerTargetPrice.quote.card.titles.embeddedBenefits')}
                isActive={currentPage === 0}
              />
              <div className="h-4 w-[2px] bg-ppa/grayBorder my-auto" />
              <NavButton
                onClick={() => setCurrentPage(1)}
                text={t('drawerTargetPrice.quote.card.titles.charges')}
                isActive={currentPage === 1}
              />
              <div className="h-4 w-[2px] bg-ppa/grayBorder my-auto" />
              <NavButton
                onClick={() => setCurrentPage(2)}
                text={t('drawerTargetPrice.quote.card.titles.subsidies')}
                isActive={currentPage === 2}
              />
              <div className="h-4 w-[2px] bg-ppa/grayBorder my-auto" />
              <NavButton
                onClick={() => setCurrentPage(3)}
                text={t(
                  'drawerTargetPrice.quote.card.titles.otherRequirements',
                )}
                isActive={currentPage === 3}
              />
            </nav>

            <article className="flex">{cardPages[currentPage]}</article>
          </div>

          <div className="flex flex-col text-sm">
            <div className="flex flex-col">
              <div
                className={classNames(
                  'flex items-center text-sm text-ppa/title',
                  'border-b-2 border-ppa/grayBorder',
                )}
              >
                <span className="py-4 px-2 flex-1 font-normal">
                  {t('drawerTargetPrice.quote.card.summary.total')}:
                </span>
                <span className="py-4 px-2 flex-1 font-medium text-center">
                  {quoteData.averaged.summary.finalPrice} £/MWh
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Quote
