import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import {
  parseContractDuration,
  capitalizeText,
} from '../../../../../../../utils'

import { dateFormats } from '../../../../../../../utils/data'

import { ConsumerTender, Site } from '../../../../../../../types'

import Button from '../../../../../../../components/atoms/button'
import Modal from '../../../../../../../components/molecules/modal'
import DefList from '../../../../../../../components/atoms/def-list'
import Message from '../../../../../../../components/atoms/message'

interface CreateTenderModalProps {
  isOpen: boolean
  onClose: () => void
  tenderData: ConsumerTender
  siteData: Site
  onCreateTender: (tender: ConsumerTender) => void
}

const CreateTenderModal: React.FC<CreateTenderModalProps> = ({
  isOpen,
  onClose,
  tenderData,
  siteData,
  onCreateTender,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.createTenderModal',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const rowKeysTender = useMemo(() => {
    return [
      {
        keyName: 'contractDuration',
        title: t('tenderList.titles.contractDuration'),
        renderCustomEl: (item: ConsumerTender) => {
          return parseContractDuration(item.startDate, item.endDate)
        },
      },
      {
        keyName: 'deadline',
        title: t('tenderList.titles.deadline'),
        renderCustomEl: (item: ConsumerTender) => {
          if (!item?.deadline) return undefined
          return dayjs
            .utc(item.deadline)
            .tz('Europe/London')
            .format(dateFormats.userDateTime)
        },
      },
      {
        keyName: 'greenTariff',
        title: t('tenderList.titles.greenCertificates.title'),
        renderCustomEl: (item: ConsumerTender) => {
          const baseCertificates = t('tenderList.titles.greenCertificates.roc')
          const greenCertificate = t('tenderList.titles.greenCertificates.rego')

          if (item.greenTariff) {
            return `${baseCertificates}, ${greenCertificate}`
          }

          return baseCertificates
        },
      },
    ]
  }, [tenderData])

  const rowKeysSiteDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('siteList.titles.name'),
        renderCustomEl: (site: Site) => {
          return site.name
        },
      },
      {
        keyName: 'technology',
        title: t('siteList.titles.technology'),
        renderCustomEl: (site: Site) => {
          const technology = site?.technology
          if (!technology) return undefined
          return capitalizeText(tUtils(`technology.${technology}.abbreviation`))
        },
      },
      {
        keyName: 'capacity',
        title: t('siteList.titles.capacity'),
        suffix: 'MW',
        renderCustomEl: (site: Site) => {
          if (!site.capacity) return undefined
          return site.capacity
        },
      },
      {
        keyName: 'voltage',
        title: t('siteList.titles.voltage'),
        suffix: 'kV',
        renderCustomEl: (site: Site) => {
          if (!site.voltage) return undefined
          return site.voltage
        },
      },
      {
        keyName: 'mpan',
        title: t('siteList.titles.mpan'),
        renderCustomEl: (site: Site) => {
          if (!site.mpan) return undefined
          return site.mpan
        },
      },
      {
        keyName: 'msid',
        title: t('siteList.titles.msid'),
        renderCustomEl: (site: Site) => {
          if (!site.msid) return undefined
          return site.msid
        },
      },
      {
        keyName: 'rocBand',
        title: t('siteList.titles.rocBand'),
        renderCustomEl: (site: Site) => {
          if (!site.rocBand) return undefined
          return site.rocBand
        },
      },
      {
        keyName: 'company',
        title: t('siteList.titles.company.name'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.name
        },
      },
      {
        keyName: 'number',
        title: t('siteList.titles.company.number'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return site.company.number
        },
      },
      {
        keyName: 'location',
        title: t('siteList.titles.location'),
        renderCustomEl: (site: Site) => {
          if (!site.location) return undefined
          return site.location
        },
      },
      {
        keyName: 'brokerFee',
        title: t('siteList.titles.brokerFee'),
        renderCustomEl: (site: Site) => {
          if (!site.brokerFee) return undefined
          return site.brokerFee
        },
      },
    ]
  }, [siteData])

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <div className="w-[550px] mx-8 max-h-[90vh] overflow-y-auto">
        <h1 className="text-xl text-ppa/title font-bold mt-8 mb-4">
          {t('title')}
        </h1>
        <Message variant="info" size="text-sm">
          {t('infoMessage')}
        </Message>
        <div className="flex flex-col gap-y-8">
          <div className="mt-5">
            <h2 className="text-lg text-ppa/title font-semibold">
              {t('tenderList.mainTile')}
            </h2>
            <DefList rowKeys={rowKeysTender} data={tenderData} />
          </div>

          <div>
            <h2 className="text-lg text-ppa/title font-semibold">
              {t('siteList.mainTile')}
            </h2>
            <DefList rowKeys={rowKeysSiteDetails} data={siteData} />
          </div>

          <div className="flex justify-end gap-x-5 mt-2 mb-8 w-full">
            <div className="w-24">
              <Button variant="secondary" onClick={onClose} fullWidth>
                {t('buttons.close')}
              </Button>
            </div>
            <div className="w-24">
              <Button
                variant="primary"
                onClick={() => onCreateTender(tenderData)}
                fullWidth
              >
                {t('buttons.create')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CreateTenderModal
