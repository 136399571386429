import React from 'react'
import { useTranslation } from 'react-i18next'

export interface RequiredFieldProps {
  label?: string
}

const RequiredField: React.FC<RequiredFieldProps> = ({ label }) => {
  const { t: tUtils } = useTranslation('private/index', { keyPrefix: 'utils' })

  return (
    <span className="text-ppa/grayText text-xs font-normal">
      <span className="text-ppa/formStar mr-1">*</span>
      {label || tUtils('requiredField')}
    </span>
  )
}

export default RequiredField
