import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import { TenderStatusProps } from '../details'

import TenderDetails, { ButtonList } from '../components/details'

import BidsTable, { Bid } from '../../../../../components/organisms/bids-table'

const TendersUnderOfferDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.tenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoadingTenderAction, setIsLoadingTenderAction] = useState(false)

  const bidGetList = usePPAGetList<Bid>({
    dataKey: 'bids',
    path: `/core/private/bid/by-admin/${tenderDetails.id}`,
  })

  const handleExecuteTender = async () => {
    if (isLoadingTenderAction) return
    setIsLoadingTenderAction(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/tender/execute/${tenderDetails.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleExecuteTender.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsLoadingTenderAction(false)
    }
  }

  const handleRevertTender = async () => {
    if (isLoadingTenderAction) return
    setIsLoadingTenderAction(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/tender/revert/${tenderDetails.id}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleRevertTender.success.description'),
        },
        'success',
      )
      refetch()
    } catch (err: any) {
      throwFeedbackError({
        err,
        context: 'tender',
      })
    } finally {
      setIsLoadingTenderAction(false)
    }
  }

  const buttons = useMemo((): ButtonList[] | undefined => {
    if (!tenderDetails) return undefined

    return [
      {
        props: { variant: 'secondary' },
        onClick: () => handleRevertTender(),
        text: t('buttons.revert'),
      },
      {
        props: { variant: 'primary' },
        onClick: () => handleExecuteTender(),
        text: t('buttons.execute'),
      },
    ]
  }, [tenderDetails])

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} buttons={buttons} />
      <BidsTable
        tender={tenderDetails}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
        enableSelectingBids
      />
    </section>
  )
}

export default TendersUnderOfferDetails
