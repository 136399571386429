import React from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { breakPointsContainer } from '../../../styles'

import PageTitle from '../../../components/atoms/page-title'

import MarketSummary from './components/market-summary'
import DailyReport from './components/daily-report'

import ForecastPower from './components/forecast/power'
import ForecastCoal from './components/forecast/coal'
import ForecastGas from './components/forecast/gas'

import Advertisement from './components/advertisement'
import { isFeatureEnabled } from '../../../utils/features'

const Market: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'market',
  })

  return (
    <section
      className={classNames(
        breakPointsContainer,
        'flex flex-col w-full gap-y-8',
      )}
    >
      <PageTitle title={t('label')} />
      <MarketSummary />
      {/* <DailyReport /> */}
      <ForecastPower />
      <ForecastGas />
      {/* <ForecastCoal /> */}
    </section>
  )
}

export default Market
