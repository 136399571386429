import { useState } from 'react'
import classNames from 'classnames'
import { transition } from '../../../styles/index'

interface VerticalTabItem {
  label: string
  content?: any
}

interface VerticalTabProps {
  header: string
  tabs: VerticalTabItem[]
}

const VerticalTab: React.FC<VerticalTabProps> = ({ tabs, header }) => {
  const [selectedTabItem, setSelectedTabItem] = useState(0)

  return (
    <div className="pl-5">
      <h1 className="mt-11 mb-12 font-semibold text-3xl text-ppa/title">
        {header}
      </h1>
      <div className="md:flex">
        <nav className="block pl-1.5 pr-24 bg-transparent max-w-60 sticky top-0">
          {tabs.map((tab, index) => (
            <button
              key={tab.label}
              type="button"
              className={classNames(
                'block font-medium text-xl text-left py-2',
                transition,
                selectedTabItem === index
                  ? 'text-ppa/primary'
                  : 'text-ppa/subtitle',
              )}
              onClick={() => setSelectedTabItem(index)}
            >
              {tab.label}
            </button>
          ))}
        </nav>
        <section className="mt-10 md:border-l md:border-ppa/grayText md:mt-0">
          {tabs.map((tabContent, tabContentIndex) => (
            <article
              // eslint-disable-next-line react/no-array-index-key
              key={tabContentIndex}
              className={classNames(
                transition,
                'pt-1.5 md:pl-6',
                selectedTabItem === tabContentIndex ? 'block' : 'hidden',
              )}
            >
              {tabContent.content}
            </article>
          ))}
        </section>
      </div>
    </div>
  )
}

export default VerticalTab
