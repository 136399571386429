import Sites from '.'
import SiteDetails from './details'

import SitesCompanyList from './company-list'
import SiteCompanyDetails from './company-list/details'

import { isFeatureEnabled } from '../../../../utils/features'

export const sitesLinks = [
  { to: '/portfolio', label: 'sitesOptions.portfolio' },
]

if (isFeatureEnabled('companiesDetailsPage')) {
  sitesLinks.push({ to: '/companies', label: 'sitesOptions.companiesList' })
}

export const sitesSubRoutes = {
  portfolio: {
    index: <Sites />,
    details: <SiteDetails />,
  },
  ...(isFeatureEnabled('companiesDetailsPage')
    ? {
        companies: {
          index: <SitesCompanyList />,
          details: <SiteCompanyDetails />,
        },
      }
    : {}),
}
