import React from 'react'
import { tendersSubRoutes } from './routes'

interface TendersProps {
  status: keyof typeof tendersSubRoutes
}

const Tenders: React.FC<TendersProps> = ({ status }) => {
  return tendersSubRoutes[status].index
}

export default Tenders
