import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './styles/index'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

/**
 * Using <React.StrictMode /> can cause double renderings and performance gap,
 * keep in mind that this tool it's useful but impact performance in dev environment.
 * @see https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
 * @see https://reactjs.org/docs/strict-mode.html
 */
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
)
