import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import RequiredField from '../../../../../../components/atoms/required-form-field'
import InputGroup from '../../../../../../components/molecules/input-group'
import InputFile from '../../../../../../components/atoms/input-file'
import Button from '../../../../../../components/atoms/button'
import Input from '../../../../../../components/atoms/input'
import SelectComponent, {
  SelectOptions,
} from '../../../../../../components/atoms/select'

type FormValues = {
  month?: string
  year?: SelectOptions
  billFile?: File
}

interface BillFormProps {
  onSubmit: (data: any) => void
  onSkip?: () => void
  onPreviousStep?: () => void
  isLoading?: boolean
}
export type BillFormRef = {
  resetForm: () => void
}

const FormBill: React.FC<BillFormProps> = ({
  onSubmit,
  isLoading,
  onSkip,
  onPreviousStep,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.billDrawer',
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      month: '',
      year: undefined,
      billFile: undefined,
    },
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-5 min-w-[510px]"
    >
      <div className="flex flex-col md:flex-row gap-5">
        <Controller
          name="month"
          control={control}
          rules={{
            required: { value: true, message: t('month.required') },
          }}
          render={({ field: props }) => (
            <InputGroup
              label={t('month.title')}
              error={errors.month?.message}
              required
            >
              <Input
                {...props}
                placeholder={t('month.placeholder')}
                error={errors.month?.message}
              />
            </InputGroup>
          )}
        />
        <Controller
          name="year"
          control={control}
          rules={{
            required: { value: true, message: t('year.required') },
          }}
          render={({ field: props }) => (
            <InputGroup
              label={t('year.title')}
              error={errors.year?.message}
              required
            >
              <SelectComponent
                {...props}
                type="single"
                options={[
                  {
                    value: '2024',
                    label: '2024',
                  },
                  {
                    value: '2023',
                    label: '2023',
                  },
                  {
                    value: '2022',
                    label: '2022',
                  },
                ]}
                placeholder={t('year.placeholder')}
                error={errors.year?.message}
              />
            </InputGroup>
          )}
        />
      </div>

      <Controller
        name="billFile"
        control={control}
        rules={{
          required: { value: true, message: t('file.required') },
        }}
        render={({ field: { name } }) => (
          <InputGroup
            label={t('file.title')}
            error={errors.billFile?.message}
            infoLabel={t('file.infoLabel')}
            required
          >
            <InputFile
              name={name}
              setValue={setValue}
              error={errors.billFile?.message}
              acceptFiles="dataText"
            />
          </InputGroup>
        )}
      />

      <div>
        <RequiredField />
      </div>

      <div className="flex items-center justify-end gap-x-5">
        {onPreviousStep && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              variant="tertiary"
              onClick={onPreviousStep}
              fullWidth
              disabled={isLoading}
            >
              {t('back')}
            </Button>
          </div>
        )}
        {onSkip && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              variant="secondary"
              onClick={onSkip}
              fullWidth
              disabled={isLoading}
            >
              {t('skip')}
            </Button>
          </div>
        )}
        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default FormBill
