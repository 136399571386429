import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { ChevronLeftIcon } from '@heroicons/react/outline'

import useUser from '../../../../../../hooks/use-user'

import { container, breakPointsContainer } from '../../../../../../styles'

import { capitalizeText } from '../../../../../../utils'

import { mappedTenderStatus } from '../../../types'

import { TenderStatus, SupplierTender, Member } from '../../../../../../types'

import { ReactComponent as ActAsIcon } from '../../../assets/actAsIcon.svg'

import TenderPill from '../../../../components/tender-pill'
import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import SleevedTenderDetailsDefList from '../details-tender'
import SiteDetailsDefList from '../details-site'

export type Tender = SupplierTender & {
  generator: Generator & { members: Member[] }
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

interface TenderDetailsSleevedProps {
  tender: Tender
  buttons?: ButtonList[]
}

const SleevedTenderDetails: React.FC<TenderDetailsSleevedProps> = ({
  tender,
  buttons,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.sleevedTenders.details',
  })

  const navigate = useNavigate()

  const { actAs } = useUser()

  const handleActAs = () => {
    if (tender?.generator === undefined) return
    if (tender?.generator.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: t('handleActAs.warn.title'),
          description: t('handleActAs.warn.description'),
        },
        'warn',
      )
      return
    }

    const { name, status, brokerContextName, members } = tender.generator

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id: tender.generatorId,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'GENERATOR',
        status,
        userId: filteredMembers[0].userId,
        brokerContextName,
      },
    })

    navigate('/sites')
  }

  const rowKeysGeneratorDetails = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('defList.titles.generator'),
        renderCustomEl: (item: Tender['generator']) => {
          return item.name
        },
      },
    ]
  }, [tender])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/sleevedTenders/status/${
                mappedTenderStatus[tender.status as TenderStatus]
              }`,
            )
          }
        >
          {t('backButton', {
            status: capitalizeText(tender.status as TenderStatus),
          })}
        </Button>
      </div>

      <div className="flex flex-col gap-y-8 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start">
            <h1 className="text-3xl font-semibold text-ppa/title mr-5 w-full">
              {tender.shortId}
            </h1>
            <TenderPill status={tender.status as any} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-3 2xl:flex-row">
        <div className="2xl:w-1/2">
          <div className="flex flex-col gap-y-3">
            <div className="flex justify-start items-center gap-x-8">
              <h3 className="text-lg font-semibold text-ppa/title">
                {t('defList.generatorTitle')}
              </h3>
              <Button
                variant="primary"
                icon={<ActAsIcon />}
                onClick={handleActAs}
              >
                {t('buttons.actAs')}
              </Button>
            </div>
            <DefList
              rowKeys={rowKeysGeneratorDetails}
              data={tender.generator}
            />
            <SiteDetailsDefList data={tender.site} />
          </div>
        </div>

        <div
          className={classNames(
            '2xl:w-1/2',
            'border-ppa/grayBorder',
            'border-t-0 2xl:border-l 2xl:mt-10 2xl:ml-10 2xl:pl-10',
          )}
        >
          <SleevedTenderDetailsDefList data={tender} />
        </div>
      </div>

      {buttons && (
        <div className="flex items-center justify-start gap-x-5 w-full mt-5">
          {buttons.map((item) => (
            <Button
              {...item.props}
              key={item.text}
              onClick={() => item.onClick()}
            >
              {item.text}
            </Button>
          ))}
        </div>
      )}
    </section>
  )
}

export default SleevedTenderDetails
