import axios, { AxiosResponse, AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import { useAuth0 } from '@auth0/auth0-react'

import { feedbackMessage, ToastType } from '../../components/atoms/feedback'
import useAuth from '../use-user'

import {
  AxiosExtensionError,
  AxiosExtensionErrorExtract,
  ErrorNames,
} from '../../types/errors'

interface usePPAProps {
  url: string
  method: 'POST' | 'PUT' | 'PATCH' | 'GET' | 'DELETE'
  body?: any
  headers?: Record<string, any>
  responseType?: any
}

export interface PPAError {
  errorType: string
  message: string
}

type FetchDataResult<ResponseData = Record<any, any>> = Promise<
  | { response: AxiosResponse<ResponseData>; error: undefined }
  | { response: undefined; error: PPAError | AxiosError | string }
>

type ThrowFeedbackErrorProps = {
  err?: unknown
  context?: string
  duration?: number
} & {
  [key in ErrorNames]?: (props: AxiosExtensionErrorExtract<key>) =>
    | {
        title: string
        description: string
        type: ToastType
        duration?: number
      }
    | undefined
}

export type ThrowFeedbackErrorFn = (props?: ThrowFeedbackErrorProps) => void

type ProcessCustomErrorsProps = {
  errorProps: ThrowFeedbackErrorProps
  axiosResponse: AxiosExtensionError
}

type ProcessCustomErrorsFn = (props: ProcessCustomErrorsProps) => {
  title: string
  description: string
  type: ToastType
  duration?: number
}

interface usePPAData {
  fetchData: <ResponseData = Record<any, any>>(
    props: usePPAProps,
  ) => FetchDataResult<ResponseData>
  throwFeedbackError: ThrowFeedbackErrorFn
}

const baseURL = process.env.REACT_APP_PPAYA_BASE_URL

const usePPA = (): usePPAData => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { logout, getIdTokenClaims } = useAuth0()
  const { isReady, user } = useAuth<
    'GENERATOR' | 'SUPPLIER' | 'CONSUMER' | 'ADMIN'
  >()

  const getActingAs = (): Record<string, string> => {
    if (!isReady || !user) return {}

    if (
      (user.appMetadata.userType === 'GENERATOR' ||
        user.appMetadata.userType === 'SUPPLIER' ||
        user.appMetadata.userType === 'CONSUMER') &&
      user.adminActing?.id &&
      user.adminActing?.userId
    ) {
      return {
        actingas: user.appMetadata.userType,
        actingasid: user.appMetadata.id,
      }
    }

    return {}
  }

  const getToken = async (): Promise<string | undefined> => {
    try {
      const token = await getIdTokenClaims()
      // eslint-disable-next-line no-underscore-dangle
      return token?.__raw
    } catch (err: any) {
      console.error('Error on validating the token.', err)
      /**
       * @FIXME
       * @CHECK this.
       * Might send two feedback messages later on.
       */
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: tUtils('feedbackMessage.error.description'),
        },
        'error',
      )
      logout({
        logoutParams: {
          returnTo: window.location.origin,
          federated: false,
        },
      })
    }
    return undefined
  }

  const fetchData = async <ResponseData = Record<any, any>,>({
    method,
    url,
    body,
    headers,
  }: usePPAProps): FetchDataResult<ResponseData> => {
    const token = await getToken()

    if (!token)
      return {
        error: {
          errorType: 'FRONTEND_AUTHORIZATION_ERROR',
          message: 'Token was not found',
        },
        response: undefined,
      }

    const actingAsHeaders = getActingAs()

    try {
      const response: AxiosResponse<ResponseData> = await axios({
        baseURL,
        url: `/api${url}`,
        method,
        data: body || undefined,
        headers: {
          'Content-type': 'application/json',
          ...headers,
          authorization: `Bearer ${token}`,
          Accept: '*/*',
          ...actingAsHeaders,
        },
      })

      return { response, error: undefined }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const errResp = error?.response?.data
        if (
          errResp?.errorName === 'AUTHORIZATION_ERROR' &&
          errResp?.reason === 'Invalid token/Expired token'
        ) {
          console.error('AUTHORIZATION_ERROR', error)
          logout({
            logoutParams: {
              returnTo: window.location.origin,
              federated: false,
            },
          })
        }
      }
      return { error, response: undefined }
    }
  }

  const throwFeedbackError: ThrowFeedbackErrorFn = (props) => {
    const defaultResult: ReturnType<ProcessCustomErrorsFn> = {
      title: tUtils('feedbackMessage.error.title'),
      description: tUtils('feedbackMessage.error.description'),
      type: 'error',
      duration: props?.duration,
    }

    const processCustomErrors: ProcessCustomErrorsFn = ({
      errorProps,
      axiosResponse,
    }) => {
      try {
        const { errorName, httpCode, message, metadata } = axiosResponse
        if (errorName === 'PAYLOAD_ERROR') {
          if (
            message.includes('is not allowed') &&
            !message.includes('is not allowed to be empty')
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.PAYLOAD_ERROR.IS_NOT_ALLOWED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.PAYLOAD_ERROR.IS_NOT_ALLOWED.description',
              ),
              type: 'error',
            }
          }
        }

        const customErrorFn = errorProps[errorName]
        if (customErrorFn) {
          const customErrorResult = customErrorFn(axiosResponse as any)
          if (customErrorResult) {
            return {
              title: customErrorResult.title,
              description: customErrorResult.description,
              type: customErrorResult.type,
              duration: customErrorResult?.duration,
            }
          }
        }

        if (errorName === 'PAYLOAD_ERROR') {
          const basePathT = `throwFeedbackError.errorCodes.${errorProps.context}`
          const result = defaultResult
          result.title = tUtils(`${basePathT}.PAYLOAD_ERROR.title`)

          result.description = metadata
            .map((item) => {
              const keyTranslation = tUtils(`${basePathT}.keys.${item.key}`)

              if (keyTranslation.includes(`${basePathT}.keys.${item.key}`)) {
                return ''
              }

              const reasonTranslation = tUtils(
                `${basePathT}.${errorName}.reason.${item.reason}`,
              )

              if (
                reasonTranslation.includes(
                  `${basePathT}.${errorName}.reason.${item.reason}`,
                )
              ) {
                return ''
              }
              return `${keyTranslation} ${reasonTranslation}`
            })
            .filter((item) => item)
            .join('\n')

          return result
        }

        if (errorName === 'ERR_BUSINESS_LOGIC') {
          const basePathT = `throwFeedbackError.errorCodes.${errorProps.context}`
          const result = defaultResult
          result.title = tUtils(`${basePathT}.ERR_BUSINESS_LOGIC.title`)

          const uniqueReasons: { [key: string]: boolean } = {}

          result.description = metadata
            .map((item) => {
              const reasonTranslation = tUtils(
                `${basePathT}.${errorName}.reason.${item.reason}`,
              )

              if (!uniqueReasons[reasonTranslation]) {
                uniqueReasons[reasonTranslation] = true
                return reasonTranslation
              }

              return ''
            })
            .filter((item) => item)
            .join('\n')

          return result
        }

        if (errorName === 'ERR_DB_STATE') {
          const basePathT = `throwFeedbackError.errorCodes.${errorProps.context}`
          const result = defaultResult
          result.title = defaultResult.title

          const uniqueReasons: { [key: string]: boolean } = {}

          result.description = metadata
            .map((item) => {
              const reasonTranslation = tUtils(
                `${basePathT}.${errorName}.reason.${item.reason}`,
              )

              if (!uniqueReasons[reasonTranslation]) {
                uniqueReasons[reasonTranslation] = true
                return reasonTranslation
              }

              return ''
            })
            .filter((item) => item)
            .join('\n')

          return result
        }

        return defaultResult
      } catch (error) {
        return defaultResult
      }
    }

    const result = defaultResult

    if (props?.err && props.err instanceof AxiosError) {
      const axiosError = props.err as AxiosError<AxiosExtensionError>
      if (axiosError?.response && props?.context) {
        const axiosResponse = axiosError.response.data

        const processedCustomError = processCustomErrors({
          errorProps: props,
          axiosResponse,
        })
        result.title = processedCustomError.title
        result.description = processedCustomError.description
        result.type = processedCustomError.type
        result.duration = processedCustomError?.duration || props?.duration
      }
    }

    feedbackMessage(
      { title: result.title, description: result.description },
      result.type,
      result?.duration,
    )
  }

  return { throwFeedbackError, fetchData }
}

export default usePPA
