import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import { XIcon } from '@heroicons/react/outline'

import useUser from '../../../../../../hooks/use-user'

import { QueryFilter } from '../../../hooks/use-ppa-get-list-admins'

import Filter from '../../../../../../components/molecules/filter'
import Search from '../../../../../../components/atoms/search'

import SelectComponent, {
  SelectOptions,
} from '../../../../../../components/atoms/select'
import Button from '../../../../../../components/atoms/button'

import { UserStatus } from '../../../../../../types/user'

export type FilterFormValues = {
  status?: SelectOptions<UserStatus>
}

export interface FilterSitesProps {
  onSubmit: (data: QueryFilter) => void
  cleanFilters: () => void
}

const statusOptionsList: Array<SelectOptions<UserStatus>> = [
  { label: 'onboarding', value: 'ONBOARDING' },
  { label: 'review', value: 'REVIEW' },
  { label: 'active', value: 'ACTIVE' },
]

const SearchFilter: React.FC<FilterSitesProps> = ({
  onSubmit,
  cleanFilters,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.suppliers.page.filters',
  })

  const { t: tUtils } = useTranslation('private/index', { keyPrefix: 'utils' })

  const { user } = useUser()

  const statusOptions = useMemo((): Array<SelectOptions<UserStatus>> => {
    let result = statusOptionsList.map((item) => {
      return {
        value: item.value,
        label: tUtils(`status.user.${item.value}`),
      }
    })

    if (user.appMetadata.brokerContextName !== 'PPAYA') {
      result = result.filter((item) => item.value === 'ACTIVE')
    }

    return result
  }, [tUtils])

  const [searchText, setSearchText] = useState<string | undefined>(undefined)

  const [filtersCount, setFiltersCount] = useState<number>(0)

  const { control, handleSubmit, reset, getValues } = useForm<FilterFormValues>(
    {
      defaultValues: {
        status: undefined,
      },
    },
  )

  /**
   * @TODO re-work filters and search later to the new filter logic when BE is ready
   */

  const handleSubmitForm = (data: FilterFormValues & { search?: string }) => {
    const result: QueryFilter = []

    if (data?.search) {
      result.push({ field: 'search', operator: 'contains', value: data.search })
    }

    if (data?.status?.value) {
      result.push({
        field: 'status',
        operator: 'in',
        value: [data.status.value],
      })
    }

    const numberOfFiltersToApply = result.filter(
      (item) => item.field === 'status',
    )

    onSubmit(result)
    setFiltersCount(numberOfFiltersToApply.length)
  }
  const handleSearchChange = async (newSearch?: string) => {
    const parsedSearch = newSearch?.trim().toLowerCase() || ''
    setSearchText(parsedSearch)

    if (filtersCount >= 1) {
      handleSubmitForm({
        ...getValues(),
        search: parsedSearch,
      })
    } else {
      handleSubmitForm({
        search: parsedSearch,
      })
      setFiltersCount(0)
    }
  }

  return (
    <div className="flex">
      <Search onSearchChange={handleSearchChange} />
      {filtersCount > 0 && (
        <button
          type="button"
          onClick={() => {
            reset({ status: undefined })
            cleanFilters()
            setFiltersCount(0)
          }}
          className="flex items-center text-ppa/placeholder text-xs font-normal gap-x-1.5 mr-2 w-24"
        >
          <XIcon className="w-3 h-3" />
          {t('clearAll')}
        </button>
      )}
      <Filter numFiltersApplied={filtersCount}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit((data) => {
              handleSubmitForm({ ...data, search: searchText })
            })()
          }}
          className="min-w-[270px] max-w-[300px]"
        >
          <div className="flex flex-col overflow-visible gap-y-3.5 mt-5 mb-3.5">
            <div className="flex flex-col gap-y-3 min-w-[270px]">
              <span className="text-sm text-ppa/title font-normal">
                {t('status.title')}
              </span>
              <Controller
                name="status"
                control={control}
                render={({ field: { ref, value, ...props } }) => (
                  <SelectComponent
                    options={statusOptions}
                    placeholder={t('status.placeholder')}
                    value={value}
                    {...props}
                    testId="filter-status"
                  />
                )}
              />
            </div>

            <div className="mt-2">
              <Button type="submit" variant="primary" fullWidth>
                {t('applyFilters')}
              </Button>
            </div>
          </div>
        </form>
      </Filter>
    </div>
  )
}

export default SearchFilter
