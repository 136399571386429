import React, { useMemo } from 'react'

import usePPAGetList from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import Select, {
  SelectProps,
  SelectOptions,
} from '../../../../../../components/atoms/select'

import { SupplierWithProfile } from '../../../../../../types'

const SelectSuppliers: React.FC<
  Omit<SelectProps, 'type' | 'onCreateOption' | 'options'>
> = ({ value, ...props }) => {
  const { isLoading, data, error } = usePPAGetList<SupplierWithProfile>({
    dataKey: 'suppliers',
    path: '/core/private/consumer-tender/consumer/participating-suppliers',
    pagination: {
      limit: 50,
      offset: 0,
    },
  })

  const selectedSuppliers = value || []

  const options = useMemo((): SelectOptions[] => {
    if (isLoading || error || !data || data.length === 0) return []

    const selectedValues = selectedSuppliers.map((selected: SelectOptions) =>
      JSON.parse(selected.value),
    )
    return data
      .filter(
        (supplier) =>
          !selectedValues.some(
            (selected: any) => selected.name === supplier.name,
          ),
      )
      .map((supplier) => ({
        value: JSON.stringify(supplier),
        label: supplier.name,
      }))
  }, [isLoading, error, data, selectedSuppliers])

  return (
    <Select
      {...props}
      options={options}
      value={value}
      type="multi"
      testId="select-suppliers"
    />
  )
}

export default SelectSuppliers
