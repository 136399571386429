import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

import classNames from 'classnames'

import { ExclamationIcon, ChevronRightIcon } from '@heroicons/react/outline'

import { ReactComponent as DoneIcon } from './assets/doneIcon.svg'
import { ReactComponent as ReloadIcon } from './assets/reload.svg'

import Checkbox from '../../atoms/checkbox'
import Button, { Props as ButtonProps } from '../../atoms/button'
import Message from '../../atoms/message'
import { feedbackMessage } from '../../atoms/feedback'

import {
  EmbeddedBenefitName,
  EmbeddedBenefitType,
  SubsidyName,
  SubsidyType,
  ChargeName,
  ChargeType,
  SupplierTenderBid,
  BidStatus,
  SupplierTender,
  ChargePowerPriceType,
  PowerPriceType,
  PriceTypeSupplierBid,
} from '../../../types'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'
import { transition } from '../../../styles'

dayjs.extend(timezone)

interface ParsedBid {
  id: SupplierTenderBid['id']
  supplierId: string
  contractTypeName: PriceTypeSupplierBid
  forecastMatch: string
  embeddedBenefits: Omit<Record<EmbeddedBenefitName, string>, 'BSUOS' | 'OTHER'>
  subsidies: Omit<Record<SubsidyName, string>, 'ROC' | 'FIT_EXPORT'>
  charges: Record<ChargeName, string>
  expiresAt: SupplierTenderBid['expiresAt']
  rank: SupplierTenderBid['rank']
  rankWithEstimations: SupplierTenderBid['rankWithEstimations']
  status: SupplierTenderBid['status']
  supplierTenderInvitation: {
    supplier: {
      name: string
    }
  }
  supplierTenderBidPowerPrices: {
    id: string
    toggled: boolean
    name:
      | 'MATCH_AND_UNMATCHED'
      | 'MATCHED'
      | 'UNMATCHED'
      | 'FIXED'
      | 'PASSTHROUGH'
    type: string
    value: string
    supplierTenderBidId: string
    createdAt: string
  }[]
}

interface ParsedBidMemo {
  bids: ParsedBid[]
  status: BidStatus[]
}

/**
 * 30 minutes in milliseconds = 1.800.000
 */
const warnMinutesValue = 1800000

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

const parseTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  seconds %= 60
  minutes %= 60

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`
}

const formatContractType = (type: PriceTypeSupplierBid | undefined): string => {
  if (!type) return '-'

  return type
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const ExpireTime: React.FC<{ expireAt: string }> = ({ expireAt }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details.bids.table',
  })

  const expireAtDate = dayjs(expireAt).tz('Europe/London')
  const [countDown, setCountDown] = useState(
    expireAtDate.valueOf() - dayjs().tz('Europe/London').valueOf(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(
        expireAtDate.valueOf() - dayjs().tz('Europe/London').valueOf(),
      )
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <ExclamationIcon
        className={classNames(
          transition,
          'w-5 h-5 mr-2 text-ppa/yellowWarning',
          countDown <= 0 && 'text-ppa/redError',
          countDown < warnMinutesValue ? 'opacity-1' : 'opacity-0',
        )}
      />

      {countDown <= 0 ? t('expired') : parseTime(countDown)}
    </>
  )
}

const defaultStyles = {
  eachRow:
    'flex min-h-[45px] min-w-[235px] text-ppa/grayText border-b border-b-ppa/grayBorder bg-white',
  titleRow: 'bg-ppa/grayBackground',
  selectedColumn:
    'border-l-2 border-l-ppa/secondarySelected border-r-2 border-r-ppa/secondarySelected',
}

const mappedPriceTypes = {
  MWH: 'pricePerMWh',
  FIXED: 'pricePerMWh',
  FLEXIBLE: 'pricePercentage',
  MONTHLY: 'pricePerMonth',
  DAILY: 'pricePerDay',
}

interface ParseObjectToStringPayload {
  id: string
  name: string
  type: PowerPriceType | ChargePowerPriceType
  active: boolean
  order: number
  value?: number
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: (selectedBidId: string) => Promise<void>
}

interface TableBidsProps {
  tender?: SupplierTender
  bids?: SupplierTenderBid[]
  error?: any
  refetch: () => void
  isLoading?: boolean
  buttons?: ButtonList[]
  enableSelectingBids?: boolean
  disableExpired?: boolean
}

const SupplierTableBids: React.FC<TableBidsProps> = ({
  tender,
  bids,
  error,
  isLoading,
  refetch,
  buttons,
  enableSelectingBids = false,
  disableExpired = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details.bids.table',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const [selectedBids, setSelectedBids] = useState<string[]>([])

  const [collapseRows, setCollapseRows] = useState({
    subsidies: true,
    embeddedBenefits: true,
    charges: true,
  })

  const canSelectBids = useMemo(() => {
    return enableSelectingBids && buttons && buttons.length > 0
  }, [bids])

  const parsedBids = useMemo((): ParsedBidMemo | undefined => {
    const parseObjectToString = (
      object: ParseObjectToStringPayload,
      estimated?: number,
    ): string => {
      if (!object?.value) return `0.00 ${tUtils(mappedPriceTypes.FIXED)}`
      const value = Number(object.value).toFixed(2)

      if (object.type === 'FLEXIBLE') {
        if (!estimated) return `${value} ${tUtils(mappedPriceTypes.FLEXIBLE)}`

        const estimatedValue = Number(estimated).toFixed(2)
        return `${value} ${tUtils(
          mappedPriceTypes.FLEXIBLE,
        )} (${estimatedValue} ${tUtils(mappedPriceTypes.FIXED)} ${t(
          'estimation',
        )})`
      }

      return `${value} ${tUtils(mappedPriceTypes[object.type])}`
    }

    if (isLoading || error || !bids || !tender) return undefined

    let statusToSearch: BidStatus[] = ['ACTIVE']
    if (tender.status === 'UNDER_OFFER') {
      statusToSearch = ['ACCEPTED']
    } else if (tender.status === 'EXECUTED') {
      statusToSearch = ['EXECUTED', 'ACCEPTED', 'NOT_ACCEPTED']
    }

    const filteredBids = bids.filter((bid) =>
      statusToSearch.includes(bid.status),
    )

    if (filteredBids.length === 0) return undefined

    const mappedBids = filteredBids.map((bid) => {
      const {
        supplierTenderBidBenefits,
        supplierTenderBidGreenCertificates,
        supplierTenderBidCharges,
        contractTypeName,
        forecastMatch,
      } = bid

      const objectBenefits: Partial<
        Record<EmbeddedBenefitName, EmbeddedBenefitType>
      > = {}
      supplierTenderBidBenefits?.forEach((benefit) => {
        objectBenefits[benefit.name] = benefit
      })

      const newBenefits: ParsedBid['embeddedBenefits'] = {
        GDUOS: parseObjectToString(
          objectBenefits.GDUOS as EmbeddedBenefitType,
          bid.estimations?.gduos,
        ),
        DLOSS: parseObjectToString(
          objectBenefits.DLOSS as EmbeddedBenefitType,
          bid.estimations?.losses?.dloss,
        ),
        TLOSS: parseObjectToString(
          objectBenefits.TLOSS as EmbeddedBenefitType,
          bid.estimations?.losses?.tloss,
        ),
        RCRC: parseObjectToString(
          objectBenefits.RCRC as EmbeddedBenefitType,
          bid.estimations?.other.rcrc,
        ),
        ELEXON: parseObjectToString(
          objectBenefits.ELEXON as EmbeddedBenefitType,
          bid.estimations?.other.elexon,
        ),
        AAHEDC: parseObjectToString(
          objectBenefits.AAHEDC as EmbeddedBenefitType,
          bid.estimations?.other.aahedc,
        ),
      }

      const objectSubsidies: Partial<Record<SubsidyName, SubsidyType>> = {}
      supplierTenderBidGreenCertificates?.forEach((subsidy) => {
        objectSubsidies[subsidy.name] = subsidy
      })

      const newSubsidies: ParsedBid['subsidies'] = {
        REGO: parseObjectToString(
          objectSubsidies.REGO as SubsidyType,
          bid.estimations?.subsidies.rego,
        ),
        ROC_BUYOUT: parseObjectToString(
          objectSubsidies.ROC_BUYOUT as SubsidyType,
          bid.estimations?.subsidies.rocBuyout,
        ),
        ROC_RECYCLE: parseObjectToString(
          objectSubsidies.ROC_RECYCLE as SubsidyType,
          bid.estimations?.subsidies.rocRecycle,
        ),
        FIT: parseObjectToString(objectSubsidies.FIT as SubsidyType),
      }

      const objectCharges: Partial<Record<ChargeName, ChargeType>> = {}
      supplierTenderBidCharges?.forEach((charge) => {
        objectCharges[charge.name] = charge
      })

      const newCharges: ParsedBid['charges'] = {
        RISK_PREMIUM: parseObjectToString(
          objectCharges.RISK_PREMIUM as ChargeType,
        ),
        DA_DC: parseObjectToString(objectCharges.DA_DC as ChargeType),
        THIRD_PARTY: parseObjectToString(
          objectCharges.THIRD_PARTY as ChargeType,
        ),
        OTHER: parseObjectToString(objectCharges.OTHER as ChargeType),
        MANAGEMENT_FEE: parseObjectToString(
          objectCharges.MANAGEMENT_FEE as ChargeType,
        ),
        BROKER_FEE: parseObjectToString(objectCharges.BROKER_FEE as ChargeType),
      }

      return {
        ...bid,
        embeddedBenefits: newBenefits,
        subsidies: newSubsidies,
        charges: newCharges,
        contractTypeName: contractTypeName as PriceTypeSupplierBid,
        forecastMatch,
      }
    })

    return {
      bids: mappedBids,
      status: statusToSearch,
    }
  }, [bids, tender, isLoading, error])

  return (
    <div className="flex flex-col w-full min-w-[850px] overflow-y-hidden gap-y-6 pb-4">
      <div className="flex flex-col gap-y-3">
        <div className="flex">
          <h1 className="text-2xl font-medium text-ppa/title">{t('title')}</h1>
          <button
            type="button"
            onClick={() => {
              refetch()
              setSelectedBids([])
            }}
            className="ml-2"
          >
            <ReloadIcon />
          </button>
        </div>

        {tender && tender.status === 'ACTIVE' && (
          <Message variant="info" size="text-sm">
            {t('info.0')}
            <br />
            {t('info.1')}
          </Message>
        )}
      </div>

      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoading && !parsedBids && (
        <strong className="text-lg font-medium text-ppa/title ml-6">
          {t('noBidsFound')}
        </strong>
      )}

      {!isLoading && tender && parsedBids && (
        <div className="flex flex-col gap-y-5 fade-in">
          <section className="flex flex-col gap-y-5">
            <article className={classNames('flex overflow-x-auto')}>
              <div className="flex flex-col fixed z-50 bg-white w-[280px]">
                <div
                  className={classNames(
                    defaultStyles.eachRow,
                    defaultStyles.titleRow,
                  )}
                />
                {!disableExpired && (
                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'items-center justify-start',
                    )}
                  >
                    {t('bidsExpiresIn')}
                  </div>
                )}
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('contractType')}</span>
                </div>

                <div
                  className={classNames(
                    'text-ppa/grayText py-2 flex text-sm flex-col items-start justify-start gap-y-1 border-t border-t-ppa/grayBorder w-full',
                    parsedBids.bids.some((bid) =>
                      bid.supplierTenderBidPowerPrices.some(
                        (price) => price.name === 'UNMATCHED',
                      ),
                    )
                      ? 'h-24'
                      : 'h-13',
                  )}
                >
                  <span className="font-normal">{t('powerPrice.title')}</span>
                  <span className="font-light text-xs">
                    {t('powerPrice.smallTitle')}
                  </span>

                  {parsedBids.bids.some((bid) =>
                    bid.supplierTenderBidPowerPrices.some(
                      (price) => price.name === 'MATCHED',
                    ),
                  ) && (
                    <span className="font-light">
                      {t('powerPrice.matched')}
                    </span>
                  )}

                  {parsedBids.bids.some((bid) =>
                    bid.supplierTenderBidPowerPrices.some(
                      (price) => price.name === 'UNMATCHED',
                    ),
                  ) && (
                    <span className="font-light">
                      {t('powerPrice.unmatched')}
                    </span>
                  )}
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-sm font-normal flex items-center justify-start border-t border-t-ppa/grayBorder w-full h-12',
                  )}
                >
                  <span className="font-normal">{t('forecastMatch')}</span>
                </div>

                <div
                  className={classNames(
                    'text-ppa/grayText text-xs font-normal py-2',
                    'flex items-center justify-start gap-y-1',
                    'border-t border-t-ppa/grayBorder w-full',
                  )}
                >
                  <ChevronRightIcon
                    onClick={() =>
                      setCollapseRows({
                        ...collapseRows,
                        embeddedBenefits: !collapseRows.embeddedBenefits,
                      })
                    }
                    className={classNames(
                      transition,
                      'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                      collapseRows.embeddedBenefits && 'rotate-90',
                    )}
                  />
                  <span>{t('embeddedBenefits.title')}</span>
                </div>
                <div
                  className={classNames(
                    transition,
                    'text-ppa/grayText text-xs font-normal',
                    'flex flex-col justify-center items-start pl-8',
                    'gap-y-1 min-w-[235px]',
                    !collapseRows.embeddedBenefits &&
                      'max-h-[0px] invisible opacity-0',
                  )}
                >
                  <span>GDUoS</span>
                  <span>D-loss</span>
                  <span>T-loss</span>
                  <span>RCRC</span>
                  <span>ELEXON</span>
                  <span>AAHEDC</span>
                </div>
                <div
                  className={classNames(
                    'text-ppa/grayText text-xs font-normal py-2',
                    'flex items-center justify-start gap-y-1',
                    'border-t border-t-ppa/grayBorder w-full',
                  )}
                >
                  <ChevronRightIcon
                    onClick={() =>
                      setCollapseRows({
                        ...collapseRows,
                        subsidies: !collapseRows.subsidies,
                      })
                    }
                    className={classNames(
                      transition,
                      'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                      collapseRows.subsidies && 'rotate-90',
                    )}
                  />
                  <span>{t('subsidies.title')}</span>
                </div>
                <div
                  className={classNames(
                    transition,
                    'text-ppa/grayText text-xs font-normal',
                    'flex flex-col justify-center items-start ml-8',
                    'gap-y-1 min-w-[235px]',
                    !collapseRows.subsidies &&
                      'max-h-[0px] invisible opacity-0',
                  )}
                >
                  <span>ROC ({t('subsidies.buyout')})</span>
                  <span>ROC ({t('subsidies.recycle')})</span>
                  <span>REGO</span>
                </div>

                <div
                  className={classNames(
                    'text-ppa/grayText text-xs font-normal py-2',
                    'flex items-center justify-start gap-y-1',
                    'border-t border-t-ppa/grayBorder w-full',
                  )}
                >
                  <ChevronRightIcon
                    onClick={() =>
                      setCollapseRows({
                        ...collapseRows,
                        charges: !collapseRows.charges,
                      })
                    }
                    className={classNames(
                      transition,
                      'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                      collapseRows.charges && 'rotate-90',
                    )}
                  />
                  <span>{t('charges.title')}</span>
                </div>
                <div
                  className={classNames(
                    transition,
                    'text-ppa/grayText text-xs font-normal',
                    'flex flex-col justify-center items-start ml-8',
                    'gap-y-1 min-w-[235px]',
                    !collapseRows.charges && 'max-h-[0px] invisible opacity-0',
                  )}
                >
                  <span>{t('charges.riskPremium')}</span>
                  <span>{t('charges.daDc')}</span>
                  <span>{t('charges.thirdParty')}</span>
                  <span>{t('charges.managementFee')}</span>
                  <span>{t('charges.other')}</span>
                  <span className="font-medium">{t('charges.brokerFee')}</span>
                </div>
                <div
                  className={classNames(
                    transition,
                    'text-ppa/grayText text-sm font-normal',
                    'flex flex-col justify-center items-start',
                    'gap-y-1 min-w-[235px]',
                    'border-y border-y-ppa/grayBorder w-full',
                  )}
                >
                  <span className="font-medium">{t('total.label')}</span>
                  <span>{t('total.rank')}</span>
                </div>
                <div
                  className={classNames(
                    transition,
                    'text-ppa/grayText text-sm font-normal',
                    'flex flex-col justify-center items-start',
                    'gap-y-1 min-w-[235px]',
                    'border-b border-b-ppa/grayBorder w-full',
                  )}
                >
                  <span className="font-medium">{t('total.label')}</span>
                  <span>{t('total.rankWithEstimations')}</span>
                </div>
              </div>

              {parsedBids.bids.map((bid, bidIdx) => (
                <div
                  key={bid.id}
                  className={classNames(
                    'flex flex-col',
                    bidIdx === 0 && 'ml-[280px]',
                    !disableExpired &&
                      dayjs(bid.expiresAt).isBefore(dayjs()) &&
                      'bg-[#F4F4F4]',
                  )}
                >
                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'items-center justify-end gap-y-3',
                      selectedBids.includes(bid.id) &&
                        `${defaultStyles.selectedColumn} border-t-2 border-t-ppa/secondarySelected rounded-t`,
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      ['ACTIVE', 'ACCEPTED'].includes(bid.status) &&
                        defaultStyles.titleRow,
                    )}
                  >
                    {bid.status === 'EXECUTED' && <DoneIcon className="mr-2" />}

                    <span
                      className={classNames(
                        'font-normal text-sm text-ppa/grayText',
                      )}
                    >
                      {bid.supplierTenderInvitation.supplier.name}
                    </span>
                  </div>

                  {!disableExpired && (
                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        'justify-end items-center text-sm font-light',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <ExpireTime expireAt={bid.expiresAt} />
                    </div>
                  )}

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-[49px]',
                      'text-ppa/grayText text-sm font-normal flex flex-col items-end justify-center px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>
                      {formatContractType(bid.contractTypeName) || '-'}
                    </span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px]',
                      'text-ppa/grayText text-xs font-normal flex flex-col items-end justify-center gap-y-1 border-b border-b-ppa/grayBorder pt-7',
                      parsedBids.bids.some((sleevedBid) =>
                        sleevedBid.supplierTenderBidPowerPrices.some(
                          (price) => price.name === 'MATCHED',
                        ),
                      )
                        ? 'h-24'
                        : 'h-[57px]',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span className="text-sm -mt-1">
                      {bid?.supplierTenderBidPowerPrices?.find(
                        (price) =>
                          price.name === 'FIXED' ||
                          price.name === 'PASSTHROUGH',
                      )?.value &&
                        `${
                          bid.supplierTenderBidPowerPrices.find(
                            (price) =>
                              price.name === 'FIXED' ||
                              price.name === 'PASSTHROUGH',
                          )?.value
                        } p/kWh`}
                    </span>

                    {bid?.supplierTenderBidPowerPrices?.find(
                      (price) => price.name === 'MATCHED',
                    )?.value && (
                      <span className="text-sm mt-5">
                        {`${
                          bid.supplierTenderBidPowerPrices.find(
                            (price) => price.name === 'MATCHED',
                          )?.value
                        } p/kWh`}
                      </span>
                    )}

                    {bid?.supplierTenderBidPowerPrices?.find(
                      (price) => price.name === 'UNMATCHED',
                    )?.value && (
                      <span className="text-sm">
                        {`${
                          bid.supplierTenderBidPowerPrices.find(
                            (price) => price.name === 'UNMATCHED',
                          )?.value
                        } p/kWh`}
                      </span>
                    )}
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'cursor-pointer',
                      'min-w-[235px] h-12',
                      'text-ppa/grayText text-sm font-normal flex items-center justify-end px-1.5 gap-y-1 border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>
                      {bid.forecastMatch
                        ? `${Number(bid.forecastMatch) * 100} %`
                        : '-'}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>Embedded benefits</span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-xs font-normal',
                      'flex flex-col items-end justify-center px-1.5',
                      'gap-y-1 min-w-[235px]',
                      'border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      !collapseRows.embeddedBenefits &&
                        'max-h-[0px] text-transparent',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>{bid.embeddedBenefits?.GDUOS || '-'}</span>
                    <span>{bid.embeddedBenefits?.DLOSS || '-'}</span>
                    <span>{bid.embeddedBenefits?.TLOSS || '-'}</span>
                    <span>{bid.embeddedBenefits?.RCRC || '-'}</span>
                    <span>{bid.embeddedBenefits?.ELEXON || '-'}</span>
                    <span>{bid.embeddedBenefits?.AAHEDC || '-'}</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>Subsidies</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/grayText text-xs font-normal',
                      'flex flex-col items-end justify-center px-1.5',
                      'gap-y-1 min-w-[235px]',
                      'border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      !collapseRows.subsidies && 'max-h-[0px] text-transparent',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>{bid.subsidies?.ROC_BUYOUT || '-'}</span>
                    <span>{bid.subsidies?.ROC_RECYCLE || '-'}</span>
                    <span>{bid.subsidies?.REGO || '-'}</span>
                  </div>

                  <div
                    className={classNames(
                      'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                      'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>Charges</span>
                  </div>

                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-xs font-normal',
                      'flex flex-col items-end justify-center px-1.5',
                      'gap-y-1 min-w-[235px]',
                      'border-b border-b-ppa/grayBorder',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      !collapseRows.charges && 'max-h-[0px] text-transparent',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    <span>{bid.charges?.RISK_PREMIUM || '-'}</span>
                    <span>{bid.charges?.DA_DC || '-'}</span>
                    <span>{bid.charges?.THIRD_PARTY || '-'}</span>
                    <span>{bid.charges?.MANAGEMENT_FEE || '-'}</span>
                    <span>{bid.charges?.OTHER || '-'}</span>
                    <span>{bid.charges?.BROKER_FEE || '-'}</span>
                  </div>

                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'font-semibold text-sm',
                      'items-center justify-end',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    {bid?.rank ? (
                      <span>{bid.rank}&nbsp;£/MWh</span>
                    ) : (
                      <span>{t('bidWithNoRank')}</span>
                    )}
                  </div>

                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'font-semibold text-sm',
                      'items-center justify-end',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        defaultStyles.selectedColumn,
                    )}
                  >
                    {bid?.rankWithEstimations ? (
                      <span>{bid.rankWithEstimations}&nbsp;£/MWh</span>
                    ) : (
                      <span>{t('bidWithNoRank')}</span>
                    )}
                  </div>

                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      'items-center justify-center',
                      bid.status === 'NOT_ACCEPTED' && 'bg-[#F4F4F4]',
                      bid.status === 'EXECUTED' && 'bg-[#83bb351a]',
                      selectedBids.includes(bid.id) &&
                        `${defaultStyles.selectedColumn} border-b-2 border-b-ppa/secondarySelected rounded-b`,
                    )}
                  >
                    {canSelectBids && (
                      <Checkbox
                        value={selectedBids.includes(bid.id)}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setSelectedBids([...selectedBids, bid.id])
                            return
                          }
                          setSelectedBids(
                            selectedBids.filter((item) => item !== bid.id),
                          )
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </article>

            <div className="flex items-center justify-start gap-x-5 w-full mt-5">
              {buttons &&
                buttons.map((item) => (
                  <Button
                    {...item.props}
                    key={item.text}
                    disabled={
                      selectedBids.length === 0 || selectedBids.length > 1
                    }
                    onClick={async () => {
                      if (selectedBids.length === 0) {
                        feedbackMessage(
                          {
                            title: tUtils('feedbackMessage.warn.title'),
                            description: t('noBidsSelected'),
                          },
                          'warn',
                        )
                        return
                      }
                      if (selectedBids.length > 1) {
                        feedbackMessage(
                          {
                            title: tUtils('feedbackMessage.warn.title'),
                            description: t('moreThanOneBidSelected'),
                          },
                          'warn',
                        )
                        return
                      }
                      await item.onClick(selectedBids[0])
                    }}
                  >
                    {item.text}
                  </Button>
                ))}
            </div>
          </section>
        </div>
      )}
    </div>
  )
}

export default SupplierTableBids
